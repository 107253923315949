import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState, useEffect } from 'react';
import { Box, tooltipClasses, useTheme } from '@mui/material';
import { StyledCloseButton, StyledEmailPill } from './EmailPill.styled';
import { CloseRounded as CloseIcon } from '@mui/icons-material';
import { Tooltip } from '../../../../scw-react/common/Tooltip';
import { useTranslation } from 'react-i18next';
const EmailPill = ({ email, onDelete, disabled, selected }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const emailTextRef = useRef(null);
    const [isTruncated, setIsTruncated] = useState(false);
    useEffect(() => {
        const checkTruncation = () => {
            if (emailTextRef.current) {
                const { scrollWidth, offsetWidth } = emailTextRef.current;
                setIsTruncated(scrollWidth > offsetWidth);
            }
        };
        checkTruncation();
        window.addEventListener('resize', checkTruncation);
        return () => {
            window.removeEventListener('resize', checkTruncation);
        };
    }, [email]);
    return (_jsx("div", { style: { display: 'inline-block', maxWidth: '100%' }, children: _jsxs(StyledEmailPill, { tabIndex: disabled ? -1 : 0, role: !disabled ? 'button' : undefined, "aria-label": !disabled ? t('components.molecules.emailInput.ariaLabel', { email }) : undefined, "data-disabled": disabled ? 'true' : 'false', "data-selected": selected ? 'true' : 'false', onKeyDown: (e) => {
                if (!disabled && (e.key === 'Delete' || e.key === 'Backspace')) {
                    onDelete(email);
                }
            }, sx: {
                backgroundColor: theme.palette.container.fill.card1,
                border: selected
                    ? `1px solid ${theme.palette.action.active}`
                    : `1px solid ${theme.palette.container.border.default}`,
            }, children: [_jsx(Tooltip, { title: isTruncated ? email : '', arrow: true, disableHoverListener: !isTruncated, sx: {
                        [`& .${tooltipClasses.tooltip}`]: {
                            background: theme.palette.container.fill.card1,
                            border: `1px solid ${theme.palette.container.border.default}`,
                        },
                        [`& .${tooltipClasses.arrow}`]: {
                            color: theme.palette.container.fill.card1,
                            '&::before': {
                                border: `1px solid ${theme.palette.container.border.default}`,
                            },
                        },
                    }, children: _jsx(Box, { ref: emailTextRef, sx: {
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            maxWidth: '100%',
                            marginLeft: (theme) => theme.spacing(1),
                        }, children: email }) }), _jsx(StyledCloseButton, { onClick: !disabled ? () => onDelete(email) : undefined, tabIndex: disabled ? -1 : 0, role: !disabled ? 'button' : undefined, "data-disabled": disabled ? 'true' : undefined, onKeyDown: (e) => {
                        if (!disabled && (e.key === 'Enter' || e.key === ' ')) {
                            onDelete(email);
                        }
                    }, "aria-label": !disabled ? t('components.molecules.email.removeAriaLabel', { email }) : undefined, children: _jsx(CloseIcon, { fontSize: "small" }) })] }) }));
};
export default EmailPill;
