import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useId, useState } from 'react';
import { InputAdornment, MenuItem, Typography, typographyClasses } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DoneIcon from '@mui/icons-material/Done';
import { BaseSelect } from '@securecodewarrior/design-system-react/lib/base/wanda/Select/Select';
const RenderedValue = ({ value }) => {
    const { t } = useTranslation('react_components', { keyPrefix: 'components.molecules.RoleDropdown' });
    return (_jsx(Typography, { variant: "body1", sx: ({ palette }) => ({ color: value ? 'inherit' : palette.text.disabled }), children: value !== null && value !== void 0 ? value : t('selectRole') }));
};
const StartAdornment = ({ labelId, disabled = false, text }) => (_jsx(InputAdornment, { id: labelId, position: "start", sx: ({ palette }) => (Object.assign({ pointerEvents: 'none' }, (disabled ? { color: palette.text.disabled } : {}))), children: _jsxs(Typography, { variant: "caption", children: [text, _jsx("span", { style: { color: 'red' }, children: "*" }), ":"] }) }));
const RoleDropdown = ({ role, onRoleChange, disabled, includeCompanyAdmin = true }) => {
    const { t } = useTranslation('react_components', { keyPrefix: 'components.molecules.RoleDropdown' });
    const [open, setOpen] = useState(false);
    const labelId = useId();
    const options = [
        { value: 'developer', label: t('labelDeveloper') },
        { value: 'team manager', label: t('labelTeamAdmin') },
    ];
    if (includeCompanyAdmin) {
        options.push({ value: 'company admin', label: t('labelAdmin') });
    }
    const handleChanged = (e) => {
        onRoleChange(e.target.value);
        setOpen(false);
    };
    return (_jsx(BaseSelect, { fullWidth: false, disabled: disabled, onChange: handleChanged, value: role !== null && role !== void 0 ? role : t('selectRole'), sx: { width: 333 }, SelectProps: {
            labelId,
            onOpen: () => setOpen(true),
            onClose: () => setOpen(false),
            open,
            renderValue: (selected) => { var _a; return _jsx(RenderedValue, { value: (_a = options.find((item) => item.value === selected)) === null || _a === void 0 ? void 0 : _a.label }); },
        }, InputProps: {
            startAdornment: _jsx(StartAdornment, { labelId: labelId, disabled: disabled, text: t('labelTitle') }),
        }, children: options.map((item) => {
            return (_jsxs(MenuItem, { value: item.value, disabled: item.disabled, sx: (theme) => ({
                    backgroundColor: 'transparent',
                    color: item.disabled ? theme.palette.text.disabled : theme.palette.text.soft,
                    [`& .${typographyClasses.caption}`]: { fontSize: theme.typography.pxToRem(14) },
                    '&:hover': { backgroundColor: theme.palette.action.hover },
                    '&.Mui-selected': { backgroundColor: 'transparent !important', color: theme.palette.text.primary },
                }), children: [item.label, item.value === role && (_jsx(DoneIcon, { sx: ({ palette }) => ({ fontSize: 18, color: palette.text.title, marginLeft: 'auto' }) }))] }, item.value));
        }) }));
};
export default RoleDropdown;
