import { jsx as _jsx } from "react/jsx-runtime";
import { MoreHoriz } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Tooltip } from '@securecodewarrior/design-system-react/lib/wanda';
import { useTranslation } from 'react-i18next';
export const MenuTrigger = ({ onClick, open, team, disabled }) => {
    const { t } = useTranslation('react_components', {
        keyPrefix: 'pages.administration.teamManagement.singleTeamActionMenu',
    });
    return (_jsx(Tooltip, { title: t('tooltip', { name: team.name }), arrow: true, disableHoverListener: !disabled, children: _jsx("span", { children: _jsx(IconButton, { onClick: onClick, disabled: disabled, "aria-label": 'Team more menu', "aria-controls": open ? 'team-more-menu' : undefined, "aria-haspopup": !disabled, "aria-expanded": open, sx: (theme) => (Object.assign(Object.assign({ borderRadius: theme.shape.border.radius.sm, color: theme.palette.text.body, height: theme.typography.pxToRem(26), padding: theme.spacing(1), width: theme.typography.pxToRem(26) }, (open && { border: `1px solid ${theme.palette.clickable.default}` })), { '&:hover': {
                        backgroundColor: theme.palette.container.fill.card1,
                        border: `1px solid ${theme.palette.container.border.default}`,
                    } })), children: _jsx(MoreHoriz, { sx: ({ typography }) => ({ fontSize: typography.pxToRem(18) }) }) }) }) }));
};
