var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Avatar, LinearProgress, linearProgressClasses, Skeleton, Stack, Typography, useTheme, } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Badge } from '@securecodewarrior/design-system-react/lib/wanda';
import { elbeeHighImgUrl, elbeeLowImgUrl, elbeeMediumImgUrl } from './SkillLevelImageData';
const skillPropertiesBySkillLevel = (skillLevel, theme) => {
    if (skillLevel < 500) {
        return {
            avatarShadow: undefined,
            avatarSrc: elbeeLowImgUrl,
            progressColor: theme.palette.text.status.warning,
        };
    }
    if (skillLevel > 750) {
        return {
            avatarShadow: `0px 0px 30px 0px rgba(11, 204, 226, 0.30)`,
            avatarSrc: elbeeHighImgUrl,
            progressColor: theme.palette.text.status.success,
        };
    }
    return {
        avatarShadow: undefined,
        avatarSrc: elbeeMediumImgUrl,
        progressColor: theme.palette.text.soft,
    };
};
export const SkillLevel = (_a) => {
    var { skillLevel } = _a, stackProps = __rest(_a, ["skillLevel"]);
    const theme = useTheme();
    const { t } = useTranslation();
    const skillLevelOrDefault = (skillLevel === null || skillLevel === void 0 ? void 0 : skillLevel.kind) === 'ok' ? skillLevel.trustScore : 0;
    const skillProperties = skillLevel !== undefined ? skillPropertiesBySkillLevel(skillLevelOrDefault, theme) : undefined;
    return (_jsxs(Stack, Object.assign({ direction: "row", gap: 5, width: theme.typography.pxToRem(400), alignItems: "center" }, stackProps, { children: [_jsxs(Stack, { sx: { gap: 2.75, flexGrow: 1 }, children: [_jsx(LinearProgress, { sx: (theme) => ({
                            height: theme.typography.pxToRem(10),
                            borderRadius: theme.shape.border.radius.md,
                            backgroundColor: theme.palette.container.border.default,
                            [`& .${linearProgressClasses.bar}`]: {
                                backgroundColor: skillProperties ? skillProperties.progressColor : 'transparent',
                                borderRadius: theme.shape.border.radius.md,
                            },
                        }), "aria-label": t('pages.myQuests.components.skillLevel.progressAriaLabel'), variant: "determinate", value: skillLevel !== undefined ? (skillLevelOrDefault * 100) / 1000 : 0 }), _jsx(Typography, { sx: (theme) => ({ color: theme.palette.container.border.active }), children: skillLevel === undefined ? (_jsx(Skeleton, { variant: "text", width: "80%" })) : (skillLevel === null || skillLevel === void 0 ? void 0 : skillLevel.kind) === 'ok' ? (_jsx(Trans, { i18nKey: "pages.myQuests.components.skillLevel.points", values: { points: skillLevel.trustScore, maxPoints: 1000 }, children: _jsx("b", { style: { color: theme.palette.text.body } }) })) : (_jsx(Badge, { sx: { height: 'unset' }, children: t('pages.myQuests.components.skillLevel.error') })) })] }), skillProperties ? (_jsx(Avatar, { src: skillProperties.avatarSrc, alt: t('pages.myQuests.components.skillLevel.avatarAlt'), sx: (theme) => ({
                    width: theme.typography.pxToRem(100),
                    height: theme.typography.pxToRem(100),
                    boxShadow: skillProperties.avatarShadow,
                }) })) : (_jsx(Skeleton, { variant: "circular", width: theme.typography.pxToRem(100), height: theme.typography.pxToRem(100) }))] })));
};
