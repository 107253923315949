import { z } from 'zod';
import { gridRoleEnum, roleEnum, statusEnum, userProfileSchema } from './common';
import { teamSchema } from './teamSchema';
import { sortRoles } from '../../common/utils';
const basePropertiesSchema = z
    .object({
    _cid: z.string(),
    _tid: z.string().nullish(),
    tags: z.array(z.string()).optional().default([]),
    registered: z.coerce.date().nullish(),
    lastLogin: z.coerce.date().nullish(),
    lastInvited: z.coerce.date().nullish(),
    lastReminded: z.coerce.date().nullish(),
    lastInviteBounced: z.boolean().nullish(),
    platformManaged: z.boolean().nullish().default(true),
    profile: userProfileSchema,
    team: teamSchema.optional(),
})
    .describe('This is the basis of all user properties that are fetched from the database');
const updatablePropertiesSchema = basePropertiesSchema
    .pick({ _tid: true, tags: true, profile: true })
    .partial()
    .describe('This selects (from the base user properties object) the properties that can _currently_ be updated in the UI, and makes them all options');
const userIdFields = z
    .object({ _id: z.coerce.string() })
    .describe('User _id is _always_ required, so thi this is separate to allow for partials');
const userDataFields = z
    .object({
    status: statusEnum,
    roles: z.array(roleEnum).transform(sortRoles),
    email: z.string(),
})
    .describe('The top level fields (i.e. no `properties` object) for a user. These can be `partial`ed for updates');
const fetchedPropertiesFields = z
    .object({ properties: basePropertiesSchema })
    .describe("The properties object for a user where all the fields exist and it's existence is required");
const updatePropertiesFields = z
    .object({ properties: updatablePropertiesSchema.optional() })
    .describe("The updateable properties object for a user, marked as optional so that it doesn't have to be supplied");
const fetchedUser = userIdFields
    .merge(userDataFields)
    .merge(fetchedPropertiesFields)
    .describe('The combined schemas to represent a user as fetched from the database');
const updateUser = userIdFields
    .merge(userDataFields.partial())
    .merge(updatePropertiesFields)
    .describe('The combined schemas to represent all fields of a user that can be updated');
const isGridRole = (role) => gridRoleEnum.safeParse(role).success;
const gridUser = fetchedUser
    .transform((data) => {
    var _a, _b;
    return ({
        id: data._id,
        name: `${data.properties.profile.name.first} ${data.properties.profile.name.last}`,
        createdAt: data.properties.registered,
        team: (_a = data.properties.team) === null || _a === void 0 ? void 0 : _a.name,
        lastLogin: data.properties.lastLogin,
        registered: data.properties.registered,
        lastInvited: data.properties.lastInvited,
        inviteBounce: data.properties.lastInviteBounced,
        inviteReminders: data.properties.lastReminded,
        platformManaged: data.properties.platformManaged,
        role: (_b = data.roles.filter(isGridRole)[0]) !== null && _b !== void 0 ? _b : null,
        tags: data.properties.tags || [],
        email: data.email,
        status: data.status,
    });
})
    .describe('The transformed user object as represented required for the data grid');
export const invitedUserResponseSchema = z
    .object({
    _id: z.string(),
    email: z.string(),
    status: statusEnum,
    roles: z.array(roleEnum).transform(sortRoles),
    properties: z.object({
        _tid: z.string(),
        _cid: z.string(),
        tags: z.array(z.string()),
        inviteReminders: z.array(z.coerce.date()),
        inviteBounce: z.boolean().nullable(),
        registered: z.coerce.date().nullable(),
        loginHistory: z.array(z.coerce.date()),
        inviteHistory: z.array(z.coerce.date()),
        platformManaged: z.boolean(),
        profile: userProfileSchema,
    }),
})
    .transform((data) => ({
    _id: data._id,
    email: data.email,
    status: data.status,
    roles: data.roles,
    properties: {
        profile: data.properties.profile,
        registered: data.properties.registered,
        lastLogin: data.properties.loginHistory[0],
        lastInvited: data.properties.inviteHistory[0],
        lastReminded: data.properties.inviteReminders[0],
        lastInviteBounced: data.properties.inviteBounce,
        _tid: data.properties._tid,
        _cid: data.properties._cid,
        platformManaged: data.properties.platformManaged,
        tags: data.properties.tags,
    },
}));
export const fetchedUsers = z.array(fetchedUser);
export const updatedUsers = z.array(updateUser);
export const gridUsers = z.array(gridUser);
const userDetailsProfileSchema = userProfileSchema.merge(z.object({ timezone: z.string(), isMetricsPublic: z.boolean() }));
const userDetailsPropertiesSchema = basePropertiesSchema
    .pick({ tags: true, _tid: true, _cid: true })
    .merge(z.object({ profile: userDetailsProfileSchema }))
    .describe('These are the fields that come back from the user details endpoint that are required for updating');
export const userDetailsSchema = z.object({
    _id: z.string(),
    email: z.string(),
    roles: z.array(roleEnum).transform(sortRoles),
    status: statusEnum,
    properties: userDetailsPropertiesSchema,
});
