var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { DialogActions, IconButton, Stack, Typography } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import { DataGrid } from '@securecodewarrior/design-system-react/lib/labs';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { RocketIcon } from '../../../common/icons/RocketIcon';
import { Dialog } from '../../../common/Dialog';
import { usePlatformContext } from '../../../platformContext';
import { communicationsAnalytics } from '../Communications.analytics';
export const ExecutiveSponsorEmailEditDialog = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { logAnalyticsEvent } = usePlatformContext();
    const analytics = communicationsAnalytics(logAnalyticsEvent);
    const { open, onClose, saveSubscriptions, subscriptions, isLoading } = props;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedUserIds, setSelectedUserIds] = useState([]);
    const columns = [
        {
            field: 'userName',
            headerName: `${t('pages.administration.communicationsCentre.executiveSponsorEmail.dialog.column.name')}`,
            flex: 1,
        },
        {
            field: 'userEmail',
            headerName: `${t('pages.administration.communicationsCentre.executiveSponsorEmail.dialog.column.email')}`,
            flex: 1,
        },
    ];
    const subscriptionsArray = useMemo(() => {
        return Array.isArray(subscriptions === null || subscriptions === void 0 ? void 0 : subscriptions.executiveSponsorEmails) ? subscriptions.executiveSponsorEmails : [];
    }, [subscriptions]);
    useEffect(() => {
        const initialSelectedIds = subscriptionsArray
            .filter((subscription) => subscription.enabled)
            .map((subscription) => subscription.id);
        setSelectedUserIds(initialSelectedIds);
    }, [subscriptionsArray]);
    const onSelectionUpdate = (newSelection) => {
        setSelectedUserIds(newSelection);
    };
    const handleSave = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        setIsSubmitting(true);
        (_a = subscriptions === null || subscriptions === void 0 ? void 0 : subscriptions.executiveSponsorEmails) === null || _a === void 0 ? void 0 : _a.forEach((subscription) => {
            subscription.enabled = selectedUserIds.includes(subscription.id);
        });
        if (subscriptions === null || subscriptions === void 0 ? void 0 : subscriptions.executiveSponsorEmails) {
            const mappedSubscriptions = subscriptions.executiveSponsorEmails.map((subscription) => ({
                userId: subscription.id,
                userName: subscription.userName,
                userEmail: subscription.userEmail,
                subscriptionEnabled: subscription.enabled,
            }));
            yield saveSubscriptions({ executiveSponsorEmails: mappedSubscriptions });
            analytics.executiveSponsorEmail.onEdit();
        }
        setIsSubmitting(false);
        onClose();
    });
    return (_jsxs(Dialog, { open: open, onClose: onClose, sx: {
            '& .MuiDialog-paper': {
                width: theme.spacing(200),
                paddingBlock: theme.spacing(0),
            },
        }, children: [_jsx(IconButton, { "aria-label": "close", onClick: onClose, sx: (theme) => ({
                    position: 'absolute',
                    right: theme.spacing(2),
                    top: theme.spacing(2),
                    color: theme.palette.grey[500],
                    background: theme.palette.container.fill.card2,
                    width: theme.spacing(7),
                    height: theme.spacing(7),
                }), children: _jsx(CloseIcon, {}) }), _jsxs(DialogContent, { children: [_jsxs(Stack, { gap: 5, children: [_jsx(Typography, { variant: 'h3', children: t('pages.administration.communicationsCentre.executiveSponsorEmail.dialog.edit') }), _jsx(Typography, { variant: 'subtitle1', component: 'p', sx: {
                                    color: theme.palette.text.body,
                                }, children: t('pages.administration.communicationsCentre.executiveSponsorEmail.dialog.description') })] }), _jsx(DataGrid, { sx: {
                            paddingTop: theme.spacing(8),
                        }, checkboxSelection: true, onRowSelectionModelChange: onSelectionUpdate, rowSelectionModel: selectedUserIds, variant: "pagination", dataSource: {
                            data: subscriptionsArray,
                            loading: isLoading,
                        }, name: "admin-list", columns: columns, columnVisibilityModel: { name: true, email: true }, slotProps: {
                            toolbar: {
                                csvOptions: { disableToolbarButton: true },
                            },
                        } })] }), _jsxs(DialogActions, { sx: {
                    borderTop: `1px solid ${theme.palette.container.border.default}`,
                    padding: theme.spacing(7),
                    display: 'flex',
                    justifyContent: 'space-between',
                }, children: [_jsx(Button, { variant: "text", onClick: onClose, children: t('pages.administration.communicationsCentre.executiveSponsorEmail.dialog.cancel') }), _jsx(Button, { variant: "contained", onClick: () => handleSave(), children: isSubmitting ? (_jsx(RocketIcon, { sx: { width: '100%', height: '100%' } })) : (t('pages.administration.communicationsCentre.executiveSponsorEmail.dialog.saveRecipients', {
                            count: selectedUserIds.length,
                        })) })] })] }));
};
