import { useReducer } from 'react';
const initialState = {
    emails: [],
    role: undefined,
    team: '',
    tags: [],
    openDropdown: null,
};
const reducer = (state, action) => {
    switch (action.type) {
        case 'UPDATE_EMAILS':
            return Object.assign(Object.assign({}, state), { emails: action.value });
        case 'UPDATE_ROLE':
            return Object.assign(Object.assign({}, state), { role: action.value });
        case 'UPDATE_TEAM':
            return Object.assign(Object.assign({}, state), { team: action.value, role: action.value !== '' && state.role === 'company admin' ? undefined : state.role });
        case 'UPDATE_TAGS':
            return Object.assign(Object.assign({}, state), { tags: action.value });
        case 'UPDATE_OPEN_DROPDOWN':
            return Object.assign(Object.assign({}, state), { openDropdown: action.value });
        case 'RESET_STATE':
            return initialState;
        default:
            return state;
    }
};
export const useInviteUsers = () => {
    const [state, dispatch] = useReducer(reducer, Object.assign({}, initialState));
    const handleEmailsChange = (value) => dispatch({ type: 'UPDATE_EMAILS', value });
    const handleRoleChange = (value) => dispatch({ type: 'UPDATE_ROLE', value });
    const handleTeamChange = (value) => dispatch({ type: 'UPDATE_TEAM', value });
    const handleTagChange = (value) => dispatch({ type: 'UPDATE_TAGS', value });
    const setOpenDropdown = (value) => dispatch({ type: 'UPDATE_OPEN_DROPDOWN', value });
    const resetState = () => dispatch({ type: 'RESET_STATE' });
    const isTeamDefault = state.team === '';
    return {
        state,
        isTeamDefault,
        actions: {
            handleEmailsChange,
            handleRoleChange,
            handleTeamChange,
            handleTagChange,
            setOpenDropdown,
            resetState,
        },
    };
};
