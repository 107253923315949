import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useReducer } from 'react';
const initialState = {
    teamId: null,
    teamActionType: null,
    userId: null,
    userActionType: null,
};
export const SingleActionContext = createContext({
    state: initialState,
    dispatch: () => { },
});
const singleActionContextReducer = (state, { type, payload }) => {
    switch (type) {
        case 'SET_TEAM':
            return Object.assign(Object.assign({}, state), { teamId: payload, userId: null, userActionType: null });
        case 'SET_TEAM_ACTION':
            return Object.assign(Object.assign({}, state), { teamActionType: payload, userId: null, userActionType: null });
        case 'SET_USER':
            return Object.assign(Object.assign({}, state), { userId: payload, teamId: null, teamActionType: null });
        case 'SET_USER_ACTION':
            return Object.assign(Object.assign({}, state), { userActionType: payload, teamId: null, teamActionType: null });
        case 'RESET':
            return { teamId: null, teamActionType: null, userId: null, userActionType: null };
    }
};
export const SingleActionProvider = ({ children }) => {
    const [state, dispatch] = useReducer(singleActionContextReducer, initialState);
    return _jsx(SingleActionContext.Provider, { value: { state, dispatch }, children: children });
};
