import { demoUsers } from './demoUsers';
// constants and utilities
export const USER_COUNT = 406;
export const COMPLETED_LEARNERS = 173;
export const IN_PROGRESS_LEARNERS = 144;
export const NOT_STARTED_LEARNERS = 28;
export const MATCHED_LEARNERS = COMPLETED_LEARNERS + IN_PROGRESS_LEARNERS + NOT_STARTED_LEARNERS;
export const PENDING_LEARNERS = 24;
export const EXCLUDED_LEARNERS = 37;
export const TOTAL_LEARNERS = MATCHED_LEARNERS + PENDING_LEARNERS + EXCLUDED_LEARNERS;
export const NO_ACTIVITY_USER_COUNT = 50;
const getLastActivityDate = (trustScore) => {
    if (trustScore > 0) {
        return new Date(Date.now() - Math.floor(Math.random() * (1000 * 60 * 60 * 24 * 365) * (trustScore < 140 ? 2 : 1.05)))
            .toISOString()
            .split('T')[0];
    }
    return null;
};
// Create the user list with reporting data
const createReportingDemoUser = () => demoUsers.map((user) => (Object.assign(Object.assign({}, user), { lastActivityDate: getLastActivityDate(user.trustScore), lastLoggedIn: new Date(Date.now() - Math.floor(Math.random() * 1000 * 60 * 60 * 24 * 365))
        .toISOString()
        .split('T')[0] })));
export const userList = createReportingDemoUser();
