var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createTRPCProxyClient, httpBatchLink, httpLink, splitLink } from '@trpc/client';
import { createContext, useContext, useCallback, useState } from 'react';
import superjson from 'superjson';
import { wrapPromise } from '@securecodewarrior/design-system-react';
import { isDemoModeEnabled } from './demo/isDemoModeEnabled';
import { isPathMocked } from './demo/trpc/types';
//need to find out how to init properly while avoiding null
export const ApiContext = createContext(undefined);
const withMockSplitLink = (link) => {
    return splitLink({
        condition: (op) => {
            if (!isDemoModeEnabled() || !!op.context.skipDemo) {
                return false;
            }
            return isPathMocked(op.path);
        },
        false: link, // when there is no mock data, use the normal link
        true: httpLink({
            url: '',
            headers: () => ({}),
            fetch: (input) => __awaiter(void 0, void 0, void 0, function* () {
                const procedureName = input.toString().split('?')[0].split('/').pop();
                if (!procedureName) {
                    throw new Error('Invalid procedure name');
                }
                if (!isPathMocked(procedureName)) {
                    throw new Error('Invalid mocked procedure name');
                }
                const { mockData } = yield import('./demo/trpc/data');
                return Promise.resolve(new Response(JSON.stringify({ result: { data: mockData[procedureName] } }), { status: 200 }));
            }),
        }),
    });
};
export const createApiClient = (ctx) => {
    return createTRPCProxyClient({
        transformer: superjson,
        links: [
            splitLink({
                // check for context property `skipBatch`
                condition: (op) => !!op.context.skipBatch,
                // when condition is true, use normal request
                true: withMockSplitLink(httpLink({
                    url: `${ctx.apiEndpoint}/rpc`,
                    headers: () => ({ 'X-Session-ID': ctx.sessionId }),
                })),
                false: withMockSplitLink(httpBatchLink({
                    url: `${ctx.apiEndpoint}/rpc`,
                    headers: () => ({ 'X-Session-ID': ctx.sessionId }),
                })),
            }),
        ],
    });
};
export const useApiClient = () => {
    const ctx = useContext(ApiContext);
    if (!ctx) {
        throw new Error(`this component requires the api context to be present`);
    }
    const [_, setError] = useState();
    const handleErrorPromise = useCallback((promise, fulfilledFn) => {
        return promise
            .then((data) => {
            assertNonErrorResponse(data);
            return fulfilledFn === null || fulfilledFn === void 0 ? void 0 : fulfilledFn(data);
        })
            .catch((e) => {
            setError(() => {
                throw e;
            });
        });
    }, []);
    const wrapErrorPromise = useCallback((promise) => {
        return wrapPromise(promise.then((data) => {
            assertNonErrorResponse(data);
            return data;
        }));
    }, []);
    const trpc = createApiClient(ctx);
    return { trpc, wrapErrorPromise, handleErrorPromise };
};
export const useFetchWithPlatformSessionHeader = () => {
    const ctx = useContext(ApiContext);
    if (!ctx) {
        throw new Error(`this component requires the api context to be present`);
    }
    // We return a fairly standard DOM Fetch function, except that it automatically adds
    // the Platform Session ID header (X-Session-ID) to *every* request.
    const fetchWithSession = useCallback((input, init) => {
        const newInit = Object.assign(Object.assign({}, init), { headers: Object.assign(Object.assign({}, init === null || init === void 0 ? void 0 : init.headers), { 'X-Session-ID': ctx.sessionId }) });
        return window.fetch(input, newInit);
    }, [ctx.sessionId]);
    return { fetchWithSession, apiEndpoint: ctx.apiEndpoint };
};
const isErrorResponse = (data) => {
    return typeof data === 'object' && data !== null && 'kind' in data && data.kind === 'error';
};
function assertNonErrorResponse(value) {
    if (isErrorResponse(value)) {
        throw new Error(value.message);
    }
}
