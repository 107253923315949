import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Trans, useTranslation } from 'react-i18next';
import { Typography, Stack } from '@mui/material';
import { Button, TextArea, Tooltip } from '@securecodewarrior/design-system-react/lib/wanda';
import { ContentCopyOutlined, CheckCircleRounded as SuccessIcon } from '@mui/icons-material';
import { UserManagementModal } from '../UserManagementModal';
import TeamDropdown from '../../../../../../common/components/molecules/TeamDropdown';
import { useState } from 'react';
import { useTeams } from '../../../hooks/useTeams';
import { DialogSection } from '../../../../../common/Dialog';
import { CloseIconButton } from '../../../../../../common/components/atoms/CloseIconButton';
import { BulkActionsAsyncDialog } from '../BulkActionsAsyncDialog';
export const ChangeTeamsDialog = ({ onClose, onConfirm, userCount, updatedCount, goneAsync, companyAdminEmails, }) => {
    const { teams } = useTeams();
    const [selectedTeam, setSelectedTeam] = useState();
    return goneAsync ? (_jsx(BulkActionsAsyncDialog, { onClose: onClose })) : updatedCount || updatedCount === 0 ? (_jsx(ChangeTeamsSuccess, { userCount: userCount, updatedCount: updatedCount, onClose: onClose, teamName: selectedTeam === null || selectedTeam === void 0 ? void 0 : selectedTeam.teamName, companyAdminEmails: companyAdminEmails })) : (_jsx(ChangeTeamsPending, { userCount: userCount, onConfirm: (props) => {
            onConfirm(props);
        }, onClose: onClose, teams: teams, selectedTeam: selectedTeam, setSelectedTeam: setSelectedTeam }));
};
const ChangeTeamsPending = ({ onClose, onConfirm, userCount, selectedTeam, setSelectedTeam, teams, }) => {
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.userManagement' });
    const handleTeamChange = (teamId) => {
        const team = teams.find((team) => team._id === teamId);
        if (team) {
            setSelectedTeam({
                teamId,
                teamName: team.name || '',
            });
        }
    };
    return (_jsxs(_Fragment, { children: [_jsxs(DialogSection, { children: [_jsxs(Stack, { gap: 6, children: [_jsx(Typography, { variant: "h3", children: t('changeTeamsDialog.title', { count: userCount }) }), _jsxs(Stack, { gap: 6, children: [_jsx(Typography, { children: t('changeTeamsDialog.description') }), _jsx(TeamDropdown, { team: selectedTeam === null || selectedTeam === void 0 ? void 0 : selectedTeam.teamId, placeholder: t('changeTeamsDialog.selectTeamPlaceholder'), onTeamChange: handleTeamChange, teams: teams, isRoleAdmin: false })] })] }), _jsx(Typography, { variant: "caption", children: "Company administrators can not be part of a team and will be ignored." }), _jsx(CloseIconButton, { onClick: onClose, sx: {
                            position: 'absolute',
                            background: 'transparent',
                            top: (theme) => theme.spacing(3),
                            right: (theme) => theme.spacing(3),
                            '&:hover': {
                                background: 'transparent',
                            },
                        } })] }), _jsx(DialogSection, { footer: true, children: _jsxs(Stack, { direction: "row", justifyContent: "space-between", children: [_jsx(Button, { variant: "outlined", onClick: onClose, children: t('common.cancel') }), _jsx(Button, { variant: "contained", onClick: () => {
                                onConfirm(selectedTeam || { teamId: '', teamName: '' });
                            }, disabled: !selectedTeam, children: t('changeTeamsDialog.confirm') })] }) })] }));
};
const ChangeTeamsSuccess = ({ onClose, userCount, updatedCount, teamName, companyAdminEmails = [], }) => {
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.userManagement' });
    const notUpdatedCount = userCount - (updatedCount || 0);
    const [showCopiedTooltip, setShowCopiedTooltip] = useState(false);
    const title = (_jsx(Stack, { alignItems: "center", textAlign: "center", children: _jsx(Trans, { i18nKey: 'pages.administration.userManagement.changeTeamsDialog.successTitle', values: { count: updatedCount, teamName: teamName }, components: { br: _jsx("br", {}) } }) }));
    const handleCopyEmails = () => {
        navigator.clipboard
            .writeText(companyAdminEmails.join('\n'))
            .then(() => {
            setShowCopiedTooltip(true);
            setTimeout(() => {
                setShowCopiedTooltip(false);
            }, 2000);
        })
            .catch((err) => {
            console.error('Failed to copy text: ', err);
        });
    };
    return (_jsx(UserManagementModal, { title: title, icon: _jsx(SuccessIcon, { sx: { fontSize: '56px', color: (theme) => theme.palette.text.status.success } }), footer: _jsx(Stack, { direction: "row", justifyContent: "flex-end", children: _jsx(Button, { variant: "contained", onClick: onClose, children: t('common.okay') }) }), children: _jsxs(Stack, { gap: 5, children: [notUpdatedCount > 0 && (_jsx(Typography, { children: t('changeTeamsDialog.partialSuccess', {
                        count: notUpdatedCount,
                    }) })), companyAdminEmails.length > 0 && (_jsxs(Stack, { gap: 2, alignItems: "flex-start", direction: "row", children: [_jsx(TextArea, { rows: 3, hiddenLabel: true, sx: {
                                width: '400px',
                                '& .MuiInputBase-root .MuiInputBase-inputMultiline': {
                                    paddingTop: '0px',
                                },
                            }, value: companyAdminEmails.join('\n') }), _jsx(Tooltip, { title: showCopiedTooltip ? 'Copied!' : 'Copy to clipboard', children: _jsx(Button, { centerIcon: _jsx(ContentCopyOutlined, {}), variant: "outlined", onClick: handleCopyEmails }) })] }))] }) }));
};
