var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMemo } from 'react';
import { ZodError } from 'zod';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { useTrpc } from '../../common/hooks';
import { invitedUserResponseSchema } from '../schema/user';
import { useUsersQueryOptions } from './useUsersQueryOptions';
export const useUsers = () => {
    var _a;
    const { ctx } = useTrpc();
    const { config } = useUsersQueryOptions();
    const client = useQueryClient();
    const { data: users, isLoading, isError: isQueryError, error: queryError, refetch } = useQuery(config);
    const { mutateAsync: inviteUsers, isError: isInvitingError, isPending: isInviting, error: invitingError, } = useMutation({
        mutationFn: (data) => __awaiter(void 0, void 0, void 0, function* () {
            var _b;
            const usersToCreate = data.emails.map((email) => ({
                email,
                roles: [data.role],
                properties: {
                    tags: data.tags,
                    _tid: data.team,
                    _cid: data._cid,
                    provision: { type: 'invite: manual' },
                },
            }));
            const created = [];
            const failed = [];
            for (const user of usersToCreate) {
                try {
                    const res = yield fetch(new URL('management/admin/users/invite', ctx === null || ctx === void 0 ? void 0 : ctx.apiEndpoint), {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json', 'X-Session-ID': (_b = ctx === null || ctx === void 0 ? void 0 : ctx.sessionId) !== null && _b !== void 0 ? _b : '' },
                        body: JSON.stringify(user),
                    });
                    if (!res.ok) {
                        const errorBody = yield res.json().catch(() => ({ error: 'Failed to parse error body' }));
                        failed.push({ email: user.email, error: errorBody.error });
                        continue;
                    }
                    const response = yield res.json();
                    const parsed = invitedUserResponseSchema.parse(response);
                    created.push(parsed);
                }
                catch (err) {
                    // If we fail parsing, then something has gone wrong, but the user has been created, so we'll just add them to the list
                    if (err instanceof ZodError) {
                        created.push({
                            _id: user.email,
                            email: user.email,
                            roles: user.roles,
                            status: 'invited',
                            properties: Object.assign(Object.assign({}, user.properties), { profile: { name: { first: '', last: '', middle: '' } }, platformManaged: true }),
                        });
                        continue;
                    }
                    failed.push({ email: user.email, error: err });
                }
            }
            return { created, failed };
        }),
        onMutate: (data) => __awaiter(void 0, void 0, void 0, function* () {
            yield client.cancelQueries(config);
            const previous = client.getQueryData(config.queryKey);
            const newUsers = data.emails.map((email) => ({
                _id: email,
                email: email,
                roles: [data.role],
                status: 'invited',
                properties: {
                    tags: data.tags,
                    _tid: data.team,
                    _cid: data._cid,
                    platformManaged: true,
                    profile: { name: { first: '', middle: '', last: '' } },
                },
            }));
            if (!previous) {
                client.setQueryData(config.queryKey, newUsers);
                return { previous: [] };
            }
            client.setQueryData(config.queryKey, [...newUsers, ...previous]);
            return { previous };
        }),
        onError: (_err, _variables, ctx) => {
            if (ctx === null || ctx === void 0 ? void 0 : ctx.previous) {
                client.setQueryData(config.queryKey, ctx.previous);
            }
        },
        onSettled: () => {
            client.invalidateQueries(config);
        },
    });
    const tags = (_a = useMemo(() => Array.from(new Set(users === null || users === void 0 ? void 0 : users.map((user) => user.properties.tags).flat())), [users])) !== null && _a !== void 0 ? _a : [];
    return {
        users: users !== null && users !== void 0 ? users : [],
        tags,
        inviteUsers,
        isLoading,
        isInviting,
        error: queryError !== null && queryError !== void 0 ? queryError : invitingError,
        isQueryError,
        isInvitingError,
        refetch,
    };
};
