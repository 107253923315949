import { styled, Paper, Box } from '@mui/material';
export const DialogSection = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'footer',
})(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    ['&:only-child']: {
        padding: theme.spacing(0, 7, 0, 7),
    },
}), (props) => ({
    padding: props.footer ? props.theme.spacing(7, 7, 0, 7) : props.theme.spacing(0, 7, 7, 7),
    borderTop: props.footer ? `1px solid ${props.theme.palette.container.border.default}` : 'none',
}));
export const DialogContainer = styled(Paper)(({ theme }) => ({
    background: theme.palette.container.fill.card1,
    borderRadius: theme.spacing(3),
    border: theme.palette.container.border.default,
    paddingBlock: theme.spacing(8),
    width: '500px',
    position: 'relative',
}));
