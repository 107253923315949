import { getDaysDelta } from '../utils';
export const listQuestAnalytics = (sendEvent) => ({
    onSeeReportsClicked: (currentQuestCount) => {
        sendEvent({ event: 'See Reports Clicked', scope: 'quests-admin-overview', currentQuestCount });
    },
    onCreateNewClicked: (currentQuestCount, currentDraftCount, kind) => {
        const centerButton = currentQuestCount === 0 && currentDraftCount === 0;
        sendEvent({
            event: 'Create New Quest Clicked',
            scope: 'quests-admin-overview',
            currentQuestCount,
            centerButton,
            kind,
        });
    },
    onViewQuestClicked: (quest) => {
        var _a;
        const eventBase = {
            event: 'View Quest Clicked',
            scope: 'quests-admin-overview',
            questStatus: quest.status,
            completionPercentage: (_a = quest.completionRate) !== null && _a !== void 0 ? _a : 0,
            participantCount: Number(quest.participantCount),
        };
        if (quest.kind === 'lms') {
            sendEvent(Object.assign(Object.assign({}, eventBase), { kind: 'lms' }));
        }
        else {
            const startDateDeltaInDays = getDaysDelta(quest.startDate, new Date());
            const endDateDeltaInDays = getDaysDelta(quest.endDate, new Date());
            sendEvent(Object.assign(Object.assign({}, eventBase), { kind: 'platform', startDateDeltaInDays,
                endDateDeltaInDays }));
        }
    },
    onSeeQuestReportClicked: (quest) => {
        var _a;
        const eventBase = {
            event: 'See Quest Report Clicked',
            scope: 'quests-admin-overview',
            questStatus: quest.status,
            completionPercentage: (_a = quest.completionRate) !== null && _a !== void 0 ? _a : 0,
            participantCount: Number(quest.participantCount),
        };
        if (quest.kind === 'lms') {
            sendEvent(Object.assign(Object.assign({}, eventBase), { kind: 'lms' }));
        }
        else {
            const startDateDeltaInDays = getDaysDelta(quest.startDate, new Date());
            const endDateDeltaInDays = getDaysDelta(quest.endDate, new Date());
            sendEvent(Object.assign(Object.assign({}, eventBase), { kind: 'platform', startDateDeltaInDays,
                endDateDeltaInDays }));
        }
    },
    onEditDraftClicked: (draft) => {
        const draftAgeInDays = getDaysDelta(new Date(), draft.createdAt);
        sendEvent({
            event: 'Edit Draft Clicked',
            scope: 'quests-admin-overview',
            draftAgeInDays,
            kind: draft.kind,
        });
    },
    onQuestsListTabFiltered: (filterStatus, resultCount, totalCount) => {
        sendEvent({
            event: 'Quests List Tab Filtering',
            scope: 'quests-admin-overview',
            filterStatus,
            resultCount,
            totalCount,
        });
    },
});
