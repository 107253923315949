var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useTeam } from '../../../hooks/useTeam';
import { TeamFormModal } from '../TeamFormModal';
export const getAllowances = (size, team) => {
    const teamSize = typeof size === 'number' ? size : 0;
    return {
        allowances: Object.assign(Object.assign({}, team.allowances), { training: Object.assign({ license: teamSize === 0 ? 'unlimited' : 'usage' }, (teamSize > 0 ? { granted: teamSize } : {})) }),
    };
};
const EditModal = ({ team, isLoading = false, onConfirm, onClose }) => {
    var _a, _b, _c, _d;
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.teamManagement' });
    const initialState = {
        teamName: (team === null || team === void 0 ? void 0 : team.name) || '',
        maximumTeamSize: ((_a = team === null || team === void 0 ? void 0 : team.allowances) === null || _a === void 0 ? void 0 : _a.training.license) === 'unlimited' ? 0 : ((_b = team === null || team === void 0 ? void 0 : team.allowances) === null || _b === void 0 ? void 0 : _b.training.granted) || 0,
        activationDate: (team === null || team === void 0 ? void 0 : team.activationDate) ? dayjs(team.activationDate) : undefined,
        expirationDate: (team === null || team === void 0 ? void 0 : team.expirationDate) ? dayjs(team.expirationDate) : undefined,
    };
    const handleSubmit = (formState) => __awaiter(void 0, void 0, void 0, function* () {
        const { teamName, maximumTeamSize, activationDate, expirationDate } = formState;
        if (!team)
            return;
        const payload = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, team), { _id: team._id, name: teamName.trim() }), getAllowances(maximumTeamSize, team)), (activationDate ? { activationDate: activationDate.toDate() } : {})), (expirationDate ? { expirationDate: expirationDate.toDate() } : {}));
        onConfirm(payload);
    });
    return (_jsx(TeamFormModal, Object.assign({ onClose: onClose, onSubmit: handleSubmit, initialState: initialState, title: t('editTeamModal.title'), description: t('editTeamModal.description'), submitButtonText: t('editTeamModal.submitButtonText'), isLoading: isLoading }, ((team === null || team === void 0 ? void 0 : team.allowances)
        ? { currentTeamSize: ((_c = team.allowances.training.used) !== null && _c !== void 0 ? _c : 0) + ((_d = team.allowances.training.reserved) !== null && _d !== void 0 ? _d : 0) }
        : {}))));
};
export const SingleTeamEditDialog = ({ onClose, onConfirm, teamId }) => {
    const { team, isLoading } = useTeam(teamId);
    const handleConfirm = (data) => __awaiter(void 0, void 0, void 0, function* () {
        yield onConfirm(data);
    });
    return _jsx(EditModal, { team: team, isLoading: isLoading, onClose: onClose, onConfirm: handleConfirm });
};
