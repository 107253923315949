var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button, TextInput } from '@securecodewarrior/design-system-react/lib/wanda';
import { useState } from 'react';
import { UserDialog } from '../../styled/UserDialog';
import { useUpdateUser } from '../../hooks/useUpdateUser';
import RoleDropdown from '../../../../../common/components/molecules/RoleDropdown';
import TeamDropdown from '../../../../../common/components/molecules/TeamDropdown';
import { TagInput } from '../../../../common/TagInput/TagInput';
import { CloseIconButton } from '../../../../../common/components/atoms/CloseIconButton';
import { validateEmail } from '../../../../../portal-frontend/components/emailValidation';
import { useTeams } from '../../hooks/useTeams';
import { useUsers } from '../../hooks/useUsers';
const EditUserModal = ({ id, isOpen, onClose }) => {
    const { state, dirty, actions } = useUpdateUser(id);
    const { teams } = useTeams();
    const { tags } = useUsers();
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.userManagement.editUserDialog' });
    const [isEmailValid, setIsEmailValid] = useState(true);
    const handleEmailChange = (event) => {
        const email = event.target.value;
        actions.setEmail(event);
        setIsEmailValid(validateEmail(email));
    };
    const handleSave = () => __awaiter(void 0, void 0, void 0, function* () {
        const success = yield actions.handleSave();
        if (success) {
            onClose();
        }
    });
    const isDisabled = (state.role !== 'company admin' && !state._tid) || !dirty || !state.role || !isEmailValid;
    if (!state) {
        alert('User not found');
        return null;
    }
    return (_jsxs(UserDialog, { open: isOpen, onClose: onClose, children: [_jsx(CloseIconButton, { onClick: onClose, sx: {
                    position: 'absolute',
                    background: 'transparent',
                    top: (theme) => theme.spacing(3),
                    right: (theme) => theme.spacing(3),
                    '&:hover': { background: 'transparent' },
                } }), _jsxs(Stack, { paddingY: 8, children: [_jsxs(Stack, { paddingX: 7, paddingBottom: 8, gap: 6, children: [_jsx(Typography, { variant: "h3", children: t('dialogTitle') }), _jsxs(Stack, { gap: 3, children: [_jsx(Typography, { variant: "body1", children: t('personalInfo') }), _jsx(TextInput, { label: t('firstName'), value: state.firstName, onChange: actions.setFirstName }), _jsx(TextInput, { label: t('lastName'), value: state.lastName, onChange: actions.setLastName }), _jsx(TextInput, { label: t('email'), value: state.email, onChange: handleEmailChange, error: !isEmailValid }), !isEmailValid && (_jsx(Typography, { variant: "body2", color: "error", children: t('invalidEmailMessage', { defaultValue: 'Invalid email address' }) }))] }), _jsxs(Stack, { gap: 3, children: [_jsx(Typography, { variant: "body1", children: t('details') }), _jsx(RoleDropdown, { role: state.role, onRoleChange: actions.setRole }), _jsx(TeamDropdown, { team: state._tid, onTeamChange: actions.setTeam, teams: teams, isRoleAdmin: state.role === 'company admin' }), _jsx(TagInput, { value: state.tags, options: tags, onChange: actions.setTags, disabled: false })] })] }), _jsxs(Stack, { direction: "row", justifyContent: "space-between", alignItems: "center", borderTop: ({ palette }) => `1px solid ${palette.container.border.default}`, paddingTop: 7, paddingX: 7, children: [_jsx(Button, { variant: "outlined", onClick: onClose, children: t('cancelButton') }), _jsx(Button, { variant: "contained", onClick: handleSave, disabled: isDisabled, children: t('saveButton') })] })] })] }));
};
export default EditUserModal;
