import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from '@mui/system';
import { forwardRef } from 'react';
import { autocompleteClasses, useTheme } from '@mui/material';
export const TagInputListbox = forwardRef((props, ref) => {
    const theme = useTheme();
    return (_jsx(Box, Object.assign({ ref: ref, role: "listbox", sx: {
            background: theme.palette.container.fill.card1,
            borderRadius: `0 0 ${theme.shape.borderRadius * 2}px ${theme.shape.borderRadius * 2}px`,
            [`&.${autocompleteClasses.listbox} .${autocompleteClasses.option}`]: {
                justifyContent: 'space-between',
                svg: {
                    color: 'transparent',
                    fontSize: theme.typography.pxToRem(18),
                },
                '&:hover, &.Mui-focusVisible, &.Mui-focused': {
                    background: `${theme.palette.container.fill.card2}`,
                },
                '&[aria-selected="true"]': {
                    background: 'unset',
                    color: theme.palette.text.title,
                    svg: {
                        color: 'inherit',
                    },
                    '&:hover, &.Mui-focusVisible, &.Mui-focused': {
                        background: `${theme.palette.container.fill.card2}`,
                    },
                },
            },
        } }, props, { children: props.children })));
});
TagInputListbox.displayName = 'TagInputListbox';
