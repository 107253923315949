var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, debounce, InputAdornment, Stack, Typography } from '@mui/material';
import { useSkeletonAccessibilityAttr, wrapPromise, Container, Suspenser, } from '@securecodewarrior/design-system-react';
import { Link, OptionSelect, PaginatedFilterList, TextInput } from '@securecodewarrior/design-system-react/lib/legacy';
import { Search as SearchIcon } from '@mui/icons-material';
import React, { useRef, useState, useMemo, useEffect } from 'react';
import { visuallyHidden } from '@mui/utils';
import { useTranslation } from 'react-i18next';
import { AssessmentsTableCardSkeleton } from './AssessmentsTableCardSkeleton';
import { AdminGrid, DeveloperGrid, TableCard } from './AssessmentsTableCard';
import { VIEWS } from '../../view';
import { setAssessmentDocumentTitle } from '../../../utils/documentTitle';
const SORT_OPTIONS = {
    name_asc: 'pages.assessments.list.sortOptions.nameAsc',
    name_desc: 'pages.assessments.list.sortOptions.nameDesc',
    numberOfChallenges_asc: 'pages.assessments.list.sortOptions.numberOfChallengesAsc',
    numberOfChallenges_desc: 'pages.assessments.list.sortOptions.numberOfChallengesDesc',
    timelimit_asc: 'pages.assessments.list.sortOptions.timeLimitAsc',
    timelimit_desc: 'pages.assessments.list.sortOptions.timeLimitDesc',
    difficulty_asc: 'pages.assessments.list.sortOptions.difficultyAsc',
    difficulty_desc: 'pages.assessments.list.sortOptions.difficultyDesc',
};
const STATUS_OPTIONS = {
    all: 'pages.assessments.list.statusAll',
    enabled: 'common.statusActive',
    disabled: 'pages.assessments.list.statusSuperseded',
    closed: 'common.statusClosed',
};
const GROUP_ALL = '100';
const GROUP_UNASSIGNED = '200';
export const AssessmentList = (props) => {
    const { t } = useTranslation();
    useEffect(() => setAssessmentDocumentTitle(t, undefined), [t]);
    return (_jsxs(_Fragment, { children: [_jsxs(Box, { component: "header", py: 4, px: 10, borderBottom: 1, borderColor: (theme) => theme.palette.grayscale.bg4, children: [_jsx(Typography, { variant: "h1", children: t('pages.assessments.common.title') }), _jsx(Typography, { variant: "body2", children: t('pages.assessments.common.subtitle') })] }), _jsxs(Container, { maxWidth: "lg", children: [_jsxs(Box, { sx: visuallyHidden, children: [_jsx(Typography, { component: "h2", children: t('common.pages.about.accessibility') }), _jsx(Typography, { children: t('pages.assessments.list.accessibility.info') })] }), _jsxs(Box, { borderRadius: 2, bgcolor: (theme) => theme.palette.grayscale.bg3, p: 5, mb: 8, children: [_jsx(Typography, { variant: "h5", component: "h2", pb: 4, children: t('common.pages.about.title') }), _jsx(Typography, { variant: "body1", pb: 4, children: t('pages.assessments.list.pageDescription.developer') }), _jsx(Typography, { variant: "body1", children: t('pages.assessments.list.pageDescription.generic') })] }), _jsx(AssessmentsTable, Object.assign({}, props))] })] }));
};
const LevelGroupsOptionSelect = (props) => {
    const { t } = useTranslation();
    const levelGroupOptions = useMemo(() => (Object.assign({ [GROUP_ALL]: t('pages.assessments.list.allGroups'), [GROUP_UNASSIGNED]: t('pages.assessments.list.unassigned') }, props.groups)), [props.groups, t]);
    return (_jsx(OptionSelect, { onChange: props.onGroupChange, label: t('common.filtering.group.label'), value: props.group, options: levelGroupOptions }));
};
const AssessmentsTable = (props) => {
    var _a, _b, _c;
    const { t } = useTranslation();
    const { onSearchChange, onReset } = props, rest = __rest(props, ["onSearchChange", "onReset"]);
    const [search, setSearch] = useState((_a = props.defaultSearch) !== null && _a !== void 0 ? _a : '');
    const nrOfSkeletons = useRef((_c = (_b = props.paginationInfo) === null || _b === void 0 ? void 0 : _b.perPage) !== null && _c !== void 0 ? _c : 10);
    useEffect(() => {
        props.assessments.then((r) => {
            nrOfSkeletons.current = Math.max(r.length, 1);
        });
    });
    const handleSearchChanged = (e) => {
        setSearch(e.target.value);
        notifySearchChanged(e);
    };
    const notifySearchChanged = React.useMemo(() => debounce((e) => onSearchChange === null || onSearchChange === void 0 ? void 0 : onSearchChange(e.target.value), 500), [onSearchChange]);
    const clearFilters = () => {
        setSearch('');
        onReset === null || onReset === void 0 ? void 0 : onReset();
    };
    const listToRender = React.useMemo(() => (_jsxs(_Fragment, { children: [props.view === VIEWS.DEVELOPER && (_jsx(DeveloperAssessmentsList, Object.assign({}, rest, { skeletonSize: nrOfSkeletons.current, onReset: clearFilters, assessmentsReader: wrapPromise(props.assessments) }))), props.view === VIEWS.COMPANY_ADMIN && (_jsx(AdminAssessmentsList, Object.assign({}, rest, { skeletonSize: nrOfSkeletons.current, onReset: clearFilters, assessmentsReader: wrapPromise(props.assessments) })))] })), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.assessments, props.view]);
    return (_jsxs(_Fragment, { children: [_jsx(Typography, { variant: "h2", sx: visuallyHidden, children: t('pages.assessments.list.assessmentsList') }), _jsx(PaginatedFilterList, { filtersRenderer: _jsxs(Stack, { direction: "row", alignItems: "center", gap: 2, mb: 4, sx: {
                        '& > *': {
                            flexGrow: 1,
                            flexBasis: 0,
                        },
                    }, children: [_jsx(TextInput, { size: "small", placeholder: t('common.filtering.search.placeholder'), onChange: handleSearchChanged, value: search, InputProps: {
                                startAdornment: (_jsx(InputAdornment, { position: "start", children: _jsx(SearchIcon, {}) })),
                            } }), 'group' in props && _jsx(LevelGroupsOptionSelect, Object.assign({}, props)), _jsx(OptionSelect, { onChange: props.onStatusChange, label: t('common.labels.status'), value: props.status, displayFn: t, options: STATUS_OPTIONS }), _jsx(OptionSelect, { onChange: props.onSortChange, label: t('common.sorting.label'), value: props.sort, displayFn: t, options: SORT_OPTIONS }), _jsx(Box, { children: _jsx(Link, { onClick: clearFilters, children: t('common.labels.clearAll') }) })] }), itemsRenderer: listToRender, paginationInfo: props.paginationInfo, listOfItems: props.assessments, liveStatusText: {
                    fetching: 'pages.assessments.list.accessibility.fetchingAssessments',
                    found: 'pages.assessments.list.accessibility.foundAssessments',
                }, onReset: clearFilters })] }));
};
const AdminAssessmentsList = (props) => {
    const { t } = useTranslation();
    return (_jsx(Suspenser, { skeleton: _jsx(AssessmentsTableSkeleton, { amount: props.skeletonSize, columns: 3 }), reader: props.assessmentsReader, render: (assessments) => (_jsx(_Fragment, { children: _jsxs(Box, { role: "table", children: [_jsxs(Box, { sx: visuallyHidden, role: "row", children: [_jsx(Box, { role: "columnheader", children: t('common.labels.title') }), _jsx(Box, { role: "columnheader", children: t('common.labels.description') }), _jsx(Box, { role: "columnheader", children: t('common.labels.status') }), _jsx(Box, { role: "columnheader", children: t('entities.assessments.challenges') }), _jsx(Box, { role: "columnheader", children: t('entities.assessments.completedAverageScore') }), _jsx(Box, { role: "columnheader", children: t('entities.assessments.selfAssess') }), _jsx(Box, { role: "columnheader", children: t('entities.assessments.timeLimit') }), _jsx(Box, { role: "columnheader", children: t('entities.assessments.completedAverageTime') }), _jsx(Box, { role: "columnheader", children: t('entities.assessments.fixedChallenges') }), _jsx(Box, { role: "columnheader", children: t('entities.assessments.difficulty') }), _jsx(Box, { role: "columnheader", children: t('entities.assessments.completionRatio') }), _jsx(Box, { role: "columnheader", children: t('entities.assessments.retriesAllowed') })] }), assessments.map((assessment, index) => (_createElement(TableCard, Object.assign({}, assessment, { key: index, renderGrid: () => _jsx(AdminGrid, Object.assign({}, assessment)) }))))] }) })) }));
};
const DeveloperAssessmentsList = (props) => {
    const { t } = useTranslation();
    return (_jsx(Suspenser, { skeleton: _jsx(AssessmentsTableSkeleton, { amount: props.skeletonSize, columns: 2 }), reader: props.assessmentsReader, render: (assessments) => (_jsx(_Fragment, { children: _jsxs(Box, { role: "table", children: [_jsxs(Box, { sx: visuallyHidden, role: "row", children: [_jsx(Box, { role: "columnheader", children: t('common.labels.title') }), _jsx(Box, { role: "columnheader", children: t('common.labels.description') }), _jsx(Box, { role: "columnheader", children: t('common.labels.status') }), _jsx(Box, { role: "columnheader", children: t('entities.assessments.challenges') }), _jsx(Box, { role: "columnheader", children: t('entities.assessments.selfAssess') }), _jsx(Box, { role: "columnheader", children: t('entities.assessments.timeLimit') }), _jsx(Box, { role: "columnheader", children: t('entities.assessments.fixedChallenges') }), _jsx(Box, { role: "columnheader", children: t('entities.assessments.difficulty') }), _jsx(Box, { role: "columnheader", children: t('entities.assessments.retriesAllowed') })] }), assessments.map((assessment, index) => (_createElement(TableCard, Object.assign({}, assessment, { key: index, renderGrid: () => _jsx(DeveloperGrid, Object.assign({}, assessment)) }))))] }) })) }));
};
const AssessmentsTableSkeleton = ({ amount, columns }) => {
    const skeletonAccessibilityAttrs = useSkeletonAccessibilityAttr();
    return (_jsx(Stack, Object.assign({ spacing: 4, mb: 4 }, skeletonAccessibilityAttrs, { children: Array.from(Array(amount).keys()).map((x) => (_jsx(AssessmentsTableCardSkeleton, { columns: columns }, `skeleton_${x}`))) })));
};
export default AssessmentList;
