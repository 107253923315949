import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DialogContent, DialogActions, Typography } from '@mui/material';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Dialog } from '../../../common/Dialog';
export const ExampleEmailSentDialog = ({ open, onClose }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    return (_jsxs(Dialog, { open: open, onClose: onClose, sx: {
            '& .MuiDialog-paper': {
                paddingBlock: theme.spacing(0),
            },
        }, children: [_jsxs(DialogContent, { sx: {
                    padding: theme.spacing(7),
                    display: 'flex',
                    gap: 3,
                    flexDirection: 'column',
                    alignSelf: 'stretch',
                    alignItems: 'center',
                }, children: [_jsx(CheckCircleRoundedIcon, { sx: { fontSize: theme.spacing(14), color: (theme) => theme.palette.text.status.success } }), _jsx(Typography, { variant: 'h3', children: t('pages.administration.communicationsCentre.executiveSponsorEmail.emailSentDialog.title') }), _jsx(Typography, { variant: 'body1', children: t('pages.administration.communicationsCentre.executiveSponsorEmail.emailSentDialog.description') })] }), _jsx(DialogActions, { sx: {
                    borderTop: `1px solid ${theme.palette.container.border.default}`,
                    padding: theme.spacing(7),
                    display: 'flex',
                    justifyContent: 'flex-end',
                }, children: _jsx(Button, { variant: "contained", onClick: onClose, children: t('pages.administration.communicationsCentre.executiveSponsorEmail.emailSentDialog.ok') }) })] }));
};
