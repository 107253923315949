import { Menu, styled } from '@mui/material';
export const ActionMenu = styled(Menu)(({ theme }) => ({
    '& .MuiPaper-root': {
        background: theme.palette.container.fill.card1,
        border: `1px solid ${theme.palette.clickable.default}`,
        borderRadius: theme.shape.borderRadius * 2,
        boxShadow: theme.shadows[1],
        marginTop: theme.spacing(1),
        width: theme.typography.pxToRem(221),
    },
}));
