var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { match } from 'ts-pattern';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import { mapCategory } from '../utils/mapper';
export const getQuestBuffer = (questDetails) => {
    const baseBuffer = {
        questId: questDetails.questId,
        name: questDetails.name,
        isMandatory: questDetails.isMandatory,
        badge: questDetails.badge,
        objectives: questDetails.objectives,
        status: questDetails.status,
        basedOnRevisionNumber: questDetails.revisionNumber,
        assignedTeamIds: questDetails.assignedTeamIds,
    };
    return questDetails.kind === 'platform'
        ? Object.assign(Object.assign({}, baseBuffer), { startDate: questDetails.startDate, endDate: questDetails.endDate, kind: 'platform', assignmentSelection: questDetails.assignmentSelection }) : Object.assign(Object.assign({}, baseBuffer), { kind: 'lms', isMandatory: false, assignmentSelection: 'company' });
};
export const getNewQuestState = (startDate, kind) => {
    const assignedTeamIds = [];
    return {
        questId: uuidv4(),
        kind,
        createdAt: new Date(),
        revisionNumber: 0,
        name: '',
        isMandatory: false,
        badge: null,
        objectives: [],
        startDate,
        endDate: dayjs(startDate).add(3, 'month').toDate(),
        status: 'new',
        assignedTeamIds,
        assignmentSelection: 'none',
    };
};
export const getAvailableCategories = (slugs, metadata) => {
    const availableCategories = slugs.map((slug) => mapCategory(slug, metadata));
    availableCategories.sort((a, b) => {
        return a.mainName.localeCompare(b.mainName) || a.name.localeCompare(b.name);
    });
    return availableCategories;
};
export const isCleanBuffer = (buffer, lastSavedBuffer) => {
    if (buffer.kind !== lastSavedBuffer.kind)
        return false;
    const bufferKeys = Object.keys(buffer);
    return bufferKeys.every((bufferKey) => match(bufferKey)
        .with('questId', 'kind', 'basedOnRevisionNumber', 'name', 'badge', 'status', 'isMandatory', 'assignmentSelection', (key) => buffer[key] === lastSavedBuffer[key])
        .with('startDate', 'endDate', (key) => {
        var _a, _b;
        if (buffer.kind === 'platform' && lastSavedBuffer.kind === 'platform') {
            return ((_a = buffer[key]) === null || _a === void 0 ? void 0 : _a.getTime()) === ((_b = lastSavedBuffer[key]) === null || _b === void 0 ? void 0 : _b.getTime());
        }
        return true;
    })
        .with('objectives', (key) => isCleanObjectivesBuffer(buffer[key], lastSavedBuffer[key]))
        .with('assignedTeamIds', (key) => isCleanTeamAssignmentBuffer(buffer[key], lastSavedBuffer[key]))
        .exhaustive());
};
const getNameErrors = (quest) => {
    var _a, _b;
    const nameError = ((_a = quest.name) === null || _a === void 0 ? void 0 : _a.length) < 3
        ? 'questsAdmin.validation.nameTooShort'
        : ((_b = quest.name) === null || _b === void 0 ? void 0 : _b.length) > 250
            ? 'questsAdmin.validation.nameTooLong'
            : null;
    return nameError ? [{ field: 'name', message: nameError }] : [];
};
const getAssignmentErrors = (quest) => {
    return quest.assignmentSelection === 'none' || (quest.assignmentSelection === 'team' && !quest.assignedTeamIds.length)
        ? [
            {
                field: 'assignedTeamIds',
                message: 'questsAdmin.components.organisms.questEditor.sections.userAssignment.participantsRequired',
            },
        ]
        : [];
};
const getObjectiveErrors = (quest) => {
    var _a;
    return !((_a = quest.objectives) === null || _a === void 0 ? void 0 : _a.length)
        ? [{ field: 'objectives', message: 'questsAdmin.validation.missingObjectives' }]
        : [];
};
const getEndTimeErrors = (quest, dateNowTimestamp) => {
    var _a, _b;
    const endDateTime = (_b = (_a = quest === null || quest === void 0 ? void 0 : quest.endDate) === null || _a === void 0 ? void 0 : _a.getTime) === null || _b === void 0 ? void 0 : _b.call(_a);
    const endDateError = !endDateTime
        ? 'questsAdmin.validation.invalidEndDate'
        : endDateTime < dateNowTimestamp && (quest.status === 'new' || quest.status === 'draft')
            ? 'questsAdmin.validation.invalidPastEndDate'
            : null;
    return endDateError ? [{ field: 'endDate', message: endDateError }] : [];
};
const getStartTimeErrors = (quest) => {
    var _a, _b, _c, _d;
    const startDateTime = (_b = (_a = quest === null || quest === void 0 ? void 0 : quest.startDate) === null || _a === void 0 ? void 0 : _a.getTime) === null || _b === void 0 ? void 0 : _b.call(_a);
    const endDateTime = (_d = (_c = quest === null || quest === void 0 ? void 0 : quest.endDate) === null || _c === void 0 ? void 0 : _c.getTime) === null || _d === void 0 ? void 0 : _d.call(_c);
    const startDateError = !startDateTime
        ? 'questsAdmin.validation.invalidStartDate'
        : endDateTime && endDateTime < startDateTime
            ? 'questsAdmin.validation.invalidDateOrder'
            : null;
    return startDateError ? [{ field: 'startDate', message: startDateError }] : [];
};
export const getQuestValidationErrors = (quest, dateNowTimestamp) => {
    if (quest.kind === 'lms')
        return [...getNameErrors(quest), ...getObjectiveErrors(quest)];
    return [
        ...getNameErrors(quest),
        ...getAssignmentErrors(quest),
        ...getObjectiveErrors(quest),
        ...getEndTimeErrors(quest, dateNowTimestamp),
        ...getStartTimeErrors(quest),
    ];
};
export const isCleanObjectivesBuffer = (buffer, lastSavedBuffer) => {
    if (buffer.length !== lastSavedBuffer.length)
        return false;
    return buffer.every((objective, index) => isCleanObjectiveBuffer(objective, lastSavedBuffer[index]));
};
export const isCleanTeamAssignmentBuffer = (buffer, lastSavedBuffer) => {
    return buffer.join(',') === lastSavedBuffer.join(',');
};
export const getDaysDelta = (dateA, dateB, useCeil = false) => {
    if (!dateA || !dateB)
        return -1;
    const dateATimestamp = new Date(dateA).getTime();
    const dateBTimestamp = new Date(dateB).getTime();
    if (!dateATimestamp || !dateBTimestamp)
        return -1; // Can occur for invalid date selection via text input editing
    const delta = (dateATimestamp - dateBTimestamp) / 86400000;
    return useCeil ? Math.ceil(delta) : Math.round(delta);
};
export const getCustomVulnerabilityObjectives = (objectives) => {
    return objectives.filter((o) => o.kind === 'learning-outcome');
};
export const getSecurityConceptObjectives = (objectives) => {
    return objectives.filter((o) => o.kind === 'security-concept');
};
export const getMostCommonVulnerabilitiesSelection = (objectives) => {
    const mostCommonObjective = objectives.find((o) => o.kind === 'most-common-vulnerabilities');
    return (mostCommonObjective === null || mostCommonObjective === void 0 ? void 0 : mostCommonObjective.kind) === 'most-common-vulnerabilities'
        ? mostCommonObjective.maxTopicsPerStandard
        : 'not selected';
};
export const includesPciDssObjective = (objectives) => {
    return objectives.some((o) => o.kind === 'pci-dss');
};
export const getCategorySlugsFromObjectives = (objectives) => {
    return objectives
        .map((o) => (o.kind === 'learning-outcome' ? `${o.rootCategoryId}.${o.categoryId}.${o.subcategoryId}` : ''))
        .filter((slug) => !!slug);
};
export const getConceptTopicIdsFromObjectives = (objectives) => {
    return objectives.flatMap((o) => (o.kind === 'security-concept' ? [o.stableTopicId] : []));
};
export const getInitialSnackbarState = () => ({
    message: '',
    severity: 'success',
    open: false,
    key: new Date().getTime(),
});
export const isCleanObjectiveBuffer = (buffer, lastSavedBuffer) => {
    if (buffer.kind === 'most-common-vulnerabilities' || lastSavedBuffer.kind === 'most-common-vulnerabilities') {
        return (buffer.kind === 'most-common-vulnerabilities' &&
            lastSavedBuffer.kind === 'most-common-vulnerabilities' &&
            buffer.maxTopicsPerStandard === lastSavedBuffer.maxTopicsPerStandard);
    }
    if (buffer.kind === 'pci-dss' || lastSavedBuffer.kind === 'pci-dss') {
        return buffer.kind === lastSavedBuffer.kind;
    }
    if (buffer.kind === 'security-concept' || lastSavedBuffer.kind === 'security-concept') {
        return (buffer.kind === 'security-concept' &&
            lastSavedBuffer.kind === 'security-concept' &&
            buffer.stableTopicId === lastSavedBuffer.stableTopicId);
    }
    const bufferKeys = Object.keys(buffer);
    return bufferKeys.every((bufferKey) => match(bufferKey)
        .with('kind', 'rootCategoryId', 'categoryId', 'subcategoryId', (key) => buffer[key] === lastSavedBuffer[key])
        .exhaustive());
};
export const getMuiDatePickerLanguageKey = (currentLanguage) => {
    if (typeof currentLanguage !== 'string')
        return 'en-gb';
    if (currentLanguage.startsWith('en'))
        return 'en-gb';
    return currentLanguage.split('-')[0] || 'en-gb';
};
export const getEditingDisabledTooltip = (editingDisabled, status, t, i18nFormat, skeletonMode) => {
    if (skeletonMode)
        return undefined;
    const translationStatus = 'questsAdmin.entities.quests.status.' + (status === 'active' ? 'published' : status);
    return editingDisabled
        ? i18nFormat(t('questsAdmin.components.organisms.questEditor.general.draftsOnlyEditEnabled', {
            status: t(translationStatus),
        }), 'sentenceCase')
        : undefined;
};
export const getObjectivesWithUpdatedCustomVulnerabilitySelection = (objectivesBefore, newCategorySlugSelection) => {
    const nonCustomVulnerabilitiesObjectives = objectivesBefore.filter((obj) => obj.kind !== 'learning-outcome');
    const newlySelectedCustomVulnerabilitiesObjectives = newCategorySlugSelection.map((categorySlug) => {
        const [rootCategoryId, categoryId, subcategoryId] = categorySlug.split('.');
        return {
            kind: 'learning-outcome',
            rootCategoryId: rootCategoryId,
            categoryId: categoryId,
            subcategoryId: subcategoryId,
        };
    });
    return [...nonCustomVulnerabilitiesObjectives, ...newlySelectedCustomVulnerabilitiesObjectives];
};
export const getObjectivesWithUpdatedSecurityConceptSelection = (objectivesBefore, newStableTopicIdSelection) => {
    const nonSecurityConceptObjectives = objectivesBefore.filter((obj) => obj.kind !== 'security-concept');
    const newlySelectedSecurityConceptObjectives = newStableTopicIdSelection.map((stableTopicId) => {
        return {
            kind: 'security-concept',
            stableTopicId,
        };
    });
    return [...nonSecurityConceptObjectives, ...newlySelectedSecurityConceptObjectives];
};
export const getObjectivesWithUpdatedMostCommonVulnerabilitiesSelection = (objectivesBefore, selection) => {
    const nonMostCommonObjectives = objectivesBefore.filter((objective) => objective.kind !== 'most-common-vulnerabilities');
    return selection !== 'not selected'
        ? [...nonMostCommonObjectives, { kind: 'most-common-vulnerabilities', maxTopicsPerStandard: selection }]
        : nonMostCommonObjectives;
};
export const getObjectivesWithUpdatedPciDssSelection = (objectivesBefore, selected) => {
    const nonPciDssObjectives = objectivesBefore.filter((objective) => objective.kind !== 'pci-dss');
    return selected ? [...nonPciDssObjectives, { kind: 'pci-dss' }] : nonPciDssObjectives;
};
export const getPciDssCategoryInformation = (metadata, pciStandardCategories, t) => {
    const pciCategoryInformation = [];
    pciStandardCategories.forEach((category) => {
        category.topics.forEach((topic) => {
            var _a, _b, _c;
            const [group, main, sub] = topic.vulnerabilityKey.split('.');
            const name = (_c = (_b = (_a = metadata['categories'][group]) === null || _a === void 0 ? void 0 : _a[main]) === null || _b === void 0 ? void 0 : _b.subitem[sub]) === null || _c === void 0 ? void 0 : _c.name;
            const categoryNameNumber = category.name.split(':')[0].split('.')[0].split(' ')[1];
            pciCategoryInformation.push({
                mainName: t('questsAdmin.components.organisms.questEditor.categorySelection.requirementNum', {
                    num: categoryNameNumber,
                }),
                id: topic.vulnerabilityKey,
                rootName: group,
                name,
            });
        });
    });
    return pciCategoryInformation;
};
export const getSecurityConceptsCategoryInformation = (securityConceptCourses, portalFrontendTranslation) => {
    const securityConceptsInformation = [];
    securityConceptCourses.forEach((course) => {
        course.chapters
            .flatMap((chapter) => chapter.topics)
            .forEach((topic) => {
            securityConceptsInformation.push({
                mainName: portalFrontendTranslation(course.translationKey),
                id: topic.stableTopicId,
                rootName: 'concept',
                name: portalFrontendTranslation(topic.translationKey),
            });
        });
    });
    securityConceptsInformation.sort((a, b) => a.mainName.localeCompare(b.mainName));
    return securityConceptsInformation;
};
export const getDownloadableScormPackage = (sessionId, questId, scormVersion) => __awaiter(void 0, void 0, void 0, function* () {
    const inputPart = encodeURIComponent(JSON.stringify({ 0: { json: { questId, scormVersion } } }));
    const endpoint = window.SCW_ENV.ApiEndpoint;
    const response = yield fetch(`${endpoint}/rpc/questsAdmin.fetchScormPackage?batch=1&input=${inputPart}`, {
        method: 'GET',
        headers: {
            'X-Session-Id': sessionId,
        },
    });
    const resultJson = yield response.json();
    const result = resultJson[0].result.data.json;
    if (result.status === 'error') {
        return;
    }
    const blob = new Blob([new Uint8Array(result.data)], { type: 'application/zip' });
    return URL.createObjectURL(blob);
});
