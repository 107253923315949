import { jsx as _jsx } from "react/jsx-runtime";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ApiContext } from '../../../ApiContext';
import { DialogContainer } from '../../../common/Dialog';
import { SingleActionProvider } from '../context';
const queryClient = new QueryClient({ defaultOptions: { queries: { retry: false } } });
const Provider = ({ children, ctx }) => {
    var _a, _b, _c, _d, _e;
    return (_jsx(ApiContext.Provider, { value: {
            sessionId: (_b = (_a = ctx.apiContextValues) === null || _a === void 0 ? void 0 : _a.sessionId) !== null && _b !== void 0 ? _b : 'sessionId',
            apiEndpoint: (_d = (_c = ctx.apiContextValues) === null || _c === void 0 ? void 0 : _c.apiEndpoint) !== null && _d !== void 0 ? _d : 'http://localhost:3000',
        }, children: _jsx(QueryClientProvider, { client: (_e = ctx.queryClient) !== null && _e !== void 0 ? _e : queryClient, children: _jsx(SingleActionProvider, { children: children }) }) }));
};
export const AdminDecorator = (Story, ctx) => (_jsx(Provider, { ctx: ctx.args, children: _jsx(Story, {}) }));
export const AdminDialogDecorator = (Story, ctx) => {
    return (_jsx(Provider, { ctx: ctx.args, children: _jsx(DialogContainer, { children: _jsx(Story, {}) }) }));
};
