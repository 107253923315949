import { Box, styled } from '@mui/material';
const BORDER_RADIUS_MULTIPLIER = 999;
export const StyledEmailPill = styled(Box)(({ theme }) => (Object.assign(Object.assign({ position: 'relative', borderRadius: theme.shape.borderRadius * BORDER_RADIUS_MULTIPLIER, border: `1px solid ${theme.palette.container.border.default}` }, theme.typography.body1), { color: theme.palette.text.body, padding: theme.spacing(0.25, 1), display: 'flex', alignItems: 'center', cursor: 'pointer', maxWidth: '100%', outline: 'none', '&:hover': {
        borderColor: theme.palette.clickable.button.hover,
    }, '&:focus-visible': {
        outline: `2px solid ${theme.palette.clickable.default}`,
        borderRadius: theme.shape.borderRadius * BORDER_RADIUS_MULTIPLIER,
    }, '&[data-disabled="true"]': {
        borderColor: theme.palette.container.border.default,
        cursor: 'not-allowed',
        pointerEvents: 'none',
    } })));
export const StyledCloseButton = styled(Box)(({ theme }) => ({
    width: '16px',
    height: '16px',
    marginLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    '&:hover': {
        color: theme.palette.text.secondary,
    },
    '&:focus-visible': {
        outline: `2px solid ${theme.palette.clickable.default}`,
        borderRadius: theme.shape.borderRadius * BORDER_RADIUS_MULTIPLIER,
    },
    '&[data-disabled="true"]': {
        color: theme.palette.text.disabled,
        cursor: 'not-allowed',
    },
}));
