var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { CheckCircleRounded } from '@mui/icons-material';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import { useTeam } from '../../../hooks/useTeam';
import { DeleteIcon } from '../../../../../common/icons';
import { ActionDialog } from '../../../../common/components/organisms/ActionDialog';
import { useTranslation } from 'react-i18next';
const SingleTeamDeletePending = ({ onClose, onConfirm, team }) => {
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.teamManagement' });
    return (_jsx(ActionDialog, { title: t('singleTeamDeleteDialog.pending.title', { name: team.name }), icon: _jsx(DeleteIcon, { sx: { fontSize: '56px', color: (theme) => theme.palette.text.status.error } }), footer: _jsxs(Stack, { direction: "row", justifyContent: "space-between", children: [_jsx(Button, { variant: "outlined", onClick: onClose, children: t('common.cancel') }), _jsx(Button, { variant: "contained", onClick: onConfirm, color: "warning", children: t('singleTeamDeleteDialog.pending.confirm') })] }), children: _jsxs(Stack, { alignItems: "center", gap: 2, children: [_jsx(Typography, { children: t('singleTeamDeleteDialog.pending.description') }), _jsx(Typography, { children: t('singleTeamDeleteDialog.pending.warning') })] }) }));
};
const SingleTeamDeleteSuccess = ({ onClose, team }) => {
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.teamManagement' });
    return (_jsx(ActionDialog, { title: t('singleTeamDeleteDialog.success.title'), icon: _jsx(CheckCircleRounded, { sx: { fontSize: '56px', color: (theme) => theme.palette.text.status.success } }), footer: _jsx(Stack, { direction: "row", justifyContent: "flex-end", children: _jsx(Button, { variant: "contained", onClick: onClose, children: t('common.okay') }) }), children: _jsx(Typography, { children: t('singleTeamDeleteDialog.success.description', { name: team.name }) }) }));
};
export const SingleTeamDeleteDialog = ({ onClose, onConfirm, teamId }) => {
    const { team } = useTeam(teamId);
    const [success, setSuccess] = useState(false);
    if (!team)
        return null;
    const handleConfirm = () => __awaiter(void 0, void 0, void 0, function* () {
        const deleted = yield onConfirm();
        if (deleted === undefined) {
            return;
        }
        setSuccess(!!deleted);
    });
    return success ? (_jsx(SingleTeamDeleteSuccess, { onClose: onClose, team: team })) : (_jsx(SingleTeamDeletePending, { onClose: onClose, onConfirm: handleConfirm, team: team }));
    // TODO return error state if necessary
};
