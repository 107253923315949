import { INDUSTRY_TRUST_SCORES } from './staticContent';
import { userList } from './users';
import { groupBy } from './util/groupBy';
const companyHistory = [];
for (let i = 0; i < 20; i++) {
    const exponent = Math.pow(i / 19, 2); // Exponential smoothing
    companyHistory.push({
        __typename: 'HistoricalTrustScore',
        date: new Date(Date.now() - (26 - i) * 7 * 24 * 60 * 60 * 1000).toISOString(),
        trustScore: Math.min(235, Math.floor(100 + exponent * (235 - 100) - Math.random() * 3)), // random deviation
        version: '2025',
    });
}
for (let i = 20; i < 26; i++) {
    const exponent = Math.pow(i / 25, 2);
    companyHistory.push({
        __typename: 'HistoricalTrustScore',
        date: new Date(Date.now() - (26 - i) * 7 * 24 * 60 * 60 * 1000).toISOString(),
        trustScore: Math.min(250, Math.floor(235 + exponent * (250 - 235) - Math.random())), // random deviation
        version: '2025',
    });
}
companyHistory.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
const recommendations = [];
/*
TODO: add recommendations?
{
  __typename: 'Recommendation',
  creationDate: '2024-06-10T00:00:00.000Z',
  ordinal: 2,
  recommendation:
      'Schedule regular refresher courses. 30% of your learners have not maintained their secure coding skills in over 2 years.',
  },
  {
    __typename: 'Recommendation',
    creationDate: '2024-06-10T00:00:00.000Z',
    ordinal: 1,
    recommendation:
      'Prioritise SQL Injection and Cross Site Scripting in your courses and assessments. 80% of learners are unaware of these critical concepts.',
  },*/
const today = new Date(Date.now());
today.setHours(0, 0, 0, 0);
const company = {
    recommendations,
    trustScore: {
        date: today.toISOString(),
        trustScore: companyHistory[companyHistory.length - 1].trustScore,
        version: '2025',
    },
};
//TODO: generate concept coverage
const conceptCoverage = {
    __typename: 'ConceptCoverageConnection',
    edges: [
        {
            __typename: 'ConceptCoverageEdge',
            cursor: 'c2ltcGxlLWN1cnNvcjA=',
            node: {
                __typename: 'ConceptCoverage',
                category: 'Access Control',
                openStandards: ['OWASP API Security Top 10 - 2023', 'OWASP Web Top 10 - 2021'],
                skillLevel: 14.737519269110203,
                subCategory: 'Insecure Direct Object Reference',
            },
        },
        {
            __typename: 'ConceptCoverageEdge',
            cursor: 'c2ltcGxlLWN1cnNvcjE=',
            node: {
                __typename: 'ConceptCoverage',
                category: 'Access Control',
                openStandards: ['OWASP API Security Top 10 - 2023', 'OWASP Web Top 10 - 2021'],
                skillLevel: 15.871500473731981,
                subCategory: 'Missing Function Level Access Control',
            },
        },
        {
            __typename: 'ConceptCoverageEdge',
            cursor: 'c2ltcGxlLWN1cnNvcjI=',
            node: {
                __typename: 'ConceptCoverage',
                category: 'Access Control',
                openStandards: ['OWASP API Security Top 10 - 2023', 'OWASP Web Top 10 - 2021'],
                skillLevel: 3.629047645428449,
                subCategory: 'Missing Object Level Access Control',
            },
        },
        {
            __typename: 'ConceptCoverageEdge',
            cursor: 'c2ltcGxlLWN1cnNvcjg=',
            node: {
                __typename: 'ConceptCoverage',
                category: 'Authentication',
                openStandards: ['OWASP Web Top 10 - 2021'],
                skillLevel: 8.689565008290252,
                subCategory: 'Insufficiently Protected Credentials',
            },
        },
        {
            __typename: 'ConceptCoverageEdge',
            cursor: 'c2ltcGxlLWN1cnNvcjk=',
            node: {
                __typename: 'ConceptCoverage',
                category: 'Authentication',
                openStandards: ['OWASP API Security Top 10 - 2023'],
                skillLevel: 0.0,
                subCategory: 'Use of Single-factor Authentication',
            },
        },
        {
            __typename: 'ConceptCoverageEdge',
            cursor: 'c2ltcGxlLWN1cnNvcjQ3',
            node: {
                __typename: 'ConceptCoverage',
                category: 'Security Misconfiguration',
                openStandards: ['OWASP Web Top 10 - 2021', 'SCW Frontend Top 5 2020'],
                skillLevel: 7.449343188277522,
                subCategory: 'Clickjacking',
            },
        },
        {
            __typename: 'ConceptCoverageEdge',
            cursor: 'c2ltcGxlLWN1cnNvcjQ4',
            node: {
                __typename: 'ConceptCoverage',
                category: 'Security Misconfiguration',
                openStandards: ['OWASP Web Top 10 - 2021'],
                skillLevel: 19.37884521098469,
                subCategory: 'Debug Features Enabled',
            },
        },
        {
            __typename: 'ConceptCoverageEdge',
            cursor: 'c2ltcGxlLWN1cnNvcjQ5',
            node: {
                __typename: 'ConceptCoverage',
                category: 'Security Misconfiguration',
                openStandards: ['OWASP API Security Top 10 - 2023', 'OWASP Web Top 10 - 2021', 'SCW Frontend Top 5 2020'],
                skillLevel: 17.611980649546776,
                subCategory: 'Disabled Security Features',
            },
        },
    ],
    pageInfo: {
        __typename: 'PageInfo',
        endCursor: 'c2ltcGxlLWN1cnNvcjk=',
        hasNextPage: false,
        hasPreviousPage: false,
        startCursor: 'c2ltcGxlLWN1cnNvcjA=',
    },
};
const industry = {
    edges: INDUSTRY_TRUST_SCORES,
    pageInfo: {
        __typename: 'PageInfo',
        endCursor: 'c2ltcGxlLWN1cnNvcjUyOA==',
        hasNextPage: false,
        hasPreviousPage: false,
        startCursor: '',
    },
};
const usersGroupedByJobDescription = groupBy(userList, (i) => i.jobDescription);
const usersGroupedByTeam = groupBy(userList, (i) => i.team);
const jobDescriptions = Object.entries(usersGroupedByJobDescription).map(([k, v]) => ({
    _typename: 'JobDescription',
    count: v.length,
    jobDescription: k,
}));
const teams = {
    edges: Object.entries(usersGroupedByTeam).map(([k, v]) => ({
        cursor: '',
        node: {
            __typename: 'TeamTrustScore',
            teamName: k,
            trustScore: Math.floor(v.reduce((a, b) => a + b.trustScore, 0) / v.length),
            userCount: v.length,
        },
    })),
    pageInfo: {
        __typename: 'PageInfo',
        endCursor: 'c2ltcGxlLWN1cnNvcjI5',
        hasNextPage: false,
        hasPreviousPage: false,
        startCursor: '',
    },
};
const users = {
    edges: userList.map((u) => ({
        __typename: 'UserTrustScoreEdge',
        cursor: '',
        node: {
            __typename: 'UserTrustScore',
            email: u.email,
            jobDescription: u.jobDescription,
            lastCompletedActivity: u.lastActivityDate,
            lastLogin: u.lastLoggedIn,
            name: u.name,
            tags: u.tags,
            team: u.team,
            trustScore: u.trustScore,
            userWeighting: 1.0,
        },
    })),
    pageInfo: {
        __typename: 'PageInfo',
        endCursor: 'c2ltcGxlLWN1cnNvcjg5',
        hasNextPage: false,
        hasPreviousPage: false,
        startCursor: '',
    },
};
export const trustScores = {
    company,
    companyHistory,
    conceptCoverage,
    industry,
    jobDescriptions,
    teams,
    users,
};
