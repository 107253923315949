var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Trans, useTranslation } from 'react-i18next';
import { getDownloadableScormPackage, getInitialSnackbarState, } from '../../../utils';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import { Box, Divider, Stack, Typography, useTheme } from '@mui/material';
import { ArchiveRounded, DeleteRounded, SaveRounded, SendRounded, InfoOutlined, DownloadRounded, } from '@mui/icons-material';
import { Tooltip } from '../../../../common/Tooltip';
import { useMemo, useState } from 'react';
import { ButtonOptionSelect } from '@securecodewarrior/design-system-react/lib/labs/molecules/ButtonOptionSelect';
import { usePlatformContext } from '../../../../platformContext';
import { questDetailsAnalytics } from '../../../analytics/QuestDetails.analytics';
import SnackbarAlert from '../../molecules/SnackbarAlert';
export const ActionPanel = (props) => {
    var _a;
    const { skeletonMode, isPerformingMutation, isFormDirty, status, validationErrors, questId } = props;
    const { t } = useTranslation();
    const theme = useTheme();
    const [snackbarConfig, setSnackbarConfig] = useState(getInitialSnackbarState());
    const triggerSnackbar = (message, severity) => {
        setSnackbarConfig({ message, severity, open: true, key: new Date().getTime() });
    };
    const handleCloseSnackbar = () => {
        setSnackbarConfig((prev) => (Object.assign(Object.assign({}, prev), { open: false })));
    };
    const { logAnalyticsEvent } = usePlatformContext();
    const analytics = useMemo(() => questDetailsAnalytics(logAnalyticsEvent), [logAnalyticsEvent]);
    const handleSaveClick = (event) => {
        var _a;
        event.preventDefault();
        (_a = props.onSave) === null || _a === void 0 ? void 0 : _a.call(props);
    };
    const handlePublishClick = (event) => {
        var _a;
        event.preventDefault();
        (_a = props.onPublish) === null || _a === void 0 ? void 0 : _a.call(props);
    };
    const handleArchiveClick = (event) => {
        var _a;
        event.preventDefault();
        (_a = props.onArchive) === null || _a === void 0 ? void 0 : _a.call(props);
    };
    const handleDeleteClick = (event) => {
        var _a;
        event.preventDefault();
        (_a = props.onDelete) === null || _a === void 0 ? void 0 : _a.call(props);
    };
    const handleScormDownload = (value) => __awaiter(void 0, void 0, void 0, function* () {
        const sessionId = sessionStorage.getItem('sessionId');
        if (!sessionId || !questId) {
            return;
        }
        const packageUrl = yield getDownloadableScormPackage(sessionId, questId, value);
        if (!packageUrl) {
            triggerSnackbar(t('questsAdmin.pages.edit.downloadScormError'), 'error');
            return;
        }
        const link = document.createElement('a');
        link.href = packageUrl;
        link.download = 'scorm-package.zip';
        link.click();
        analytics.onDownloadedScormVersion(status, questId, value);
    });
    const showArchiveButton = status === 'active' || status === 'finished';
    const showDeleteButton = status === 'scheduled' || status === 'draft';
    const readOnly = !!skeletonMode || isPerformingMutation;
    return (_jsxs(Stack, { sx: {
            borderLeft: `1px solid ${theme.palette.container.border.default}`,
            width: '100%',
            height: '100%',
        }, children: [props.kind === 'lms' && (status === 'draft' || status === 'new') && (_jsxs(Stack, { sx: {
                    padding: theme.spacing(10, 7),
                    flex: 1,
                    minHeight: 0,
                    overflow: 'auto',
                    scrollbarColor: `${theme.palette.grayscale.bg6} ${theme.palette.grayscale.bg4}`,
                    scrollbarWidth: 'thin',
                    '::-webkit-scrollbar': {
                        background: theme.palette.grayscale.bg4,
                        width: '10px',
                    },
                    '::-webkit-scrollbar-thumb': {
                        background: theme.palette.grayscale.bg6,
                        borderRadius: 4,
                    },
                    '& li': {
                        listStyle: 'unset',
                        marginBottom: 0.5,
                    },
                }, children: [_jsx(InfoOutlined, { sx: (theme) => ({ alignSelf: 'center', color: theme.palette.text.soft, width: '52px', height: '52px' }) }), _jsx(Typography, { component: "h2", variant: "h3", sx: { textAlign: 'center', marginTop: 4, marginBottom: 6 }, children: t('common.lms.lmsQuest') }), _jsx(Typography, { sx: { marginBottom: 6 }, children: _jsx(Trans, { children: t('questsAdmin.pages.edit.lmsInfo.info1') }) }), _jsx(Typography, { sx: { marginBottom: 1 }, children: t('questsAdmin.pages.edit.lmsInfo.info2') }), _jsx(Typography, { sx: { marginBottom: 6, paddingLeft: 6.5 }, children: _jsx(Trans, { children: t('questsAdmin.pages.edit.lmsInfo.info3') }) }), _jsx(Typography, { sx: { marginBottom: 0 }, children: _jsx(Trans, { children: t('questsAdmin.pages.edit.lmsInfo.info4') }) })] })), _jsxs(Stack, { sx: {
                    padding: theme.spacing(5),
                    justifyContent: 'flex-start',
                    gap: theme.spacing(4),
                }, children: [showArchiveButton && (_jsx(Button, { type: "button", onClick: handleArchiveClick, startIcon: _jsx(ArchiveRounded, {}), variant: "text", disabled: readOnly, children: t('questsAdmin.pages.edit.archiveButton') })), showDeleteButton && (_jsx(Button, { type: "button", onClick: handleDeleteClick, startIcon: _jsx(DeleteRounded, {}), variant: "text", color: "warning", disabled: readOnly, children: t(status === 'draft'
                            ? 'questsAdmin.components.molecules.confirmationDialog.deleteDraftDialog.confirmButtonLabel'
                            : 'questsAdmin.pages.edit.deleteButton') })), status === 'draft' && _jsx(Divider, {}), (status === 'new' || status === 'draft') && (_jsxs(_Fragment, { children: [_jsx(Button, { type: "submit", onClick: handleSaveClick, disabled: status === 'new' ? readOnly : readOnly || !isFormDirty, startIcon: _jsx(SaveRounded, {}), variant: "text", children: status === 'new' ? t('questsAdmin.pages.edit.saveAsDraft') : t('questsAdmin.pages.edit.saveDraft') }), _jsx(Tooltip, { title: t(((_a = validationErrors[0]) === null || _a === void 0 ? void 0 : _a.message) || '') || '', arrow: true, children: _jsx(Box, { children: _jsx(Button, { type: "submit", onClick: handlePublishClick, disabled: (validationErrors === null || validationErrors === void 0 ? void 0 : validationErrors.length) > 0 || readOnly, startIcon: _jsx(SendRounded, {}), variant: "contained", sx: { width: '100%' }, children: t('questsAdmin.pages.edit.saveAndPublish') }) }) })] })), props.kind === 'lms' && status === 'active' && (_jsx(ButtonOptionSelect, { onChange: handleScormDownload, startIcon: _jsx(DownloadRounded, {}), variant: "contained", options: {
                            '1.2': 'SCORM 1.2',
                            '2004': 'SCORM 2004',
                        }, children: t('questsAdmin.pages.edit.downloadScorm') }))] }), _jsx(SnackbarAlert, { message: snackbarConfig.message, severity: snackbarConfig.severity, open: snackbarConfig.open, onClose: handleCloseSnackbar }, snackbarConfig.key)] }));
};
