import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useId } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, FormGroup, Stack, Typography, Grid2 } from '@mui/material';
import { usePlatformContext } from '../../platformContext';
import { mapCategory } from '../../utils/mapper';
import { Badge } from '../../../common/components/atoms/Badge/Badge';
import { ChoiceLabel } from '../../../common/components/atoms/Choice/ChoiceLabel';
const badgeConfigForType = {
    optional: { color: 'blue', i18nKey: 'pages.myQuests.components.completedQuestCard.optional' },
    mandatory: { color: 'red', i18nKey: 'pages.myQuests.components.completedQuestCard.mandatory' },
    lms: { color: 'default', i18nKey: 'common.lms.lmsQuest' },
    'platform task': { color: 'default', i18nKey: 'pages.myQuests.components.completedQuestCard.platformTask' },
};
export const CompletedQuestDetail = ({ quest }) => {
    const { t } = useTranslation();
    const badgeConfig = badgeConfigForType[quest.type];
    return (_jsxs(Stack, { p: 7, gap: 5, children: [_jsxs(Stack, { gap: 5, direction: "row", children: [_jsx(Box, { component: "img", src: quest.imageUrl, sx: (theme) => ({
                            width: 140,
                            height: 140,
                            border: `2px solid ${theme.palette.container.border.default}`,
                            borderRadius: theme.shape.border.radius.md,
                            flexShrink: 0,
                        }), alt: t('pages.learn.components.completedQuestDetail.questBadge') }), _jsxs(Stack, { py: 2, gap: 5, alignItems: "flex-start", children: [_jsx(Badge, { color: badgeConfig.color, children: _jsx(Trans, { i18nKey: badgeConfig.i18nKey }) }), _jsx(Typography, { variant: "h4", component: "h2", id: "my-quests-completed-quest-detail-modal-title", children: quest.name }), !!quest.description && (_jsx(Typography, { id: "my-quests-completed-quest-detail-modal-description", children: quest.description }))] })] }), _jsxs(Stack, { sx: (theme) => ({
                    gap: 4,
                    pt: 4,
                    flexGrow: 1,
                    alignSelf: 'stretch',
                    borderTop: `1px solid ${theme.palette.container.border.default}`,
                }), role: "list", "aria-label": t('pages.learn.components.completedQuestDetail.objectives'), children: [quest.categories.length > 0 && (_jsx(CategoryCollection, { name: t('components.organisms.categorySelection.filter.vulnerabilities'), topics: quest.categories })), quest.concepts.length > 0 && (_jsx(CategoryCollection, { name: t('components.organisms.categorySelection.filter.concepts'), topics: quest.concepts }))] })] }));
};
export const CategoryCollection = (props) => {
    const a11yId = useId();
    const { metadata, portalFrontendTranslation } = usePlatformContext();
    return (_jsx(Stack, { role: "listitem", "aria-labelledby": a11yId, bgcolor: (theme) => theme.palette.container.fill.body, border: (theme) => `1px solid ${theme.palette.container.border.default}`, borderRadius: (theme) => theme.shape.border.radius.md, p: 5, children: _jsxs(Grid2, { container: true, spacing: 5, children: [_jsx(Grid2, { size: { xs: 12, sm: 4 }, children: _jsx(Typography, { variant: "h6", component: "h3", id: a11yId, children: props.name }) }), _jsx(Grid2, { size: { xs: 12, sm: 8 }, children: _jsx(FormGroup, { sx: { flexWrap: 'wrap', flexDirection: { sm: 'column', md: 'row' }, gap: 2 }, children: props.topics.map((topic) => (_jsx(ChoiceLabel, { children: typeof topic === 'string'
                                ? mapCategory(topic, metadata).name
                                : portalFrontendTranslation(topic.translationKey) }, typeof topic === 'string' ? topic : topic.stableTopicId))) }) })] }) }));
};
