import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UsersDataGrid } from './organisms/UsersDataGrid';
import { Card } from '@securecodewarrior/design-system-react/lib/labs';
import InviteUsersModal from './organisms/InviteUsersModal';
import { SingleActionProvider } from '../../common/context';
export const Users = () => {
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.userManagement' });
    return (_jsxs(Stack, { gap: ({ spacing }) => spacing(10), children: [_jsxs(Stack, { direction: 'row', justifyContent: 'space-between', children: [_jsx(Typography, { variant: "subtitle1", children: t('searchAllUsers') }), _jsx(InviteUsersModal, {})] }), _jsx(Card, { sx: ({ spacing }) => ({ padding: spacing(7) }), children: _jsx(SingleActionProvider, { children: _jsx(UsersDataGrid, {}) }) })] }));
};
