import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Stack } from '@mui/material';
import { Button, TextInput } from '@securecodewarrior/design-system-react/lib/wanda';
import { Delete as DeleteIcon, CheckCircleRounded as SuccessIcon } from '@mui/icons-material';
import { UserManagementModal } from './UserManagementModal';
import { bulkConfirmationCode } from '../../utils/bulkConfirmationCode';
import { BulkActionsAsyncDialog } from './BulkActionsAsyncDialog';
const BulkDeleteUsersPending = ({ onClose, onConfirm, userCount }) => {
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.userManagement' });
    const [confirmation, setConfirmation] = useState({ code: '', input: '', isValid: true });
    const handleChange = (event) => {
        setConfirmation((prev) => (Object.assign(Object.assign({}, prev), { input: event.target.value })));
    };
    const handleClick = () => {
        if (!userCount)
            return;
        if (confirmation.input !== confirmation.code && userCount >= 25) {
            setConfirmation((prev) => (Object.assign(Object.assign({}, prev), { isValid: false })));
            return;
        }
        onConfirm();
    };
    useEffect(() => {
        setConfirmation((prev) => (Object.assign(Object.assign({}, prev), { code: bulkConfirmationCode() })));
    }, [setConfirmation]);
    return (_jsxs(UserManagementModal, { title: t('deleteUsersDialog.title', { count: userCount }), icon: _jsx(DeleteIcon, { sx: { fontSize: '56px', color: (theme) => theme.palette.clickable.button.danger.default } }), footer: _jsxs(Stack, { direction: "row", justifyContent: "space-between", children: [_jsx(Button, { variant: "outlined", onClick: onClose, children: t('common.cancel') }), _jsx(Button, { variant: "contained", onClick: handleClick, color: "warning", children: t('deleteUsersDialog.confirm', { count: userCount }) })] }), children: [_jsx(Typography, { children: t('deleteUsersDialog.description') }), _jsx(Typography, { children: t('deleteUsersDialog.warning') }), userCount >= 25 && (_jsxs(Stack, { direction: "column", alignItems: "center", justifyContent: "center", children: [_jsx(Typography, { children: t('deleteUsersDialog.confirmation') }), _jsx(Typography, { variant: "body0", sx: { marginBottom: 2 }, children: confirmation.code }), _jsx(TextInput, { value: confirmation.input, onChange: handleChange, error: !confirmation.isValid, helperText: !confirmation.isValid ? t('deleteUsersDialog.invalidCode') : '', InputProps: { startAdornment: undefined }, inputProps: {
                            // Disabling autocomplete to prevent browser from auto-filling the input
                            autocomplete: 'off',
                            form: {
                                autocomplete: 'off',
                            },
                            'aria-label': `${t('deleteUsersDialog.confirmation')} ${confirmation.code}`,
                        } })] }))] }));
};
const BulkDeleteUsersSuccess = ({ currentUserSelected, onClose, updatedCount }) => {
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.userManagement' });
    return (_jsx(UserManagementModal, { title: t('deleteUsersDialog.successTitle', { count: updatedCount }), icon: _jsx(SuccessIcon, { sx: { fontSize: '56px', color: (theme) => theme.palette.text.status.success } }), footer: _jsx(Stack, { direction: "row", justifyContent: "flex-end", children: _jsx(Button, { variant: "contained", onClick: onClose, children: t('common.okay') }) }), children: _jsx(Stack, { children: currentUserSelected && (_jsx(Typography, { textAlign: "center", children: t('deleteUsersDialog.cannotDeleteYourself') })) }) }));
};
export const DeleteUsersDialog = ({ onClose, onConfirm, userCount, updatedCount, currentUserSelected, goneAsync, }) => {
    if (goneAsync) {
        return _jsx(BulkActionsAsyncDialog, { onClose: onClose });
    }
    if (updatedCount !== undefined) {
        return (_jsx(BulkDeleteUsersSuccess, { onClose: onClose, updatedCount: updatedCount, currentUserSelected: currentUserSelected }));
    }
    return _jsx(BulkDeleteUsersPending, { userCount: userCount, onClose: onClose, onConfirm: onConfirm });
};
