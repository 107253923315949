import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ActivityStatusPage } from '../../common/components/organisms/Activities/ActivityStatusPage';
import { assertNever } from '@securecodewarrior/design-system-react/lib/utils/functionalHelpers';
import { PullRequestPlayerEmbedder } from '../players/PullRequestPlayerEmbedder';
import { breadcrumbsHeight, createPlayerContainerStyle } from '../commonStyle';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { NavigationBar } from '../navigation/NavigationBar';
import { usePlatformContext } from '../platformContext';
import { usePlatformNavigate } from '../navigation';
import { useParams } from 'react-router-dom';
import { useApiClient } from '../ApiContext';
export const PullRequestPlayerWrapper = () => {
    const { contentId } = useParams();
    if (!contentId) {
        throw new Error('this component may only be used in a router that will set the correct params');
    }
    const platformCtx = usePlatformContext();
    const { logAnalyticsEvent } = platformCtx;
    const navigate = usePlatformNavigate();
    const { trpc, handleErrorPromise } = useApiClient();
    useEffect(() => {
        logAnalyticsEvent({ event: 'Access Pull Request', scope: 'explore', content_id: contentId });
    }, [contentId, logAnalyticsEvent]);
    const [completionStatus, setCompletionStatus] = useState('in_progress');
    const [response, setResponse] = useState();
    const fetch = (redoIfCompleted = false) => {
        setResponse(undefined);
        const request = trpc.explore.pullrequest.startOrContinue.mutate({ revisionId: contentId, redoIfCompleted });
        handleErrorPromise(request, setResponse);
    };
    useEffect(fetch, [contentId]); // eslint-disable-line react-hooks/exhaustive-deps
    const eventHandlers = useMemo(() => {
        return {
            onEndOfActivityReached: (passed) => {
                logAnalyticsEvent({ event: 'Finish Pull Request', scope: 'explore', content_id: contentId, passed });
            },
            onNavigateAwayFromActivityRequest: (passed) => {
                setCompletionStatus(passed ? 'passed' : 'failed');
            },
        };
    }, [logAnalyticsEvent, contentId]);
    const navigateBack = useCallback(() => {
        platformCtx.clearExploreCacheOnNextRequest();
        navigate.to({ pathname: '/explore', search: '', hash: '' });
    }, [navigate, platformCtx]);
    if (!response) {
        return _jsx(NavigationBar, { type: "pullrequest", module: "explore", status: "none", onBackNavigation: navigateBack });
    }
    return response.kind === 'ok' ? (completionStatus === 'in_progress' ? (_jsxs(_Fragment, { children: [_jsx(NavigationBar, { type: "pullrequest", module: "explore", status: "in_progress", onBackNavigation: navigateBack }), _jsx(PullRequestPlayerEmbedder, { playerUrl: response.externalPlayerUrl, style: style, eventHandlers: eventHandlers, playmode: "explore" })] })) : completionStatus === 'passed' || completionStatus === 'failed' ? (_jsxs(_Fragment, { children: [_jsx(NavigationBar, { type: "pullrequest", module: "explore", status: completionStatus, date: new Date(), onBackNavigation: navigateBack }), completionStatus === 'passed' ? (_jsx(ActivityStatusPage, { scope: "explore", variant: "success", contentType: "pullrequest", contentId: contentId, onClickBackToActivity: undefined })) : (_jsx(ActivityStatusPage, { scope: "explore", variant: "failed", contentType: "pullrequest", contentId: contentId, canRetryAt: new Date(0), onClickReplay: () => fetch(true) }))] })) : (assertNever(completionStatus, 'invalid activity status'))) : response.kind === 'already_completed' ? (_jsxs(_Fragment, { children: [_jsx(NavigationBar, { type: "pullrequest", module: "explore", status: "passed", date: response.lastCompleted, onBackNavigation: navigateBack }), _jsx(ActivityStatusPage, { scope: "explore", variant: "already_completed", contentType: "pullrequest", contentId: contentId, onClickStartRefresher: () => fetch(true) })] })) : (assertNever(response, 'invalid state'));
};
const style = createPlayerContainerStyle({ breadcrumbsHeight, showFooter: false });
