import { jsx as _jsx } from "react/jsx-runtime";
import { Stack, useTheme } from '@mui/material';
export const Header = ({ children }) => {
    const theme = useTheme();
    return (_jsx(Stack, { component: "header", sx: {
            flexDirection: 'row',
            alignItems: 'center',
            mb: 6,
            justifyContent: 'space-between',
            marginBottom: 0,
            height: theme.typography.pxToRem(108),
            minHeight: theme.typography.pxToRem(108),
            padding: theme.spacing(0, 10),
            background: theme.palette.container.fill.nav,
            borderBottom: `1px solid ${theme.palette.container.border.default}`,
        }, children: children }));
};
