import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useId, useState, useEffect } from 'react';
import { InputAdornment, MenuItem, Typography, typographyClasses } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DoneIcon from '@mui/icons-material/Done';
import { BaseSelect } from '@securecodewarrior/design-system-react/lib/base/wanda/Select/Select';
import { Tooltip } from '../../../scw-react/common/Tooltip';
const TeamDropdown = ({ team, onTeamChange, teams, isRoleAdmin, openDropdown, setOpenDropdown, placeholder, }) => {
    const { t } = useTranslation('react_components', { keyPrefix: 'components.molecules.TeamDropdown' });
    const [open, setOpen] = useState(false);
    const labelId = useId();
    useEffect(() => {
        const isValidTeam = teams.some((teamItem) => teamItem._id === team);
        if (!isValidTeam && team) {
            onTeamChange('');
        }
    }, [team, teams, onTeamChange]);
    const handleMenuItemClick = (clickedTeamId) => {
        const newTeam = clickedTeamId === team ? '' : clickedTeamId;
        onTeamChange(newTeam);
        setOpen(false);
    };
    const PlaceHolder = () => (_jsx(MenuItem, { value: '', disabled: true, children: placeholder }));
    return (_jsx(Tooltip, { title: isRoleAdmin ? t('adminToolTip') : '', placement: "bottom", arrow: true, sx: { maxWidth: 174 }, children: _jsx("span", { style: { width: '100%', display: 'inline-block' }, children: _jsx(BaseSelect, { fullWidth: true, disabled: isRoleAdmin || teams.length === 0, value: isRoleAdmin ? '' : team !== null && team !== void 0 ? team : '', sx: { width: 333 }, placeholder: placeholder ? _jsx(PlaceHolder, {}) : undefined, SelectProps: {
                    labelId,
                    onOpen: () => {
                        setOpen(true);
                        setOpenDropdown === null || setOpenDropdown === void 0 ? void 0 : setOpenDropdown(true);
                    },
                    onClose: () => {
                        setOpen(false);
                        setOpenDropdown === null || setOpenDropdown === void 0 ? void 0 : setOpenDropdown(false);
                    },
                    open: openDropdown === undefined ? open : openDropdown,
                    renderValue: () => {
                        var _a;
                        const selectedTeamName = (_a = teams.find((teamItem) => teamItem._id === team)) === null || _a === void 0 ? void 0 : _a.name;
                        return selectedTeamName ? (_jsx(Typography, { variant: "body1", sx: { color: 'inherit' }, children: selectedTeamName })) : placeholder ? (_jsx(Typography, { variant: "body1", sx: { color: (theme) => theme.palette.text.disabled }, children: placeholder })) : null;
                    },
                }, InputProps: {
                    startAdornment: (_jsx(InputAdornment, { position: "start", sx: Object.assign({ pointerEvents: 'none' }, (isRoleAdmin ? { color: (theme) => theme.palette.text.disabled } : {})), children: _jsxs(Typography, { variant: "caption", children: [t('labelTitle'), isRoleAdmin ? '' : !team ? _jsx("span", { style: { color: 'red' }, children: "*" }) : '', ":"] }) })),
                }, children: teams.map((teamObject) => (_jsxs(MenuItem, { value: teamObject._id, onClick: () => handleMenuItemClick(teamObject._id), sx: (theme) => ({
                        backgroundColor: 'transparent',
                        color: theme.palette.text.soft,
                        [`& .${typographyClasses.caption}`]: {
                            fontSize: theme.typography.pxToRem(14),
                        },
                        '&:hover': {
                            backgroundColor: theme.palette.action.hover,
                        },
                        '&.Mui-selected': {
                            backgroundColor: 'transparent !important',
                            color: theme.palette.text.primary,
                        },
                    }), children: [teamObject.name, teamObject._id === team && (_jsx(DoneIcon, { sx: (theme) => ({
                                fontSize: 18,
                                color: theme.palette.text.title,
                                marginLeft: 'auto',
                            }) }))] }, teamObject._id))) }) }) }));
};
export default TeamDropdown;
