import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTheme, Box, ButtonBase, Collapse, collapseClasses, Typography, typographyClasses } from '@mui/material';
import { Skeleton } from '@securecodewarrior/design-system-react';
import { Trans, useTranslation } from 'react-i18next';
import { visuallyHidden } from '@mui/utils';
import { forwardRef } from 'react';
import { getDaysLeft } from '../../../dateHelper';
export const QuestCard = forwardRef(function QC(props, ref) {
    const { t } = useTranslation();
    const expanded = props.expanded;
    const questArray = 'skeleton' in props ? undefined : Array.isArray(props.quests) ? props.quests : [props.quests];
    const title = questArray
        ? (questArray === null || questArray === void 0 ? void 0 : questArray.length) === 1
            ? questArray[0].name
            : t('pages.myQuests.questCard.yourTraining')
        : undefined;
    const pxToRem = useTheme().typography.pxToRem;
    return (_jsxs(ButtonBase, { ref: ref, sx: (theme) => ({
            display: 'inline-flex',
            flexDirection: 'column',
            '&:focus-visible .quest-card': {
                outline: `2px solid ${theme.palette.clickable.default}`,
                outlineOffset: '2px',
            },
            '&:hover .quest-card': {
                border: `1px solid ${theme.palette.container.border.active}`,
            },
            '&:hover .quest-image': {
                filter: 'brightness(0.7)',
            },
            textAlign: 'unset',
        }), onClick: 'onClick' in props ? props.onClick : undefined, "aria-label": 'aria-label' in props ? props['aria-label'] : undefined, children: [_jsxs(Box, { className: "quest-card", sx: (theme) => ({
                    display: 'inline-flex',
                    mb: 4,
                    mt: 5,
                    overflow: 'hidden',
                    border: `1px solid ${expanded ? theme.palette.container.border.active : theme.palette.container.border.default}`,
                    borderRadius: theme.shape.border.radius.md,
                }), children: [questArray ? (_jsx(QuestImage, { imgs: questArray.map((quest) => quest.imageUrl) })) : (_jsx(Skeleton, { variant: "rectangular", sx: { width: pxToRem(100), height: pxToRem(100), transform: 'unset' } })), _jsxs(Collapse, { orientation: "horizontal", in: expanded, sx: (theme) => ({
                            height: pxToRem(100),
                            backgroundColor: theme.palette.container.fill.body,
                            [`.${collapseClasses.wrapperInner}`]: {
                                // Min width when expanded should be 300px. Can't put it outside the collapse or the collapse transition will break.
                                // In here it should be 198 because 198 + 100px (QuestImage) + 2px (borders) = 300px
                                minWidth: expanded ? pxToRem(198) : undefined,
                                // Same for max width, which is 460px
                                // But it also needs to limit to the max amount of space available or it will overflow the QuestFilterTabs container
                                // See https://github.com/SecureCodeWarrior/platform/pull/7538#discussion_r1873459161
                                //             -- 460px max -        ----- container width -------   --------------- sum of gaps ----------------   ---------- sum of QuestImage -----------   -sum of QuestImage borders--   divider width
                                maxWidth: `min(${pxToRem(358)}, calc(1040px - ${theme.spacing(20)} - ${theme.spacing(5)} * ${props.numberOfCards} - ${pxToRem(100)} * ${props.numberOfCards} - 2px * ${props.numberOfCards} - 4px))`,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignSelf: 'center',
                                padding: theme.spacing(5, 7, 5, 5),
                                gap: 1,
                                [`.${typographyClasses.root}`]: {
                                    maxWidth: pxToRem(320),
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                },
                            },
                        }), children: [_jsxs(Typography, { variant: "h5", title: title, component: title ? 'h5' : 'div', children: [_jsx("span", { style: visuallyHidden, children: "selected" }), title !== null && title !== void 0 ? title : _jsx(Skeleton, {})] }), _jsx(Typography, { variant: "caption", sx: (theme) => ({ color: theme.palette.text.body }), children: questArray === undefined ? (_jsx(Skeleton, { width: "70%" })) : questArray.length === 1 ? (_jsx(Trans, { i18nKey: "pages.myQuests.questCard.expiresIn", values: { count: getDaysLeft(questArray[0].deadline) } })) : (t('pages.myQuests.questCard.allQuests')) })] })] }), _jsx(Box, { sx: (theme) => ({
                    visibility: expanded ? 'visible' : 'hidden',
                    width: '100%',
                    height: '4px',
                    bgcolor: theme.palette.container.border.active,
                    borderTopLeftRadius: '4px',
                    borderTopRightRadius: '4px',
                }) })] }));
});
const QuestImage = ({ imgs }) => (_jsxs(Box, { className: "quest-image", "aria-hidden": "true", sx: (theme) => ({
        width: theme.typography.pxToRem(100),
        height: theme.typography.pxToRem(100),
        display: 'grid',
        grid: imgs.length === 1 ? '1fr / 1fr' : '1fr 1fr / 1fr 1fr',
        overflow: 'hidden',
        div: {
            background: `center / cover ${theme.palette.container.fill.card1}`,
            '&:nth-of-type(2),&:nth-of-type(3)': {
                backgroundColor: theme.palette.container.fill.card2,
            },
        },
    }), children: [_jsx("div", { style: { backgroundImage: imgs[0] && `url('${imgs[0]}')` } }), _jsx("div", { style: { backgroundImage: imgs[1] && `url('${imgs[1]}')` } }), _jsx("div", { style: { backgroundImage: imgs[2] && `url('${imgs[2]}')` } }), _jsx("div", { style: { backgroundImage: imgs[3] && `url('${imgs[3]}')` } })] }));
