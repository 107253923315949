var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTheme, Autocomplete, autocompleteClasses, Divider, } from '@mui/material';
import { useState } from 'react';
import { ChipWithClose } from '../../../common/components/atoms/Chip/Chip';
import { TagInputListbox } from './TagInputListBox';
import { KeyboardArrowDown, CheckRounded } from '@mui/icons-material';
import { Badge } from '@securecodewarrior/design-system-react/lib/wanda';
import { useTranslation } from 'react-i18next';
import { TagInputOption, TagInputTextField } from './TagInput.styled';
export const TagInput = (_a) => {
    var _b;
    var { options: initialOptions, onChange, disabled } = _a, props = __rest(_a, ["options", "onChange", "disabled"]);
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.userManagement' });
    const theme = useTheme();
    const [value, setValue] = useState((_b = props.value) !== null && _b !== void 0 ? _b : []);
    const [options, setOptions] = useState([...initialOptions]);
    const [inputValue, setInputValue] = useState('');
    const [createdOption, setCreatedOption] = useState('');
    const tagRegex = /[ !"#$%&'`(){}|\\[\]^*+\-./:;<=>?@~\\\w\d]+/g;
    const cleanValue = (val) => val.trim();
    const handleChange = (_, newValue) => {
        let processedValue = newValue.map(cleanValue);
        if (processedValue.some((option) => option === createdOption)) {
            if (!tagRegex.test(inputValue)) {
                return;
            }
            processedValue = processedValue.map((option) => (option === createdOption ? cleanValue(inputValue) : option));
        }
        setOptions([
            ...options,
            ...processedValue.filter((option) => !options.some((opt) => opt.toLowerCase() === option.toLowerCase())),
        ]);
        onChange(processedValue);
        setValue(processedValue);
        setCreatedOption('');
    };
    const filterOptions = (options, state) => {
        const trimmed = state.inputValue.trim();
        const filtered = options.filter((option) => option.toLowerCase().includes(trimmed.toLowerCase()));
        if (trimmed !== '' && !options.some((option) => option.toLowerCase() === trimmed.toLowerCase())) {
            filtered.push(t('createNewTag', { tag: trimmed }));
        }
        return filtered;
    };
    const handleInputChange = (_, newValue) => {
        const trimmedInputValue = newValue.trim();
        if (trimmedInputValue !== '' && !options.some((option) => option === trimmedInputValue)) {
            setCreatedOption(t('createNewTag', { tag: trimmedInputValue.trim() }));
        }
        setInputValue(trimmedInputValue.trim());
    };
    const renderTags = (value, getTagProps) => {
        return value.map((option, index) => {
            const { key } = getTagProps({ index });
            const handleDelete = disabled
                ? undefined
                : () => {
                    const current = value.filter((item) => item !== option);
                    setValue(current);
                    onChange(current);
                };
            return _jsx(ChipWithClose, { label: option, onDelete: handleDelete, disableFirstLetterCapitalization: true }, key);
        });
    };
    const handleKeyboardInput = (event) => {
        if (!tagRegex.test(inputValue)) {
            return;
        }
        if (event.key === 'Enter') {
            event.preventDefault();
            const cleanedInputValue = inputValue.trim();
            if (cleanedInputValue !== '' &&
                !options.some((option) => option.toLowerCase() === cleanedInputValue.toLowerCase())) {
                setValue([...value, cleanedInputValue]);
                setOptions([...options, cleanedInputValue]);
                onChange([...value, cleanedInputValue]);
                setInputValue('');
            }
        }
    };
    return (_jsx(Autocomplete, { multiple: true, value: value, selectOnFocus: true, clearOnBlur: true, handleHomeEndKeys: true, autoHighlight: true, disabled: disabled, options: options, limitTags: 3, getLimitTagsText: (more) => _jsx(Badge, { children: `+${more}` }), ListboxComponent: TagInputListbox, onKeyDown: handleKeyboardInput, onChange: handleChange, filterOptions: filterOptions, onInputChange: handleInputChange, popupIcon: _jsx(KeyboardArrowDown, {}), renderTags: renderTags, renderOption: (props, option) => {
            return (_jsxs(_Fragment, { children: [option === createdOption && (_jsx(Divider, { sx: { marginBlock: theme.spacing(1), '&:first-child': { display: 'none !important' } } })), _jsxs(TagInputOption, Object.assign({}, props, { className: `${props.className} ${option === createdOption && 'create'}`, children: [option, option !== createdOption && _jsx(CheckRounded, {})] }))] }));
        }, renderInput: (params) => (_jsx(TagInputTextField, Object.assign({}, params, { label: `${t('tags')}:`, value: inputValue, placeholder: !disabled ? t('typeToSearch') : '', onChange: (e) => setInputValue(e.target.value), InputLabelProps: {
                disableAnimation: true,
                shrink: false,
                sx: Object.assign(Object.assign({ transform: `translate(12px, 10px)`, left: 0 }, theme.typography.caption), { color: `${theme.palette.text.soft} !important` }),
            } }))), slotProps: {
            paper: {
                sx: {
                    background: theme.palette.container.fill.card1,
                    borderRadius: `0 0 ${theme.shape.borderRadius * 2}px ${theme.shape.borderRadius * 2}px`,
                    boxShadow: 'unset',
                    border: `1px solid ${theme.palette.clickable.default}`,
                    borderTop: '0px',
                    [`& .${autocompleteClasses.noOptions}`]: { padding: 0 },
                    [`& .${autocompleteClasses.listbox}`]: {
                        borderRadius: `0 0 ${theme.shape.borderRadius * 2}px ${theme.shape.borderRadius * 2}px`,
                    },
                    [`& .${autocompleteClasses.option}.Mui-focused, & .${autocompleteClasses.option}.Mui-focusVisible`]: {
                        background: theme.palette.container.fill.card2,
                    },
                },
            },
            listbox: {
                sx: {
                    maxHeight: '235px',
                    overflowY: 'auto',
                },
            },
        }, sx: {
            minHeight: 36,
            '&.Mui-expanded': {
                '& .MuiInputBase-root': {
                    borderRadius: `${theme.shape.borderRadius * 2}px ${theme.shape.borderRadius * 2}px 0 0 !important`,
                },
            },
            '& .MuiInputBase-root.MuiOutlinedInput-root': {
                '&.Mui-focused': {
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: `1px solid ${theme.palette.clickable.default}`,
                    },
                },
            },
            [`& .${autocompleteClasses.clearIndicator}, & .${autocompleteClasses.popupIndicator}`]: {
                color: theme.palette.clickable.default,
            },
            [`& .${autocompleteClasses.tag}`]: {
                margin: 0,
            },
        } }));
};
