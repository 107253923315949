var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useTrpc } from '../../common/hooks';
import { useFetchWithPlatformSessionHeader } from '../../../ApiContext';
import { teamDetailsSchema } from '../../common/schema';
const createRequestInit = (method, body) => ({
    method,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
});
export const useTeamApis = () => {
    const { trpc } = useTrpc();
    const { fetchWithSession, apiEndpoint } = useFetchWithPlatformSessionHeader();
    const teamEndpoint = `${apiEndpoint}/management/admin/teams`;
    const getTeams = () => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield trpc.userManagement.getTeams.query();
        if (response.kind !== 'ok')
            throw new Error(response.message);
        return response.teams;
    });
    const getTeam = (teamId) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield fetchWithSession(`${teamEndpoint}/${teamId}`);
        if (!response.ok) {
            const errorData = yield response.json().catch(() => ({}));
            throw new Error(errorData.message || `Failed to fetch team: ${response.statusText}`);
        }
        const body = yield response.json();
        return teamDetailsSchema.parse(body);
    });
    const addTeam = (data) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield fetchWithSession(teamEndpoint, createRequestInit('POST', data));
        if (!response.ok) {
            const errorData = yield response.json().catch(() => ({}));
            throw new Error(errorData.message || errorData.error || response.statusText || 'Failed to create team');
        }
        const body = yield response.json();
        return teamDetailsSchema.parse(body);
    });
    const deleteTeam = (teamId) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield fetchWithSession(`${teamEndpoint}/${teamId}`, createRequestInit('DELETE', {}));
        return response.ok;
    });
    const enableTeam = (data) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield fetchWithSession(`${teamEndpoint}/${data._id}`, createRequestInit('PUT', Object.assign(Object.assign({}, data), { status: 'enabled' })));
        return response.ok;
    });
    const disableTeam = (data) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield fetchWithSession(`${teamEndpoint}/${data._id}`, createRequestInit('PUT', Object.assign(Object.assign({}, data), { status: 'disabled' })));
        return response.ok;
    });
    const editTeam = (payload) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        const response = yield fetchWithSession(`${teamEndpoint}/${payload._id}`, createRequestInit('PUT', Object.assign(Object.assign({}, payload), { activationDate: (_a = payload.activationDate) === null || _a === void 0 ? void 0 : _a.valueOf(), expirationDate: (_b = payload.expirationDate) === null || _b === void 0 ? void 0 : _b.valueOf() })));
        if (!response.ok) {
            const errorData = yield response.json().catch(() => ({}));
            throw new Error(errorData.message || response.statusText || 'Failed to edit team');
        }
        const data = yield response.json();
        return teamDetailsSchema.parse(data);
    });
    return {
        getTeams,
        getTeam,
        addTeam,
        deleteTeam,
        enableTeam,
        disableTeam,
        editTeam,
    };
};
