/*
Language: Apex
Author: David Schach <dschach@x2od.com>
Category: Salesforce, Force.com, Lightning Platform, Salesforce Platform
Website: https://developer.salesforce.com/
*/
function source(re) {
    if (!re)
        return null;
    if (typeof re === 'string')
        return re;
    return re.source;
}
function either(...args) {
    return '(' + args.map((x) => source(x)).join('|') + ')';
}
function concat(...args) {
    return args.map((x) => source(x)).join('');
}
//function lookahead(re: RegExp | string): string {
//    return concat('(?=', re, ')');
//}
export default function (hljs) {
    const APEX_IDENT_RE = '[a-zA-Z][a-zA-Z_0-9]*';
    // const APEX_ALPHA_UNDER = '[a-zA-Z][a-zA-Z_]*';
    const NUMBER = {
        className: 'number',
        variants: [
            {
                match: /\b[0-9]+(?:\.[0-9]+)?/,
            },
            {
                match: /\s(?:[0-9,]+)?\.[0-9]+/,
            },
            {
                // hex
                // 0b binary-digits integer-type-suffix[opt] OR 0B binary-digits integer-type-suffix[opt]
                match: /\b0(x|X)[0-9a-fA-F_]+(U|u|L|l|UL|Ul|uL|ul|LU|Lu|lU|lu)?\b/,
            },
            {
                // numeric binary
                match: /\b0(b|B)[01_]+(U|u|L|l|UL|Ul|uL|ul|LU|Lu|lU|lu)?\b/,
            },
            {
                // numeric decimal
                // decimal-digits . decimal-digits exponent-part[opt] real-type-suffix[opt] OR . decimal-digits exponent-part[opt] real-type-suffix[opt]
                match: /\b([0-9_]+)?\.[0-9_]+((e|E)[0-9]+)?(F|f|D|d|M|m)?\b/,
            },
            {
                // numeric decimal
                // decimal-digits exponent-part real-type-suffix[opt]
                match: /\b[0-9_]+(e|E)[0-9_]+(F|f|D|d|M|m)?\b/,
            },
            {
                // numeric decimal
                // decimal-digits real-type-suffix
                match: /\b[0-9_]+(F|f|D|d|M|m)\b/,
            },
            {
                // numeric decimal
                // decimal-digits integer-type-suffix[opt]
                match: /\b[0-9_]+(U|u|L|l|UL|Ul|uL|ul|LU|Lu|lU|lu)?\b/,
            },
        ],
        relevance: 0,
    };
    const MAIN_KEYWORDS = [
        'trigger|10',
        'class',
        'interface',
        'abstract',
        'AccessLevel',
        'USER_MODE',
        'SYSTEM_MODE',
        'AccessType',
        'break',
        'cast',
        'catch',
        'continue',
        'default',
        'do',
        'else',
        'exports',
        'extends|6',
        'finally',
        'for',
        'get',
        'put',
        'set',
        'global',
        'if',
        'implements',
        'new',
        'newMap|10',
        'old|10',
        'oldMap|10',
        'operationType',
        'override',
        'private',
        'protected',
        'public',
        'return',
        'size',
        'static',
        'throws',
        'throw',
        'testmethod|10',
        'try',
        'virtual',
        'webservice',
        'when',
        'while',
    ];
    const LANGUAGE_VARS = ['final', 'instanceof', 'super', 'this', 'transient'];
    const LITERALS = ['false', 'true', 'null'];
    const TYPES = [
        'anytype',
        'blob|0',
        'boolean|0',
        'byte|0',
        'currency|0',
        'date|0',
        'datetime|0',
        'decimal|0',
        'double|0',
        'enum|0',
        'float|0',
        'integer|0',
        'long|0',
        'object',
        'pagereference|10',
        'selectoption|10',
        'short|0',
        'sobject|10',
        'string|0',
        'time|0',
        'void|0',
        'float|0',
    ];
    const BUILT_INS = ['finish', 'start', 'execute'];
    const DMLS = ['insert', 'update', 'upsert|8', 'delete', 'undelete', 'merge', 'convertLead|10'];
    const IMPORTANT_WORDS = {
        beginKeywords: 'schedulable batchable queueable comparable callable',
        relevance: 10,
    };
    const KEYWORDS = {
        $pattern: '[A-Za-z][0-9A-Za-z$_]*',
        keyword: MAIN_KEYWORDS,
        'variable.language': LANGUAGE_VARS,
        built_in: BUILT_INS.concat(DMLS),
        type: TYPES,
        literal: LITERALS,
    };
    const COMPOUND_KEYWORDS = {
        match: /\b(switch\s+on|as\s+user|as\s+system)\b/,
        relevance: 8,
        className: 'keyword',
    };
    const NAMESPACE_LIST = [
        'ApexPages|10',
        'AppLauncher',
        'Approval',
        'Assert',
        'Auth',
        'Cache',
        'Canvas',
        'ChatterAnswers|10',
        'CommercePayments|10',
        'ConnectApi|10',
        'Database',
        'Datacloud|10',
        'Dataweave|10',
        'DataSource|10',
        'Dom',
        'EventBus|10',
        'ExternalService',
        'Flow',
        'Functions',
        'Invocable',
        'KbManagement|10',
        'Label',
        'LxScheduler|10',
        'Messaging',
        'Metadata',
        'Pref_center|10',
        'Process',
        'QuickAction',
        'Reports',
        'RichMessageing',
        'Savepoint',
        'SchedulableContext',
        'Schema',
        'Search',
        'Sfc|10',
        'Sfdc_Checkout|10',
        'sfdc_surveys|10',
        'Site',
        'Support',
        'System',
        'TerritoryMgmt|10',
        'Test',
        'Trigger|10',
        'TxnSecurity|10',
        'Type',
        'UserProvisioning|10',
        'VisualEditor|10',
        'Wave|10',
    ];
    const OPERATOR_REGEX = [
        /-/,
        /--/,
        /~/,
        /\*/,
        /\*=/,
        /\/=/,
        /%/,
        /\+/,
        /\+\+/,
        /<</,
        />>/,
        />=/,
        /<=/,
        /\s<\s/,
        /\s>\s/,
        /\^/,
        /\^=/,
        /!=/,
        /!/,
        /==/,
        /&&/,
        /&/,
        /\|\|/,
        /\|/,
        /(?<=\s)\?|:(?=\s)/,
        /=/,
        /=>/,
        /\?\./,
    ];
    const OPERATORS = {
        match: either(...OPERATOR_REGEX),
        className: 'operator',
        relevance: 0,
    };
    const NAMESPACES = {
        begin: concat('\\b', either(...NAMESPACE_LIST)),
        end: '.',
        excludeEnd: true,
        className: 'built_in',
        relevance: 10,
    };
    const CLASS_SHARING = {
        relevance: 10,
        match: /\b(with|without|inherited)\s+sharing\b/,
        className: 'keyword',
    };
    const COMMENT_LINE = hljs.COMMENT('//', /[$\n]/);
    const COMMENT_BLOCK = hljs.COMMENT('/\\*', '\\*/', {
        relevance: 0,
        contains: [
            {
                // eat up @'s in emails to prevent them to be recognized as doctags
                begin: /\w+@/,
                relevance: 0,
            },
            {
                className: 'doctag',
                begin: '@[A-Za-z_]+',
            },
            {
                begin: '`',
                end: '`',
                excludeBegin: true,
                excludeEnd: true,
                className: 'code',
                contains: [hljs.BACKSLASH_ESCAPE],
                relevance: 0,
            },
            hljs.APOS_STRING_MODE,
            {
                begin: /(?<=@param)\s+/,
                excludeBegin: true,
                end: /\w+/,
                className: 'variable',
            },
        ],
    });
    const ANNOTATIONS = {
        relevance: 10,
        className: 'meta',
        // We will allow any annotation, so we do not need to maintain this as often
        match: '@' + APEX_IDENT_RE,
    };
    // const COLLECTION_MAP_VALUE: Mode[] = [
    //     {
    //         match: [concat(/\b/, APEX_IDENT_RE, /\b/), />/],
    //         scope: {
    //             1: 'type',
    //         },
    //         relevance: 10,
    //     },
    // ];
    // const COLLECTION_DECLARATION: Mode[] = [
    //     {
    //         match: [/\b(list|set|map)\s*/, '<', /[\.\w]+/],
    //         scope: { 1: 'type', 3: 'type' },
    //         relevance: 10,
    //     },
    //     {
    //         match: [APEX_IDENT_RE, lookahead(/\s*\[\]/)],
    //         scope: {
    //             1: 'type',
    //         },
    //     },
    // ];
    // const METHOD_CALL: Mode = {
    //     variants: [
    //         {
    //             match: [/\./, concat('(?:' + APEX_IDENT_RE + ')'), /(?=\s*\(\))/],
    //             scope: { 2: 'title.function.invoke' },
    //         },
    //         {
    //             match: [/\./, concat('(?:' + APEX_IDENT_RE + ')'), /(?=\s*\([^\)])/],
    //             scope: { 2: 'title.function.invoke' },
    //         },
    //         {
    //             match: [/(?<=\s)/, concat('(?:' + APEX_IDENT_RE + ')'), /(?=\s*\()/],
    //             scope: { 2: 'title.function' },
    //         },
    //     ],
    //     contains: [COMMENT_LINE, COMMENT_BLOCK, hljs.APOS_STRING_MODE],
    //     relevance: 0,
    // };
    const SOQL_KEYWORDS = [
        'ABOVE_OR_BELOW',
        'ABOVE',
        'ACTIVE',
        'ADVANCED',
        'ALL',
        /ALL\s+FIELDS/,
        'AND',
        'ANY',
        'ARRAY',
        'AS',
        'ASC',
        'BY',
        'CATEGORY',
        'CONTAINS',
        'COUNT',
        'COUNT_DISTINCT',
        'SUM',
        'MAX',
        'MIN',
        'HOUR_IN_DAY',
        'CONVERTCURRENCY',
        'CUBE',
        'DATA',
        'DESC',
        'DIVISION',
        'END',
        'EXCLUDES',
        'FIELDS',
        'FIND|10',
        'FIRST',
        'FOR',
        'FROM',
        /GROUP\s+BY/,
        'HAVING',
        'INCLUDES',
        'LAST',
        'LAST_90_DAYS',
        'LAST_MONTH',
        'LAST_N_DAYS',
        'LAST_WEEK',
        'LAST',
        'LIKE',
        'LIMIT',
        'NETWORK',
        'NEXT_90_DAYS',
        'NEXT_MONTH',
        'NEXT_N_DAYS',
        'NEXT_WEEK',
        'NULLS',
        'OFFSET',
        'ON',
        'OR',
        /ORDER\s+BY/,
        'REFERENCE',
        'RETURNING',
        'ROLLUP',
        'ROWS',
        'SEARCH',
        'SECURITY_ENFORCED',
        'SELECT',
        'SNIPPET',
        'SORT',
        'THIS_MONTH',
        'THIS_WEEK',
        'TODAY',
        'TOLABEL',
        'TOMORROW',
        'TRACKING',
        'TYPEOF',
        'UPDATE',
        /USING\s+SCOPE/,
        'VIEW',
        'VIEWSTAT',
        'VIEWSTATE',
        'WHERE',
        'WITH',
        'YESTERDAY',
        'USER_MODE',
    ];
    const SOQL_QUERY = {
        begin: /\[[\s\n]*(?=(SELECT|FIND))/,
        end: /\]/,
        className: 'subst',
        relevance: 10,
        contains: [
            {
                begin: concat(/\b/, either(...SOQL_KEYWORDS), /\b/),
                className: 'keyword',
            },
            {
                match: /(\bIN\b|<|<=|>|>=|\bNOT\s+IN\b|=|!\s*=|\s:{1}|:{1}\s)/,
                className: 'literal',
            },
            {
                match: /(?<=\bFROM\b\s+)\w+/,
                className: 'type',
                relevance: 0,
            },
            // {
            //     match: [concat(/\b/, APEX_ALPHA_UNDER), ':', /[0-9]+\b/],
            //     scope: {
            //         1: 'keyword',
            //         3: 'number',
            //     },
            //     relevance: 10,
            // },
            NUMBER,
            // METHOD_CALL,
            hljs.APOS_STRING_MODE,
        ],
        illegal: '::',
    };
    // const APEX_DECLARATIONS: Mode[] = [
    //     {
    //         match: [/\b(?<=enum|\bnew)/, /\s+/, APEX_IDENT_RE, /\s*(?=[{()])/],
    //         scope: {
    //             3: 'type',
    //         },
    //         contains: [COMMENT_LINE, COMMENT_BLOCK], // , CUSTOM_OBJECT
    //     },
    //     // Class Name
    //     {
    //         match: [/(?<=\bclass\b)/, /\s+/, APEX_IDENT_RE],
    //         scope: {
    //             //1: 'keyword',
    //             3: 'title.class',
    //         },
    //     },
    //     // Constructor
    //     {
    //         match: [/(?<=(public|private))/, /\s+/, APEX_IDENT_RE, /(?=\s*\(.*\)\s*{)/], // /(?=\s*\()/],
    //         scope: {
    //             3: 'constructor',
    //         },
    //     },
    //     // Trigger
    //     {
    //         begin: [/(?<=\btrigger\b)/, /\s+/, APEX_IDENT_RE, /\s+/, 'on', /\s+/, APEX_IDENT_RE],
    //         end: '{',
    //         scope: {
    //             //1: 'keyword',
    //             3: 'title.class',
    //             7: 'type',
    //         },
    //         contains: [
    //             COMMENT_LINE,
    //             COMMENT_BLOCK,
    //             {
    //                 match: /(?:before|after)\s+(?:insert|update|delete|undelete)/,
    //                 className: 'built_in',
    //                 relevance: 10,
    //             },
    //         ],
    //         relevance: 10,
    //     },
    //     // extending
    //     {
    //         match: [/(?:extends)/, /\s+/, APEX_IDENT_RE],
    //         scope: {
    //             3: 'title.class.inherited',
    //         },
    //         illegal: [/\b_/, /_\b/],
    //     },
    // ];
    //
    // const MERGE_FIELDS: Mode = {
    //     begin: ['{', /\$[a-zA-Z]+]/, '.', /\w+/],
    //     end: '}',
    //     scope: {
    //         2: 'built_in',
    //         4: 'property',
    //     },
    // };
    //
    // const FOR_LOOP: Mode = {
    //     variants: [
    //         {
    //             //for (APTask__c apTask : myTasks
    //             match: [/\bfor\b/, /\s*\(/, APEX_IDENT_RE, /\s+/, APEX_IDENT_RE, /\s+:/, /(?=\s*\[)/],
    //             scope: {
    //                 1: 'keyword',
    //                 3: 'type',
    //                 //5: 'variable'
    //             },
    //         },
    //         {
    //             match: [
    //                 /\bfor\b/,
    //                 /\s*\(/,
    //                 APEX_IDENT_RE,
    //                 /\s+/,
    //                 APEX_IDENT_RE,
    //                 /\s+:/,
    //                 /\s*/,
    //                 APEX_IDENT_RE,
    //             ],
    //             scope: {
    //                 1: 'keyword',
    //                 3: 'type',
    //                 //5: 'variable',
    //                 8: 'variable',
    //             },
    //         },
    //     ],
    //     contains: [COMMENT_LINE, COMMENT_BLOCK, SOQL_QUERY],
    // };
    //
    // const ASSIGNMENTS: Mode[] = [
    //     {
    //         match: [APEX_IDENT_RE, /\s+/, APEX_IDENT_RE, /\s+/, /=/],
    //         scope: {
    //             1: 'type',
    //             3: 'variable',
    //             5: 'operator',
    //         },
    //         relevance: 0,
    //     },
    //     {
    //         match: [APEX_IDENT_RE, /\s+/, APEX_IDENT_RE, /\s+/, ';'],
    //         scope: {
    //             1: 'type',
    //             3: 'variable',
    //         },
    //         relevance: 0,
    //     },
    //     {
    //         match: [/\s+/, APEX_IDENT_RE, /\s+/, /=/],
    //         scope: {
    //             2: 'variable',
    //             4: 'operator',
    //         },
    //         relevance: 0,
    //     },
    //     {
    //         match: [/(?<=\w+\s+=\s+\()/, APEX_IDENT_RE, /(?=\))/],
    //         scope: {
    //             2: 'type',
    //         },
    //         relevance: 0,
    //     },
    // ];
    const SALESFORCE_ID = {
        match: /(?<!\.)\bId\b/,
        className: 'type',
        relevance: 8,
    };
    const ILLEGALS = [
        '</',
        '<#',
        '<]',
        '<div>',
        '<!--',
        '!DOCTYPE',
        /<iframe\b/,
        /^#/,
        /^import \.[a-zA-Z]+\./,
        /^import [\w]+/,
        /^import$/,
        /^include </,
        /^use\s+</,
        /\b(const|var)\s+\w+\s*=/,
        /\bstruct\b/,
        'System.log',
        'console.log',
        /\bfor\s+\w+\s+IN\s+/,
        /\bif\s+\w+\s+IN\s+/,
        /\bend\s+if\b/,
        /\bend\s+select\b/,
        /\b(int|var)\s+\w+\s+=/,
        /\b(int[0-9]+|bool)\b/,
        /\b\$/,
        '::=',
        /\s#[a-zA-Z]/,
        /\s_[a-zA-Z]/,
        /\s\$[a-zA-Z]/,
        '#if',
        '%if',
        /\bif(?!\s+\()/, //coffeescript
        '%endif',
        '#endif',
        /\w::\w/,
        /RETURNING\s+\*/,
        /\bint\b/,
        /import\s+\w+\s+=\s+require\("\w+"\)/,
        '/^include\b/',
        /\buse\s+strict\b/,
        /\w+\s+=\s+"\S*";/,
        /\/include\//,
        /\Anamespace\b/,
        /\bend\.\n/,
        /\bend\n/,
        '"""',
    ];
    return {
        name: 'Apex',
        aliases: ['apex', 'lightning'],
        case_insensitive: true, // language is case-insensitive
        disableAutodetect: false,
        keywords: KEYWORDS,
        illegal: ILLEGALS,
        contains: [
            ANNOTATIONS,
            // APEX_DECLARATIONS,
            // ASSIGNMENTS,
            CLASS_SHARING,
            // COLLECTION_DECLARATION,
            // COLLECTION_MAP_VALUE,
            COMMENT_BLOCK,
            COMMENT_LINE,
            COMPOUND_KEYWORDS,
            // FOR_LOOP,
            hljs.APOS_STRING_MODE,
            // METHOD_CALL,
            // MERGE_FIELDS,
            NAMESPACES,
            NUMBER,
            OPERATORS,
            SALESFORCE_ID,
            SOQL_QUERY,
            IMPORTANT_WORDS,
        ],
    };
}
