var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useContext } from 'react';
import { SingleActionContext } from '../../common/context';
import { useTeamMutation } from './useTeamMutation';
// eslint-disable-next-line sonarjs/cognitive-complexity
export const useTeamSingleAction = () => {
    const ctx = useContext(SingleActionContext);
    if (!ctx) {
        throw new Error('useTeamSingleAction must be used within a SingleActionProvider');
    }
    const { mutate } = useTeamMutation();
    const setTeamId = (teamId) => ctx.dispatch({ type: 'SET_TEAM', payload: teamId });
    const setActionType = (actionType) => ctx.dispatch({ type: 'SET_TEAM_ACTION', payload: actionType });
    const clearAction = () => ctx.dispatch({ type: 'RESET' });
    const confirmAction = (data) => __awaiter(void 0, void 0, void 0, function* () {
        if (ctx.state.teamId && ctx.state.teamActionType) {
            switch (ctx.state.teamActionType) {
                case 'TEAM_ENABLE':
                    return data ? mutate({ type: 'enableTeam', payload: data }) : undefined;
                case 'TEAM_DISABLE':
                    return data ? mutate({ type: 'disableTeam', payload: data }) : undefined;
                case 'TEAM_DELETE':
                    return mutate({ type: 'deleteTeam', payload: ctx.state.teamId });
                case 'TEAM_EDIT':
                    return data ? mutate({ type: 'editTeam', payload: data }) : undefined;
            }
        }
    });
    return {
        teamId: ctx.state.teamId,
        actionType: ctx.state.teamActionType,
        setTeamId,
        setActionType,
        clearAction,
        confirmAction,
    };
};
