var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import { CommunicationsSection as Section } from '../Communications.styled';
import { Link } from '@securecodewarrior/design-system-react/lib/wanda';
import { OptimisticSwitch } from './OptimisticSwitch';
import { SwitchContainer } from './SwitchContainer';
import { useCommunicationPreferences } from '../hooks/useCommunicationPreferences';
import { communicationsAnalytics } from '../Communications.analytics';
import { usePlatformContext } from '../../../platformContext';
export const CommunicationChannels = ({ showToaster }) => {
    var _a;
    const { t } = useTranslation();
    const { preferences, isLoading, savePreferences } = useCommunicationPreferences({ showToaster });
    const { logAnalyticsEvent } = usePlatformContext();
    const analytics = communicationsAnalytics(logAnalyticsEvent);
    const handleSwitchChange = (value) => __awaiter(void 0, void 0, void 0, function* () {
        yield savePreferences({
            communicationChannels: Object.assign(Object.assign({}, preferences === null || preferences === void 0 ? void 0 : preferences.communicationChannels), { msTeams: value }),
        });
        analytics.msTeams.onToggle(value);
    });
    return (_jsxs(Section, { children: [_jsx(Typography, { variant: "h5", children: t('pages.administration.communicationsCentre.communicationChannels.title') }), _jsx(SwitchContainer, { title: t('pages.administration.communicationsCentre.communicationChannels.msTeams.title'), descriptionComponent: _jsx(Typography, { variant: "caption", children: _jsx(Trans, { i18nKey: "pages.administration.communicationsCentre.communicationChannels.msTeams.caption", components: {
                            1: (_jsx(Link, { href: "https://help.securecodewarrior.com/hc/en-us/articles/9118839965967-Enable-Microsoft-Teams-notifications", target: "_blank", rel: "noopener noreferrer" })),
                        } }) }), switchComponent: _jsx(OptimisticSwitch, { checked: (_a = preferences === null || preferences === void 0 ? void 0 : preferences.communicationChannels) === null || _a === void 0 ? void 0 : _a.msTeams, disabled: isLoading, onSave: handleSwitchChange, inputProps: {
                        'aria-label': t('pages.administration.communicationsCentre.communicationChannels.msTeams.title'),
                    } }) })] }));
};
