import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Divider } from '@mui/material';
import { ActionMenuItem, ActionMenu } from '../../../../common/components/styled';
import { DeleteIcon } from '../../../../../common/icons';
import { useTranslation } from 'react-i18next';
export const MenuBody = ({ anchorEl, onClose, onClick, team }) => {
    const { t } = useTranslation('react_components', {
        keyPrefix: 'pages.administration.teamManagement.singleTeamActionMenu',
    });
    return (_jsxs(ActionMenu, { open: !!anchorEl, onClose: onClose, anchorEl: anchorEl, children: [_jsx(ActionMenuItem, { onClick: () => onClick('TEAM_EDIT'), children: t('editTeam') }), team.status === 'disabled' ? (_jsx(ActionMenuItem, { onClick: () => onClick('TEAM_ENABLE'), children: t('enableTeam') })) : (_jsx(ActionMenuItem, { onClick: () => onClick('TEAM_DISABLE'), children: t('disableTeam') })), _jsx(Divider, {}), _jsxs(ActionMenuItem, { hasIcon: true, onClick: () => onClick('TEAM_DELETE'), sx: { color: 'error.main' }, children: [_jsx(DeleteIcon, { sx: { fontSize: 16 } }), t('deleteTeam')] })] }));
};
