import { jsx as _jsx } from "react/jsx-runtime";
import { FilterDropdown } from '../FilterDropdown/FilterDropdown';
import { useCallback } from 'react';
export const SelectionFilterDropdown = ({ value, onChange, label, selectedCount, selectLabel = 'Select', allLabel = 'All', disabled = false, fullWidth = false, }) => {
    const options = {
        all: allLabel,
        select: selectedCount > 0 ? `${selectLabel} (${selectedCount})` : selectLabel,
    };
    const handleChange = useCallback((newValue) => {
        onChange(newValue);
    }, [onChange]);
    return (_jsx(FilterDropdown, { options: options, value: value, onChange: handleChange, label: label, fullWidth: fullWidth, disabled: disabled }));
};
