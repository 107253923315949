export const teamTestData = [
    {
        _id: '674caa80b5114288d52184b4',
        name: 'Team 1',
        tags: ['Tag 1'],
        status: 'enabled',
        createdAt: new Date('2025-02-28T02:38:40.246Z'),
        teamSize: 10,
    },
    {
        _id: '677c89e41cb43844e9669ea8',
        name: 'Team 2',
        tags: ['Tag 3'],
        status: 'enabled',
        createdAt: new Date('2025-02-28T02:38:40.246Z'),
        teamSize: 0,
    },
    {
        _id: '677c89e41cb43844e9669ea9',
        name: 'Team 3',
        tags: ['Tag 1'],
        status: 'enabled',
        createdAt: new Date('2025-02-28T02:38:40.246Z'),
        teamSize: 5,
    },
    {
        _id: '677c89e41cb43844e9669eaa',
        name: 'Team 4',
        tags: ['Tag 2'],
        status: 'enabled',
        createdAt: new Date('2025-02-28T02:38:40.246Z'),
        teamSize: 13,
    },
    {
        _id: '677c89e41cb43844e9669eab',
        name: 'Team 5',
        tags: [],
        status: 'enabled',
        createdAt: new Date('2025-02-28T02:38:40.246Z'),
        teamSize: 8,
    },
    {
        _id: '677c89e41cb43844e9669eac',
        name: 'Team 6',
        tags: [],
        status: 'enabled',
        createdAt: new Date('2025-02-28T02:38:40.246Z'),
        teamSize: 13,
    },
    {
        _id: '677c89e41cb43844e9669ead',
        name: 'Team 7',
        tags: [],
        status: 'enabled',
        createdAt: new Date('2025-02-28T02:38:40.246Z'),
        teamSize: 9,
    },
    {
        _id: '677c89e41cb43844e9669eae',
        name: 'Team 8',
        tags: [],
        status: 'enabled',
        createdAt: new Date('2025-02-28T02:38:40.246Z'),
        teamSize: 9,
    },
    {
        _id: '677c89e41cb43844e9669eaf',
        name: 'Team 9',
        tags: [],
        status: 'enabled',
        createdAt: new Date('2025-02-28T02:38:40.246Z'),
        teamSize: 7,
    },
    {
        _id: '677c89e41cb43844e9669eb0',
        name: 'Team 10',
        tags: [],
        status: 'enabled',
        createdAt: new Date('2025-02-28T02:38:40.246Z'),
        teamSize: 7,
    },
    {
        _id: '677c89e41cb43844e9669eb1',
        name: 'Team 11',
        tags: [],
        status: 'enabled',
        createdAt: new Date('2025-02-28T02:38:40.246Z'),
        teamSize: 5,
    },
    {
        _id: '677c89e41cb43844e9669eb2',
        name: 'Team 12',
        tags: [],
        status: 'enabled',
        createdAt: new Date('2025-02-28T02:38:40.246Z'),
        teamSize: 0,
    },
    {
        _id: '677c89e41cb43844e9669eb3',
        name: 'Team 13',
        tags: [],
        status: 'enabled',
        createdAt: new Date('2025-02-28T02:38:40.246Z'),
        teamSize: 0,
    },
    {
        _id: '677c89e41cb43844e9669eb4',
        name: 'Team 14',
        tags: [],
        status: 'enabled',
        createdAt: new Date('2025-02-28T02:38:40.246Z'),
        teamSize: 0,
    },
    {
        _id: '677c89e41cb43844e9669eb5',
        name: 'Team 15',
        tags: [],
        status: 'enabled',
        createdAt: new Date('2025-02-28T02:38:40.246Z'),
        teamSize: 0,
    },
    {
        _id: '677c89e41cb43844e9669eb6',
        name: 'Team 16',
        tags: [],
        status: 'enabled',
        createdAt: new Date('2025-02-28T02:38:40.246Z'),
        teamSize: 0,
    },
    {
        _id: '677c89e41cb43844e9669eb7',
        name: 'Team 17',
        tags: [],
        status: 'enabled',
        createdAt: new Date('2025-02-28T02:38:40.246Z'),
        teamSize: 0,
    },
    {
        _id: '677c89e41cb43844e9669eb8',
        name: 'Team 18',
        tags: [],
        status: 'enabled',
        createdAt: new Date('2025-02-28T02:38:40.246Z'),
        teamSize: 0,
    },
    {
        _id: '677c89e41cb43844e9669eb9',
        name: 'Team 19',
        tags: [],
        status: 'enabled',
        createdAt: new Date('2025-02-28T02:38:40.246Z'),
        teamSize: 0,
    },
    {
        _id: '677c89e41cb43844e9669eba',
        name: 'Team 20',
        tags: [],
        status: 'enabled',
        createdAt: new Date('2025-02-28T02:38:40.246Z'),
        teamSize: 0,
    },
    {
        _id: '677c89e41cb43844e9669ebb',
        name: 'Team 21',
        tags: [],
        status: 'enabled',
        createdAt: new Date('2025-02-28T02:38:40.246Z'),
        teamSize: 0,
    },
    {
        _id: '677c89e41cb43844e9669ebc',
        name: 'Team 22',
        tags: [],
        status: 'enabled',
        createdAt: new Date('2025-02-28T02:38:40.246Z'),
        teamSize: 0,
    },
    {
        _id: '677c89e41cb43844e9669ebd',
        name: 'Team 23',
        tags: [],
        status: 'enabled',
        createdAt: new Date('2025-02-28T02:38:40.246Z'),
        teamSize: 0,
    },
    {
        _id: '677c89e41cb43844e9669ebe',
        name: 'Team 24',
        tags: [],
        status: 'enabled',
        createdAt: new Date('2025-02-28T02:38:40.246Z'),
        teamSize: 0,
    },
    {
        _id: '677c89e41cb43844e9669ebf',
        name: 'Team 25',
        tags: [],
        status: 'enabled',
        createdAt: new Date('2025-02-28T02:38:40.246Z'),
        teamSize: 0,
    },
];
export const teamDetailsTestData = [
    {
        _id: '674caa80b5114288d52184b4',
        _cid: '674caa80b5114288d52184b3',
        name: 'Team 1',
        languages: [],
        expirationDate: null,
        activationDate: null,
        onboardingMessage: '',
        status: 'enabled',
        defaultRuleSettingsEnabled: true,
        dedupCode: null,
        allowances: {
            training: {
                license: 'unlimited',
                reserved: 0,
                used: 1,
                available: 0,
                granted: 18,
                granted_overrun: 0,
                available_overrun: 0,
                reserved_overrun: 0,
                used_overrun: 0,
            },
            assessments: {
                license: 'unlimited',
                reserved: 6,
                used: 15,
                available: 0,
                granted: 0,
                granted_overrun: 0,
                available_overrun: 0,
                reserved_overrun: 0,
                used_overrun: 0,
            },
            tournaments: {
                license: 'unlimited',
                reserved: 0,
                used: 0,
                available: 0,
                granted: 0,
                granted_overrun: 0,
                available_overrun: 0,
                reserved_overrun: 0,
                used_overrun: 0,
            },
            sensei: {
                license: 'disabled',
                reserved: 0,
                used: 0,
                available: 0,
                granted: 0,
                granted_overrun: 0,
                available_overrun: 0,
                reserved_overrun: 0,
                used_overrun: 0,
            },
        },
        tags: [],
        isTeamKeyEnabled: false,
        apiKeys: [],
    },
    {
        _id: '677c89e41cb43844e9669ea8',
        _cid: '674caa80b5114288d52184b3',
        name: 'Team 1',
        languages: [
            { _id: 'terraform', _framework: 'gcp' },
            { _id: 'typescript', _framework: 'react' },
            { _id: 'typescript', _framework: 'nodejs_express' },
        ],
        expirationDate: null,
        activationDate: null,
        onboardingMessage: '',
        status: 'enabled',
        defaultRuleSettingsEnabled: true,
        dedupCode: null,
        allowances: {
            training: {
                license: 'unlimited',
                available: 0,
                granted: 104,
                reserved: 1,
                used: 3,
                reserved_overrun: 0,
                used_overrun: 0,
                granted_overrun: 0,
                available_overrun: 0,
            },
            assessments: {
                license: 'unlimited',
                reserved_overrun: 0,
                reserved: 0,
                used_overrun: 0,
                used: 0,
            },
            tournaments: { license: 'unlimited' },
            sensei: { license: 'disabled' },
        },
        tags: [],
        isTeamKeyEnabled: false,
        apiKeys: [],
    },
    {
        _id: '677c89e41cb43844e9669ea9',
        _cid: '674caa80b5114288d52184b3',
        name: 'Team 2',
        languages: [
            { _id: 'cloudformation', _framework: 'vanilla' },
            { _id: 'kubernetes', _framework: 'vanilla' },
            { _id: 'rust', _framework: 'vanilla' },
            { _id: 'perl', _framework: 'dancer2' },
            { _id: 'bash', _framework: 'vanilla' },
        ],
        expirationDate: null,
        activationDate: null,
        onboardingMessage: '',
        status: 'enabled',
        defaultRuleSettingsEnabled: true,
        dedupCode: null,
        allowances: {
            training: {
                license: 'unlimited',
                available: 0,
                granted: 3,
                reserved: 2,
                used: 0,
                reserved_overrun: 0,
                used_overrun: 0,
                granted_overrun: 0,
                available_overrun: 0,
            },
            assessments: {
                license: 'unlimited',
                reserved_overrun: 0,
                reserved: 0,
                used_overrun: 0,
                used: 0,
            },
            tournaments: { license: 'unlimited' },
            sensei: { license: 'disabled' },
        },
        tags: [],
        isTeamKeyEnabled: false,
        apiKeys: [],
    },
    {
        _id: '677c89e41cb43844e9669eaa',
        _cid: '674caa80b5114288d52184b3',
        name: 'Team 3',
        languages: [],
        expirationDate: null,
        activationDate: null,
        onboardingMessage: '',
        status: 'enabled',
        defaultRuleSettingsEnabled: true,
        dedupCode: null,
        allowances: {
            training: {
                license: 'unlimited',
                available: 0,
                granted: 4,
                reserved: 0,
                used: 2,
                reserved_overrun: 0,
                used_overrun: 0,
                granted_overrun: 0,
                available_overrun: 0,
            },
            assessments: {
                license: 'unlimited',
                reserved_overrun: 0,
                reserved: 0,
                used_overrun: 0,
                used: 0,
            },
            tournaments: { license: 'unlimited' },
            sensei: { license: 'disabled' },
        },
        tags: [],
        isTeamKeyEnabled: false,
        apiKeys: [],
    },
    {
        _id: '677c89e41cb43844e9669eab',
        _cid: '674caa80b5114288d52184b3',
        name: 'Team 4',
        languages: [
            { _id: 'javascript', _framework: 'vuejs' },
            { _id: 'javascript', _framework: 'react_native' },
            { _id: 'plsql', _framework: 'vanilla' },
            { _id: 'cobol', _framework: 'mainframe' },
            { _id: 'go', _framework: 'vanilla' },
        ],
        expirationDate: null,
        activationDate: null,
        onboardingMessage: '',
        status: 'disabled',
        defaultRuleSettingsEnabled: true,
        dedupCode: null,
        allowances: {
            training: {
                license: 'unlimited',
                available: 0,
                granted: 0,
                reserved: 0,
                used: 0,
                reserved_overrun: 0,
                used_overrun: 0,
                granted_overrun: 0,
                available_overrun: 0,
            },
            assessments: {
                license: 'unlimited',
                reserved_overrun: 0,
                reserved: 0,
                used_overrun: 0,
                used: 0,
            },
            tournaments: { license: 'unlimited' },
            sensei: { license: 'disabled' },
        },
        tags: [],
        isTeamKeyEnabled: false,
        apiKeys: [],
    },
    {
        _id: '677c89e41cb43844e9669eac',
        _cid: '674caa80b5114288d52184b3',
        name: 'Team 5',
        languages: [
            { _id: 'java', _framework: 'vanilla' },
            { _id: 'java', _framework: 'spring' },
            { _id: 'java', _framework: 'struts' },
            { _id: 'java', _framework: 'ee' },
            { _id: 'java', _framework: 'jsf' },
        ],
        expirationDate: null,
        activationDate: null,
        onboardingMessage: '',
        status: 'disabled',
        defaultRuleSettingsEnabled: true,
        dedupCode: null,
        allowances: {
            training: {
                license: 'unlimited',
                available: 0,
                granted: 0,
                reserved: 0,
                used: 0,
                reserved_overrun: 0,
                used_overrun: 0,
                granted_overrun: 0,
                available_overrun: 0,
            },
            assessments: {
                license: 'unlimited',
                reserved_overrun: 0,
                reserved: 0,
                used_overrun: 0,
                used: 0,
            },
            tournaments: { license: 'unlimited' },
            sensei: { license: 'disabled' },
        },
        tags: [],
        isTeamKeyEnabled: false,
        apiKeys: [],
    },
    {
        _id: '677c89e41cb43844e9669ead',
        _cid: '674caa80b5114288d52184b3',
        name: 'Team 6',
        languages: [
            { _id: 'javascript', _framework: 'vuejs' },
            { _id: 'javascript', _framework: 'react_native' },
            { _id: 'plsql', _framework: 'vanilla' },
            { _id: 'cobol', _framework: 'mainframe' },
            { _id: 'go', _framework: 'vanilla' },
        ],
        expirationDate: null,
        activationDate: null,
        onboardingMessage: '',
        status: 'enabled',
        defaultRuleSettingsEnabled: true,
        dedupCode: null,
        allowances: {
            training: {
                license: 'unlimited',
                available: 0,
                granted: 3,
                reserved: 1,
                used: 0,
                reserved_overrun: 0,
                used_overrun: 0,
                granted_overrun: 0,
                available_overrun: 0,
            },
            assessments: {
                license: 'unlimited',
                reserved_overrun: 0,
                reserved: 0,
                used_overrun: 0,
                used: 0,
            },
            tournaments: { license: 'unlimited' },
            sensei: { license: 'disabled' },
        },
        tags: [],
        isTeamKeyEnabled: false,
        apiKeys: [],
    },
    {
        _id: '677c89e41cb43844e9669eae',
        _cid: '674caa80b5114288d52184b3',
        name: 'Team 7',
        languages: [
            { _id: 'terraform', _framework: 'azure' },
            { _id: 'terraform', _framework: 'gcp' },
            { _id: 'typescript', _framework: 'react' },
            { _id: 'typescript', _framework: 'nodejs_express' },
            { _id: 'tsql', _framework: 'vanilla' },
        ],
        expirationDate: null,
        activationDate: null,
        onboardingMessage: '',
        status: 'enabled',
        defaultRuleSettingsEnabled: true,
        dedupCode: null,
        allowances: {
            training: {
                license: 'unlimited',
                available: 0,
                granted: 1,
                reserved: 1,
                used: 0,
                reserved_overrun: 0,
                used_overrun: 0,
                granted_overrun: 0,
                available_overrun: 0,
            },
            assessments: {
                license: 'unlimited',
                reserved_overrun: 0,
                reserved: 0,
                used_overrun: 0,
                used: 0,
            },
            tournaments: { license: 'unlimited' },
            sensei: { license: 'disabled' },
        },
        tags: [],
        isTeamKeyEnabled: false,
        apiKeys: [],
    },
    {
        _id: '677c89e41cb43844e9669eaf',
        _cid: '674caa80b5114288d52184b3',
        name: 'Team 8',
        languages: [
            { _id: 'dart', _framework: 'flutter' },
            { _id: 'terraform', _framework: 'azure' },
            { _id: 'terraform', _framework: 'gcp' },
            { _id: 'typescript', _framework: 'react' },
            { _id: 'typescript', _framework: 'nodejs_express' },
        ],
        expirationDate: null,
        activationDate: null,
        onboardingMessage: '',
        status: 'disabled',
        defaultRuleSettingsEnabled: true,
        dedupCode: null,
        allowances: {
            training: {
                license: 'unlimited',
                available: 0,
                granted: 0,
                reserved: 0,
                used: 0,
                reserved_overrun: 0,
                used_overrun: 0,
                granted_overrun: 0,
                available_overrun: 0,
            },
            assessments: {
                license: 'unlimited',
                reserved_overrun: 0,
                reserved: 0,
                used_overrun: 0,
                used: 0,
            },
            tournaments: { license: 'unlimited' },
            sensei: { license: 'disabled' },
        },
        tags: [],
        isTeamKeyEnabled: false,
        apiKeys: [],
    },
    {
        _id: '677c89e41cb43844e9669eb0',
        _cid: '674caa80b5114288d52184b3',
        name: 'Team 9',
        languages: [
            { _id: 'java', _framework: 'jsf' },
            { _id: 'java', _framework: 'servlets' },
            { _id: 'java', _framework: 'ee_api' },
            { _id: 'java', _framework: 'spring_api' },
            { _id: 'java', _framework: 'android' },
        ],
        expirationDate: null,
        activationDate: null,
        onboardingMessage: '',
        status: 'enabled',
        defaultRuleSettingsEnabled: true,
        dedupCode: null,
        allowances: {
            training: {
                license: 'unlimited',
                available: 0,
                granted: 0,
                reserved: 0,
                used: 0,
                reserved_overrun: 0,
                used_overrun: 0,
                granted_overrun: 0,
                available_overrun: 0,
            },
            assessments: {
                license: 'unlimited',
                reserved_overrun: 0,
                reserved: 0,
                used_overrun: 0,
                used: 0,
            },
            tournaments: { license: 'unlimited' },
            sensei: { license: 'disabled' },
        },
        tags: [],
        isTeamKeyEnabled: false,
        apiKeys: [],
    },
    {
        _id: '677c89e41cb43844e9669eb1',
        _cid: '674caa80b5114288d52184b3',
        name: 'Team 10',
        languages: [
            { _id: 'java', _framework: 'spring_api' },
            { _id: 'java', _framework: 'android' },
            { _id: 'nodejs', _framework: 'express' },
            { _id: 'nodejs', _framework: 'api' },
            { _id: 'python', _framework: 'vanilla' },
        ],
        expirationDate: null,
        activationDate: null,
        onboardingMessage: '',
        status: 'enabled',
        defaultRuleSettingsEnabled: true,
        dedupCode: null,
        allowances: {
            training: {
                license: 'unlimited',
                available: 0,
                granted: 4,
                reserved: 1,
                used: 2,
                reserved_overrun: 0,
                used_overrun: 0,
                granted_overrun: 0,
                available_overrun: 0,
            },
            assessments: {
                license: 'unlimited',
                reserved_overrun: 0,
                reserved: 0,
                used_overrun: 0,
                used: 0,
            },
            tournaments: { license: 'unlimited' },
            sensei: { license: 'disabled' },
        },
        tags: [],
        isTeamKeyEnabled: false,
        apiKeys: [],
    },
    {
        _id: '677c89e41cb43844e9669eb2',
        _cid: '674caa80b5114288d52184b3',
        name: 'Team 11',
        languages: [
            { _id: 'swift', _framework: 'vanilla' },
            { _id: 'kotlin', _framework: 'vanilla' },
            { _id: 'kotlin', _framework: 'spring_api' },
        ],
        expirationDate: null,
        activationDate: null,
        onboardingMessage: '',
        status: 'disabled',
        defaultRuleSettingsEnabled: true,
        dedupCode: null,
        allowances: {
            training: {
                license: 'unlimited',
                available: 0,
                granted: 0,
                reserved: 0,
                used: 0,
                reserved_overrun: 0,
                used_overrun: 0,
                granted_overrun: 0,
                available_overrun: 0,
            },
            assessments: {
                license: 'unlimited',
                reserved_overrun: 0,
                reserved: 0,
                used_overrun: 0,
                used: 0,
            },
            tournaments: { license: 'unlimited' },
            sensei: { license: 'disabled' },
        },
        tags: [],
        isTeamKeyEnabled: false,
        apiKeys: [],
    },
    {
        _id: '677c89e41cb43844e9669eb3',
        _cid: '674caa80b5114288d52184b3',
        name: 'Team 12',
        languages: [
            { _id: 'scala', _framework: 'play' },
            { _id: 'swift', _framework: 'vanilla' },
            { _id: 'kotlin', _framework: 'vanilla' },
            { _id: 'kotlin', _framework: 'spring_api' },
            { _id: 'javascript', _framework: 'vanilla' },
        ],
        expirationDate: null,
        activationDate: null,
        onboardingMessage: '',
        status: 'disabled',
        defaultRuleSettingsEnabled: true,
        dedupCode: null,
        allowances: {
            training: {
                license: 'unlimited',
                available: 0,
                granted: 0,
                reserved: 0,
                used: 0,
                reserved_overrun: 0,
                used_overrun: 0,
                granted_overrun: 0,
                available_overrun: 0,
            },
            assessments: {
                license: 'unlimited',
                reserved_overrun: 0,
                reserved: 0,
                used_overrun: 0,
                used: 0,
            },
            tournaments: { license: 'unlimited' },
            sensei: { license: 'disabled' },
        },
        tags: [],
        isTeamKeyEnabled: false,
        apiKeys: [],
    },
    {
        _id: '677c89e41cb43844e9669eb4',
        _cid: '674caa80b5114288d52184b3',
        name: 'Team 13',
        languages: [
            { _id: 'c_sharp', _framework: 'vanilla' },
            { _id: 'c_sharp', _framework: 'web_api' },
            { _id: 'c_sharp', _framework: 'mvc' },
            { _id: 'c_sharp', _framework: 'web_forms' },
        ],
        expirationDate: null,
        activationDate: null,
        onboardingMessage: '',
        status: 'enabled',
        defaultRuleSettingsEnabled: true,
        dedupCode: null,
        allowances: {
            training: {
                license: 'unlimited',
                available: 0,
                granted: 2,
                reserved: 1,
                used: 1,
                reserved_overrun: 0,
                used_overrun: 0,
                granted_overrun: 0,
                available_overrun: 0,
            },
            assessments: {
                license: 'unlimited',
                reserved_overrun: 0,
                reserved: 0,
                used_overrun: 0,
                used: 0,
            },
            tournaments: { license: 'unlimited' },
            sensei: { license: 'disabled' },
        },
        tags: [],
        isTeamKeyEnabled: false,
        apiKeys: [],
    },
    {
        _id: '677c89e41cb43844e9669eb5',
        _cid: '674caa80b5114288d52184b3',
        name: 'Team 14',
        languages: [
            { _id: 'java', _framework: 'struts' },
            { _id: 'java', _framework: 'ee' },
            { _id: 'java', _framework: 'jsf' },
            { _id: 'java', _framework: 'servlets' },
            { _id: 'java', _framework: 'ee_api' },
        ],
        expirationDate: null,
        activationDate: null,
        onboardingMessage: '',
        status: 'enabled',
        defaultRuleSettingsEnabled: true,
        dedupCode: null,
        allowances: {
            training: {
                license: 'unlimited',
                available: 0,
                granted: 6,
                reserved: 3,
                used: 1,
                reserved_overrun: 0,
                used_overrun: 0,
                granted_overrun: 0,
                available_overrun: 0,
            },
            assessments: {
                license: 'unlimited',
                reserved_overrun: 0,
                reserved: 0,
                used_overrun: 0,
                used: 0,
            },
            tournaments: { license: 'unlimited' },
            sensei: { license: 'disabled' },
        },
        tags: [],
        isTeamKeyEnabled: false,
        apiKeys: [],
    },
    {
        _id: '677c89e41cb43844e9669eb6',
        _cid: '674caa80b5114288d52184b3',
        name: 'Team 15',
        languages: [],
        expirationDate: null,
        activationDate: null,
        onboardingMessage: '',
        status: 'enabled',
        defaultRuleSettingsEnabled: true,
        dedupCode: null,
        allowances: {
            training: {
                license: 'unlimited',
                available: 0,
                granted: 1,
                reserved: 0,
                used: 0,
                reserved_overrun: 0,
                used_overrun: 0,
                granted_overrun: 0,
                available_overrun: 0,
            },
            assessments: {
                license: 'unlimited',
                reserved_overrun: 0,
                reserved: 0,
                used_overrun: 0,
                used: 0,
            },
            tournaments: { license: 'unlimited' },
            sensei: { license: 'disabled' },
        },
        tags: [],
        isTeamKeyEnabled: false,
        apiKeys: [],
    },
    {
        _id: '677c89e41cb43844e9669eb7',
        _cid: '674caa80b5114288d52184b3',
        name: 'Team 16',
        languages: [],
        expirationDate: null,
        activationDate: null,
        onboardingMessage: '',
        status: 'disabled',
        defaultRuleSettingsEnabled: true,
        dedupCode: null,
        allowances: {
            training: {
                license: 'unlimited',
                available: 0,
                granted: 0,
                reserved: 0,
                used: 0,
                reserved_overrun: 0,
                used_overrun: 0,
                granted_overrun: 0,
                available_overrun: 0,
            },
            assessments: {
                license: 'unlimited',
                reserved_overrun: 0,
                reserved: 0,
                used_overrun: 0,
                used: 0,
            },
            tournaments: { license: 'unlimited' },
            sensei: { license: 'disabled' },
        },
        tags: [],
        isTeamKeyEnabled: false,
        apiKeys: [],
    },
    {
        _id: '677c89e41cb43844e9669eb8',
        _cid: '674caa80b5114288d52184b3',
        name: 'Team 17',
        languages: [
            { _id: 'go', _framework: 'vanilla' },
            { _id: 'go', _framework: 'api' },
            { _id: 'pseudocode', _framework: 'vanilla' },
            { _id: 'pseudocode', _framework: 'mobile' },
            { _id: 'terraform', _framework: 'vanilla' },
        ],
        expirationDate: null,
        activationDate: null,
        onboardingMessage: '',
        status: 'enabled',
        defaultRuleSettingsEnabled: true,
        dedupCode: null,
        allowances: {
            training: {
                license: 'unlimited',
                available: 0,
                granted: 2,
                reserved: 2,
                used: 0,
                reserved_overrun: 0,
                used_overrun: 0,
                granted_overrun: 0,
                available_overrun: 0,
            },
            assessments: {
                license: 'unlimited',
                reserved_overrun: 0,
                reserved: 0,
                used_overrun: 0,
                used: 0,
            },
            tournaments: { license: 'unlimited' },
            sensei: { license: 'disabled' },
        },
        tags: [],
        isTeamKeyEnabled: false,
        apiKeys: [],
    },
    {
        _id: '677c89e41cb43844e9669eb9',
        _cid: '674caa80b5114288d52184b3',
        name: 'Team 18',
        languages: [
            { _id: 'kotlin', _framework: 'spring_api' },
            { _id: 'javascript', _framework: 'vanilla' },
            { _id: 'javascript', _framework: 'angular_io' },
            { _id: 'javascript', _framework: 'react' },
            { _id: 'javascript', _framework: 'vuejs' },
        ],
        expirationDate: null,
        activationDate: null,
        onboardingMessage: '',
        status: 'enabled',
        defaultRuleSettingsEnabled: true,
        dedupCode: null,
        allowances: {
            training: {
                license: 'unlimited',
                available: 0,
                granted: 4,
                reserved: 2,
                used: 1,
                reserved_overrun: 0,
                used_overrun: 0,
                granted_overrun: 0,
                available_overrun: 0,
            },
            assessments: {
                license: 'unlimited',
                reserved_overrun: 0,
                reserved: 0,
                used_overrun: 0,
                used: 0,
            },
            tournaments: { license: 'unlimited' },
            sensei: { license: 'disabled' },
        },
        tags: [],
        isTeamKeyEnabled: false,
        apiKeys: [],
    },
    {
        _id: '677c89e41cb43844e9669eba',
        _cid: '674caa80b5114288d52184b3',
        name: 'Team 19',
        languages: [
            { _id: 'objective_c', _framework: 'ios' },
            { _id: 'php', _framework: 'vanilla' },
            { _id: 'java', _framework: 'vanilla' },
            { _id: 'java', _framework: 'spring' },
            { _id: 'java', _framework: 'struts' },
        ],
        expirationDate: null,
        activationDate: null,
        onboardingMessage: '',
        status: 'enabled',
        defaultRuleSettingsEnabled: true,
        dedupCode: null,
        allowances: {
            training: {
                license: 'unlimited',
                available: 0,
                granted: 3,
                reserved: 2,
                used: 1,
                reserved_overrun: 0,
                used_overrun: 0,
                granted_overrun: 0,
                available_overrun: 0,
            },
            assessments: {
                license: 'unlimited',
                reserved_overrun: 0,
                reserved: 0,
                used_overrun: 0,
                used: 0,
            },
            tournaments: { license: 'unlimited' },
            sensei: { license: 'disabled' },
        },
        tags: [],
        isTeamKeyEnabled: false,
        apiKeys: [],
    },
    {
        _id: '677c89e41cb43844e9669ebb',
        _cid: '674caa80b5114288d52184b3',
        name: 'Team 20',
        languages: [],
        expirationDate: null,
        activationDate: null,
        onboardingMessage: '',
        status: 'disabled',
        defaultRuleSettingsEnabled: true,
        dedupCode: null,
        allowances: {
            training: {
                license: 'unlimited',
                available: 0,
                granted: 0,
                reserved: 0,
                used: 0,
                reserved_overrun: 0,
                used_overrun: 0,
                granted_overrun: 0,
                available_overrun: 0,
            },
            assessments: {
                license: 'unlimited',
                reserved_overrun: 0,
                reserved: 0,
                used_overrun: 0,
                used: 0,
            },
            tournaments: { license: 'unlimited' },
            sensei: { license: 'disabled' },
        },
        tags: [],
        isTeamKeyEnabled: false,
        apiKeys: [],
    },
    {
        _id: '677c89e41cb43844e9669ebc',
        _cid: '674caa80b5114288d52184b3',
        name: 'Team 21',
        languages: [
            { _id: 'docker', _framework: 'vanilla' },
            { _id: 'cloudformation', _framework: 'vanilla' },
            { _id: 'kubernetes', _framework: 'vanilla' },
            { _id: 'rust', _framework: 'vanilla' },
            { _id: 'perl', _framework: 'dancer2' },
        ],
        expirationDate: null,
        activationDate: null,
        onboardingMessage: '',
        status: 'enabled',
        defaultRuleSettingsEnabled: true,
        dedupCode: null,
        allowances: {
            training: {
                license: 'unlimited',
                available: 0,
                granted: 2,
                reserved: 0,
                used: 2,
                reserved_overrun: 0,
                used_overrun: 0,
                granted_overrun: 0,
                available_overrun: 0,
            },
            assessments: {
                license: 'unlimited',
                reserved_overrun: 0,
                reserved: 0,
                used_overrun: 0,
                used: 0,
            },
            tournaments: { license: 'unlimited' },
            sensei: { license: 'disabled' },
        },
        tags: [],
        isTeamKeyEnabled: false,
        apiKeys: [],
    },
    {
        _id: '677c89e41cb43844e9669ebd',
        _cid: '674caa80b5114288d52184b3',
        name: 'Team 22',
        languages: [
            { _id: 'dart', _framework: 'flutter' },
            { _id: 'terraform', _framework: 'azure' },
            { _id: 'terraform', _framework: 'gcp' },
            { _id: 'typescript', _framework: 'react' },
            { _id: 'typescript', _framework: 'nodejs_express' },
        ],
        expirationDate: null,
        activationDate: null,
        onboardingMessage: '',
        status: 'enabled',
        defaultRuleSettingsEnabled: true,
        dedupCode: null,
        allowances: {
            training: {
                license: 'unlimited',
                available: 0,
                granted: 5,
                reserved: 2,
                used: 3,
                reserved_overrun: 0,
                used_overrun: 0,
                granted_overrun: 0,
                available_overrun: 0,
            },
            assessments: {
                license: 'unlimited',
                reserved_overrun: 0,
                reserved: 0,
                used_overrun: 0,
                used: 0,
            },
            tournaments: { license: 'unlimited' },
            sensei: { license: 'disabled' },
        },
        tags: [],
        isTeamKeyEnabled: false,
        apiKeys: [],
    },
    {
        _id: '677c89e41cb43844e9669ebe',
        _cid: '674caa80b5114288d52184b3',
        name: 'Team 23',
        languages: [
            { _id: 'rpg', _framework: 'vanilla' },
            { _id: 'c', _framework: 'embedded' },
            { _id: 'php', _framework: 'laravel' },
            { _id: 'sap', _framework: 'abap' },
            { _id: 'dart', _framework: 'flutter' },
        ],
        expirationDate: null,
        activationDate: null,
        onboardingMessage: '',
        status: 'enabled',
        defaultRuleSettingsEnabled: true,
        dedupCode: null,
        allowances: {
            training: {
                license: 'unlimited',
                available: 0,
                granted: 3,
                reserved: 1,
                used: 0,
                reserved_overrun: 0,
                used_overrun: 0,
                granted_overrun: 0,
                available_overrun: 0,
            },
            assessments: {
                license: 'unlimited',
                reserved_overrun: 0,
                reserved: 0,
                used_overrun: 0,
                used: 0,
            },
            tournaments: { license: 'unlimited' },
            sensei: { license: 'disabled' },
        },
        tags: [],
        isTeamKeyEnabled: false,
        apiKeys: [],
    },
    {
        _id: '677c89e41cb43844e9669ebf',
        _cid: '674caa80b5114288d52184b3',
        name: 'Team 24',
        languages: [
            { _id: 'kotlin', _framework: 'spring_api' },
            { _id: 'javascript', _framework: 'vanilla' },
            { _id: 'javascript', _framework: 'angular_io' },
            { _id: 'javascript', _framework: 'react' },
            { _id: 'javascript', _framework: 'vuejs' },
        ],
        expirationDate: null,
        activationDate: null,
        onboardingMessage: '',
        status: 'enabled',
        defaultRuleSettingsEnabled: true,
        dedupCode: null,
        allowances: {
            training: {
                license: 'unlimited',
                available: 0,
                granted: 10,
                reserved: 2,
                used: 1,
                reserved_overrun: 0,
                used_overrun: 0,
                granted_overrun: 0,
                available_overrun: 0,
            },
            assessments: {
                license: 'unlimited',
                reserved_overrun: 0,
                reserved: 0,
                used_overrun: 0,
                used: 0,
            },
            tournaments: { license: 'unlimited' },
            sensei: { license: 'disabled' },
        },
        tags: [],
        isTeamKeyEnabled: false,
        apiKeys: [],
    },
];
export const detailedTeamEnabledTestData = {
    _id: '674caa80b5114288d52184b4',
    _cid: '674caa80b5114288d52184b3',
    name: 'Team 1',
    languages: [],
    expirationDate: null,
    activationDate: null,
    onboardingMessage: '',
    status: 'enabled',
    defaultRuleSettingsEnabled: true,
    dedupCode: null,
    allowances: {
        training: {
            license: 'unlimited',
            reserved: 0,
            used: 1,
            available: 0,
            granted: 18,
            granted_overrun: 0,
            available_overrun: 0,
            reserved_overrun: 0,
            used_overrun: 0,
        },
        assessments: {
            license: 'unlimited',
            reserved: 6,
            used: 15,
            available: 0,
            granted: 0,
            granted_overrun: 0,
            available_overrun: 0,
            reserved_overrun: 0,
            used_overrun: 0,
        },
        tournaments: {
            license: 'unlimited',
            reserved: 0,
            used: 0,
            available: 0,
            granted: 0,
            granted_overrun: 0,
            available_overrun: 0,
            reserved_overrun: 0,
            used_overrun: 0,
        },
        sensei: {
            license: 'disabled',
            reserved: 0,
            used: 0,
            available: 0,
            granted: 0,
            granted_overrun: 0,
            available_overrun: 0,
            reserved_overrun: 0,
            used_overrun: 0,
        },
    },
    tags: [],
    isTeamKeyEnabled: false,
};
export const detailedTeamDisabledTestData = {
    _id: '674caa80b5114288d52184b4',
    _cid: '674caa80b5114288d52184b3',
    name: 'Team 1',
    languages: [],
    expirationDate: null,
    activationDate: null,
    onboardingMessage: '',
    status: 'disabled',
    defaultRuleSettingsEnabled: true,
    dedupCode: null,
    allowances: {
        training: {
            license: 'unlimited',
            reserved: 0,
            used: 1,
            available: 0,
            granted: 18,
            granted_overrun: 0,
            available_overrun: 0,
            reserved_overrun: 0,
            used_overrun: 0,
        },
        assessments: {
            license: 'unlimited',
            reserved: 6,
            used: 15,
            available: 0,
            granted: 0,
            granted_overrun: 0,
            available_overrun: 0,
            reserved_overrun: 0,
            used_overrun: 0,
        },
        tournaments: {
            license: 'unlimited',
            reserved: 0,
            used: 0,
            available: 0,
            granted: 0,
            granted_overrun: 0,
            available_overrun: 0,
            reserved_overrun: 0,
            used_overrun: 0,
        },
        sensei: {
            license: 'disabled',
            reserved: 0,
            used: 0,
            available: 0,
            granted: 0,
            granted_overrun: 0,
            available_overrun: 0,
            reserved_overrun: 0,
            used_overrun: 0,
        },
    },
    tags: [],
    isTeamKeyEnabled: false,
};
