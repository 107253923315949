export const testUserData = [
    {
        _id: 'test',
        status: 'registered',
        email: 'company+admin1@securecodewarrior.com',
        roles: ['company admin'],
        properties: {
            _cid: '09210dbb488cc852e3faea0f',
            profile: { name: { first: 'test', middle: '', last: 'user' } },
            tags: [],
            registered: null,
            lastLogin: null,
            lastInvited: null,
            lastReminded: null,
            platformManaged: false,
            lastInviteBounced: true,
        },
    },
    {
        _id: '1cdbeeeccea73ca8ecc0409a',
        status: 'invited',
        properties: {
            _cid: '09210dbb488cc852e3faea0f',
            tags: ['trailing'],
            registered: null,
            lastLogin: null,
            lastInvited: new Date('2024-08-21T15:14:59.720Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eaf',
                name: 'Team 8',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 7,
            },
            profile: { name: { first: 'Roland', middle: 'Daniel', last: 'Harvey' } },
            _tid: '677c89e41cb43844e9669eaf',
            platformManaged: false,
            lastInviteBounced: true,
        },
        roles: ['team manager'],
        email: 'Roland.Harvey40@circular-eyebrow.net',
    },
    {
        _id: '41e49cf222b13df4cdfc9d3f',
        status: 'enabled',
        properties: {
            _cid: 'fb9880c0445e63a5cb9c374d',
            tags: ['swift-ios', 'kotlin-vanilla', 'moretags2'],
            registered: new Date('2020-04-25T23:30:16.973Z'),
            lastLogin: new Date('2021-08-08T18:57:15.295Z'),
            lastInvited: new Date('2020-12-12T19:26:55.356Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669ead',
                name: 'Team 6',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 9,
            },
            profile: {
                name: { first: 'Phillip', middle: 'Jayden', last: 'Tillman-Lynch' },
            },
            _tid: '677c89e41cb43844e9669ead',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['developer'],
        email: 'Phillip_Tillman-Lynch93@vengeful-abacus.name',
    },
    {
        _id: '2bbe952f2f81dc8a62b1fece',
        status: 'enabled',
        properties: {
            _cid: 'f70b1498d8ddf1506dd9e3d5',
            tags: ['middle space', 'cde', 'add'],
            registered: new Date('2024-11-16T18:44:09.414Z'),
            lastLogin: new Date('2024-11-18T18:16:53.255Z'),
            lastInvited: new Date('2024-11-17T20:47:48.838Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eab',
                name: 'Team 4',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 8,
            },
            profile: {
                name: { first: 'Brent', middle: 'Matthew', last: 'Runolfsson' },
            },
            _tid: '677c89e41cb43844e9669eab',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['developer'],
        email: 'Brent_Runolfsson@true-poppy.com',
    },
    {
        _id: '4f9c6b8e1f5e527d68bfbacc',
        status: 'disabled',
        properties: {
            _cid: 'cd35b7fa4f7d17dfc0c24aa8',
            tags: ['more tags', 'leading'],
            registered: new Date('2020-06-22T17:55:59.282Z'),
            lastLogin: new Date('2021-06-15T07:48:16.670Z'),
            lastInvited: new Date('2021-02-02T11:55:30.053Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eb1',
                name: 'Team 10',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 5,
            },
            profile: { name: { first: 'Alfred', middle: 'Maverick', last: 'Kling' } },
            _tid: '677c89e41cb43844e9669eb1',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['developer', 'team manager'],
        email: 'Alfred.Kling89@teeming-glider.biz',
    },
    {
        _id: 'd1d98a513fd29286567fb1bf',
        status: 'registered',
        properties: {
            _cid: '0c0de9596f18cecfefaad7ef',
            tags: ['Syd branch'],
            registered: new Date('2020-07-02T04:32:18.277Z'),
            lastLogin: new Date('2022-03-29T19:10:21.014Z'),
            lastInvited: new Date('2021-09-07T02:33:54.774Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eac',
                name: 'Team 5',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 13,
            },
            profile: { name: { first: 'Rose', middle: 'Joan', last: 'Hahn' } },
            _tid: '677c89e41cb43844e9669eac',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['team manager'],
        email: 'Rose.Hahn@known-jury.biz',
    },
    {
        _id: '6e0a9df74d2de2c0a51cb7db',
        status: 'disabled',
        properties: {
            _cid: '5f1f37be8d01feac8bdfcdad',
            tags: ['testspace'],
            registered: new Date('2022-02-02T15:48:16.921Z'),
            lastLogin: new Date('2022-03-01T07:01:18.255Z'),
            lastInvited: new Date('2022-02-07T18:07:20.886Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eaf',
                name: 'Team 8',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 7,
            },
            profile: {
                name: { first: 'Miranda', middle: 'Elizabeth', last: 'Graham' },
            },
            _tid: '677c89e41cb43844e9669eaf',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['team manager'],
        email: 'Miranda.Graham5@stark-newsstand.org',
    },
    {
        _id: 'bc1bac6e1de6deb4b731ac0a',
        status: 'disabled',
        properties: {
            _cid: '3ae3c178588beb84357ae37b',
            tags: ['middle space', 'trailing'],
            registered: new Date('2021-01-06T11:48:58.003Z'),
            lastLogin: new Date('2021-01-22T05:09:50.623Z'),
            lastInvited: new Date('2021-01-17T14:28:03.953Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eb1',
                name: 'Team 10',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 5,
            },
            profile: { name: { first: 'Agnes', middle: 'Helen', last: 'Little' } },
            _tid: '677c89e41cb43844e9669eb1',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: [],
        email: 'Agnes.Little69@joyous-publication.biz',
    },
    {
        _id: '78db5fddbba1e4e829d8dd38',
        status: 'enabled',
        properties: {
            _cid: 'b7fd1dd3817ac5b0ac8acce7',
            tags: [],
            registered: new Date('2023-07-08T22:46:29.033Z'),
            lastLogin: new Date('2024-02-23T00:16:22.525Z'),
            lastInvited: new Date('2023-11-08T10:15:51.277Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eb1',
                name: 'Team 10',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 5,
            },
            profile: { name: { first: 'Eleanor', middle: 'Meadow', last: 'Wunsch' } },
            _tid: '677c89e41cb43844e9669eb1',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['developer'],
        email: 'Eleanor.Wunsch@good-toothpick.biz',
    },
    {
        _id: '058aab4a4aaced4aa9e9ea70',
        status: 'disabled',
        properties: {
            _cid: '93aacaa90bbbf733ff8d03a9',
            tags: ['more tags'],
            registered: new Date('2022-08-05T01:54:24.574Z'),
            lastLogin: new Date('2023-06-22T19:25:28.062Z'),
            lastInvited: new Date('2023-05-07T22:12:54.275Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eaa',
                name: 'Team 3',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 13,
            },
            profile: { name: { first: 'Annie', middle: 'Thea', last: 'Hermiston' } },
            _tid: '677c89e41cb43844e9669eaa',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: [],
        email: 'Annie_Hermiston23@familiar-freezing.net',
    },
    {
        _id: 'becbcf3d00df4f0c9ae4c8ea',
        status: 'disabled',
        properties: {
            _cid: 'aabbea687c621a6eaeca7a6d',
            tags: ['java-android', 'kotlin-vanilla', 'trailing'],
            registered: new Date('2021-03-12T12:21:55.284Z'),
            lastLogin: new Date('2021-12-13T09:52:13.143Z'),
            lastInvited: new Date('2021-07-15T15:18:42.609Z'),
            lastReminded: null,
            team: {
                _id: '674caa80b5114288d52184b4',
                name: 'Team 1',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 10,
            },
            profile: { name: { first: 'Thelma', middle: 'Sophia', last: 'Okuneva' } },
            _tid: '674caa80b5114288d52184b4',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['company admin', 'developer'],
        email: 'Thelma_Okuneva77@questionable-pile.com',
    },
    {
        _id: '56fd12d453f7b0f52dcf19de',
        status: 'disabled',
        properties: {
            _cid: 'c50d489b1bb5dfca470e82b5',
            tags: ['Syd branch', 'kotlin-vanilla'],
            registered: new Date('2023-01-31T03:57:12.414Z'),
            lastLogin: new Date('2024-08-19T19:57:29.337Z'),
            lastInvited: new Date('2024-03-16T13:35:11.076Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eb0',
                name: 'Team 9',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 7,
            },
            profile: { name: { first: 'Gene', middle: 'Otto', last: 'Corwin' } },
            _tid: '677c89e41cb43844e9669eb0',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['developer'],
        email: 'Gene.Corwin90@white-fat.name',
    },
    {
        _id: 'aaa192bfcd75d884f985e403',
        status: 'disabled',
        properties: {
            _cid: 'a49a8fdeedb2ffffa6ec9dad',
            tags: ['middle space', 'add', 'uat'],
            registered: new Date('2020-07-09T05:15:36.556Z'),
            lastLogin: new Date('2021-01-15T02:10:36.098Z'),
            lastInvited: new Date('2020-12-15T09:57:56.999Z'),
            lastReminded: null,
            team: {
                _id: '674caa80b5114288d52184b4',
                name: 'Team 1',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 10,
            },
            profile: { name: { first: 'Kayla', middle: 'Venus', last: 'Corwin' } },
            _tid: '674caa80b5114288d52184b4',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['developer', 'company admin'],
        email: 'Kayla_Corwin24@discrete-formation.org',
    },
    {
        _id: 'bb9aab233fd8aaf5f66468a3',
        status: 'invited',
        properties: {
            _cid: '5dbfafadb4e71fbfdfb6bd7e',
            tags: ['trailing'],
            registered: null,
            lastLogin: null,
            lastInvited: new Date('2022-10-14T16:37:37.199Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eb0',
                name: 'Team 9',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 7,
            },
            profile: { name: { first: 'Martin', middle: 'Mateo', last: 'Blick' } },
            _tid: '677c89e41cb43844e9669eb0',
            platformManaged: false,
            lastInviteBounced: true,
        },
        roles: ['company admin'],
        email: 'Martin_Blick@alienated-follower.com',
    },
    {
        _id: 'f667dcdf829ae23e1cfccff0',
        status: 'enabled',
        properties: {
            _cid: 'cc15e0fba973c3bac134bc6d',
            tags: [],
            registered: new Date('2024-10-02T10:58:10.886Z'),
            lastLogin: new Date('2024-12-13T01:42:31.969Z'),
            lastInvited: new Date('2024-11-10T18:17:52.254Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eae',
                name: 'Team 7',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 9,
            },
            profile: { name: { first: 'Stacey', middle: 'Medusa', last: 'Ritchie' } },
            _tid: '677c89e41cb43844e9669eae',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['company admin'],
        email: 'Stacey_Ritchie94@nautical-viability.biz',
    },
    {
        _id: 'b70d50deb5dbbac0171986d8',
        status: 'disabled',
        properties: {
            _cid: 'bb2a86ab78ccbbdb76cdd49d',
            tags: ['middle space', 'java-android'],
            registered: new Date('2023-08-06T17:46:22.687Z'),
            lastLogin: new Date('2025-01-08T14:15:57.279Z'),
            lastInvited: new Date('2023-09-09T20:47:41.345Z'),
            lastReminded: null,
            team: {
                _id: '674caa80b5114288d52184b4',
                name: 'Team 1',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 10,
            },
            profile: { name: { first: 'Leticia', middle: 'Josie', last: 'Harber' } },
            _tid: '674caa80b5114288d52184b4',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['team manager'],
        email: 'Leticia_Harber29@immediate-bar.org',
    },
    {
        _id: 'cbe8d70fdbf038ac75bc9d9f',
        status: 'disabled',
        properties: {
            _cid: '0531aa2af3b302a62ead28fa',
            tags: ['trailingSpace ', 'ijl', 'leading and trailing'],
            registered: new Date('2023-02-02T19:56:31.359Z'),
            lastLogin: new Date('2023-08-09T04:52:57.902Z'),
            lastInvited: new Date('2023-03-07T05:13:03.444Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eb1',
                name: 'Team 10',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 5,
            },
            profile: { name: { first: 'Philip', middle: 'Isaac', last: 'Quigley' } },
            _tid: '677c89e41cb43844e9669eb1',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['company admin', 'team manager'],
        email: 'Philip_Quigley42@clumsy-transparency.com',
    },
    {
        _id: 'c2f90b20e24dbdde5e72feec',
        status: 'invited',
        properties: {
            _cid: 'dcc3bf9d8fbfffd9dfa8dec2',
            tags: ['more tags', ' leadingSpace'],
            registered: null,
            lastLogin: null,
            lastInvited: new Date('2023-03-24T19:08:45.185Z'),
            lastReminded: null,
            team: {
                _id: '674caa80b5114288d52184b4',
                name: 'Team 1',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 10,
            },
            profile: { name: { first: 'Howard', middle: 'Dior', last: 'Schroeder' } },
            _tid: '674caa80b5114288d52184b4',
            platformManaged: false,
            lastInviteBounced: true,
        },
        roles: ['team manager'],
        email: 'Howard.Schroeder@wicked-waist.net',
    },
    {
        _id: '4f2de88edab960ce34dcb42d',
        status: 'disabled',
        properties: {
            _cid: 'd3f20a03e26004d6dfe6fa1a',
            tags: [],
            registered: new Date('2022-09-27T20:54:46.007Z'),
            lastLogin: new Date('2023-02-24T06:14:43.869Z'),
            lastInvited: new Date('2022-10-19T17:18:22.762Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eb0',
                name: 'Team 9',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 7,
            },
            profile: {
                name: {
                    first: 'Jamie',
                    middle: 'Michelle',
                    last: 'VonRueden-Thompson',
                },
            },
            _tid: '677c89e41cb43844e9669eb0',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['developer'],
        email: 'Jamie_VonRueden-Thompson@superficial-executor.name',
    },
    {
        _id: 'c9767ebd8aabd25ffa172de4',
        status: 'registered',
        properties: {
            _cid: '9de36e12374e2b07d8cca2ea',
            tags: ['leading space', 'ijl'],
            registered: new Date('2021-01-22T03:40:49.436Z'),
            lastLogin: new Date('2024-08-18T17:17:39.990Z'),
            lastInvited: new Date('2021-02-24T03:48:24.350Z'),
            lastReminded: null,
            team: {
                _id: '674caa80b5114288d52184b4',
                name: 'Team 1',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 10,
            },
            profile: { name: { first: 'Juanita', middle: 'Jean', last: 'Pacocha' } },
            _tid: '674caa80b5114288d52184b4',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: [],
        email: 'Juanita.Pacocha26@kaleidoscopic-shore.com',
    },
    {
        _id: '023b698a4fb66a3d16c5e3d7',
        status: 'registered',
        properties: {
            _cid: 'a4e0d6effaf5dae68c50a2a0',
            tags: ['swift-ios', 'middle space', 'Syd branch'],
            registered: new Date('2019-10-13T00:11:30.637Z'),
            lastLogin: new Date('2023-03-03T13:10:10.851Z'),
            lastInvited: new Date('2019-11-11T04:06:56.339Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669ea9',
                name: 'Team 2',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 5,
            },
            profile: { name: { first: 'Perry', middle: 'Isaac', last: 'Nader' } },
            _tid: '677c89e41cb43844e9669ea9',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['company admin'],
        email: 'Perry_Nader70@helpless-cinema.biz',
    },
    {
        _id: 'da52c9cba8cbaf6b7f53a0b0',
        status: 'registered',
        properties: {
            _cid: '51d5f034bfdb1bddc1aafd36',
            tags: ['testspace', 'leading and trailing', 'add'],
            registered: new Date('2021-08-18T04:19:57.947Z'),
            lastLogin: new Date('2021-09-15T01:39:52.019Z'),
            lastInvited: new Date('2021-09-02T08:23:11.450Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eae',
                name: 'Team 7',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 9,
            },
            profile: { name: { first: 'Erica', middle: 'Adele', last: 'Welch' } },
            _tid: '677c89e41cb43844e9669eae',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['developer', 'company admin'],
        email: 'Erica.Welch59@hateful-commercial.name',
    },
    {
        _id: '1fb10d8d5a93197dd3b301a5',
        status: 'disabled',
        properties: {
            _cid: '3d00d60e7bca9984fdfb61ab',
            tags: [],
            registered: new Date('2020-08-08T08:05:24.099Z'),
            lastLogin: new Date('2024-07-04T18:05:29.173Z'),
            lastInvited: new Date('2023-09-19T02:13:54.130Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eb1',
                name: 'Team 10',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 5,
            },
            profile: {
                name: { first: 'Carol', middle: 'Juniper', last: 'Mitchell' },
            },
            _tid: '677c89e41cb43844e9669eb1',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: [],
        email: 'Carol_Mitchell@medium-ostrich.net',
    },
    {
        _id: 'ae1205843da64ceaafefba85',
        status: 'registered',
        properties: {
            _cid: '8cceec1cdece61a307ba1e21',
            tags: [' leadingSpace'],
            registered: new Date('2024-02-02T13:08:40.810Z'),
            lastLogin: new Date('2024-05-24T22:56:51.597Z'),
            lastInvited: new Date('2024-04-26T18:45:11.398Z'),
            lastReminded: null,
            team: {
                _id: '674caa80b5114288d52184b4',
                name: 'Team 1',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 10,
            },
            profile: {
                name: { first: 'Carlton', middle: 'Monroe', last: 'Collins' },
            },
            _tid: '674caa80b5114288d52184b4',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['team manager'],
        email: 'Carlton_Collins61@ordinary-stitcher.org',
    },
    {
        _id: 'f18453bb9e08dc4b3fef06cb',
        status: 'invited',
        properties: {
            _cid: 'd3ef7fc603966a23c3cdeced',
            tags: ['leading', 'moretags2'],
            registered: null,
            lastLogin: null,
            lastInvited: new Date('2024-09-10T22:46:29.238Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eab',
                name: 'Team 4',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 8,
            },
            profile: { name: { first: 'Kay', middle: 'Brielle', last: 'Hermann' } },
            _tid: '677c89e41cb43844e9669eab',
            platformManaged: false,
            lastInviteBounced: true,
        },
        roles: ['developer', 'company admin'],
        email: 'Kay_Hermann57@fearless-rust.net',
    },
    {
        _id: 'aa0f9a45b19aba2ea1fc9f41',
        status: 'enabled',
        properties: {
            _cid: 'ae2ee407c68fdc4a4ab26e74',
            tags: [],
            registered: new Date('2022-08-15T23:10:22.043Z'),
            lastLogin: new Date('2023-08-08T07:59:06.392Z'),
            lastInvited: new Date('2023-07-05T19:07:20.642Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eae',
                name: 'Team 7',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 9,
            },
            profile: { name: { first: 'Kristi', middle: 'Leigh', last: 'Parisian' } },
            _tid: '677c89e41cb43844e9669eae',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: [],
        email: 'Kristi_Parisian@hurtful-omelet.info',
    },
    {
        _id: 'b5bfcfb100e93d1a4be58e8f',
        status: 'enabled',
        properties: {
            _cid: 'ffdde981cecb3c3ea3c1f474',
            tags: ['cde'],
            registered: new Date('2024-01-20T07:54:50.448Z'),
            lastLogin: new Date('2024-08-27T18:54:00.103Z'),
            lastInvited: new Date('2024-01-20T12:44:12.127Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eac',
                name: 'Team 5',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 13,
            },
            profile: { name: { first: 'Bertha', middle: 'Teagan', last: 'Olson' } },
            _tid: '677c89e41cb43844e9669eac',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['team manager'],
        email: 'Bertha_Olson22@secret-secrecy.net',
    },
    {
        _id: 'c8a8e40df7a36ba0fc6d7ff2',
        status: 'disabled',
        properties: {
            _cid: 'f7dc0eeee8d2d1d79ccf3caa',
            tags: ['trailingSpace ', 'middle space'],
            registered: new Date('2021-04-06T14:29:31.051Z'),
            lastLogin: new Date('2021-07-13T07:12:13.266Z'),
            lastInvited: new Date('2021-06-10T02:22:53.812Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669ead',
                name: 'Team 6',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 9,
            },
            profile: { name: { first: 'Michele', middle: 'Jazz', last: 'Auer' } },
            _tid: '677c89e41cb43844e9669ead',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['team manager'],
        email: 'Michele_Auer56@well-lit-edge.com',
    },
    {
        _id: 'f7e6f9459fecc71b8c72bb7a',
        status: 'enabled',
        properties: {
            _cid: '5ddcc1be788eeebfebaefcb0',
            tags: [],
            registered: new Date('2020-02-27T20:24:59.447Z'),
            lastLogin: new Date('2023-04-08T07:56:42.812Z'),
            lastInvited: new Date('2021-08-23T01:23:14.785Z'),
            lastReminded: null,
            team: {
                _id: '674caa80b5114288d52184b4',
                name: 'Team 1',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 10,
            },
            profile: {
                name: { first: 'Kristopher', middle: 'Joseph', last: 'Yost' },
            },
            _tid: '674caa80b5114288d52184b4',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['company admin'],
        email: 'Kristopher.Yost71@powerful-interior.com',
    },
    {
        _id: '41cdcc054824a08cbc4ccade',
        status: 'registered',
        properties: {
            _cid: 'aed8cd47c51e550e52bcd3bf',
            tags: ['trailing Space.'],
            registered: new Date('2024-11-09T02:31:57.399Z'),
            lastLogin: new Date('2025-01-09T10:59:01.276Z'),
            lastInvited: new Date('2024-11-15T06:25:12.719Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669ead',
                name: 'Team 6',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 9,
            },
            profile: {
                name: {
                    first: 'Wendell',
                    middle: 'Christopher',
                    last: 'Hackett-Hettinger',
                },
            },
            _tid: '677c89e41cb43844e9669ead',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['company admin'],
        email: 'Wendell_Hackett-Hettinger84@homely-bonnet.net',
    },
    {
        _id: '1e4a7acaeb724ef4adae3ee8',
        status: 'disabled',
        properties: {
            _cid: '1818fc745be6ac1ef2464baa',
            tags: [],
            registered: new Date('2022-07-11T19:48:03.616Z'),
            lastLogin: new Date('2023-10-13T22:23:48.055Z'),
            lastInvited: new Date('2023-10-02T16:10:01.724Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eae',
                name: 'Team 7',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 9,
            },
            profile: { name: { first: 'Olivia', middle: 'Alice', last: 'Donnelly' } },
            _tid: '677c89e41cb43844e9669eae',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['team manager'],
        email: 'Olivia_Donnelly@stupendous-parsnip.org',
    },
    {
        _id: 'e7c55730a8d21df283d02fc0',
        status: 'enabled',
        properties: {
            _cid: 'fc6aab4ee5b9eea23dd24dc1',
            tags: [],
            registered: new Date('2019-10-28T11:20:29.762Z'),
            lastLogin: new Date('2019-11-06T05:22:48.759Z'),
            lastInvited: new Date('2019-10-29T15:49:33.531Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669ea9',
                name: 'Team 2',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 5,
            },
            profile: { name: { first: 'Wm', middle: 'Leo', last: 'Farrell' } },
            _tid: '677c89e41cb43844e9669ea9',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['team manager'],
        email: 'Wm_Farrell89@swift-plastic.info',
    },
    {
        _id: 'd57da40108aad8b5cd7aefc7',
        status: 'registered',
        properties: {
            _cid: '3bf3ae5f6e6d2d526b5a5bbf',
            tags: [],
            registered: new Date('2021-12-22T19:35:43.512Z'),
            lastLogin: new Date('2024-12-13T08:42:13.688Z'),
            lastInvited: new Date('2022-04-05T16:34:55.657Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eab',
                name: 'Team 4',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 8,
            },
            profile: { name: { first: 'Ruby', middle: 'Amber', last: 'Welch' } },
            _tid: '677c89e41cb43844e9669eab',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['team manager'],
        email: 'Ruby_Welch59@acclaimed-stool.info',
    },
    {
        _id: '03c7aaa630d3fdbff0b17dad',
        status: 'invited',
        properties: {
            _cid: '4bc69b3feb9a0c323dde91b4',
            tags: ['space.', 'uat'],
            registered: null,
            lastLogin: null,
            lastInvited: new Date('2021-03-20T12:03:05.780Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eb0',
                name: 'Team 9',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 7,
            },
            profile: { name: { first: 'Ignacio', middle: 'Ellis', last: 'Nader' } },
            _tid: '677c89e41cb43844e9669eb0',
            platformManaged: false,
            lastInviteBounced: true,
        },
        roles: ['team manager'],
        email: 'Ignacio_Nader98@eminent-formation.org',
    },
    {
        _id: '5c01b2c3a0fe50740cf9232f',
        status: 'invited',
        properties: {
            _cid: 'aecad2cb08eec86aba9c1d4c',
            tags: ['middle space'],
            registered: null,
            lastLogin: null,
            lastInvited: new Date('2023-01-13T03:09:15.207Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eac',
                name: 'Team 5',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 13,
            },
            profile: { name: { first: 'Betsy', middle: 'Alice', last: 'Pouros' } },
            _tid: '677c89e41cb43844e9669eac',
            platformManaged: false,
            lastInviteBounced: true,
        },
        roles: [],
        email: 'Betsy_Pouros2@nutritious-quinoa.name',
    },
    {
        _id: 'efdc4ab0bcfd828b6a961a17',
        status: 'invited',
        properties: {
            _cid: '4a1c0ddc51abb63aad64bfba',
            tags: ['ijl', 'developer'],
            registered: null,
            lastLogin: null,
            lastInvited: new Date('2020-08-12T17:28:52.617Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eaa',
                name: 'Team 3',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 13,
            },
            profile: { name: { first: 'Lydia', middle: 'Zion', last: 'Blick' } },
            _tid: '677c89e41cb43844e9669eaa',
            platformManaged: false,
            lastInviteBounced: true,
        },
        roles: ['company admin'],
        email: 'Lydia.Blick21@gray-plastic.net',
    },
    {
        _id: '71cf81ab2d21220fbeae925a',
        status: 'disabled',
        properties: {
            _cid: '362be7d4afa77bbe8abfe2c8',
            tags: ['abc'],
            registered: new Date('2021-09-13T04:41:34.006Z'),
            lastLogin: new Date('2023-03-19T04:57:09.165Z'),
            lastInvited: new Date('2022-10-16T06:23:06.718Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eac',
                name: 'Team 5',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 13,
            },
            profile: { name: { first: 'Scott', middle: 'Emerson', last: 'Thiel' } },
            _tid: '677c89e41cb43844e9669eac',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: [],
        email: 'Scott.Thiel@palatable-seagull.com',
    },
    {
        _id: 'a891bb2b6cce9bd2afd1c6bb',
        status: 'enabled',
        properties: {
            _cid: '4b2e00a1d1d49baee6df02c8',
            tags: ['leading space', 'java-android', 'cde'],
            registered: new Date('2023-10-06T20:30:04.111Z'),
            lastLogin: new Date('2024-08-28T15:17:29.627Z'),
            lastInvited: new Date('2023-10-28T00:15:47.988Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eb0',
                name: 'Team 9',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 7,
            },
            profile: {
                name: { first: 'Mark', middle: 'Gabriel', last: 'Greenholt' },
            },
            _tid: '677c89e41cb43844e9669eb0',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['team manager'],
        email: 'Mark_Greenholt@noxious-onset.net',
    },
    {
        _id: 'fdcb9dbe5faa0cfaa3fd0d0d',
        status: 'registered',
        properties: {
            _cid: '688c642f3ae61cdae9eccd4f',
            tags: ['abc', 'swift-ios'],
            registered: new Date('2024-03-19T06:15:59.803Z'),
            lastLogin: new Date('2024-06-23T02:54:21.661Z'),
            lastInvited: new Date('2024-04-02T07:16:17.191Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eaa',
                name: 'Team 3',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 13,
            },
            profile: {
                name: { first: 'Adrian', middle: 'Sebastian', last: 'Langworth' },
            },
            _tid: '677c89e41cb43844e9669eaa',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['company admin', 'team manager'],
        email: 'Adrian_Langworth26@radiant-jacket.com',
    },
    {
        _id: 'ccadfbcea9a206afb1b15dfc',
        status: 'enabled',
        properties: {
            _cid: '75fcfefd9eed0be9ea48ac77',
            tags: [],
            registered: new Date('2022-07-21T16:35:45.863Z'),
            lastLogin: new Date('2024-11-10T01:08:56.163Z'),
            lastInvited: new Date('2023-11-10T13:54:01.417Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eb0',
                name: 'Team 9',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 7,
            },
            profile: { name: { first: 'Florence', middle: 'Demi', last: 'Muller' } },
            _tid: '677c89e41cb43844e9669eb0',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['company admin'],
        email: 'Florence.Muller14@respectful-honesty.name',
    },
    {
        _id: '3434ee7ae76ecec39629bcc9',
        status: 'enabled',
        properties: {
            _cid: 'f91e2a3d6fd697c77b0c2986',
            tags: [],
            registered: new Date('2021-02-19T14:24:35.964Z'),
            lastLogin: new Date('2022-10-12T22:26:58.787Z'),
            lastInvited: new Date('2021-03-31T12:35:20.234Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eaa',
                name: 'Team 3',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 13,
            },
            profile: { name: { first: 'Dana', middle: 'Valerie', last: 'Collins' } },
            _tid: '677c89e41cb43844e9669eaa',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: [],
        email: 'Dana_Collins@bulky-unblinking.net',
    },
    {
        _id: 'eef60ee9f3fa4df4694cdfa6',
        status: 'disabled',
        properties: {
            _cid: 'ae04abdbd1d8d5ddd020fc4a',
            tags: ['leading', 'trailing Space.'],
            registered: new Date('2023-10-17T07:48:54.033Z'),
            lastLogin: new Date('2024-08-15T16:40:11.717Z'),
            lastInvited: new Date('2024-05-28T10:37:45.092Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eac',
                name: 'Team 5',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 13,
            },
            profile: { name: { first: 'Forrest', middle: 'Julian', last: 'Mertz' } },
            _tid: '677c89e41cb43844e9669eac',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['company admin', 'developer'],
        email: 'Forrest.Mertz98@impossible-merit.com',
    },
    {
        _id: '6eccf4fa7a31dddfecea7e8a',
        status: 'registered',
        properties: {
            _cid: '510dfc56b657cdffda4c10cb',
            tags: [' leadingSpace'],
            registered: new Date('2021-11-05T00:08:11.983Z'),
            lastLogin: new Date('2023-09-11T10:16:13.199Z'),
            lastInvited: new Date('2023-08-26T09:21:09.692Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eaa',
                name: 'Team 3',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 13,
            },
            profile: { name: { first: 'Miriam', middle: 'Raven', last: 'Sanford' } },
            _tid: '677c89e41cb43844e9669eaa',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['developer'],
        email: 'Miriam_Sanford9@illustrious-depot.org',
    },
    {
        _id: 'fd8af0d2708d519441fd2dc3',
        status: 'disabled',
        properties: {
            _cid: 'dfdd121d8ada1bf1930dfc46',
            tags: ['leading space', 'kotlin-vanilla', 'trailing'],
            registered: new Date('2024-03-18T04:16:00.598Z'),
            lastLogin: new Date('2025-01-01T20:11:13.608Z'),
            lastInvited: new Date('2024-11-03T23:09:35.203Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669ead',
                name: 'Team 6',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 9,
            },
            profile: { name: { first: 'Tyler', middle: 'Mateo', last: 'Bashirian' } },
            _tid: '677c89e41cb43844e9669ead',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['company admin'],
        email: 'Tyler_Bashirian@shoddy-premeditation.biz',
    },
    {
        _id: '0d4b42f39c93ebc8efbb74a8',
        status: 'invited',
        properties: {
            _cid: '70aea131383f7b4abaca78be',
            tags: ['leading'],
            registered: null,
            lastLogin: null,
            lastInvited: new Date('2024-02-07T06:07:02.640Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eac',
                name: 'Team 5',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 13,
            },
            profile: {
                name: { first: 'Felix', middle: 'Michael', last: 'Schiller' },
            },
            _tid: '677c89e41cb43844e9669eac',
            platformManaged: false,
            lastInviteBounced: true,
        },
        roles: ['company admin'],
        email: 'Felix.Schiller@white-valentine.name',
    },
    {
        _id: 'c3ed82512560fa7aaadb89bc',
        status: 'enabled',
        properties: {
            _cid: '83ee5dd6cda84ecbecbd76a9',
            tags: ['uat', 'java-android', 'testspace'],
            registered: new Date('2024-06-16T12:16:22.446Z'),
            lastLogin: new Date('2024-09-06T20:20:30.421Z'),
            lastInvited: new Date('2024-08-14T13:17:58.726Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eac',
                name: 'Team 5',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 13,
            },
            profile: {
                name: { first: 'Edward', middle: 'Lucky', last: 'Considine' },
            },
            _tid: '677c89e41cb43844e9669eac',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: [],
        email: 'Edward_Considine@intrepid-restaurant.info',
    },
    {
        _id: 'd6c53dd75eabc5e9a7bc59c2',
        status: 'invited',
        properties: {
            _cid: 'dbe6f38003dffda7f16ea1a4',
            tags: ['trailing', 'java-android'],
            registered: null,
            lastLogin: null,
            lastInvited: new Date('2024-11-21T22:07:28.871Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eaa',
                name: 'Team 3',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 13,
            },
            profile: { name: { first: 'Dale', middle: 'Mason', last: 'Adams' } },
            _tid: '677c89e41cb43844e9669eaa',
            platformManaged: false,
            lastInviteBounced: true,
        },
        roles: ['developer'],
        email: 'Dale.Adams@hot-skeleton.name',
    },
    {
        _id: '624efb5c20e326e36c2a1cc7',
        status: 'disabled',
        properties: {
            _cid: 'c287a7dbfcaedb3799ccccf3',
            tags: ['space.'],
            registered: new Date('2024-08-09T20:26:37.941Z'),
            lastLogin: new Date('2024-09-15T08:20:34.836Z'),
            lastInvited: new Date('2024-08-23T09:56:21.909Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eaa',
                name: 'Team 3',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 13,
            },
            profile: {
                name: { first: 'Amanda', middle: 'Genisis', last: 'Rosenbaum' },
            },
            _tid: '677c89e41cb43844e9669eaa',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: [],
        email: 'Amanda_Rosenbaum45@colorful-excess.name',
    },
    {
        _id: '0bcba3a14d486512ab9780a2',
        status: 'disabled',
        properties: {
            _cid: '79b5d006acbdbfb87ee5ead0',
            tags: [],
            registered: new Date('2023-06-08T05:19:45.291Z'),
            lastLogin: new Date('2024-01-08T17:40:08.943Z'),
            lastInvited: new Date('2023-10-29T00:37:53.515Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eae',
                name: 'Team 7',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 9,
            },
            profile: {
                name: { first: 'Jessica', middle: 'Joan', last: 'Bergstrom' },
            },
            _tid: '677c89e41cb43844e9669eae',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['team manager'],
        email: 'Jessica_Bergstrom@arid-goodwill.org',
    },
    {
        _id: 'bed34fa2ebfef5c61ffba0e2',
        status: 'registered',
        properties: {
            _cid: '8792285fcf87950b2e65822f',
            tags: ['Syd branch', 'fgh'],
            registered: new Date('2024-01-30T19:03:24.407Z'),
            lastLogin: new Date('2024-12-23T16:16:56.693Z'),
            lastInvited: new Date('2024-08-27T15:41:45.272Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eaf',
                name: 'Team 8',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 7,
            },
            profile: { name: { first: 'Lucas', middle: 'Jakobe', last: 'Bauch' } },
            _tid: '677c89e41cb43844e9669eaf',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['team manager', 'company admin'],
        email: 'Lucas_Bauch89@fluffy-dead.biz',
    },
    {
        _id: '6c5dce2abc7ddb122fbf16de',
        status: 'disabled',
        properties: {
            _cid: '0e70f43bb8c16d44cfdcac74',
            tags: [],
            registered: new Date('2023-04-21T13:44:50.881Z'),
            lastLogin: new Date('2024-05-29T14:31:01.460Z'),
            lastInvited: new Date('2023-06-14T04:06:59.918Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eaf',
                name: 'Team 8',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 7,
            },
            profile: { name: { first: 'Connie', middle: 'Diana', last: "O'Conner" } },
            _tid: '677c89e41cb43844e9669eaf',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['company admin'],
        email: 'Connie.OConner16@monumental-metabolite.biz',
    },
    {
        _id: '0a65f2adf5e21af77ffc97be',
        status: 'disabled',
        properties: {
            _cid: 'addeaaa0b1094bbdaeaafcb3',
            tags: ['ijl'],
            registered: new Date('2019-05-13T04:13:16.981Z'),
            lastLogin: new Date('2024-07-22T00:46:39.513Z'),
            lastInvited: new Date('2020-02-04T10:21:45.118Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eaa',
                name: 'Team 3',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 13,
            },
            profile: { name: { first: 'Tony', middle: 'Hank', last: 'Romaguera' } },
            _tid: '677c89e41cb43844e9669eaa',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['team manager', 'developer'],
        email: 'Tony.Romaguera@oily-duster.name',
    },
    {
        _id: 'e88985a7bed275eef03b4ac1',
        status: 'enabled',
        properties: {
            _cid: '9cbd01bfbebbdf22df498ec9',
            tags: ['kotlin-vanilla', 'noSpace'],
            registered: new Date('2024-02-19T22:17:08.283Z'),
            lastLogin: new Date('2024-10-19T10:59:15.728Z'),
            lastInvited: new Date('2024-06-22T21:11:12.337Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669ea9',
                name: 'Team 2',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 5,
            },
            profile: { name: { first: 'Laurie', middle: 'Demi', last: 'Schulist' } },
            _tid: '677c89e41cb43844e9669ea9',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['team manager', 'company admin'],
        email: 'Laurie_Schulist23@those-finer.net',
    },
    {
        _id: 'eabb57aad83df67afde8fede',
        status: 'registered',
        properties: {
            _cid: '3f9f961108fe73c6cbdbcb12',
            tags: ['swift-ios', 'space.'],
            registered: new Date('2023-07-14T10:24:41.455Z'),
            lastLogin: new Date('2024-06-03T11:43:51.560Z'),
            lastInvited: new Date('2023-08-29T17:05:05.619Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eb0',
                name: 'Team 9',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 7,
            },
            profile: { name: { first: 'Emma', middle: 'Eyre', last: 'Fadel' } },
            _tid: '677c89e41cb43844e9669eb0',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['company admin', 'developer'],
        email: 'Emma.Fadel@flickering-minister.info',
    },
    {
        _id: 'be03967a682efa1bc3f78939',
        status: 'disabled',
        properties: {
            _cid: 'a65cf30c9cec08ceff0da0cc',
            tags: ['trailing Space.', 'cde'],
            registered: new Date('2021-10-16T15:16:11.719Z'),
            lastLogin: new Date('2024-01-03T04:31:35.639Z'),
            lastInvited: new Date('2021-12-27T22:04:20.234Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eaa',
                name: 'Team 3',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 13,
            },
            profile: { name: { first: 'Alton', middle: 'Matthew', last: 'Welch' } },
            _tid: '677c89e41cb43844e9669eaa',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['team manager'],
        email: 'Alton_Welch19@hairy-taro.net',
    },
    {
        _id: 'e64d91b19abaa5df854ffdfe',
        status: 'enabled',
        properties: {
            _cid: 'ec1fd5de5500cb428ecd6e33',
            tags: ['trailing'],
            registered: new Date('2024-01-02T02:43:12.952Z'),
            lastLogin: new Date('2024-03-06T13:29:14.116Z'),
            lastInvited: new Date('2024-01-17T13:32:23.891Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eaf',
                name: 'Team 8',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 7,
            },
            profile: { name: { first: 'Glenda', middle: 'Pax', last: 'Emard' } },
            _tid: '677c89e41cb43844e9669eaf',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['company admin', 'developer'],
        email: 'Glenda.Emard99@internal-celsius.net',
    },
    {
        _id: 'efae0be0ced1fc7e37bd9bc9',
        status: 'invited',
        properties: {
            _cid: 'd3bc86d0cc17da2ef5093a1d',
            tags: ['leading and trailing'],
            registered: null,
            lastLogin: null,
            lastInvited: new Date('2020-04-03T11:11:27.537Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eac',
                name: 'Team 5',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 13,
            },
            profile: { name: { first: 'Terry', middle: 'Jakobe', last: 'Conroy' } },
            _tid: '677c89e41cb43844e9669eac',
            platformManaged: false,
            lastInviteBounced: true,
        },
        roles: ['team manager'],
        email: 'Terry_Conroy4@irresponsible-produce.info',
    },
    {
        _id: 'ecfbf6ad9ad9cc80ece6d20a',
        status: 'enabled',
        properties: {
            _cid: 'accba1faecfc46472c355d3e',
            tags: ['leading and trailing'],
            registered: new Date('2021-11-27T21:30:04.031Z'),
            lastLogin: new Date('2024-12-26T05:16:57.652Z'),
            lastInvited: new Date('2022-08-11T18:48:00.161Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eae',
                name: 'Team 7',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 9,
            },
            profile: { name: { first: 'Kate', middle: 'Abigail', last: 'Welch' } },
            _tid: '677c89e41cb43844e9669eae',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: [],
        email: 'Kate_Welch37@respectful-hunt.info',
    },
    {
        _id: '8b6e43684eab851cd6ff6d0b',
        status: 'registered',
        properties: {
            _cid: 'a52e4a9aab17d3571c8eac93',
            tags: [],
            registered: new Date('2019-07-03T21:37:39.932Z'),
            lastLogin: new Date('2021-09-01T05:23:14.151Z'),
            lastInvited: new Date('2020-07-01T21:37:29.550Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eaa',
                name: 'Team 3',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 13,
            },
            profile: { name: { first: 'Clyde', middle: 'Elijah', last: 'Huel' } },
            _tid: '677c89e41cb43844e9669eaa',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: [],
        email: 'Clyde_Huel@gentle-fisherman.org',
    },
    {
        _id: '54c5a5ad60bd8dffdcac411a',
        status: 'invited',
        properties: {
            _cid: 'd9ab0ea6ccd4fe3261bcd3f7',
            tags: ['kotlin-vanilla', 'fgh'],
            registered: null,
            lastLogin: null,
            lastInvited: new Date('2019-12-27T16:44:46.439Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eaa',
                name: 'Team 3',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 13,
            },
            profile: { name: { first: 'Sheila', middle: 'Naomi', last: 'Herman' } },
            _tid: '677c89e41cb43844e9669eaa',
            platformManaged: false,
            lastInviteBounced: true,
        },
        roles: ['company admin'],
        email: 'Sheila_Herman@shocked-king.org',
    },
    {
        _id: 'f1edbee8ce4edbcf37d4f3ea',
        status: 'registered',
        properties: {
            _cid: '6f5f0fcaee1ab8feadd0a87e',
            tags: ['test'],
            registered: new Date('2024-03-22T04:15:30.217Z'),
            lastLogin: new Date('2024-12-31T22:43:10.624Z'),
            lastInvited: new Date('2024-05-02T18:36:33.396Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eae',
                name: 'Team 7',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 9,
            },
            profile: { name: { first: 'Annette', middle: 'Blue', last: 'Gorczany' } },
            _tid: '677c89e41cb43844e9669eae',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['team manager', 'company admin'],
        email: 'Annette.Gorczany29@splendid-interior.name',
    },
    {
        _id: 'b4c0a473e41ba845e5dfeb0e',
        status: 'enabled',
        properties: {
            _cid: 'ed7f19ede7c6a433efedc33d',
            tags: ['swift-ios', 'trailing'],
            registered: new Date('2020-07-21T00:47:42.215Z'),
            lastLogin: new Date('2021-10-21T13:43:27.117Z'),
            lastInvited: new Date('2021-05-26T10:35:11.400Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eae',
                name: 'Team 7',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 9,
            },
            profile: { name: { first: 'Lyle', middle: 'Ellis', last: 'Pagac' } },
            _tid: '677c89e41cb43844e9669eae',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['team manager', 'company admin'],
        email: 'Lyle_Pagac7@left-declaration.com',
    },
    {
        _id: '7d76ba00188a7d89c4acab74',
        status: 'enabled',
        properties: {
            _cid: '8ab6b2aae1dee5213b6e2a5a',
            tags: ['java-android', 'more tags', 'middle space'],
            registered: new Date('2024-02-13T14:50:10.015Z'),
            lastLogin: new Date('2024-02-19T23:45:56.102Z'),
            lastInvited: new Date('2024-02-18T08:13:03.418Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eaf',
                name: 'Team 8',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 7,
            },
            profile: { name: { first: 'Vicki', middle: 'Taylen', last: 'Lesch' } },
            _tid: '677c89e41cb43844e9669eaf',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['team manager'],
        email: 'Vicki.Lesch91@bouncy-technician.org',
    },
    {
        _id: 'e177d9ce748e0beb3de4d86c',
        status: 'enabled',
        properties: {
            _cid: '11f8d279ea07fb8edafd40ce',
            tags: ['add', 'fgh'],
            registered: new Date('2019-05-12T06:51:31.985Z'),
            lastLogin: new Date('2022-12-03T22:18:16.955Z'),
            lastInvited: new Date('2022-07-19T08:09:26.733Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eab',
                name: 'Team 4',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 8,
            },
            profile: {
                name: { first: 'Shannon', middle: 'Blaise', last: 'Goodwin' },
            },
            _tid: '677c89e41cb43844e9669eab',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['company admin'],
        email: 'Shannon.Goodwin7@metallic-pearl.com',
    },
    {
        _id: '4e9eccff9fa8c92fab0d85b0',
        status: 'invited',
        properties: {
            _cid: '1bfdf8d7f6929969c53e09ab',
            tags: ['test', ' leadingSpace'],
            registered: null,
            lastLogin: null,
            lastInvited: new Date('2022-06-13T02:26:43.269Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669ead',
                name: 'Team 6',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 9,
            },
            profile: { name: { first: 'Jaime', middle: 'Cooper', last: 'Lindgren' } },
            _tid: '677c89e41cb43844e9669ead',
            platformManaged: false,
            lastInviteBounced: true,
        },
        roles: ['developer', 'company admin'],
        email: 'Jaime.Lindgren65@frequent-extent.name',
    },
    {
        _id: 'b0ab3ae9fd0aaa695ddb155c',
        status: 'registered',
        properties: {
            _cid: '0f2ad9dce60a372bdeb4c830',
            tags: ['swift-ios', 'uat'],
            registered: new Date('2019-06-23T02:48:15.964Z'),
            lastLogin: new Date('2025-01-09T06:20:51.830Z'),
            lastInvited: new Date('2019-11-01T09:27:14.354Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669ea9',
                name: 'Team 2',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 5,
            },
            profile: { name: { first: 'Dwight', middle: 'Jackson', last: 'Fahey' } },
            _tid: '677c89e41cb43844e9669ea9',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['company admin'],
        email: 'Dwight_Fahey44@strong-monocle.info',
    },
    {
        _id: 'cb936c5b852b25daae4d012f',
        status: 'invited',
        properties: {
            _cid: 'afda3ec45acfc59daadd6c3b',
            tags: ['trailingSpace ', 'leading', 'cde'],
            registered: null,
            lastLogin: null,
            lastInvited: new Date('2021-01-03T20:54:28.325Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eaa',
                name: 'Team 3',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 13,
            },
            profile: { name: { first: 'Lance', middle: 'Monroe', last: 'Swift' } },
            _tid: '677c89e41cb43844e9669eaa',
            platformManaged: false,
            lastInviteBounced: true,
        },
        roles: [],
        email: 'Lance.Swift7@tired-longboat.name',
    },
    {
        _id: 'fed301a20b8e2361946af9ef',
        status: 'enabled',
        properties: {
            _cid: 'ddebffae9becf1d3d57a59df',
            tags: ['leading space', 'ijl', 'kotlin-vanilla'],
            registered: new Date('2023-09-23T19:49:28.676Z'),
            lastLogin: new Date('2024-10-08T06:07:05.383Z'),
            lastInvited: new Date('2023-09-30T01:54:16.801Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eab',
                name: 'Team 4',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 8,
            },
            profile: {
                name: { first: 'Andres', middle: 'Harrison', last: 'Stroman' },
            },
            _tid: '677c89e41cb43844e9669eab',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: [],
        email: 'Andres_Stroman@babyish-section.name',
    },
    {
        _id: '29f94074ebcbf5b7d6acde3e',
        status: 'registered',
        properties: {
            _cid: 'd4d42fceb03caf2ebcac6d2c',
            tags: ['developer', 'middle space'],
            registered: new Date('2024-03-20T05:32:45.668Z'),
            lastLogin: new Date('2024-09-14T23:13:25.228Z'),
            lastInvited: new Date('2024-05-13T01:05:49.536Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eac',
                name: 'Team 5',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 13,
            },
            profile: { name: { first: 'Maggie', middle: 'Kali', last: 'Bergnaum' } },
            _tid: '677c89e41cb43844e9669eac',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['team manager'],
        email: 'Maggie_Bergnaum15@unlined-omelet.biz',
    },
    {
        _id: '4714acc0d346dae8a78f8eb1',
        status: 'disabled',
        properties: {
            _cid: 'd7009dfd8d9c06dd8fe7e833',
            tags: [],
            registered: new Date('2024-03-05T23:13:46.245Z'),
            lastLogin: new Date('2024-03-25T16:57:14.679Z'),
            lastInvited: new Date('2024-03-17T17:09:48.413Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eac',
                name: 'Team 5',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 13,
            },
            profile: { name: { first: 'Jordan', middle: 'Harrison', last: 'Haag' } },
            _tid: '677c89e41cb43844e9669eac',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['company admin'],
        email: 'Jordan.Haag@terrible-membership.com',
    },
    {
        _id: 'e11d385ef2655ef20ed51f8b',
        status: 'registered',
        properties: {
            _cid: '3ead74ecff2c3ffbf7d6fcaf',
            tags: [],
            registered: new Date('2021-10-23T00:57:00.683Z'),
            lastLogin: new Date('2022-01-22T11:06:55.620Z'),
            lastInvited: new Date('2021-11-25T04:57:17.967Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669ead',
                name: 'Team 6',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 9,
            },
            profile: { name: { first: 'Joanna', middle: 'Maddie', last: 'Wisoky' } },
            _tid: '677c89e41cb43844e9669ead',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['team manager', 'developer'],
        email: 'Joanna.Wisoky@scared-blowgun.com',
    },
    {
        _id: '0fc7afc96fd898b2f95cecb9',
        status: 'registered',
        properties: {
            _cid: '52c19c84881f51854acf8b25',
            tags: ['moretags2', 'trailingSpace '],
            registered: new Date('2024-11-29T21:38:51.317Z'),
            lastLogin: new Date('2024-12-02T19:00:50.920Z'),
            lastInvited: new Date('2024-11-30T02:17:45.754Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eaa',
                name: 'Team 3',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 13,
            },
            profile: {
                name: { first: 'Marion', middle: 'Sebastian', last: 'McClure' },
            },
            _tid: '677c89e41cb43844e9669eaa',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['team manager'],
        email: 'Marion_McClure@numb-cutover.net',
    },
    {
        _id: '095f8bbdab9fb0a3f08bbbbe',
        status: 'registered',
        properties: {
            _cid: 'c59a8a25ae1ab25ae79e1181',
            tags: ['middle space'],
            registered: new Date('2020-12-07T13:08:04.698Z'),
            lastLogin: new Date('2021-06-11T17:31:53.066Z'),
            lastInvited: new Date('2021-04-30T06:18:45.518Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669ea9',
                name: 'Team 2',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 5,
            },
            profile: {
                name: { first: 'Adrienne', middle: 'Ireland', last: 'Kilback' },
            },
            _tid: '677c89e41cb43844e9669ea9',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: [],
        email: 'Adrienne_Kilback@authorized-sideboard.info',
    },
    {
        _id: 'c45ae1b045fe359848bc0ec3',
        status: 'registered',
        properties: {
            _cid: 'f8e7c5dcf81d18055b33f6b2',
            tags: ['testspace'],
            registered: new Date('2020-04-03T12:58:04.887Z'),
            lastLogin: new Date('2023-06-10T16:59:11.396Z'),
            lastInvited: new Date('2020-09-04T10:10:50.453Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eab',
                name: 'Team 4',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 8,
            },
            profile: { name: { first: 'Javier', middle: 'Levi', last: 'Barton' } },
            _tid: '677c89e41cb43844e9669eab',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['developer'],
        email: 'Javier.Barton2@irresponsible-hose.biz',
    },
    {
        _id: '5c860c1fceb73cce6f385923',
        status: 'enabled',
        properties: {
            _cid: 'b03d110acd2be8eadc70aeba',
            tags: ['middle space', 'Syd branch', 'cde'],
            registered: new Date('2025-01-13T00:57:41.990Z'),
            lastLogin: new Date('2025-01-13T18:08:02.163Z'),
            lastInvited: new Date('2025-01-13T03:36:55.826Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eac',
                name: 'Team 5',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 13,
            },
            profile: {
                name: { first: 'Angie', middle: 'Hippolyta', last: 'Stracke' },
            },
            _tid: '677c89e41cb43844e9669eac',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['company admin'],
        email: 'Angie_Stracke6@purple-monocle.net',
    },
    {
        _id: '0c8d2d1d9ab5235fe7ee3be7',
        status: 'registered',
        properties: {
            _cid: 'cdf0618fffda48af4dcdeada',
            tags: [],
            registered: new Date('2021-08-18T15:33:06.328Z'),
            lastLogin: new Date('2025-01-07T22:39:32.215Z'),
            lastInvited: new Date('2022-03-03T12:17:55.133Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eaf',
                name: 'Team 8',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 7,
            },
            profile: { name: { first: 'Ronnie', middle: 'Wyatt', last: 'Block' } },
            _tid: '677c89e41cb43844e9669eaf',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['developer'],
        email: 'Ronnie.Block@unsung-invite.name',
    },
    {
        _id: 'cc839dc2ebc7dbcaecaa5afd',
        status: 'registered',
        properties: {
            _cid: 'c0dcecf2f91171996e5fb7c7',
            tags: ['developer', 'trailingSpace '],
            registered: new Date('2023-07-18T15:51:33.228Z'),
            lastLogin: new Date('2023-11-13T12:01:39.017Z'),
            lastInvited: new Date('2023-10-31T09:07:38.374Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eae',
                name: 'Team 7',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 9,
            },
            profile: { name: { first: 'Phyllis', middle: 'Blanche', last: 'Boyer' } },
            _tid: '677c89e41cb43844e9669eae',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['company admin'],
        email: 'Phyllis_Boyer@rundown-gown.org',
    },
    {
        _id: 'd7124bf8e9b11c87cae1a7b7',
        status: 'disabled',
        properties: {
            _cid: 'eb6fb6adbdfea44d6bf4a9ee',
            tags: [],
            registered: new Date('2024-07-16T19:22:32.193Z'),
            lastLogin: new Date('2024-11-01T13:44:00.533Z'),
            lastInvited: new Date('2024-08-05T09:50:08.772Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eac',
                name: 'Team 5',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 13,
            },
            profile: { name: { first: 'Jenna', middle: 'Morgan', last: 'Hane' } },
            _tid: '677c89e41cb43844e9669eac',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['team manager'],
        email: 'Jenna.Hane@standard-pharmacopoeia.org',
    },
    {
        _id: 'fddb74c93bb6dfeb3fde80ae',
        status: 'registered',
        properties: {
            _cid: 'f4c52c9ba05ccf3ae8a82c87',
            tags: ['Syd branch', 'moretags2'],
            registered: new Date('2019-08-28T22:59:33.075Z'),
            lastLogin: new Date('2020-01-27T13:38:57.538Z'),
            lastInvited: new Date('2020-01-13T08:33:03.390Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669ead',
                name: 'Team 6',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 9,
            },
            profile: { name: { first: 'Shirley', middle: 'Taylor', last: 'Sipes' } },
            _tid: '677c89e41cb43844e9669ead',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['developer'],
        email: 'Shirley.Sipes97@ragged-forgery.name',
    },
    {
        _id: 'd1d9c3c662b2ffefdcb7b2ae',
        status: 'invited',
        properties: {
            _cid: '49ddb0cad6baba94faa9df83',
            tags: ['leading and trailing', 'cde'],
            registered: null,
            lastLogin: null,
            lastInvited: new Date('2022-01-19T18:49:25.012Z'),
            lastReminded: null,
            team: {
                _id: '674caa80b5114288d52184b4',
                name: 'Team 1',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 10,
            },
            profile: {
                name: { first: 'Shelly', middle: 'Genisis', last: 'Langworth' },
            },
            _tid: '674caa80b5114288d52184b4',
            platformManaged: false,
            lastInviteBounced: true,
        },
        roles: ['developer', 'company admin'],
        email: 'Shelly.Langworth63@poor-icebreaker.org',
    },
    {
        _id: 'eff8a0d8f76f525c7171e4ff',
        status: 'registered',
        properties: {
            _cid: 'eaf6f598023999d5faa5435f',
            tags: [],
            registered: new Date('2022-09-08T03:28:33.360Z'),
            lastLogin: new Date('2024-09-04T17:49:54.095Z'),
            lastInvited: new Date('2024-04-07T06:53:44.328Z'),
            lastReminded: null,
            team: {
                _id: '674caa80b5114288d52184b4',
                name: 'Team 1',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 10,
            },
            profile: { name: { first: 'Jay', middle: 'William', last: 'Wolf' } },
            _tid: '674caa80b5114288d52184b4',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: [],
        email: 'Jay.Wolf52@deep-attraction.info',
    },
    {
        _id: '2cff1bd9eb514b2dd72e4e62',
        status: 'registered',
        properties: {
            _cid: 'c5cc524affafb34c22c9d4ce',
            tags: ['developer', 'fgh', 'kotlin-vanilla'],
            registered: new Date('2023-10-07T16:39:07.148Z'),
            lastLogin: new Date('2024-12-10T01:07:17.980Z'),
            lastInvited: new Date('2024-11-07T04:01:00.231Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669ead',
                name: 'Team 6',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 9,
            },
            profile: { name: { first: 'Winifred', middle: 'Irene', last: 'Grant' } },
            _tid: '677c89e41cb43844e9669ead',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['team manager'],
        email: 'Winifred.Grant@noteworthy-fun.net',
    },
    {
        _id: 'b40ebca9f31bf9f44134c0ac',
        status: 'invited',
        properties: {
            _cid: '5730dea2717cdccecd0e197d',
            tags: ['leading', 'middle space'],
            registered: null,
            lastLogin: null,
            lastInvited: new Date('2023-04-12T14:01:33.072Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eab',
                name: 'Team 4',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 8,
            },
            profile: { name: { first: 'Roy', middle: 'Ezra', last: 'Cassin' } },
            _tid: '677c89e41cb43844e9669eab',
            platformManaged: false,
            lastInviteBounced: true,
        },
        roles: ['company admin', 'developer'],
        email: 'Roy_Cassin61@short-interior.org',
    },
    {
        _id: '11f666dc7c8bfc54bdcecdae',
        status: 'enabled',
        properties: {
            _cid: '49d5edf793ebabf9210d1b3e',
            tags: [],
            registered: new Date('2022-05-23T05:54:05.551Z'),
            lastLogin: new Date('2023-09-11T07:37:12.148Z'),
            lastInvited: new Date('2022-11-26T03:12:59.874Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669ead',
                name: 'Team 6',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 9,
            },
            profile: { name: { first: 'Percy', middle: 'Jack', last: 'Koelpin' } },
            _tid: '677c89e41cb43844e9669ead',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: [],
        email: 'Percy.Koelpin@gaseous-instructor.biz',
    },
    {
        _id: '7cea534e205debeaed82fde7',
        status: 'disabled',
        properties: {
            _cid: 'bb6a84bd87f25aabbbc68ed4',
            tags: ['Syd branch', 'trailingSpace '],
            registered: new Date('2024-06-19T16:12:53.267Z'),
            lastLogin: new Date('2024-10-20T10:01:55.375Z'),
            lastInvited: new Date('2024-07-12T02:36:52.299Z'),
            lastReminded: null,
            team: {
                _id: '674caa80b5114288d52184b4',
                name: 'Team 1',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 10,
            },
            profile: { name: { first: 'Karl', middle: 'Jayden', last: 'Anderson' } },
            _tid: '674caa80b5114288d52184b4',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['developer'],
        email: 'Karl_Anderson@precious-bathhouse.biz',
    },
    {
        _id: '01909c9b6edb679c0e7c3a4a',
        status: 'enabled',
        properties: {
            _cid: 'f03c25b6afb08d19babbadae',
            tags: [],
            registered: new Date('2023-07-06T13:11:03.368Z'),
            lastLogin: new Date('2023-12-28T22:37:54.323Z'),
            lastInvited: new Date('2023-12-14T09:33:06.002Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eab',
                name: 'Team 4',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 8,
            },
            profile: { name: { first: 'Toby', middle: 'Jaxon', last: 'Quigley' } },
            _tid: '677c89e41cb43844e9669eab',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['team manager'],
        email: 'Toby_Quigley64@idolized-vision.biz',
    },
    {
        _id: '5f1f60fdcc0a4aafce8ebf33',
        status: 'registered',
        properties: {
            _cid: 'cbdfcfd3bd5fa1fb1dc620f7',
            tags: ['test', 'leading and trailing'],
            registered: new Date('2019-08-13T08:04:27.176Z'),
            lastLogin: new Date('2023-11-18T19:22:45.158Z'),
            lastInvited: new Date('2020-10-10T14:23:35.684Z'),
            lastReminded: null,
            team: {
                _id: '677c89e41cb43844e9669eac',
                name: 'Team 5',
                tags: [],
                status: 'enabled',
                createdAt: new Date('2025-02-28T02:38:40.246Z'),
                teamSize: 13,
            },
            profile: {
                name: { first: 'Noel', middle: 'Cooper', last: 'Schaden-Huels' },
            },
            _tid: '677c89e41cb43844e9669eac',
            platformManaged: false,
            lastInviteBounced: false,
        },
        roles: ['company admin'],
        email: 'Noel_Schaden-Huels4@energetic-underpants.org',
    },
];
export const userDetailsTestData = [
    {
        _id: 'test',
        email: 'company+admin1@securecodewarrior.com',
        roles: ['company admin', 'user'],
        status: 'registered',
        properties: {
            _cid: '09210dbb488cc852e3faea0f',
            _tid: null,
            profile: {
                name: { first: 'test', middle: '', last: 'user' },
                timezone: '+1000',
                isMetricsPublic: false,
            },
            tags: [],
        },
    },
    {
        _id: '1cdbeeeccea73ca8ecc0409a',
        email: 'Roland.Harvey40@circular-eyebrow.net',
        roles: ['team manager', 'user'],
        status: 'invited',
        properties: {
            _cid: '09210dbb488cc852e3faea0f',
            _tid: '677c89e41cb43844e9669eaf',
            profile: {
                name: { first: 'Roland', middle: 'Daniel', last: 'Harvey' },
                timezone: '+1000',
                isMetricsPublic: false,
            },
            tags: ['trailing'],
        },
    },
    {
        _id: '41e49cf222b13df4cdfc9d3f',
        email: 'Phillip_Tillman-Lynch93@vengeful-abacus.name',
        roles: ['developer', 'user'],
        status: 'enabled',
        properties: {
            _cid: 'fb9880c0445e63a5cb9c374d',
            _tid: '677c89e41cb43844e9669ead',
            profile: {
                name: {
                    first: 'Phillip',
                    middle: 'Jayden',
                    last: 'Tillman-Lynch',
                },
                timezone: '+1000',
                isMetricsPublic: false,
            },
            tags: ['swift-ios', 'kotlin-vanilla', 'moretags2'],
        },
    },
    {
        _id: '2bbe952f2f81dc8a62b1fece',
        email: 'Brent_Runolfsson@true-poppy.com',
        roles: ['developer', 'user'],
        status: 'enabled',
        properties: {
            _cid: 'f70b1498d8ddf1506dd9e3d5',
            _tid: '677c89e41cb43844e9669eab',
            profile: {
                name: { first: 'Brent', middle: 'Matthew', last: 'Runolfsson' },
                timezone: '+1000',
                isMetricsPublic: false,
            },
            tags: ['middle space', 'cde', 'add'],
        },
    },
    {
        _id: '4f9c6b8e1f5e527d68bfbacc',
        email: 'Alfred.Kling89@teeming-glider.biz',
        roles: ['developer', 'team manager', 'user'],
        status: 'disabled',
        properties: {
            _cid: 'cd35b7fa4f7d17dfc0c24aa8',
            _tid: '677c89e41cb43844e9669eb1',
            profile: {
                name: { first: 'Alfred', middle: 'Maverick', last: 'Kling' },
                timezone: '+1000',
                isMetricsPublic: false,
            },
            tags: ['more tags', 'leading'],
        },
    },
];
