import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useUsers } from '../../../hooks/useUsers';
import { useUserSingleAction } from '../../../hooks/useUserSingleAction';
import { MenuTrigger } from './MenuTrigger';
import { MenuBody } from './MenuBody';
export const SingleUserActionMenu = ({ userId, disabled }) => {
    const { setActionType, setUserId } = useUserSingleAction();
    const [anchorEl, setAnchorEl] = useState(null);
    const { users } = useUsers();
    const user = users.find((u) => u._id === userId);
    if (!user) {
        return null;
    }
    const handleTriggerClick = (event) => {
        setUserId(userId);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => setAnchorEl(null);
    const handleActionClick = (action) => {
        setActionType(action);
        handleClose();
    };
    return (_jsxs(_Fragment, { children: [_jsx(MenuTrigger, { onClick: handleTriggerClick, open: !!anchorEl, userId: userId, disabled: disabled }), _jsx(MenuBody, { anchorEl: anchorEl, onClose: handleClose, onClick: handleActionClick, user: user })] }));
};
