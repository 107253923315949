var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { z } from 'zod';
import { useFetchWithPlatformSessionHeader } from '../../../ApiContext';
import { userDetailsSchema } from '../schema/user';
const createRequestInit = (method, body) => ({
    method,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
});
const updatedSchema = z.object({ updatedCount: z.coerce.number(), goneAsync: z.boolean() });
const errorSchema = z.object({ error: z.string() });
// TODO: handle network errors
export const useUserApis = () => {
    const { fetchWithSession, apiEndpoint } = useFetchWithPlatformSessionHeader();
    const usersEndpoint = `${apiEndpoint}/management/admin/users`;
    const bulkEndpoint = `${usersEndpoint}/bulk`;
    const deleteUsers = (ids) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield fetchWithSession(`${bulkEndpoint}/delete`, createRequestInit('POST', { ids }));
        if (!response.ok) {
            return { updatedCount: 0, goneAsync: false };
        }
        const body = yield response.json();
        return updatedSchema.parse(body);
    });
    const disableUsers = (ids) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield fetchWithSession(`${bulkEndpoint}/disable`, createRequestInit('POST', { ids }));
        if (!response.ok) {
            return { updatedCount: 0, goneAsync: false };
        }
        const body = yield response.json();
        return updatedSchema.parse(body);
    });
    const enableUsers = (ids) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield fetchWithSession(`${bulkEndpoint}/enable`, createRequestInit('POST', { ids }));
        if (!response.ok) {
            return { updatedCount: 0, goneAsync: false };
        }
        const body = yield response.json();
        return updatedSchema.parse(body);
    });
    const reinviteUsers = (ids) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield fetchWithSession(`${bulkEndpoint}/reinvite`, createRequestInit('POST', { ids }));
        if (!response.ok) {
            return { updatedCount: 0, goneAsync: false };
        }
        const body = yield response.json();
        return updatedSchema.parse(body);
    });
    const resendInvite = (email) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield fetchWithSession(`${usersEndpoint}/invite/resend`, createRequestInit('POST', { email }));
        // Failure is 429 with message e.g. { "error": "Only 2 invites can be sent per user every 24 hours. You will be able to resend again in 7 hours" }
        if (response.status === 429) {
            const err = yield response.json();
            const { error } = errorSchema.parse(err);
            return { updatedCount: 0, message: error, goneAsync: false };
        }
        // Catch for all other failure codes
        if (!response.ok) {
            return { updatedCount: 0, message: 'Error occurred. Please try again later.', goneAsync: false };
        }
        // Success is 204 with no content
        return { updatedCount: 1, goneAsync: false };
    });
    const resetPasswords = (ids) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield fetchWithSession(`${bulkEndpoint}/resetPassword`, createRequestInit('POST', { ids }));
        if (!response.ok) {
            return { updatedCount: 0, goneAsync: false };
        }
        const body = yield response.json();
        return updatedSchema.parse(body);
    });
    const addTags = ({ ids, tags }) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield fetchWithSession(`${bulkEndpoint}/addTags`, createRequestInit('POST', { ids, tags }));
        if (!response.ok) {
            return { updatedCount: 0, goneAsync: false };
        }
        const body = yield response.json();
        return updatedSchema.parse(body);
    });
    const removeTags = ({ ids, tags }) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield fetchWithSession(`${bulkEndpoint}/removeTags`, createRequestInit('POST', { ids, tags }));
        if (!response.ok) {
            return { updatedCount: 0, goneAsync: false };
        }
        const body = yield response.json();
        return updatedSchema.parse(body);
    });
    const changeRoles = ({ ids, role }) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield fetchWithSession(`${bulkEndpoint}/roleShift`, createRequestInit('POST', { ids, role }));
        if (!response.ok) {
            console.error('Failed to set role', response.statusText);
            console.debug('Returning 0 as an empty value');
            return { updatedCount: 0, goneAsync: false };
        }
        const body = yield response.json();
        return updatedSchema.parse(body);
    });
    const changeTeams = ({ ids, _tid }) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield fetchWithSession(`${bulkEndpoint}/reassign`, createRequestInit('POST', { ids, _tid }));
        if (!response.ok) {
            return { updatedCount: 0, goneAsync: false };
        }
        const body = yield response.json();
        return updatedSchema.parse(body);
    });
    const getUser = (id) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield fetchWithSession(`${usersEndpoint}/${id}`);
        const data = yield response.json();
        return userDetailsSchema.parse(data);
    });
    const updateUser = (user) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield fetchWithSession(`${usersEndpoint}/${user._id}`, createRequestInit('PUT', user));
        const data = yield response.json();
        return userDetailsSchema.parse(data);
    });
    return {
        addTags,
        removeTags,
        deleteUsers,
        disableUsers,
        enableUsers,
        reinviteUsers,
        resetPasswords,
        resendInvite,
        changeRoles,
        changeTeams,
        getUser,
        updateUser,
    };
};
