import angular from 'angular';
import MODULE from './module';
import { dayjs } from '@securecodewarrior/design-system-react';
import { AssessmentNavigationService } from '../assessmentNavigationService';

import { getLanguageInformation } from '../../util/metadata';
import { assessmentStatusMap, getLatestAttempt, getRetriesAllowed } from '../utils';
import DsAssessmentsOverviewTemplate from './DsAssessmentOverviewDirective.html';

const didPass = (completedAttemptWithSuccessRatio) =>
  completedAttemptWithSuccessRatio.score >= completedAttemptWithSuccessRatio.assessment.successRatio;

const calculateRemaining = (assessment) =>
  Math.max(assessment.maxRetries + 1 - assessment.attempts.completed.length, 0);
const calculateUnlockTime = (attempt) =>
  dayjs
    .unix(attempt.completed / 1000)
    .add(attempt.assessment.retryWaitingHours, 'hour')
    .unix();

const getStartActionPanelConfig = (assessment, onStart) => ({
  view: 'start',
  onStart: onStart,
  attemptConfig: assessment.retriesAllowed
    ? {
        retriesEnabled: true,
        remaining: assessment.maxRetries === null ? 'unlimited' : assessment.maxRetries + 1,
        cooldownBetweenAttemptsInHours:
          assessment.retryWaitingHours === null ? undefined : assessment.retryWaitingHours,
      }
    : {
        retriesEnabled: false,
      },
});

export const mapUserAction = (languageInfoFn, actions) => (assessment) => {
  const attempt = getLatestAttempt(assessment);
  if (!attempt) {
    return getStartActionPanelConfig(assessment, actions.start);
  }

  if (attempt.status === 'done') {
    const finishedAt = attempt.completed / 1000;
    const onViewResult = actions.result(attempt);

    if (!attempt.progress?.statuses?.includes('abandoned')) {
      if (
        (assessment.successRatio === null || didPass(attempt)) &&
        assessment.retriesAllowed &&
        (assessment.maxRetries === null || calculateRemaining(assessment) > 0)
      ) {
        return {
          view: 'completedWithRetake',
          onViewResult,
          finishedAt,
          onRetake: actions.retake,
          attemptConfig: {
            retriesEnabled: true,
            remaining: assessment.maxRetries === null ? 'unlimited' : assessment.maxRetries + 1,
            unlocksAt: assessment.retryWaitingHours === null ? undefined : calculateUnlockTime(attempt),
            cooldownBetweenAttemptsInHours:
              assessment.retryWaitingHours === null ? undefined : assessment.retryWaitingHours,
          },
        };
      }

      if (assessment.successRatio === null) {
        return { view: 'completed', onViewResult, finishedAt };
      }

      if (didPass(attempt)) {
        return { view: 'passed', onViewResult, finishedAt };
      }
    }

    if (assessment.retriesAllowed === false) {
      return { view: 'failed', onViewResult, finishedAt };
    }

    if (assessment.maxRetries !== null && calculateRemaining(assessment) === 0) {
      return { view: 'failed', onViewResult, finishedAt };
    }
  }

  if (
    attempt.status === 'done' ||
    (attempt.status === 'pending' &&
      assessment.attempts.completed !== undefined &&
      assessment.attempts.completed.length !== 0)
  ) {
    return {
      view: 'retake',
      onRetake: actions.retake,
      attemptConfig: assessment.retriesAllowed
        ? {
            retriesEnabled: true,
            remaining: assessment.maxRetries === null ? 'unlimited' : Math.max(calculateRemaining(assessment), 1),
            unlocksAt: assessment.retryWaitingHours === null ? undefined : calculateUnlockTime(attempt),
            cooldownBetweenAttemptsInHours:
              assessment.retryWaitingHours === null ? undefined : assessment.retryWaitingHours,
          }
        : {
            retriesEnabled: false,
          },
    };
  }

  if (attempt.started !== null) {
    return { view: 'resume', language: languageInfoFn(attempt.language), onResume: actions.resume(attempt) };
  }

  return getStartActionPanelConfig(assessment, actions.start);
};

const languagesEquals = (lang1, lang2) => lang1._id === lang2._id && lang1._framework === lang2._framework;

const getAssessmentTeamLanguages = (assessmentLanguages, teamLanguages) => {
  if (!teamLanguages || teamLanguages.length === 0) {
    return assessmentLanguages;
  }

  return assessmentLanguages.filter(
    (lang) => teamLanguages.find((teamLanguage) => languagesEquals(lang, teamLanguage)) !== undefined
  );
};

export const assessmentInformationAdapter = (languageInfoFn, actions) => (assessment, teamLanguages) => ({
  version: assessment.version + 1,
  status: assessmentStatusMap[assessment.status],
  challengeAmount: assessment.numberOfChallenges,
  challengeDifficulty: assessment.difficulty,
  minPassPercentage: assessment.successRatio,
  timeLimit: assessment.timeLimit,
  retriesAllowed: getRetriesAllowed(assessment),
  supportedLanguages: getAssessmentTeamLanguages(assessment.languages, teamLanguages).map((l) => languageInfoFn(l)),
  randomChallenges: !assessment.fixedChallenges,
  name: assessment.name,
  description: assessment.description,
  action: mapUserAction(languageInfoFn, actions)(assessment),
  ...(assessment.startDate !== null && { startDate: Math.floor(assessment.startDate / 1000) }),
  ...(assessment.endDate !== null && { endDate: Math.floor(assessment.endDate / 1000) }),
});

const getAttemptStatus = (attempt) => {
  if (attempt.progress?.statuses?.includes('abandoned')) {
    return 'abandoned';
  } else if (attempt.completed === null) {
    return 'inProgress';
  } else if (attempt.assessment.successRatio === null) {
    return 'done';
  } else if (didPass(attempt)) {
    return 'passed';
  } else {
    return 'failed';
  }
};

export const attemptsAdapter = (adaptLanguageInfo, router) => (attempts) =>
  attempts
    .filter((attempt) => attempt.started !== null)
    .map((attempt) => ({
      id: attempt._id,
      language: adaptLanguageInfo(attempt.language),
      startTime: attempt.started !== null ? Math.floor(attempt.started / 1000) : null,
      finishTime: attempt.completed !== null ? Math.floor(attempt.completed / 1000) : null,
      scoreInPercent: attempt.score,
      status: getAttemptStatus(attempt),
      invitedBy: !attempt._author
        ? undefined
        : [attempt.author.name.first, attempt.author.name.middle, attempt.author.name.last].filter((x) => x).join(' '),
      resultsUrl: router.href('assessments.view.attempt', {
        assessmentId: attempt.assessmentId,
        attemptId: attempt._id,
      }),
    }));

angular.module(MODULE).directive('dsScwAssessmentsOverview', [
  '$state',
  '$translate',
  '$rootScope',
  'AuthService',
  'LmsIntegrationService',
  'AssessmentsApiService',
  'ErrorHandler',
  '$location',
  function (
    $state,
    $translate,
    $rootScope,
    AuthService,
    LmsIntegrationService,
    AssessmentsApiService,
    ErrorHandler,
    $location
  ) {
    return {
      restrict: 'E',
      scope: {
        assessmentPromise: '<',
        attemptsPromise: '<',
        metadata: '<',
        session: '<',
        getLmsIdentifier: '<',
        paginationInfo: '=',
        assessmentInfo: '<',
        getAllAttempts: '<',
        assessment: '<',
      },
      templateUrl: DsAssessmentsOverviewTemplate,
      link: function (scope) {
        scope.assessmentNavigationService = new AssessmentNavigationService();
        scope.assessmentNavigationService.setFrom($location.search());
        scope.navigationDetails = scope.assessmentNavigationService.getReturnDetails();

        const actions = {
          start: (language) => createAttemptAndStartAssessment(scope.assessment._id, language),
          retake: (language) => createAttemptAndStartAssessment(scope.assessment._id, language),
          resume: (attempt) => () => {
            $state.go('assessments.take', {
              _assessment: attempt._assessment,
              _attempt: attempt._id,
            });
          },
          result: (attempt) => () => {
            $state.go(
              'assessments.view.attempt',
              { assessmentId: attempt._assessment, attemptId: attempt._id, publicNotStarted: false },
              { reload: 1 }
            );
          },
        };
        const languageInfoFn = getLanguageInformation(scope.metadata.languages);
        const adaptAttempts = attemptsAdapter(languageInfoFn, $state);
        const adaptAssessmentInformation = assessmentInformationAdapter(languageInfoFn, actions);

        scope.breadcrumbs = null;

        scope.lmsCheckOk = () => {
          // this is a reaaaallyyyyyyy dirty hack for a customer that has LMS enabled assessments in programs. You can enable lms enabled assessments after they are assigned to a program.
          // We are now just going to check if the assessment is lms enabled and if it has a query parameter related to programs. If so we just let the learner access the assessment linked to the program.
          // todo: fix the mess of enabling lms support for assessments after they are linked to as an end-of-course assessment or linked to a program.
          // THIS WAS DONE DURING ACKO AND SHOULD BE REVISITED!!!
          if (scope.navigationDetails.param) {
            return true;
          }

          if (scope.assessment?.lmsIntegrated && !LmsIntegrationService.isOpenedByLms()) {
            return false;
          }
          return true;
        };

        if (!$rootScope.isLMSInitialized()) {
          if (scope.navigationDetails.param) {
            // TODO: The new assessment overview page needs to be updated to handle navigation back to the program
            scope.breadcrumbs = {
              assessmentsListOrProgramUrl: $state.href(scope.navigationDetails.state, {
                programId: `${scope.navigationDetails.param}`,
              }),
              mode: 'program',
            };
          } else {
            scope.breadcrumbs = {
              assessmentsListOrProgramUrl: $state.href(scope.navigationDetails.state),
              mode: 'assessment',
            };
          }
        }

        scope.assessmentInformationPromise = new Promise(() => {});
        scope.attempts = {
          attempts: new Promise(() => {}),
          paginationInfo: undefined,
        };

        scope.$watch('attemptsPromise', (attemptsPromise) => {
          if (attemptsPromise === undefined) {
            return;
          }

          scope.attempts = {
            ...scope.attempts,
            attempts: attemptsPromise.then((attempts) => {
              // When pagination gets added, make sure to create a new attempts object!
              return adaptAttempts(attempts);
            }),
          };
        });

        scope.$watch('assessmentPromise', (assessmentPromise) => {
          if (assessmentPromise === undefined) {
            return;
          }

          scope.assessmentInformationPromise = assessmentPromise.then((assessment) =>
            adaptAssessmentInformation(
              assessment,
              scope.session.user.properties._tid ? scope.session.user.properties.team?.languages : undefined
            )
          );
        });

        const createAttempt = (assessmentId) => {
          return AssessmentsApiService.createAttempt(assessmentId, null, null, null).then((data) => {
            if (scope.assessment.lmsIntegrated && LmsIntegrationService.isOpenedByLms()) {
              LmsIntegrationService.sendMessage({ id: scope.getLmsIdentifier(), state: 'ENROLL' });
            }

            return data;
          });
        };

        const createAttemptAndStartAssessment = (assessmentId, languageInformation) => {
          // The old assessment pages make a new attempt object even though none has been started yet, so we gotta check
          // the language on this one to see if it hasn't been started yet.

          // When a user self-assesses the old controller logic modifies the pending array to add a fake 'pending' attempt
          // this has no id as it doesn't exist in the backend yet so we need to create an actual attempt before starting
          const unstartedAttempt = scope.assessment.attempts.pending.find(
            (a) => a.started === null && a._id !== undefined
          );

          // Check on user id here is necessary for people that were invited but possibly not part of a team
          if (
            !AuthService.isFeatureEnabled('assessments') &&
            unstartedAttempt?._user !== scope.session.user._id.toString()
          ) {
            $state.go('assessments-not-licensed');
            return;
          }

          const attemptPromise = unstartedAttempt ? Promise.resolve(unstartedAttempt) : createAttempt(assessmentId);
          const language = {
            _id: languageInformation.languageId,
            _framework: languageInformation.frameworkId,
          };

          attemptPromise
            .then((data) =>
              Promise.all([
                data,
                AssessmentsApiService.setAssessmentAttemptLanguage(data._assessment, data._id, language),
              ])
            )
            .then(([data]) => {
              $state.go('assessments.take', { _assessment: data._assessment, _attempt: data._id });
            })
            .catch((response) => {
              console.error('error while taking assessment attempt', response);
              $translate(['ERROR_TAKING_ASSESSMENT']).then(function (translations) {
                ErrorHandler.addHttpError(translations.ERROR_TAKING_ASSESSMENT);
              });
            });
        };
      },
    };
  },
]);
