import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { iconButtonClasses, styled, Typography } from '@mui/material';
import { IconButton } from '@securecodewarrior/design-system-react/lib/legacy';
import { CloseRounded as CloseIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '../../../../scw-react/common/Tooltip';
import { useState } from 'react';
export const Chip = styled('div')(({ theme }) => ({
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.75),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    border: `1px solid ${theme.palette.container.border.default}`,
    background: 'transparent',
    borderRadius: 2 * theme.shape.borderRadius,
    height: 'auto',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    [`.${iconButtonClasses.root}`]: {
        color: theme.palette.clickable.default,
        padding: 0,
        margin: `0 ${theme.spacing(1)}`,
        fontSize: theme.typography.pxToRem(16),
        '&:hover': {
            color: theme.palette.clickable.hover,
            backgroundColor: 'transparent',
        },
        ':focus-visible': {
            outlineOffset: 0,
            outlineWidth: 1,
        },
    },
    '&.deletable': {
        paddingRight: theme.spacing(1),
        '& .suffix': {
            color: theme.palette.text.title,
        },
    },
    '& .truncate': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: theme.typography.pxToRem(300),
    },
}));
export const ChipWithClose = ({ onDelete, label, suffix, closeBtnAriaLabel, disableFirstLetterCapitalization, }) => {
    const { t } = useTranslation();
    const [isTruncated, setIsTruncated] = useState(false);
    return (_jsx("div", { style: { display: 'inline-block', maxWidth: '100%' }, children: _jsxs(Chip, { className: onDelete ? 'deletable' : undefined, children: [label && (_jsx(Tooltip, { title: label, arrow: true, disableHoverListener: !isTruncated, children: _jsx(Typography, { variant: "caption", className: "truncate", ref: (node) => {
                            node && setIsTruncated(node.offsetWidth < node.scrollWidth);
                        }, sx: (theme) => (Object.assign(Object.assign({}, (disableFirstLetterCapitalization ? {} : { '&::first-letter': { textTransform: 'capitalize' } })), { marginRight: suffix ? theme.spacing(1) : 0 })), children: _jsx("span", { title: label, children: label }) }) })), suffix && (_jsx(Tooltip, { title: suffix, arrow: true, disableHoverListener: !isTruncated, children: _jsx(Typography, { ref: (node) => {
                            node && setIsTruncated(node.offsetWidth < node.scrollWidth);
                        }, className: "suffix truncate", variant: "captionBold", children: _jsx("span", { title: suffix, children: suffix }) }) })), onDelete && (_jsx(IconButton, { sx: (theme) => ({ ':focus': { outline: `1px solid ${theme.palette.clickable.default}` } }), "aria-label": closeBtnAriaLabel !== null && closeBtnAriaLabel !== void 0 ? closeBtnAriaLabel : t('pages.explore.filters.clearFilter', { filters: `${label !== null && label !== void 0 ? label : ''}${suffix !== null && suffix !== void 0 ? suffix : ''}` }), onClick: onDelete, children: _jsx(CloseIcon, { fontSize: "small" }) }))] }) }));
};
