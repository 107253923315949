import { z } from 'zod';
export const teamStatus = z.enum(['enabled', 'disabled']);
const basePropertiesSchema = z.object({
    name: z.string(),
    status: teamStatus,
    tags: z.array(z.string()).optional().default([]),
});
const teamIdFields = z
    .object({ _id: z.coerce.string() })
    .describe('Team _id is _always_ required, so this this is separate to allow for partials');
const teamEnrichedFields = z
    .object({ createdAt: z.coerce.date(), teamSize: z.number() })
    .describe('Team createdDate, generated from the _id.  Not a stored property.  Also teamSize, which is the count of users in the team.');
const fetchedTeam = teamIdFields
    .merge(basePropertiesSchema)
    .merge(teamEnrichedFields)
    .describe('The combined schemas to represent a team as fetched from the database');
const gridTeam = fetchedTeam
    .transform((data) => ({
    id: data._id,
    createdAt: data.createdAt,
    name: data.name,
    status: data.status,
    teamSize: data.teamSize,
}))
    .describe('The transformed team object as represented required for the data grid');
export const fetchedTeams = z.array(fetchedTeam);
export const gridTeams = z.array(gridTeam);
