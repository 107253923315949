import { useState } from 'react';
import { useTeams } from './useTeams';
var GridLogicOperator;
(function (GridLogicOperator) {
    GridLogicOperator["And"] = "and";
    GridLogicOperator["Or"] = "or";
})(GridLogicOperator || (GridLogicOperator = {}));
const STATUS_OPTIONS = {
    all: 'All',
    enabled: 'Enabled',
    disabled: 'Disabled',
    invited: 'Invited',
    registered: 'Registered',
};
const ROLE_OPTIONS = {
    all: 'All',
    'company admin': 'Company admin',
    developer: 'Developer',
    'team manager': 'Team manager',
};
const getFilterOptions = (selectedTeamIds, selectedTagIds) => {
    return {
        statusOptions: STATUS_OPTIONS,
        roleOptions: ROLE_OPTIONS,
        teamOptions: {
            all: 'All',
            select: selectedTeamIds.length > 0 ? `Selected teams (${selectedTeamIds.length})` : 'Select teams',
        },
        tagOptions: {
            all: 'All tags',
            select: selectedTagIds.length > 0 ? `Selected tags (${selectedTagIds.length})` : 'Select tags',
        },
    };
};
const useFilterModel = () => {
    const [filterModel, setFilterModel] = useState({
        items: [],
        logicOperator: GridLogicOperator.And,
    });
    const updateFilter = (field, value) => {
        if (!value || value === 'all') {
            removeFieldFilters(field);
            return;
        }
        const updatedItems = filterModel.items.map((item) => (item.field === field ? Object.assign(Object.assign({}, item), { value }) : item));
        const filterExists = filterModel.items.some((item) => item.field === field);
        if (filterExists) {
            setFilterModel((prevModel) => (Object.assign(Object.assign({}, prevModel), { items: updatedItems })));
        }
        else {
            setFilterModel((prevModel) => (Object.assign(Object.assign({}, prevModel), { items: [...prevModel.items, { field, operator: 'equals', value }] })));
        }
    };
    const removeFieldFilters = (field) => {
        setFilterModel((prevModel) => (Object.assign(Object.assign({}, prevModel), { items: prevModel.items.filter((item) => item.field !== field) })));
    };
    return {
        filterModel,
        setFilterModel,
        updateFilter,
        removeFieldFilters,
    };
};
const useSimpleFilters = (filterModelUtils) => {
    const { updateFilter } = filterModelUtils;
    const [statusFilter, setStatusFilter] = useState('all');
    const [roleFilter, setRoleFilter] = useState('all');
    const createFilterHandler = (field, setter) => {
        return (value) => {
            const newValue = value || 'all';
            setter(newValue);
            updateFilter(field, newValue === 'all' ? null : newValue);
        };
    };
    return {
        statusFilter,
        roleFilter,
        handleStatusFilterChange: createFilterHandler('status', setStatusFilter),
        handleRoleFilterChange: createFilterHandler('role', setRoleFilter),
    };
};
const useTeamFilters = (filterModelUtils) => {
    const { setFilterModel, removeFieldFilters } = filterModelUtils;
    const [teamFilter, setTeamFilter] = useState('all');
    const [isTeamModalOpen, setIsTeamModalOpen] = useState(false);
    const [selectedTeamIds, setSelectedTeamIds] = useState([]);
    const { teams } = useTeams();
    const handleTeamFilterChange = (value) => {
        const newValue = value || 'all';
        if (newValue === 'select') {
            setIsTeamModalOpen(true);
            return;
        }
        if (newValue === 'all') {
            setTeamFilter(newValue);
            setSelectedTeamIds([]);
            removeFieldFilters('team');
        }
    };
    const handleTeamSelect = (teamIds) => {
        setSelectedTeamIds(teamIds);
        if (teamIds.length === 0) {
            removeFieldFilters('team');
            setTeamFilter('all');
            setIsTeamModalOpen(false);
            return;
        }
        applyTeamFilters(teamIds);
        setTeamFilter('select');
        setIsTeamModalOpen(false);
    };
    const applyTeamFilters = (teamIds) => {
        const teamFilters = {
            field: 'team',
            operator: 'isAnyOf',
            value: teamIds.map((teamId) => { var _a; return (_a = teams.find((team) => team._id === teamId)) === null || _a === void 0 ? void 0 : _a.name; }).filter(Boolean),
        };
        setFilterModel((prevModel) => (Object.assign(Object.assign({}, prevModel), { items: [...prevModel.items.filter((item) => item.field !== 'team'), teamFilters], logicOperator: GridLogicOperator.And })));
    };
    return {
        teamFilter,
        isTeamModalOpen,
        selectedTeamIds,
        handleTeamFilterChange,
        handleTeamSelect,
        handleTeamModalClose: () => setIsTeamModalOpen(false),
    };
};
const useTagFilters = (filterModelUtils, allTags) => {
    const { setFilterModel, removeFieldFilters } = filterModelUtils;
    const [tagFilter, setTagFilter] = useState('all');
    const [isTagModalOpen, setIsTagModalOpen] = useState(false);
    const [selectedTagIds, setSelectedTagIds] = useState([]);
    const handleTagFilterChange = (value) => {
        const newValue = value || 'all';
        if (newValue === 'select') {
            setIsTagModalOpen(true);
            return;
        }
        if (newValue === 'all') {
            setTagFilter(newValue);
            setSelectedTagIds([]);
            removeFieldFilters('tags');
        }
    };
    const handleTagSelect = (tags) => {
        setSelectedTagIds(tags);
        if (tags.length === 0) {
            removeFieldFilters('tags');
            setTagFilter('all');
            setIsTagModalOpen(false);
            return;
        }
        applyTagFilters(tags);
        setTagFilter('select');
        setIsTagModalOpen(false);
    };
    const applyTagFilters = (tags) => {
        const tagFilters = {
            field: 'tags',
            operator: 'isAnyOf',
            value: tags,
        };
        setFilterModel((prevModel) => (Object.assign(Object.assign({}, prevModel), { items: [...prevModel.items.filter((item) => item.field !== 'tags'), tagFilters], logicOperator: GridLogicOperator.And })));
    };
    return {
        tagFilter,
        isTagModalOpen,
        selectedTagIds,
        allTags,
        handleTagFilterChange,
        handleTagSelect,
        handleTagModalClose: () => setIsTagModalOpen(false),
    };
};
export const useUserFilters = (allTags = []) => {
    const filterModelUtils = useFilterModel();
    const { filterModel, setFilterModel } = filterModelUtils;
    const simpleFilters = useSimpleFilters(filterModelUtils);
    const teamFilters = useTeamFilters(filterModelUtils);
    const tagFilters = useTagFilters(filterModelUtils, allTags);
    const filterOptions = getFilterOptions(teamFilters.selectedTeamIds, tagFilters.selectedTagIds);
    const filterState = {
        statusFilter: simpleFilters.statusFilter,
        roleFilter: simpleFilters.roleFilter,
        teamFilter: teamFilters.teamFilter,
        tagFilter: tagFilters.tagFilter,
        selectedTeamIds: teamFilters.selectedTeamIds,
        selectedTagIds: tagFilters.selectedTagIds,
        isTeamModalOpen: teamFilters.isTeamModalOpen,
        isTagModalOpen: tagFilters.isTagModalOpen,
        filterModel,
    };
    const filterActions = {
        handleStatusFilterChange: simpleFilters.handleStatusFilterChange,
        handleRoleFilterChange: simpleFilters.handleRoleFilterChange,
        handleTeamFilterChange: teamFilters.handleTeamFilterChange,
        handleTagFilterChange: tagFilters.handleTagFilterChange,
        handleTeamSelect: teamFilters.handleTeamSelect,
        handleTagSelect: tagFilters.handleTagSelect,
        handleTeamModalClose: teamFilters.handleTeamModalClose,
        handleTagModalClose: tagFilters.handleTagModalClose,
        setFilterModel,
    };
    return {
        allTags: tagFilters.allTags,
        filterOptions,
        filterState,
        filterActions,
    };
};
