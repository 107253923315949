import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Stack, Typography } from '@mui/material';
import { Trophy } from './Trophy';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import { RefreshRounded } from '@mui/icons-material';
import { confettiOverlay } from './ConfettiOverlay';
import { Trans, useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';
import { RefresherIcon } from './RefresherIcon';
import { usePlatformContext } from '../../../../scw-react/platformContext';
import { useMemo } from 'react';
import { activityStatusAnalytics } from './ActivityStatus.analytics';
export const ActivityStatusBanner = (props) => {
    const { t } = useTranslation();
    const { logAnalyticsEvent } = usePlatformContext();
    const analytics = useMemo(() => activityStatusAnalytics(props.scope, props.contentType, props.contentId, props.variant, logAnalyticsEvent), [logAnalyticsEvent, props]);
    return (_jsx(Box, { sx: props.variant === 'already_completed' ? { backgroundImage: confettiOverlay, backgroundPosition: 'center' } : {}, p: 10, children: _jsxs(Stack, { bgcolor: (style) => style.palette.container.fill.card1, border: (style) => `1px solid ${style.palette.container.border.default}`, borderRadius: 3, gap: 5, alignItems: "center", padding: 5, direction: { xs: 'column', lg: 'row' }, children: [match(props.variant)
                    .with('already_completed', () => _jsx(Trophy, { sx: { width: '215px', height: '150px' } }))
                    .with('refresher_required', () => _jsx(RefresherIcon, { sx: { width: '215px', height: '150px' } }))
                    .exhaustive(), _jsxs(Stack, { gap: 5, alignItems: "flex-start", children: [_jsx(Typography, { variant: "h1", children: _jsx(Trans, { i18nKey: `components.organisms.activityStatus.title.${props.variant}`, children: _jsx(Typography, { component: "em", sx: (theme) => ({ font: 'inherit', color: theme.palette.text.status.success }) }) }) }), _jsx(Typography, { sx: (theme) => ({ color: theme.palette.text.body }), variant: "subtitle1", maxWidth: "900px", children: t(`components.organisms.activityStatus.descriptionBanner.${props.variant}`) }), _jsx(Button, { onClick: () => {
                                props.onClickStartRefresher();
                                analytics.onRefresherButtonClicked();
                            }, variant: props.variant === 'refresher_required' ? 'contained' : 'text', startIcon: _jsx(RefreshRounded, {}), children: t('components.organisms.activityStatus.startRefresher') })] })] }) }));
};
