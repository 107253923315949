import { useState } from 'react';
import { cacheExchange, createClient, fetchExchange } from 'urql';
import { fromValue, toPromise } from 'wonka';
import { isDemoModeEnabled } from '../demo/isDemoModeEnabled';
import { demoData } from '../demo/urql/data';
export const DEV_TOKEN = 'sub~company626admin/company~5db65fc37657d007d7a62549/roles~company_admin';
const getAuthHeaders = (isDevelopment, sessionId) => {
    if (isDevelopment) {
        return {
            Authorization: `Bearer ${DEV_TOKEN}`,
        };
    }
    return sessionId
        ? {
            'X-Session-ID': sessionId,
        }
        : {};
};
export const urqlClientOptions = (isDevelopment, sessionId, reportingApiEndpoint) => {
    const headers = Object.assign({ 'X-Requested-With': 'frontend' }, getAuthHeaders(isDevelopment, sessionId));
    return {
        exchanges: [cacheExchange, fetchExchange],
        fetchOptions: {
            headers,
        },
        url: `${reportingApiEndpoint}/graphql`,
    };
};
const mockExecute = (_query) => { };
export const useUrqlClient = (sessionId, reportingApiEndpoint) => {
    const [client] = useState(isDemoModeEnabled()
        ? {
            executeMutation: mockExecute,
            executeQuery: (_query) => fromValue({ data: demoData }),
            executeSubscription: mockExecute,
            query(_query, _variables, _context) {
                return {
                    toPromise: () => toPromise(fromValue({ data: demoData })),
                };
            },
        }
        : createClient(urqlClientOptions(process.env.NODE_ENV === 'development', sessionId, reportingApiEndpoint)));
    return client;
};
