import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TimelapseRounded } from '@mui/icons-material';
import { Stack, Typography, linkClasses } from '@mui/material';
import { Link } from '../../navigation';
import { linkColorClassNames } from '@securecodewarrior/design-system-react/lib/wanda/atoms/Link/Link';
import { Trans } from 'react-i18next';
export const TrainingAlert = (props) => (_jsxs(Stack, { sx: (theme) => ({
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        borderRadius: theme.shape.border.radius.sm,
        border: `1px solid ${theme.palette.badge.error.border}`,
        backgroundColor: theme.palette.badge.error.fill,
        padding: 3,
        gap: 2,
    }), children: [_jsxs(Stack, { alignItems: "center", direction: "row", gap: 2, children: [_jsx(TimelapseRounded, { sx: (theme) => ({ color: theme.palette.text.status.error }) }), _jsx(Typography, { variant: "caption", sx: (theme) => ({
                        b: { textTransform: 'lowercase' },
                        color: theme.palette.badge.text,
                    }), children: _jsx(Trans, { i18nKey: "pages.learn.mandatoryTrainingMessage", values: { minutes: props.minutesTrainingLeft, days: props.daysLeft } }) })] }), props.linkToMyQuests && (_jsx(Typography, { variant: "caption", sx: (theme) => ({
                color: theme.palette.badge.text,
                [`a.${linkClasses.root}.${linkColorClassNames['clickable-default']}`]: {
                    color: theme.palette.badge.text,
                },
            }), children: _jsx(Trans, { i18nKey: "pages.learn.goToMyQuests", components: { Link: _jsx(Link, { to: "/my-quests", linkProps: { variant: 'captionBold' } }) } }) }))] }));
