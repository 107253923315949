var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState, useCallback, useEffect } from 'react';
import { useApiClient } from '../../../ApiContext';
import { useTranslation } from 'react-i18next';
export function useExecutiveSponsorEmailSubscriptions({ showToaster }) {
    const { trpc, wrapErrorPromise } = useApiClient();
    const [subscriptions, setSubscriptions] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [wasEmailSent, setWasEmailSent] = useState(false);
    const { t } = useTranslation();
    const loadSubscriptions = useCallback(() => {
        setIsLoading(true);
        wrapErrorPromise(trpc.executiveSponsorEmail.getExecutiveSponsorEmailSubscriptions
            .query()
            .then((resp) => {
            if (Array.isArray(resp)) {
                setSubscriptions({
                    executiveSponsorEmails: resp.map((item) => ({
                        id: item.userId,
                        userName: item.userName,
                        userEmail: item.userEmail,
                        enabled: item.subscriptionEnabled,
                    })),
                });
            }
            else if (resp.kind === 'error') {
                showToaster(t('pages.administration.communicationsCentre.error.loadExecutiveSponsorEmailSubscriptions'));
            }
            setIsLoading(false);
        })
            .catch(() => {
            showToaster(t('pages.administration.communicationsCentre.error.loadExecutiveSponsorEmailSubscriptions'));
            setIsLoading(false);
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t, showToaster, trpc, wrapErrorPromise]);
    useEffect(() => {
        if (!subscriptions) {
            loadSubscriptions();
        }
    }, [loadSubscriptions, subscriptions]);
    const saveSubscriptions = useCallback((subscriptions) => __awaiter(this, void 0, void 0, function* () {
        try {
            if (!subscriptions.executiveSponsorEmails) {
                return;
            }
            const result = yield trpc.executiveSponsorEmail.saveExecutiveSponsorEmailSubscriptions.mutate(subscriptions.executiveSponsorEmails);
            if (result.kind === 'error') {
                showToaster(t('pages.administration.communicationsCentre.error.saveExecutiveSponsorEmailSubscriptions'));
            }
        }
        catch (e) {
            showToaster(t('pages.administration.communicationsCentre.error.saveExecutiveSponsorEmailSubscriptions'));
        }
    }), [showToaster, t, trpc]);
    const sendEmailExample = useCallback(() => {
        wrapErrorPromise(trpc.executiveSponsorEmail.sendExampleExecutiveSponsorEmail.query().then((resp) => {
            if (resp.kind === 'error') {
                showToaster(t('pages.administration.communicationsCentre.error.sendExampleExecutiveSponsorEmail'));
            }
            if (resp.kind === 'ok') {
                setWasEmailSent(true);
            }
        }));
    }, [showToaster, t, trpc, wrapErrorPromise]);
    return {
        subscriptions,
        isLoading,
        loadSubscriptions,
        saveSubscriptions,
        wasEmailSent,
        setWasEmailSent,
        sendEmailExample,
    };
}
