import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Stack, Typography } from '@mui/material';
import { TopicSection } from './TopicSection';
import { useTranslation } from 'react-i18next';
export const SplitTopicActivities = (props) => {
    var _a;
    const { t } = useTranslation();
    const theoryActivities = props.activities.filter((a) => ['guideline', 'video'].includes(a.kind));
    const practiceActivities = props.activities.filter((a) => !['guideline', 'video'].includes(a.kind));
    return (_jsxs(_Fragment, { children: [props.activities.length > 0 && (_jsx(_Fragment, { children: _jsxs(Stack, { gap: 5, children: [theoryActivities.length > 0 && (_jsxs(Stack, { gap: 3, children: [_jsx(Typography, { sx: (theme) => ({ color: theme.palette.text.body }), variant: "overline", children: t('pages.quests.theory') }), _jsx(TopicSection, { onStartActivity: props.onStartActivity, backupLanguage: props.backupLanguage, kind: "activities", activities: theoryActivities, highlightedActivityId: props.highlightedActivityId })] })), practiceActivities.length > 0 && (_jsxs(Stack, { gap: 3, children: [_jsx(Typography, { sx: (theme) => ({ color: theme.palette.text.body }), variant: "overline", children: t('pages.quests.practice') }), _jsx(TopicSection, { onStartActivity: props.onStartActivity, backupLanguage: props.backupLanguage, kind: "activities", activities: practiceActivities, highlightedActivityId: props.highlightedActivityId })] }))] }) })), ((_a = props.validation) === null || _a === void 0 ? void 0 : _a.length) > 0 && (_jsx(TopicSection, { onStartActivity: props.onStartActivity, backupLanguage: props.backupLanguage, kind: "quiz", activities: props.validation, highlightedActivityId: props.highlightedActivityId }))] }));
};
