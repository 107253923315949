import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Trans, useTranslation } from 'react-i18next';
import { CommunicationsSection as Section } from '../Communications.styled';
import { Box, Stack, Typography } from '@mui/material';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import { Button, Link } from '@securecodewarrior/design-system-react/lib/wanda';
import { useState } from 'react';
import { ExecutiveSponsorEmailEditDialog } from './ExecutiveSponsorEmailEditDialog';
import { useExecutiveSponsorEmailSubscriptions } from '../hooks/useExecutiveSponsorEmailSubscriptions';
import { ExampleEmailSentDialog } from './ExampleEmailSentDialog';
import { usePlatformContext } from '../../../platformContext';
import { communicationsAnalytics } from '../Communications.analytics';
export const ExecutiveSponsorEmail = ({ showToaster }) => {
    var _a, _b;
    const { t } = useTranslation();
    const { logAnalyticsEvent } = usePlatformContext();
    const analytics = communicationsAnalytics(logAnalyticsEvent);
    const [isEditDialogOpen, setEditDialogOpen] = useState(false);
    const { subscriptions, isLoading, saveSubscriptions, wasEmailSent, setWasEmailSent, sendEmailExample } = useExecutiveSponsorEmailSubscriptions({ showToaster });
    const enabledUsers = (_b = (_a = subscriptions === null || subscriptions === void 0 ? void 0 : subscriptions.executiveSponsorEmails) === null || _a === void 0 ? void 0 : _a.filter((item) => item.enabled).length) !== null && _b !== void 0 ? _b : 0;
    const handleSendEmailExample = () => {
        sendEmailExample();
        analytics.executiveSponsorEmail.onSend();
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Section, { children: [_jsx(Typography, { variant: "h5", children: t('pages.administration.communicationsCentre.executiveSponsorEmail.title') }), _jsxs(Stack, { display: "flex", flexDirection: "row", gap: 6, alignItems: "flex-start", children: [_jsxs(Stack, { width: "50%", display: "flex", flexDirection: "column", gap: 6, alignItems: "flex-start", children: [_jsx(Typography, { sx: { gridArea: 'title' }, children: t('pages.administration.communicationsCentre.executiveSponsorEmail.item.title') }), _jsx(Typography, { variant: "caption", children: _jsx(Trans, { i18nKey: "pages.administration.communicationsCentre.executiveSponsorEmail.item.description", components: {
                                                1: _jsx(Link, { href: "#/user-management", target: "_self", rel: "noopener noreferrer" }),
                                                2: _jsx("br", {}),
                                            } }) })] }), _jsx(Box, { width: "50%", children: _jsxs(Stack, { display: "flex", flexDirection: {
                                        xs: 'column',
                                        md: 'row',
                                    }, gap: 2, alignItems: "flex-start", children: [_jsx(Button, { startIcon: _jsx(EmailRoundedIcon, {}), variant: "outlined", onClick: () => setEditDialogOpen(true), children: t('pages.administration.communicationsCentre.executiveSponsorEmail.button.editRecipient', {
                                                count: enabledUsers,
                                            }) }), _jsx(Button, { variant: "text", onClick: handleSendEmailExample, children: t('pages.administration.communicationsCentre.executiveSponsorEmail.button.sendMeExample') })] }) })] })] }), _jsx(ExecutiveSponsorEmailEditDialog, { open: isEditDialogOpen, onClose: () => {
                    setEditDialogOpen(false);
                }, saveSubscriptions: saveSubscriptions, subscriptions: subscriptions, isLoading: isLoading }), _jsx(ExampleEmailSentDialog, { open: wasEmailSent, onClose: () => setWasEmailSent(false) })] }));
};
