import { z } from 'zod';
export const teamStatus = z.enum(['enabled', 'disabled']);
const basePropertiesSchema = z.object({
    name: z.string(),
    status: teamStatus,
    tags: z.array(z.string()).optional().default([]),
});
const teamIdFields = z
    .object({ _id: z.coerce.string() })
    .describe('Team _id is _always_ required, so this this is separate to allow for partials');
const teamEnrichedFields = z
    .object({ createdAt: z.coerce.date(), teamSize: z.number() })
    .describe('Team createdDate, generated from the _id.  Not a stored property.  Also teamSize, which is the count of users in the team.');
export const fetchedTeam = teamIdFields
    .merge(basePropertiesSchema)
    .merge(teamEnrichedFields)
    .describe('The combined schemas to represent a team as fetched from the database');
const gridTeam = fetchedTeam
    .transform((data) => ({
    id: data._id,
    createdAt: data.createdAt,
    name: data.name,
    status: data.status,
    teamSize: data.teamSize,
}))
    .describe('The transformed team object as represented required for the data grid');
export const fetchedTeams = z.array(fetchedTeam);
export const gridTeams = z.array(gridTeam);
const licenseUsageSchema = z
    .object({
    license: z.string(),
    reserved: z.number().optional(),
    used: z.number().optional(),
    available: z.number().optional(),
    granted: z.number().optional(),
    granted_overrun: z.number().optional(),
    available_overrun: z.number().optional(),
    reserved_overrun: z.number().optional(),
    used_overrun: z.number().optional(),
})
    .describe('The usage details for a specific license');
const teamAllowancesSchema = z
    .object({
    training: licenseUsageSchema,
    assessments: licenseUsageSchema,
    tournaments: licenseUsageSchema,
    sensei: licenseUsageSchema,
})
    .describe('The usage details for available licenses');
const languageSchema = z
    .object({ _id: z.string(), _framework: z.string() })
    .describe('A representation of a language/framework. _id is the language, _framework is the framework');
export const teamDetailsSchema = z.object({
    _id: z.string(),
    _cid: z.string(),
    name: z.string(),
    languages: z.array(languageSchema).optional(),
    expirationDate: z.coerce.date().nullable().optional(),
    activationDate: z.coerce.date().nullable().optional(),
    onboardingMessage: z.string(),
    status: teamStatus,
    defaultRuleSettingsEnabled: z.boolean(),
    dedupCode: z.null(),
    allowances: teamAllowancesSchema,
    tags: z.array(z.unknown()),
    isTeamKeyEnabled: z.boolean().optional(),
});
export const addTeamPayloadSchema = z.object({
    name: z.string(),
    _cid: z.string(),
    allowances: z
        .object({
        training: z.object({
            granted: z.number(),
            license: z.string(),
        }),
        assessments: z.object({
            license: z.string().default('unlimited'),
            granted: z.number().default(0),
        }),
        tournaments: z.object({
            license: z.string().default('unlimited'),
            granted: z.number().default(0),
        }),
        sensei: z.object({
            license: z.string().default('unlimited'),
            granted: z.number().default(0),
        }),
    })
        .optional(),
    expirationDate: z.number().nullable().optional(),
    activationDate: z.number().nullable().optional(),
});
