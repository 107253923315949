import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Divider } from '@mui/material';
import { usePlatformContext } from '../../../../../platformContext';
import { DeleteIcon } from '../../../../../common/icons';
import { ActionMenuItem, ActionMenu } from '../../../../common/components/styled';
const EnableItem = ({ onClick, user, isCurrentUser, children }) => {
    return !isCurrentUser && user.status === 'disabled' ? (_jsx(ActionMenuItem, { onClick: () => onClick('USER_ENABLE'), children: children })) : null;
};
const DisableItem = ({ onClick, user, isCurrentUser, children }) => {
    return !isCurrentUser && user.status !== 'disabled' ? (_jsx(ActionMenuItem, { onClick: () => onClick('USER_DISABLE'), children: children })) : null;
};
const ReinviteItem = ({ onClick, user, children }) => {
    return user.status === 'invited' ? (_jsx(ActionMenuItem, { onClick: () => onClick('USER_RESEND'), children: children })) : null;
};
const ResetPasswordItem = ({ onClick, user, children }) => {
    return user.status === 'enabled' || user.status === 'registered' ? (_jsx(ActionMenuItem, { onClick: () => onClick('USER_RESET_PASSWORD'), children: children })) : null;
};
const DeleteItem = ({ onClick, isCurrentUser, children }) => {
    return isCurrentUser ? null : (_jsxs(_Fragment, { children: [_jsx(Divider, {}), _jsxs(ActionMenuItem, { hasIcon: true, onClick: () => onClick('USER_DELETE'), sx: { color: 'error.main' }, children: [_jsx(DeleteIcon, { sx: { fontSize: 16 } }), children] })] }));
};
export const MenuBody = ({ anchorEl, onClose, onClick, user }) => {
    const { t } = useTranslation('react_components', {
        keyPrefix: 'pages.administration.userManagement.singleUserActionMenu',
    });
    const { sessionUser } = usePlatformContext();
    const isCurrentUser = (sessionUser === null || sessionUser === void 0 ? void 0 : sessionUser._id) === user._id;
    return (_jsxs(ActionMenu, { open: !!anchorEl, onClose: onClose, anchorEl: anchorEl, children: [_jsx(EnableItem, { onClick: onClick, user: user, isCurrentUser: isCurrentUser, children: t('enableUser') }), _jsx(DisableItem, { onClick: onClick, user: user, isCurrentUser: isCurrentUser, children: t('disableUser') }), _jsx(ReinviteItem, { onClick: onClick, user: user, children: t('reinviteUser') }), _jsx(ResetPasswordItem, { onClick: onClick, user: user, children: t('resetPasswordUser') }), _jsx(DeleteItem, { onClick: onClick, isCurrentUser: isCurrentUser, children: t('deleteUser') })] }));
};
