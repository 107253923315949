var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMemo } from 'react';
import { ZodError } from 'zod';
import { useQuery, queryOptions } from '@tanstack/react-query';
import { useTrpc } from '../../common/hooks';
import { deduplicateAndSortTags } from '../utils/deduplicateAndSortTags';
export const useTeams = () => {
    const { trpc, ctx } = useTrpc();
    // Not really needed just yet, but it will help when we need to start doing mutations
    // and will need to do things like invalidating queries, optimistic updates, etc.
    const teamsQueryOpts = queryOptions({
        queryKey: ['trpc-teams-list', ctx === null || ctx === void 0 ? void 0 : ctx.sessionId],
        enabled: !!(ctx === null || ctx === void 0 ? void 0 : ctx.sessionId),
        retry: (failureCount, error) => (error instanceof ZodError ? false : failureCount < 3),
        queryFn: () => __awaiter(void 0, void 0, void 0, function* () {
            const response = yield trpc.userManagement.getTeams.query();
            if (response.kind !== 'ok')
                throw new Error(response.message);
            return response.teams;
        }),
    });
    const { data: teams, isLoading, isError, error } = useQuery(teamsQueryOpts);
    const tags = useMemo(() => { var _a; return deduplicateAndSortTags((_a = teams === null || teams === void 0 ? void 0 : teams.map((team) => team.tags).flat()) !== null && _a !== void 0 ? _a : []); }, [teams]);
    return {
        teams: teams !== null && teams !== void 0 ? teams : [],
        tags,
        isLoading,
        isError,
        error,
    };
};
