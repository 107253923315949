var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { AccordionDetails, AccordionSummary, Divider, Typography } from '@mui/material';
import { Button, TextInput } from '@securecodewarrior/design-system-react/lib/wanda';
import { Stack } from '@mui/system';
import { CloseIconButton } from '../../../../../../common/components/atoms/CloseIconButton';
import { DatePicker } from '../../../../../../common/components/organisms/DatePicker/DatePicker';
import { useTranslation } from 'react-i18next';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Accordion } from '../../../../../../common/components/atoms/Accordion';
import { KeyboardArrowDown } from '@mui/icons-material';
import { DialogSection } from '../../../../../common/Dialog';
import dayjs from 'dayjs';
import { useTeams } from '../../../hooks/useTeams';
export const getMuiDatePickerLanguageKey = (currentLanguage) => {
    if (currentLanguage.startsWith('en'))
        return 'en-gb';
    return currentLanguage.split('-')[0] || 'en-gb';
};
const defaultFormState = {
    teamName: '',
    maximumTeamSize: undefined,
};
const initialErrorState = {
    activationDate: undefined,
    expirationDate: undefined,
    maximumTeamSize: undefined,
};
export const TeamFormModal = ({ onClose, onSubmit, initialState = defaultFormState, title, description, submitButtonText, currentTeamSize = 1, isLoading = false, }) => {
    const [formState, setFormState] = useState(initialState);
    const [errors, setErrors] = useState(initialErrorState);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [reinitialised, setReinitialised] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const { teams } = useTeams();
    useEffect(() => {
        const isDirty = JSON.stringify(initialState) !== JSON.stringify(formState);
        if (!isLoading && !reinitialised && isDirty) {
            setFormState(initialState);
            setReinitialised(true);
        }
    }, [initialState, isLoading, formState, reinitialised]);
    useEffect(() => {
        setExpanded(!!initialState.maximumTeamSize || !!initialState.activationDate || !!initialState.expirationDate);
    }, [initialState]);
    const { t, i18n } = useTranslation('react_components', { keyPrefix: 'pages.administration.teamManagement' });
    const adapterLocale = useMemo(() => getMuiDatePickerLanguageKey(i18n.language).toLowerCase(), [i18n.language]);
    const minExpirationDate = useMemo(() => {
        const tommorow = dayjs().add(1, 'day');
        if (!formState.activationDate)
            return tommorow;
        const activationDate = formState.activationDate;
        return activationDate.isAfter(tommorow) ? activationDate : tommorow;
    }, [formState.activationDate]);
    const handleInputChange = (field, value) => {
        setReinitialised(true);
        if (field === 'maximumTeamSize') {
            const sizeValue = value;
            //if sizeValue is less than currentTeamSize and not NaN or 0, show error
            handleTeamSizeError(sizeValue < currentTeamSize && sizeValue !== 0 && !Number.isNaN(sizeValue));
            if (sizeValue <= 0 || Number.isNaN(sizeValue)) {
                setFormState((prev) => (Object.assign(Object.assign({}, prev), { [field]: undefined })));
                return;
            }
        }
        setFormState((prev) => (Object.assign(Object.assign({}, prev), { [field]: value })));
    };
    const handleTeamNameInputBlur = () => {
        const inputName = formState.teamName.trim().toLowerCase();
        // Check for empty team name
        handleTeamNameError('noTeamName', inputName === '');
        // Check for duplicate with existing team name
        const duplicateTeam = teams.find((team) => team.name.trim().toLowerCase() === inputName);
        const duplicateTeamName = !!duplicateTeam && inputName !== (initialState === null || initialState === void 0 ? void 0 : initialState.teamName.trim().toLowerCase());
        handleTeamNameError('duplicateTeamName', duplicateTeamName);
    };
    const handleDateError = (field, hasError) => {
        var _a;
        const today = dayjs();
        const errorToShow = field === 'activationDate'
            ? t('teamFormModal.dateError')
            : !((_a = formState.expirationDate) === null || _a === void 0 ? void 0 : _a.isAfter(today))
                ? t('teamFormModal.dateErrorToday')
                : t('teamFormModal.dateError');
        setErrors((prev) => (Object.assign(Object.assign({}, prev), { [field]: hasError ? errorToShow : null })));
    };
    const handleTeamSizeError = (hasError) => {
        setErrors((prev) => (Object.assign(Object.assign({}, prev), { maximumTeamSize: hasError ? t('teamFormModal.maximumTeamSizeError', { size: currentTeamSize }) : undefined })));
    };
    const handleTeamNameError = (error, hasError) => {
        setErrors((prev) => (Object.assign(Object.assign({}, prev), { [error]: hasError ? error : null })));
    };
    const resetForm = () => {
        setFormState(initialState);
        setErrors(initialErrorState);
    };
    const handleClose = () => {
        onClose();
        resetForm();
    };
    const isFormValid = !errors.activationDate &&
        !errors.expirationDate &&
        !errors.maximumTeamSize &&
        !errors.duplicateTeamName &&
        !errors.noTeamName;
    const handleSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!isFormValid) {
            return;
        }
        setIsSubmitting(true);
        try {
            yield onSubmit(formState);
            handleClose();
        }
        catch (error) {
            console.error('Failed to submit team form:', error);
        }
        finally {
            setIsSubmitting(false);
        }
    });
    return (_jsxs(_Fragment, { children: [_jsxs(DialogSection, { children: [_jsx(CloseIconButton, { onClick: handleClose, "aria-label": t('common.close'), sx: { position: 'absolute', top: (theme) => theme.spacing(3), right: (theme) => theme.spacing(3) } }), _jsxs(Stack, { gap: 6, children: [_jsxs(Stack, { gap: 6, children: [_jsx(Stack, { direction: "row", alignItems: "center", gap: 2, children: _jsx(Typography, { variant: "h3", children: title }) }), _jsx(Typography, { variant: "body1", children: description }), _jsxs(Stack, { gap: 1, children: [_jsx(TextInput, { label: _jsxs(_Fragment, { children: [t('teamFormModal.name'), _jsx("span", { style: { color: 'red' }, children: "*" })] }), value: formState.teamName, onChange: (e) => handleInputChange('teamName', e.target.value), onBlur: handleTeamNameInputBlur, required: true, error: errors.noTeamName || errors.duplicateTeamName, sx: { width: '320px' } }), errors.noTeamName ? (_jsx(Typography, { variant: "caption", color: "text.status.error", children: t('teamFormModal.noTeamName') })) : errors.duplicateTeamName ? (_jsx(Typography, { variant: "caption", color: "text.status.error", children: t('teamFormModal.duplicateTeamName') })) : ('')] })] }), _jsxs(Accordion, { expanded: expanded, children: [_jsx(AccordionSummary, { expandIcon: _jsx(KeyboardArrowDown, {}), "aria-controls": "advanced-options-content", onClick: () => setExpanded((prev) => !prev), children: _jsx(Typography, { variant: "h6", children: t('teamFormModal.advancedOptions') }) }), _jsx(AccordionDetails, { children: _jsxs(Stack, { gap: 4, children: [_jsx(Divider, { sx: { marginBlockStart: 4 } }), _jsxs(Stack, { gap: 1, children: [_jsx(TextInput, { disabled: isLoading, label: t('teamFormModal.maximumTeamSize'), sx: { '.MuiFormHelperText-root': { margin: 0 }, width: '300px' }, value: formState.maximumTeamSize || '', onChange: (e) => handleInputChange('maximumTeamSize', Number(e.target.value)), onKeyDown: (e) => {
                                                                if (e.key === 'e' || e.key === 'E') {
                                                                    e.preventDefault();
                                                                }
                                                            }, type: "number", slotProps: { htmlInput: { min: 1 } }, error: !!errors.maximumTeamSize, placeholder: t('teamFormModal.maximumTeamSizePlaceholder') }), !errors.maximumTeamSize ? (_jsx(Typography, { variant: "caption", children: t('teamFormModal.maximumTeamSizeHelperText') })) : (_jsx(Typography, { variant: "caption", color: "text.status.error", children: errors.maximumTeamSize }))] }), _jsxs(LocalizationProvider, { dateAdapter: AdapterDayjs, adapterLocale: adapterLocale, children: [_jsxs(Stack, { gap: 1, children: [_jsx(DatePicker, { disabled: isLoading, label: t('teamFormModal.activeFrom'), value: formState.activationDate, onChange: (date) => handleInputChange('activationDate', date), maxDate: formState.expirationDate || undefined, onError: (error) => {
                                                                        console.log('activationDate', error);
                                                                        handleDateError('activationDate', !!error);
                                                                    }, slotProps: {
                                                                        field: {
                                                                            clearable: true,
                                                                        },
                                                                    }, errorMessage: errors.activationDate || undefined, sx: { width: '300px' } }), !errors.activationDate && (_jsx(Typography, { variant: "caption", children: t('teamFormModal.activeFromHelperText') }))] }), _jsxs(Stack, { gap: 1, children: [_jsx(DatePicker, { disabled: isLoading, label: t('teamFormModal.activeUntil'), value: formState.expirationDate, onChange: (date) => handleInputChange('expirationDate', date), minDate: minExpirationDate, onError: (error) => {
                                                                        console.log('expirationDate', error);
                                                                        handleDateError('expirationDate', !!error);
                                                                    }, slotProps: {
                                                                        field: {
                                                                            clearable: true,
                                                                        },
                                                                    }, errorMessage: errors.expirationDate || undefined, sx: { width: '300px' } }), !errors.expirationDate && (_jsx(Typography, { variant: "caption", children: t('teamFormModal.activeUntilHelperText') }))] })] })] }) })] })] })] }), _jsx(DialogSection, { footer: true, children: _jsxs(Stack, { direction: "row", justifyContent: "space-between", alignItems: "center", children: [_jsx(Button, { variant: "outlined", onClick: handleClose, children: t('common.cancel') }), _jsx(Button, { variant: "contained", onClick: handleSubmit, disabled: !isFormValid || isSubmitting, children: submitButtonText })] }) })] }));
};
