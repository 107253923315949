import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useTeamSingleAction } from '../../../hooks/useTeamSingleAction';
import { MenuTrigger } from './MenuTrigger';
import { MenuBody } from './MenuBody';
import { useTeams } from '../../../hooks/useTeams';
export const SingleTeamActionMenu = ({ teamId, disabled = false }) => {
    const { setActionType, setTeamId } = useTeamSingleAction();
    const [anchorEl, setAnchorEl] = useState(null);
    const { teams } = useTeams();
    const team = teams.find((team) => team._id === teamId);
    if (!team) {
        return null;
    }
    const handleTriggerClick = (event) => {
        setTeamId(teamId);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => setAnchorEl(null);
    const handleActionClick = (action) => {
        setActionType(action);
        handleClose();
    };
    return (_jsxs(_Fragment, { children: [_jsx(MenuTrigger, { onClick: handleTriggerClick, open: !!anchorEl, team: team, disabled: disabled }), _jsx(MenuBody, { anchorEl: anchorEl, onClose: handleClose, onClick: handleActionClick, team: team })] }));
};
