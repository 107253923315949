import { jsx as _jsx } from "react/jsx-runtime";
import { Dialog } from '../../../../../common/Dialog';
import { SingleUserEnableDialog } from '../SingleUserEnableDialog';
import { SingleUserDisableDialog } from '../SingleUserDisableDialog';
import { SingleUserDeleteDialog } from '../SingleUserDeleteDialog';
import { SingleUserReinviteDialog } from '../SingleUserReinviteDialog';
import { SingleUserPasswordResetDialog } from '../SingleUserPasswordResetDialog';
const ContentComponent = ({ onClose, onConfirm, userId, action }) => {
    if (!action || !userId)
        return null;
    switch (action) {
        case 'USER_ENABLE':
            return _jsx(SingleUserEnableDialog, { userId: userId, onClose: onClose, onConfirm: onConfirm });
        case 'USER_DISABLE':
            return _jsx(SingleUserDisableDialog, { userId: userId, onClose: onClose, onConfirm: onConfirm });
        case 'USER_DELETE':
            return _jsx(SingleUserDeleteDialog, { userId: userId, onClose: onClose, onConfirm: onConfirm });
        case 'USER_RESEND':
            return _jsx(SingleUserReinviteDialog, { userId: userId, onClose: onClose, onConfirm: onConfirm });
        case 'USER_RESET_PASSWORD':
            return _jsx(SingleUserPasswordResetDialog, { userId: userId, onClose: onClose, onConfirm: onConfirm });
        default:
            return null;
    }
};
export const SingleActionModal = ({ onClose, onConfirm, userId, action }) => {
    return (_jsx(Dialog, { open: !!action && !!userId, onClose: onClose, children: _jsx(ContentComponent, { userId: userId, onClose: onClose, onConfirm: onConfirm, action: action }) }));
};
