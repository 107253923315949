import { styled, svgIconClasses } from '@mui/material';
export const badgeColors = ['default', 'gold', 'cyan', 'pink', 'purple', 'blue', 'red'];
const badgeColorMap = {
    default: 'default',
    gold: 'primary',
    cyan: 'accent1',
    pink: 'accent2',
    purple: 'accent3',
    blue: 'accent4',
    red: 'error',
};
// Similar to the quests admin badge
export const Badge = styled('span', {
    shouldForwardProp: (prop) => prop !== 'color',
})(({ theme, color = 'default' }) => (Object.assign(Object.assign({}, theme.typography.caption), { display: 'inline-flex', alignItems: 'center', height: theme.typography.pxToRem(24), padding: theme.spacing(1, 2), gap: theme.spacing(1), color: theme.palette.badge.text, backgroundColor: theme.palette.badge[badgeColorMap[color]].fill, border: `1px solid ${theme.palette.badge[badgeColorMap[color]].border}`, borderRadius: theme.shape.border.radius.sm, [`.${svgIconClasses.root}`]: {
        fontSize: theme.typography.pxToRem(18),
    } })));
