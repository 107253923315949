export const defaultColumnVisibilityModel = {
    name: true,
    email: true,
    team: true,
    role: true,
    status: true,
    lastLogin: true,
    tags: true,
    actions: true,
    registered: false,
    lastInvited: false,
    inviteReminders: false,
    inviteBounce: false,
};
export const createGridProps = (total = 0) => {
    return {
        initialState: {
            pagination: {
                paginationModel: { pageSize: 50, page: 0 },
                rowCount: total,
            },
            pinnedColumns: {
                right: ['actions'],
            },
        },
        disableColumnSelector: false,
        rowSelection: true,
        disableRowSelectionOnClick: true,
        checkboxSelection: true,
        autoHeight: true,
    };
};
