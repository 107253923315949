var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { DataGrid } from '@securecodewarrior/design-system-react/lib/labs';
import { useTeamsDataGridData } from '../../hooks/useTeamsDataGridData';
import { SingleTeamActionMenu } from '../molecules/SingleTeamActionMenu';
import { SingleActionModal } from '../molecules/SingleActionModal';
import { useTeamSingleAction } from '../../hooks/useTeamSingleAction';
import { useEffect, useState } from 'react';
import { formatDateLongMonth } from '../../../../dateHelper';
export const TeamsDataGrid = () => {
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.dataGrid' });
    const { teams, isLoading, isError, error, gridProps, refetch } = useTeamsDataGridData();
    const { teamId, actionType, clearAction, confirmAction, setTeamId, setActionType } = useTeamSingleAction();
    const [selectedRows, setSelectedRows] = useState([]);
    useEffect(() => {
        refetch();
    }, [refetch]);
    if (isError) {
        return _jsxs(Stack, { children: ["Error: ", error instanceof Error ? error.message : 'Unknown error'] });
    }
    const columns = [
        { field: 'name', headerName: `${t('teamName')}`, hideable: false, flex: 1 },
        {
            field: 'createdAt',
            align: 'right',
            headerName: `${t('creationDate')}`,
            flex: 1,
            valueFormatter: (date) => (date ? formatDateLongMonth(date) : date),
        },
        {
            field: 'status',
            headerName: `${t('status')}`,
            flex: 1,
            valueFormatter: (value) => t(`dataGridValues.status.${value}`),
        },
        { field: 'teamSize', align: 'right', headerName: `${t('size')}`, flex: 1 },
        {
            field: 'actions',
            type: 'actions',
            headerName: '',
            hideable: false,
            filterable: false,
            minWidth: 50,
            maxWidth: 50,
            renderCell: ({ row }) => _jsx(SingleTeamActionMenu, { teamId: row.id, disabled: !!selectedRows.length }),
        },
    ];
    const handleRowClick = ({ row }) => {
        setTeamId(row.id);
        setActionType('TEAM_EDIT');
    };
    return (_jsxs(_Fragment, { children: [_jsx(DataGrid, Object.assign({ variant: "pagination", dataSource: { data: teams, loading: isLoading }, name: "teams-list", columns: columns, onRowClick: handleRowClick, getCellClassName: (params) => {
                    if (params.row.status === 'disabled') {
                        return 'scwUserDisabled';
                    }
                    return '';
                }, slotProps: {
                    pagination: {
                        rowsPerPageOptions: [10, 25, 50, 100, 200],
                    },
                    toolbar: { csvOptions: { disableToolbarButton: true } },
                }, onRowSelectionModelChange: setSelectedRows, sx: (theme) => ({
                    '& .MuiDataGrid-cell:focus-within': { outline: 'none' },
                    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': { width: 'unset', height: 'unset' },
                    '& .MuiDataGrid-row': {
                        cursor: 'pointer',
                        '&:hover': { backgroundColor: theme.palette.container.fill.card2 },
                    },
                    '& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root input': { marginTop: '0px' },
                    '& .MuiDataGrid-cell.scwUserDisabled': { fontStyle: 'italic', color: 'text.soft' },
                    '& .MuiDataGrid-columnHeaderTitleContainer .MuiDataGrid-columnHeaderTitle': { whiteSpace: 'nowrap' },
                }) }, gridProps)), teamId && actionType && (_jsx(SingleActionModal, { teamId: teamId, onClose: clearAction, onConfirm: (payload) => __awaiter(void 0, void 0, void 0, function* () { return yield confirmAction(payload); }), action: actionType }))] }));
};
