import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Container, Stack } from '@mui/material';
import { WandaFooter } from '../../../footer/WandaFooter';
export const SectionContainer = ({ children, isCentered, styleOverrides, useContainer, }) => {
    return (_jsxs(Box, { tabIndex: 0, sx: (theme) => ({
            width: '100%',
            height: '100%',
            overflow: 'auto',
            minHeight: 0,
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            ':focus-visible': {
                outline: `2px solid ${theme.palette.clickable.default}`,
                outlineOffset: `-${theme.typography.pxToRem(4)}`,
            },
            scrollbarColor: `${theme.palette.grayscale.bg6} ${theme.palette.grayscale.bg4}`,
            scrollbarWidth: 'thin',
            '::-webkit-scrollbar': {
                background: theme.palette.grayscale.bg4,
                width: '10px',
            },
            '::-webkit-scrollbar-thumb': {
                background: theme.palette.grayscale.bg6,
                borderRadius: 4,
            },
        }), children: [useContainer && (_jsx(Container, { maxWidth: "xl", component: "div", disableGutters: true, sx: (theme) => (Object.assign({ display: 'flex', textAlign: 'center', flex: 1, justifyContent: isCentered ? 'center' : 'flex-start', alignItems: 'center', flexDirection: 'column', gap: theme.spacing(6), padding: theme.spacing(10) }, (styleOverrides || {}))), children: children })), !useContainer && (_jsx(Stack, { sx: Object.assign({ padding: 0, display: 'flex', flex: 1, justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column' }, (styleOverrides || {})), children: children })), _jsx(WandaFooter, {})] }));
};
