import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, Typography } from '@mui/material';
import { Card } from '@securecodewarrior/design-system-react/lib/labs';
import { TeamsDataGrid } from './organisms/TeamsDataGrid';
import { SingleActionProvider } from '../../common/context';
import { AddTeamModal } from './molecules/AddTeamModal/AddTeamModal';
import { useTranslation } from 'react-i18next';
export const Teams = () => {
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.teamManagement' });
    return (_jsxs(Stack, { gap: ({ spacing }) => spacing(10), children: [_jsxs(Stack, { direction: 'row', justifyContent: 'space-between', children: [_jsx(Typography, { variant: "subtitle1", children: t('searchAllTeams') }), _jsx(AddTeamModal, {})] }), _jsx(Card, { sx: ({ spacing }) => ({ padding: spacing(7) }), children: _jsx(SingleActionProvider, { children: _jsx(TeamsDataGrid, {}) }) })] }));
};
