import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Dialog, Stack, Typography } from '@mui/material';
import { QuestEditorSection } from './QuestEditorSection';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePlatformContext } from '../../../../platformContext';
import { questDetailsAnalytics } from '../../../analytics/QuestDetails.analytics';
import { TeamAssignment } from '../TeamAssignment';
import { ItemSelection } from '../../molecules/ItemSelection';
import { OptionCard } from '../../molecules/OptionCard';
import { BusinessRounded, SupervisedUserCircleRounded } from '@mui/icons-material';
export const QuestEditorUserAssignmentSection = ({ assignedTeamIds, status, editingDisabled, skeletonMode, companyTeams, assignmentSelection, onSelectionEdit, }) => {
    var _a, _b;
    const { t } = useTranslation();
    const { logAnalyticsEvent, sessionUser } = usePlatformContext();
    const analytics = useMemo(() => questDetailsAnalytics(logAnalyticsEvent), [logAnalyticsEvent]);
    const tempStoredAssignedTeamIds = useRef([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const readOnly = !!skeletonMode || editingDisabled;
    const companyRadioLabel = t('questsAdmin.components.organisms.questEditor.sections.userAssignment.everyoneInCompany', { companyName: ((_b = (_a = sessionUser === null || sessionUser === void 0 ? void 0 : sessionUser.properties) === null || _a === void 0 ? void 0 : _a.company) === null || _b === void 0 ? void 0 : _b.name) || t('pages.administration.communicationsCentre.company') });
    const showCompanyCard = skeletonMode || !editingDisabled || assignmentSelection === 'company';
    const showTeamCard = skeletonMode || !editingDisabled || assignmentSelection === 'team';
    const { displayedChips, assignedCount } = useMemo(() => {
        const assignedAndActiveTeams = companyTeams
            .filter((team) => assignedTeamIds.includes(team.id))
            .map((team) => ({ key: team.id, label: team.name }));
        const deletedOrDisabledTeamCount = assignedTeamIds.filter((teamId) => !companyTeams.some((t) => t.id === teamId)).length;
        const deletedOrDisabledTeamsChip = deletedOrDisabledTeamCount
            ? [
                {
                    key: 'deleted',
                    label: t('questsAdmin.components.organisms.questEditor.sections.userAssignment.teamsDeletedCount', {
                        count: deletedOrDisabledTeamCount,
                    }),
                },
            ]
            : [];
        return {
            displayedChips: [...deletedOrDisabledTeamsChip, ...assignedAndActiveTeams],
            assignedCount: assignedAndActiveTeams.length,
        };
    }, [assignedTeamIds, companyTeams, t]);
    const handleOpenTeamAssignmentDialog = () => {
        setDialogOpen(true);
        analytics.onTeamAssignmentDialogOpened(status, assignedCount, companyTeams.length);
    };
    const handleCloseTeamAssignmentDialog = () => {
        setDialogOpen(false);
        analytics.onTeamAssignmentyDialogClosed(status);
    };
    const handleRadioUpdate = (value) => {
        if (value === 'company') {
            tempStoredAssignedTeamIds.current = [...assignedTeamIds];
            updateTeamAssignment([], 'radio company');
        }
        else {
            updateTeamAssignment([...tempStoredAssignedTeamIds.current], 'radio team');
        }
    };
    const handleTeamAssignmentUpdate = (updatedTeamIds) => {
        setDialogOpen(false);
        updateTeamAssignment(updatedTeamIds, 'dialog');
    };
    const handleDeleteTeam = (removedTeam) => {
        const updatedTeamIds = removedTeam.key === 'deleted'
            ? assignedTeamIds.filter((teamId) => companyTeams.some((t) => t.id === teamId))
            : assignedTeamIds.filter((team) => team !== removedTeam.key);
        updateTeamAssignment(updatedTeamIds, 'chip remove');
    };
    const updateTeamAssignment = (updatedTeamIds, trigger) => {
        analytics.onTeamAssignmentUpdated(status, assignedTeamIds, updatedTeamIds, companyTeams.length, trigger);
        onSelectionEdit === null || onSelectionEdit === void 0 ? void 0 : onSelectionEdit(updatedTeamIds, trigger === 'radio company' ? 'company' : 'team');
    };
    return (_jsxs(_Fragment, { children: [_jsx(QuestEditorSection, { children: _jsxs(Box, { sx: { flex: 1 }, children: [_jsx(Typography, { component: "h2", variant: "h5", sx: (theme) => ({
                                marginBottom: theme.spacing(3),
                                lineHeight: theme.typography.pxToRem(18),
                            }), children: t('questsAdmin.pages.list.tableHeader.participants') }), _jsx(Typography, { variant: "body1", sx: (theme) => ({ marginBottom: theme.spacing(9) }), children: t('questsAdmin.components.organisms.questEditor.sections.userAssignment.selectParticipants') }), _jsx(Typography, { variant: "h6", component: "p", sx: (theme) => ({ marginBottom: theme.spacing(3), color: theme.palette.text.body }), children: t('questsAdmin.components.organisms.questEditor.sections.userAssignment.assignTo') }), _jsxs(Stack, { gap: 4, children: [showCompanyCard && (_jsx(OptionCard, { title: companyRadioLabel, description: t('questsAdmin.components.organisms.questEditor.sections.userAssignment.everyoneInCompanyDescription'), type: "radio", selected: assignmentSelection === 'company', onChange: () => handleRadioUpdate('company'), disabled: readOnly, radioGroup: "userAssignment", icon: _jsx(BusinessRounded, { sx: (theme) => ({
                                            color: theme.palette.text.soft,
                                            width: theme.typography.pxToRem(32),
                                            height: theme.typography.pxToRem(32),
                                        }) }) })), showTeamCard && (_jsx(OptionCard, { title: t('questsAdmin.components.organisms.questEditor.sections.userAssignment.specificTeams'), description: t('questsAdmin.components.organisms.questEditor.sections.userAssignment.specificTeamsDescription'), type: "radio", selected: assignmentSelection === 'team', onChange: () => handleRadioUpdate('team'), disabled: readOnly, radioGroup: "userAssignment", icon: _jsx(SupervisedUserCircleRounded, { sx: (theme) => ({
                                            color: theme.palette.text.soft,
                                            width: theme.typography.pxToRem(32),
                                            height: theme.typography.pxToRem(32),
                                        }) }), children: assignmentSelection === 'team' && (_jsx(ItemSelection, { selectedItemsLabel: t('questsAdmin.components.organisms.questEditor.sections.userAssignment.selectTeamsTitle'), selection: displayedChips, onButtonClicked: handleOpenTeamAssignmentDialog, onItemRemove: handleDeleteTeam, disabled: readOnly })) }))] })] }) }), _jsx(Dialog, { open: dialogOpen, fullWidth: true, maxWidth: "md", scroll: "body", onClose: handleCloseTeamAssignmentDialog, sx: (theme) => ({
                    '& .MuiPaper-root:not(.MuiAlert-root)': {
                        border: `1px solid ${theme.palette.container.border.default}`,
                        borderRadius: 3,
                        maxHeight: '720px',
                    },
                }), children: _jsx(TeamAssignment, { assignedTeamIds: assignedTeamIds, companyTeams: companyTeams, questStatus: status, readOnly: readOnly, onConfirm: handleTeamAssignmentUpdate, onCancel: handleCloseTeamAssignmentDialog }) })] }));
};
