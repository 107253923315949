export const deleteUsers = (users, ids) => {
    return users.filter((user) => !ids.includes(user._id));
};
export const disableUsers = (users, ids) => {
    return users.map((u) => {
        if (ids.includes(u._id))
            u.status = 'disabled';
        return u;
    });
};
export const enableUsers = (users, ids) => {
    return users.map((u) => {
        if (ids.includes(u._id))
            u.status = 'enabled';
        return u;
    });
};
export const setTeam = (users, ids, _tid) => {
    return users.map((u) => {
        if (ids.includes(u._id))
            u.properties._tid = _tid;
        return u;
    });
};
export const setRole = (users, ids, role) => {
    return users.map((u) => {
        if (ids.includes(u._id))
            u.roles = [...u.roles, role];
        return u;
    });
};
export const addTags = (users, ids, tags) => {
    return users.map((u) => {
        if (ids.includes(u._id))
            u.properties.tags = Array.from(new Set([...u.properties.tags, ...tags]));
        return u;
    });
};
export const removeTags = (users, ids, tags) => {
    return users.map((u) => {
        if (ids.includes(u._id))
            u.properties.tags = u.properties.tags.filter((tag) => !tags.includes(tag));
        return u;
    });
};
