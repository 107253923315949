import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTeamsQueryOptions } from './useTeamsQueryOptions';
export const useTeams = () => {
    const { config } = useTeamsQueryOptions();
    const { data: teams, isLoading, isError, error, refetch } = useQuery(config);
    const tags = useMemo(() => { var _a; return Array.from(new Set((_a = teams === null || teams === void 0 ? void 0 : teams.map((team) => team.tags).flat()) !== null && _a !== void 0 ? _a : [])); }, [teams]);
    return {
        teams: teams !== null && teams !== void 0 ? teams : [],
        tags,
        isLoading,
        isError,
        error,
        refetch,
    };
};
