import angular from 'angular';

import { getAmplitudeGroups } from './amplitude-client';
import { ANALYTICS_EVENTS } from './constants';
import MODULE from './module';
import { sha1 } from 'js-sha1';

const app = angular.module(MODULE);

app.constant('AnalyticsEvents', ANALYTICS_EVENTS);

app.service('AnalyticsService', [
  '$log',
  '$window',
  '$location',
  'Session',
  function ($log, $window, $location, Session) {
    function amplitudeInstance() {
      if ($window?.amplitude) {
        return $window.amplitude;
      }
      $log.error('No amplitude instance');
      return null;
    }

    function getRouteProps() {
      const absFullUrl = $location.absUrl();
      const absUrl = absFullUrl.split('?')[0];
      const sanitisedUrl = absUrl
        .replace(/\/[0-9a-f]{24}/gi, '/*')
        .replace(/\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/gi, '/*');
      const [host, path] = sanitisedUrl.split('/#');
      return {
        url: sanitisedUrl,
        type: 'page',
        host: host,
        path: `/#${path || ''}`,
      };
    }

    function getUserType(user, company) {
      if (user.roles?.includes('scw admin')) {
        return 'scw admin';
      }

      if (!company) {
        return 'web trial';
      }

      if (company?.customerType === 'trial') {
        return 'sales trial';
      }

      return 'paid user';
    }

    function isScwStaff(user) {
      const scwDomains = [
        'securecodewarrior.com',
        'scw.com',
        'scw.io',
        'securecodewarrior.net',
        'securecodewarrior.org',
        'securecodewarrior.info',
        'securecodewarriors.com',
      ];

      if (!user?.email) {
        return;
      }

      const userEmailDomain = user.email.split('@')[1]?.toLowerCase();
      return scwDomains.includes(userEmailDomain) ? 'yes' : 'no';
    }

    function generateUserProps() {
      const user = Session.user;
      const company = user?.properties?.company
        ? {
            user_type: getUserType(user, user?.properties?.company),
            salesforce_id: user?.properties?.company?.vleId,
            customer_type: user?.properties?.company?.customerType,
            customer_plan: user?.properties?.company?.customerPlan,
          }
        : {};
      // Handle SCW Admin and Standalone users
      const team = user?.properties?._tid ? { team_id: sha1(user.properties._tid) } : {};
      const company_id = user?.properties?._cid ? sha1(user.properties._cid) : undefined;

      return {
        company_id,
        is_scw_staff: isScwStaff(user),
        preferred_language_framework: user.properties.profile?.preferredDevLanguages?.join(', '),
        spoken_language: user.properties.profile?.i18nLanguagePreference || user.properties.profile?.language,
        user_email: user.email,
        user_status: user?.status,
        ...team,
        ...company,
      };
    }

    return {
      logEvent: async function (event, eventProps) {
        const amplitude = amplitudeInstance();
        if (!amplitude) {
          return;
        }

        const amplitudeEvent = {
          event_type: event,
          event_properties: {
            ...this.getDefaultEventProps(),
            ...(eventProps ?? {}),
            ...this.getExtraEventProps(),
          },
          user_properties: generateUserProps(),
        };

        await amplitude.track(amplitudeEvent);
      },
      logPageViewEvent: function (_absUrl, pageName = '', type = 'page') {
        if (pageName !== 'home' && pageName !== 'dashboard') {
          this.logEvent(ANALYTICS_EVENTS.General.VIEW_CHANGE, {
            ...getRouteProps(),
            type,
            ...(pageName && { page_name: pageName }),
          });
        }
      },
      getAmplitudeGroups, // this is used in react-components
      generateUserProps, // this is used in react-components
      getDefaultEventProps: function () {
        return {
          ...getRouteProps(),
        };
      },
      getExtraEventProps: function () {
        const urlParams = new URLSearchParams($window.location.search);
        const tenant_id = urlParams.get('tenant_id');
        const integration_id = urlParams.get('integration_id');
        const channel = window.localStorage.getItem('channel');
        return {
          ...(tenant_id ? { tenant_id } : {}),
          ...(integration_id ? { integration_id } : {}),
          ...(channel ? { channel } : {}),
        };
      },
      getRouteProps,
    };
  },
]);
