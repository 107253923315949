import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Stack, Typography } from '@mui/material';
import { Radio } from '@securecodewarrior/design-system-react/lib/base/wanda/Radio/Radio';
import { Checkbox } from '@securecodewarrior/design-system-react/lib/wanda';
import { useId } from 'react';
export const OptionCard = ({ title, description, onChange, type, radioGroup, selected, disabled, icon, children, }) => {
    const titleId = useId();
    const controlId = useId();
    return (_jsxs(Stack, { direction: "row", sx: (theme) => ({
            borderRadius: theme.shape.border.radius.md,
            border: `1px solid ${selected && !disabled ? theme.palette.container.border.active : theme.palette.container.border.default}`,
            backgroundColor: theme.palette.container.fill.card2,
        }), children: [_jsx(Box, { sx: (theme) => ({
                    p: 5,
                    paddingTop: type === 'radio' ? 6 : 5,
                    borderRight: `1px solid ${theme.palette.container.border.default}`,
                }), children: type === 'checkbox' ? (_jsx(Checkbox, { disabled: disabled, checked: selected, inputProps: { 'aria-labelledby': titleId, id: controlId }, sx: { padding: 0 }, onChange: (e, checked) => onChange(e, checked) })) : (_jsx(Radio, { radioGroup: radioGroup, inputProps: { 'aria-labelledby': titleId, id: controlId }, checked: selected, disabled: disabled, onChange: (e, checked) => onChange(e, checked) })) }), _jsxs(Stack, { p: 5, gap: 5, flexGrow: 1, children: [_jsxs(Stack, { gap: 3, direction: "row", children: [icon, _jsxs(Stack, { gap: 2, children: [_jsx(Typography, { id: titleId, component: "label", htmlFor: controlId, variant: "h6", sx: { cursor: disabled ? undefined : 'pointer' }, children: title }), _jsx(Typography, { variant: "caption", children: description })] })] }), children] })] }));
};
