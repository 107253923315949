import { styled, Accordion, accordionSummaryClasses, accordionDetailsClasses, } from '@mui/material';
export const StyledAccordion = styled(Accordion)(({ theme }) => ({
    backgroundColor: theme.palette.container.fill.card1,
    color: theme.palette.text.primary,
    boxShadow: 'none',
    [`& .${accordionSummaryClasses.root}`]: {
        minHeight: 24,
        padding: 0,
        background: theme.palette.container.fill.card1,
        [`&.${accordionSummaryClasses.expanded}`]: {
            minHeight: 0,
            margin: 0,
        },
        [`& .${accordionSummaryClasses.content}`]: {
            margin: 0,
        },
        [`& .${accordionSummaryClasses.expandIconWrapper}`]: {
            color: theme.palette.clickable.default,
        },
    },
    [`& .${accordionDetailsClasses.root}`]: {
        backgroundColor: theme.palette.container.fill.card1,
        color: theme.palette.text.primary,
        padding: 0,
    },
    '&::before': {
        backgroundColor: 'unset',
    },
}));
