import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { usePlatformContext } from '../../platformContext';
import { useApiClient } from '../../ApiContext';
import { useParams } from 'react-router-dom';
import { CourseActivity } from '../courses/activities/CourseActivity';
import { myQuestsCourseActivityAnalytics } from '../myQuests/myQuests.analytics';
import { useEffect, useState } from 'react';
import { LmsConnectionLostModal } from './components/LmsConnectionLostModal';
export const LmsQuestActivityFromRouteParams = () => {
    const { questId, courseId, topicCategory, activityType, activityNr: uncheckedActivitynr } = useParams();
    const { trpc } = useApiClient();
    const activityNr = parseInt(uncheckedActivitynr !== null && uncheckedActivitynr !== void 0 ? uncheckedActivitynr : '');
    if (!questId ||
        !courseId ||
        !topicCategory ||
        (activityType !== 'video' &&
            activityType !== 'guideline' &&
            activityType !== 'challenge' &&
            activityType !== 'coding_lab' &&
            activityType !== 'mission' &&
            activityType !== 'walkthrough') ||
        isNaN(activityNr)) {
        throw new Error('Invalid parameters');
    }
    const { logAnalyticsEvent, sessionUser } = usePlatformContext();
    const analytics = myQuestsCourseActivityAnalytics(logAnalyticsEvent, sessionUser, 'lmsQuest');
    const startOrContinueFn = (redoIfCompleted) => {
        const input = { courseId, topicCategory, activityType, activityNr, redoIfCompleted };
        return trpc.myquests.startOrContinueActivity.mutate(input);
    };
    const getNextActivityFrontendPath = (n) => {
        return lmsQuestActivityPath(questId, courseId, n.category, n.activityType, n.activityNr);
    };
    const backNavigationData = {
        highlightedActivity: { courseLanguage: courseId, topicCategory, activityType, activityNr },
    };
    const [lmsConnectionLost, setLmsConnectionLost] = useState(false);
    useEffect(() => {
        const intervalId = setInterval(() => {
            var _a;
            if (!((_a = window.top) === null || _a === void 0 ? void 0 : _a.opener)) {
                setLmsConnectionLost(true);
            }
        }, 1000);
        return () => clearInterval(intervalId);
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(CourseActivity, { courseId: courseId, activityType: activityType, analytics: analytics, analyticsScope: "lmsQuest", returnPath: `/my-quests/lms/${questId}`, backNavigationData: backNavigationData, startOrContinueFn: startOrContinueFn, getNextActivityFrontendPath: getNextActivityFrontendPath }), _jsx(LmsConnectionLostModal, { open: lmsConnectionLost })] }));
};
export const lmsQuestActivityPath = (questId, courseId, category, activityType, activityNr) => {
    return `/my-quests/lms/${questId}/${courseId}/topic/${category}/${activityType}/${activityNr}`;
};
