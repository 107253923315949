var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@tanstack/react-query';
import { useUsersQueryOptions } from './useUsersQueryOptions';
import { useUserApis } from './useUserApis';
import { deleteUsers, disableUsers, enableUsers, setTeam, setRole, addTags, removeTags } from '../utils/mutationUtils';
import { useMemo } from 'react';
export const useUserMutations = () => {
    const { onMutate, onSettled, onError } = useUsersQueryOptions();
    const apis = useUserApis();
    const onDelete = (ids) => onMutate((prev) => deleteUsers(prev, ids));
    const onDisable = (ids) => onMutate((prev) => disableUsers(prev, ids));
    const onEnable = (ids) => onMutate((prev) => enableUsers(prev, ids));
    const onAddTags = ({ ids, tags }) => onMutate((prev) => addTags(prev, ids, tags));
    const onRemoveTags = ({ ids, tags }) => onMutate((prev) => removeTags(prev, ids, tags));
    const onRoleChange = ({ ids, role }) => onMutate((prev) => setRole(prev, ids, role));
    const onTeamChange = ({ ids, _tid }) => onMutate((prev) => setTeam(prev, ids, _tid));
    const userDelete = useMutation({ mutationFn: apis.deleteUsers, onSettled, onError, onMutate: onDelete });
    const userDisable = useMutation({ mutationFn: apis.disableUsers, onSettled, onError, onMutate: onDisable });
    const userEnable = useMutation({ mutationFn: apis.enableUsers, onSettled, onError, onMutate: onEnable });
    const userReinvite = useMutation({ mutationFn: apis.reinviteUsers });
    const userResend = useMutation({ mutationFn: apis.resendInvite });
    const userPasswordReset = useMutation({ mutationFn: apis.resetPasswords });
    const userAddTags = useMutation({ mutationFn: apis.addTags, onSettled, onError, onMutate: onAddTags });
    const userRemoveTags = useMutation({ mutationFn: apis.removeTags, onSettled, onError, onMutate: onRemoveTags });
    const userChangeRoles = useMutation({ mutationFn: apis.changeRoles, onSettled, onError, onMutate: onRoleChange });
    const userChangeTeams = useMutation({ mutationFn: apis.changeTeams, onSettled, onError, onMutate: onTeamChange });
    const mutate = ({ type, payload }) => __awaiter(void 0, void 0, void 0, function* () {
        switch (type) {
            case 'delete':
                return userDelete.mutateAsync(payload);
            case 'enable':
                return userEnable.mutateAsync(payload);
            case 'reinvite':
                return userReinvite.mutateAsync(payload);
            case 'disable':
                return userDisable.mutateAsync(payload);
            case 'resetPassword':
                return yield userPasswordReset.mutateAsync(payload);
            case 'resendInvite':
                return yield userResend.mutateAsync(payload);
            case 'addTags':
                return userAddTags.mutateAsync(payload);
            case 'removeTags':
                return userRemoveTags.mutateAsync(payload);
            case 'changeRoles':
                return userChangeRoles.mutateAsync(payload);
            case 'changeTeams':
                return userChangeTeams.mutateAsync(payload);
        }
    });
    const isMutating = useMemo(() => userDelete.isPending ||
        userDisable.isPending ||
        userEnable.isPending ||
        userReinvite.isPending ||
        userPasswordReset.isPending ||
        userResend.isPending ||
        userAddTags.isPending ||
        userRemoveTags.isPending ||
        userChangeRoles.isPending ||
        userChangeTeams.isPending, [
        userDelete.isPending,
        userDisable.isPending,
        userEnable.isPending,
        userReinvite.isPending,
        userPasswordReset.isPending,
        userResend.isPending,
        userAddTags.isPending,
        userRemoveTags.isPending,
        userChangeRoles.isPending,
        userChangeTeams.isPending,
    ]);
    const isMutationError = useMemo(() => userDelete.isError ||
        userDisable.isError ||
        userEnable.isError ||
        userReinvite.isError ||
        userPasswordReset.isError ||
        userResend.isError ||
        userAddTags.isError ||
        userRemoveTags.isError ||
        userChangeRoles.isError ||
        userChangeTeams.isError, [
        userDelete.isError,
        userDisable.isError,
        userEnable.isError,
        userReinvite.isError,
        userPasswordReset.isError,
        userResend.isError,
        userAddTags.isError,
        userRemoveTags.isError,
        userChangeRoles.isError,
        userChangeTeams.isError,
    ]);
    const mutationError = useMemo(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        return (_j = (_h = (_g = (_f = (_e = (_d = (_c = (_b = (_a = userDelete.error) !== null && _a !== void 0 ? _a : userDisable.error) !== null && _b !== void 0 ? _b : userEnable.error) !== null && _c !== void 0 ? _c : userReinvite.error) !== null && _d !== void 0 ? _d : userPasswordReset.error) !== null && _e !== void 0 ? _e : userResend.error) !== null && _f !== void 0 ? _f : userAddTags.error) !== null && _g !== void 0 ? _g : userRemoveTags.error) !== null && _h !== void 0 ? _h : userChangeRoles.error) !== null && _j !== void 0 ? _j : userChangeTeams.error;
    }, [
        userDelete.error,
        userDisable.error,
        userEnable.error,
        userReinvite.error,
        userPasswordReset.error,
        userResend.error,
        userAddTags.error,
        userRemoveTags.error,
        userChangeRoles.error,
        userChangeTeams.error,
    ]);
    return {
        mutate,
        isMutating,
        isMutationError,
        mutationError,
    };
};
