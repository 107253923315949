export const communicationsAnalytics = (sendEvent) => ({
    onboardingMessage: {
        onToggle: (enabled) => sendEvent({ event: 'Onboarding Message Toggled', scope: 'communications-centre', enabled }),
        onSave: () => sendEvent({ event: 'Onboarding Message Saved', scope: 'communications-centre' }),
        onEdit: () => sendEvent({ event: 'Onboarding Message Edited', scope: 'communications-centre' }),
    },
    ssoErrorMessage: {
        onToggle: (enabled) => sendEvent({ event: 'SSO Error Message Toggled', scope: 'communications-centre', enabled }),
        onSave: () => sendEvent({ event: 'SSO Error Message Saved', scope: 'communications-centre' }),
        onEdit: () => sendEvent({ event: 'SSO Error Message Edited', scope: 'communications-centre' }),
    },
    inactivityReminder: {
        onToggle: (enabled) => sendEvent({ event: 'Inactivity Reminder Toggled', scope: 'communications-centre', enabled }),
    },
    msTeams: {
        onToggle: (enabled) => sendEvent({ event: 'MS Teams Toggled', scope: 'communications-centre', enabled }),
    },
    scimEmails: {
        onToggle: (enabled) => sendEvent({ event: 'SCIM Invitation Emails Toggled', scope: 'communications-centre', enabled }),
    },
    icsAttachments: {
        onToggle: (enabled) => sendEvent({ event: 'ICS Attachments Toggled', scope: 'communications-centre', enabled }),
    },
    questEmailsEnabled: {
        onToggle: (enabled) => sendEvent({ event: 'Goal Emails Toggled', scope: 'communications-centre', enabled }),
    },
    executiveSponsorEmail: {
        onEdit: () => sendEvent({ event: 'Executive Sponsor Subscriptions Edited', scope: 'communications-centre' }),
        onSend: () => sendEvent({ event: 'Executive Sponsor Example Email Sent', scope: 'communications-centre' }),
    },
});
