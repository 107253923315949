import _ from 'lodash';
import angular from 'angular';
import MODULE from './module';
import { initAmplitudeClient } from '../analytics/amplitude-client';

import { AUTH_EVENTS, USER_ROLES } from './constants';

const app = angular.module(MODULE);

/**
 * The purpose of this SessionId service is to avoid circular dependency
 * among Session, UserflowService and HttpConfigService.
 *
 */
app.service('SessionId', [
  '$window',
  'SESSION_KEY',
  function ($window, SESSION_KEY) {
    this.get = function () {
      return $window.sessionStorage.getItem(SESSION_KEY) || $window.localStorage.getItem(SESSION_KEY);
    };
  },
]);

app.service('Session', [
  'SessionId',
  'UserflowService',
  '$window',
  '$rootScope',
  '$translate',
  'SESSION_KEY',
  'EarlyAccessApi',
  'customerAnalyticsClient',
  function (
    SessionId,
    UserflowService,
    $window,
    $rootScope,
    $translate,
    SESSION_KEY,
    EarlyAccessApi,
    customerAnalyticsClient
  ) {
    this.create = function (sessionId, user, keepMeLoggedIn) {
      this.user = user;
      this.storeSessionId(sessionId, keepMeLoggedIn);
      if (user.properties.preferences?.demo?.reportingData?.enabled) {
        $window.sessionStorage.setItem('demo', true);
      }

      $rootScope.startUserUpdateInterval();

      // Set X-Session-ID header to sessionId, in order to bind subsequent Amplitude events to the user (if logged in).
      initAmplitudeClient($window, user);
      customerAnalyticsClient.setPortalSessionId(sessionId);

      if (!user.properties || !user.properties.profile || !user.properties.profile.name) {
        return;
      }

      const isSCWAdmin = user && user.roles.indexOf('scw admin') > -1;
      if (!isSCWAdmin) {
        UserflowService.execute();
        EarlyAccessApi.logCurrentSettings();
      }

      //Loads user language preference on their account
      if (user.properties.profile.i18nLanguagePreference) {
        //Set translations to load user preferred language
        $rootScope.changeLanguage(user.properties.profile.i18nLanguagePreference);
        $rootScope.getLocaleMetadata(user.properties.profile.i18nLanguagePreference);
      } else {
        $rootScope.getLocaleMetadata($translate.use());
        user.properties.profile.i18nLanguagePreference = $translate.use();
      }

      // Get Usersnap preference and create usersnap UI element only if preference not set or set but enabled
      const usersnapAppStatus = _.get(user, 'properties.preferences.integrations.usersnapApp.enabled');
      if (usersnapAppStatus !== undefined && !usersnapAppStatus) {
        $rootScope.hideUserSnapApp = true;
      }

      $rootScope.configureChurnZero(this.user);
      $rootScope.configureZendesk(this.user);

      $rootScope.$broadcast(AUTH_EVENTS.loginSuccess, this.user);
    };

    this.clearSession = function () {
      try {
        $window.localStorage.removeItem(SESSION_KEY);
        $window.sessionStorage.removeItem(SESSION_KEY);
        $window.localStorage.removeItem('courseManagementFilters');
        $window.localStorage.removeItem('assessmentListFilterSettings');
        $window.localStorage.removeItem('codingLabsFilterSettings');
        $window.sessionStorage.removeItem('demo');
        Object.keys($window.localStorage)?.forEach((key) => {
          if (key.startsWith('retainedTableFilters:')) {
            $window.localStorage.removeItem(key);
          }
        });
      } catch (error) {
        console.log(error);
      }
    };

    this.destroy = function () {
      this.user = null;
      this.clearSession();

      // Set userID to 'undefined', in order to bind subsequent Amplitude events to anonymous users.
      initAmplitudeClient($window, undefined);

      customerAnalyticsClient.clearPortalSessionId();

      if ($rootScope.cancelUserUpdateInterval) {
        $rootScope.cancelUserUpdateInterval();
      }

      $rootScope.clearZendesk();
      $window.ChurnZeroShutdown();
    };

    this.getSessionId = function () {
      return SessionId.get();
    };

    this.isSessionRemembered = function () {
      return $window.localStorage.getItem(SESSION_KEY) !== null;
    };

    this.storeSessionId = function (sessionId, keepMeLoggedIn) {
      if (keepMeLoggedIn === undefined && this.isSessionRemembered()) {
        keepMeLoggedIn = true;
      }

      if (keepMeLoggedIn) {
        // Explictly ticked "remember me" or the keepMyLogin is de-facto on
        $window.sessionStorage.removeItem(SESSION_KEY);
        $window.localStorage.setItem(SESSION_KEY, sessionId);
      } else {
        $window.localStorage.removeItem(SESSION_KEY);
        $window.sessionStorage.setItem(SESSION_KEY, sessionId);
      }
    };

    this.getUserProfile = function () {
      return this.user.properties.profile;
    };

    this.updateUserProfile = function (newProfile) {
      this.user.properties.profile = newProfile;
    };

    this.updateUser = function (user) {
      this.user = user;
    };

    this.getUser = function () {
      return this.user;
    };

    this.getUserCompany = function () {
      return this.user.properties.company;
    };

    this.isAnonymous = function () {
      const anonymous = 'anonymous';
      return !this.user || this.user.roles?.includes(anonymous);
    };

    this.getUserType = function (user = null) {
      const u = user || this.user;
      if (!u.roles.includes(USER_ROLES.admin)) {
        const { company } = u.properties;
        if (company?.customerType === 'trial') {
          return 'sales trial';
        }
        if (!company) {
          return 'web trial';
        }
        return 'paid user';
      }
      return 'scw admin';
    };

    return this;
  },
]);
