import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MyQuestsFullWidthBackgroundContainer } from './MyQuestFullwidthBackgroundContainer';
import { Skeleton } from '@securecodewarrior/design-system-react';
import { Box, Stack, Typography } from '@mui/material';
import { MyQuestsContainer } from './MyQuestsContainer';
import { TimelapseRounded } from '@mui/icons-material';
import { Trans } from 'react-i18next';
export const TrainingInformation = (props) => {
    return (_jsx(MyQuestsFullWidthBackgroundContainer, { topBorder: true, bottomBorder: true, position: "sticky", top: "0", zIndex: (theme) => theme.zIndex.appBar - 1, children: _jsxs(MyQuestsContainer, { display: "flex", flexDirection: "row", justifyContent: "space-between", py: 3, children: ['skeleton' in props ? (_jsx(Skeleton, { width: "260px" })) : (_jsxs(Stack, { gap: 2, direction: "row", alignItems: "center", children: [_jsx(TimelapseRounded, { sx: (theme) => ({ color: variantColors(theme)[props.variant] }) }), _jsx(Typography, { fontWeight: 700, children: _jsx(Trans, { i18nKey: `pages.myQuests.trainingInfo.${props.variant}`, values: { minutes: props.minutesLeft }, children: _jsx(Box, { component: "span", color: (theme) => variantColors(theme)[props.variant] }) }) })] })), 'skeleton' in props ? (_jsx(Skeleton, { width: "65px" })) : (_jsx(Typography, { sx: (theme) => ({ color: theme.palette.text.soft }), children: _jsx(Trans, { i18nKey: `pages.myQuests.trainingInfo.topicStats`, values: props, children: _jsx(Typography, { component: "b", sx: (theme) => ({ color: theme.palette.text.title }) }) }) }))] }) }));
};
const variantColors = (theme) => ({
    mandatory: theme.palette.text.status.error,
    optional: theme.palette.text.soft,
    nonSpecific: theme.palette.text.status.error,
});
