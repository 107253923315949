var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Dialog, dialogClasses } from '@mui/material';
import { CloseIconButton } from '../../../../common/components/atoms/CloseIconButton';
import { CompletedQuestDetail } from '../../components/CompletedQuestDetail';
export const CompletedQuestDetailModal = (_a) => {
    var { quest } = _a, rest = __rest(_a, ["quest"]);
    const { t } = useTranslation();
    return (_jsxs(Dialog, Object.assign({ sx: (theme) => ({
            [`& .${dialogClasses.paper}`]: {
                borderRadius: theme.shape.border.radius.md,
                border: `1px solid ${theme.palette.container.border.default}`,
                background: theme.palette.container.fill.card1,
                position: 'relative',
            },
        }), "aria-labelledby": "my-quests-completed-quest-detail-modal-title", "aria-describedby": quest.description ? 'my-quests-completed-quest-detail-modal-description' : undefined }, rest, { children: [_jsx(CloseIconButton, { "aria-label": t('common.action.close'), onClick: rest.onClose, sx: {
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                } }), _jsx(CompletedQuestDetail, { quest: quest })] })));
};
