import { Menu, MenuItem, styled } from '@mui/material';
export const BulkActionsMenu = styled(Menu)(({ theme }) => ({
    '& .MuiPaper-root': {
        width: theme.typography.pxToRem(221),
        borderRadius: theme.shape.borderRadius * 2,
        border: `1px solid ${theme.palette.clickable.default}`,
        background: theme.palette.container.fill.card1,
        boxShadow: theme.shadows[1],
        marginTop: theme.spacing(1),
    },
}));
export const BulkActionsMenuItem = styled(MenuItem, {
    shouldForwardProp: (prop) => prop !== 'hasIcon',
})(({ theme, hasIcon }) => (Object.assign({ minHeight: theme.typography.pxToRem(32), padding: theme.spacing(1, 3), gap: theme.spacing(2) }, (!hasIcon && {
    '&::before': {
        content: '""',
        width: theme.typography.pxToRem(16),
        display: 'block',
    },
}))));
