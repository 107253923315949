var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useContext } from 'react';
import { SingleActionContext } from '../../common/context';
import { useUserMutations } from './useUserMutations';
export const useUserSingleAction = () => {
    const ctx = useContext(SingleActionContext);
    const { mutate } = useUserMutations();
    if (!ctx) {
        throw new Error('useUserSingleAction must be used within a SingleActionProvider');
    }
    const setUserId = (userId) => ctx.dispatch({ type: 'SET_USER', payload: userId });
    const setActionType = (actionType) => ctx.dispatch({ type: 'SET_USER_ACTION', payload: actionType });
    const clearAction = () => ctx.dispatch({ type: 'RESET' });
    const confirmAction = (data) => __awaiter(void 0, void 0, void 0, function* () {
        if (ctx.state.userId && ctx.state.userActionType) {
            switch (ctx.state.userActionType) {
                case 'USER_ENABLE':
                    return mutate({ type: 'enable', payload: [ctx.state.userId] });
                case 'USER_DISABLE':
                    return mutate({ type: 'disable', payload: [ctx.state.userId] });
                case 'USER_DELETE':
                    return mutate({ type: 'delete', payload: [ctx.state.userId] });
                case 'USER_RESET_PASSWORD':
                    return mutate({ type: 'resetPassword', payload: [ctx.state.userId] });
                case 'USER_RESEND':
                    return data ? mutate({ type: 'resendInvite', payload: data }) : undefined;
            }
        }
    });
    return {
        userId: ctx.state.userId,
        actionType: ctx.state.userActionType,
        setUserId,
        setActionType,
        clearAction,
        confirmAction,
    };
};
