var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useMemo, useState } from 'react';
import { useUserMutations } from './useUserMutations';
export var BulkActionType;
(function (BulkActionType) {
    BulkActionType["ENABLE_USERS"] = "enableUsers";
    BulkActionType["DISABLE_USERS"] = "disableUsers";
    BulkActionType["CHANGE_TEAMS"] = "changeTeams";
    BulkActionType["MANAGE_TAGS"] = "manageTags";
    BulkActionType["REINVITE_USERS"] = "reinviteUsers";
    BulkActionType["RESET_PASSWORDS"] = "resetPasswords";
    BulkActionType["CHANGE_ROLES"] = "changeRoles";
    BulkActionType["DELETE_USERS"] = "deleteUsers";
})(BulkActionType || (BulkActionType = {}));
export const useBulkActions = () => {
    const { mutate } = useUserMutations();
    const [modalState, setModalState] = useState({
        actionType: null,
        goneAsync: false,
        selectedCount: 0,
        updatedCount: undefined,
        currentUserSelected: undefined,
    });
    const [selectedUserIds, setSelectedUserIds] = useState([]);
    const actionHandlers = useMemo(() => ({
        [BulkActionType.ENABLE_USERS]: {
            handler: () => __awaiter(void 0, void 0, void 0, function* () { return mutate({ type: 'enable', payload: selectedUserIds }); }),
        },
        [BulkActionType.DISABLE_USERS]: {
            handler: () => __awaiter(void 0, void 0, void 0, function* () { return mutate({ type: 'disable', payload: selectedUserIds }); }),
        },
        [BulkActionType.CHANGE_TEAMS]: {
            handler: (props) => __awaiter(void 0, void 0, void 0, function* () { return mutate({ type: 'changeTeams', payload: { ids: selectedUserIds, _tid: props.teamId } }); }),
        },
        [BulkActionType.MANAGE_TAGS]: {
            handler: (props) => __awaiter(void 0, void 0, void 0, function* () {
                return mutate({
                    type: props.selectedAction === 'add' ? 'addTags' : 'removeTags',
                    payload: { ids: selectedUserIds, tags: props.tags },
                });
            }),
        },
        [BulkActionType.REINVITE_USERS]: {
            handler: () => __awaiter(void 0, void 0, void 0, function* () { return mutate({ type: 'reinvite', payload: selectedUserIds }); }),
        },
        [BulkActionType.RESET_PASSWORDS]: {
            handler: () => __awaiter(void 0, void 0, void 0, function* () { return mutate({ type: 'resetPassword', payload: selectedUserIds }); }),
        },
        [BulkActionType.CHANGE_ROLES]: {
            handler: (props) => __awaiter(void 0, void 0, void 0, function* () { return mutate({ type: 'changeRoles', payload: { ids: selectedUserIds, role: props.role } }); }),
        },
        [BulkActionType.DELETE_USERS]: {
            handler: () => __awaiter(void 0, void 0, void 0, function* () { return mutate({ type: 'delete', payload: selectedUserIds }); }),
        },
    }), [mutate, selectedUserIds]);
    const handleActionConfirm = useCallback((actionType, props) => __awaiter(void 0, void 0, void 0, function* () {
        const handler = actionHandlers[actionType];
        if (!handler)
            return;
        try {
            const result = yield handler.handler(props);
            setModalState((prev) => (Object.assign(Object.assign({}, prev), result)));
        }
        catch (error) {
            setModalState((prev) => (Object.assign(Object.assign({}, prev), { updatedCount: 0, currentUserSelected: false })));
        }
    }), [actionHandlers]);
    const handleBulkAction = useCallback((actionType) => {
        setModalState({
            actionType,
            selectedCount: selectedUserIds.length,
            updatedCount: undefined,
            goneAsync: false,
        });
    }, [selectedUserIds.length]);
    const closeModal = useCallback(() => {
        setModalState((prev) => (Object.assign(Object.assign({}, prev), { actionType: null })));
    }, []);
    return {
        handleBulkAction,
        selectedUserIds,
        setSelectedUserIds,
        modalState,
        handleActionConfirm,
        closeModal,
    };
};
