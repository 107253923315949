var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTeamsQueryOptions } from './useTeamsQueryOptions';
import { useTeamApis } from './useTeamApis';
import { useAdminError } from '../../common/utils/AdminErrorContext';
export const useTeamMutation = () => {
    var _a, _b, _c, _d;
    const { onMutate, onSettled, onError } = useTeamsQueryOptions();
    const apis = useTeamApis();
    const { showError } = useAdminError();
    const onDelete = (ids) => onMutate((previous) => previous.filter((t) => !ids.includes(t._id)));
    const onDisable = (data) => onMutate((prev) => prev.map((t) => {
        if (t._id === data._id) {
            t.status = 'disabled';
        }
        return t;
    }));
    const onEnable = (data) => onMutate((prev) => prev.map((t) => {
        if (t._id === data._id) {
            t.status = 'enabled';
        }
        return t;
    }));
    const onEdit = (team) => {
        return onMutate((previous) => {
            return previous.map((t) => {
                var _a;
                if (t._id === team._id) {
                    return {
                        _id: team._id,
                        name: team.name,
                        status: t.status,
                        tags: (_a = t.tags) !== null && _a !== void 0 ? _a : [],
                        createdAt: t.createdAt,
                        teamSize: t.teamSize,
                    };
                }
                return t;
            });
        });
    };
    const client = useQueryClient();
    const handleError = (error, operation) => {
        const errorMessage = error instanceof Error ? error.message : 'An unexpected error occurred';
        showError(`${operation} operation failed: ${errorMessage}`);
        return onError(error, null, undefined);
    };
    const editTeam = useMutation({
        mutationFn: apis.editTeam,
        onMutate: onEdit,
        onSettled: (data) => {
            onSettled();
            client.invalidateQueries({ queryKey: ['get-team', data === null || data === void 0 ? void 0 : data._id] });
        },
        onError: (error) => handleError(error, 'Edit team'),
    });
    const addTeam = useMutation({
        mutationFn: apis.addTeam,
        onSettled,
        onError: (error) => handleError(error, 'Add team'),
    });
    const deleteTeam = useMutation({ mutationFn: apis.deleteTeam, onMutate: onDelete, onSettled, onError });
    const enableTeam = useMutation({ mutationFn: apis.enableTeam, onMutate: onEnable, onSettled, onError });
    const disableTeam = useMutation({ mutationFn: apis.disableTeam, onMutate: onDisable, onSettled, onError });
    const mutate = ({ type, payload }) => __awaiter(void 0, void 0, void 0, function* () {
        switch (type) {
            case 'deleteTeam':
                return deleteTeam.mutateAsync(payload);
            case 'disableTeam':
                return disableTeam.mutateAsync(payload);
            case 'enableTeam':
                return enableTeam.mutateAsync(payload);
            case 'addTeam':
                return addTeam.mutateAsync(payload);
            case 'editTeam':
                return editTeam.mutateAsync(payload);
            default:
                throw new Error('Invalid mutation type');
        }
    });
    return {
        mutate,
        isMutating: deleteTeam.isPending || enableTeam.isPending || disableTeam.isPending || addTeam.isPending || editTeam.isPending,
        isMutationError: deleteTeam.isError || enableTeam.isError || disableTeam.isError || addTeam.isError || editTeam.isError,
        mutationError: (_d = (_c = (_b = (_a = deleteTeam.error) !== null && _a !== void 0 ? _a : enableTeam.error) !== null && _b !== void 0 ? _b : disableTeam.error) !== null && _c !== void 0 ? _c : addTeam.error) !== null && _d !== void 0 ? _d : editTeam.error,
    };
};
