import { createElement as _createElement } from "react";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { MyQuestsLanguageModal } from '../myQuests/components/MyQuestsLanguageModal';
import { myQuestsCourseActivityAnalytics, myQuestsHomepageAnalytics } from '../myQuests/myQuests.analytics';
import { assertNever } from '@securecodewarrior/design-system-react/lib/utils/functionalHelpers';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import { TrainingInformation } from '../myQuests/components/TrainingInformation';
import { TopicCard, TopicCardSkeleton } from '../myQuests/components/TopicCard';
import { Box, Stack, Typography } from '@mui/material';
import { MyQuestsContainer } from '../myQuests/components/MyQuestsContainer';
import * as Helpers from '../myQuests/MyQuestsPageView.helpers';
import { WithFooter } from '../../footer/WithFooter';
import { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { usePlatformNavigate } from '../../navigation';
import { usePlatformContext } from '../../platformContext';
import { useLocation } from 'react-router-dom';
import { lmsQuestActivityPath } from './LmsQuestActivityFromRouteParams';
import { MyQuestsFullWidthBackgroundContainer } from '../myQuests/components/MyQuestFullwidthBackgroundContainer';
import { Skeleton } from '@securecodewarrior/design-system-react';
import { Divider } from '../../Divider';
export const LmsQuestPageView = (props) => {
    var _a;
    const navigate = usePlatformNavigate();
    const { t } = useTranslation();
    const { logAnalyticsEvent, sessionUser } = usePlatformContext();
    const activityAnalytics = myQuestsCourseActivityAnalytics(logAnalyticsEvent, sessionUser, 'lmsQuest');
    const analytics = myQuestsHomepageAnalytics(logAnalyticsEvent, 'lmsQuest');
    const highlightedActivity = (_a = useLocation().state) === null || _a === void 0 ? void 0 : _a.highlightedActivity;
    const activityToScrollInView = useRef(highlightedActivity); // this could be in TopicCard, except it can't because that would cause misbehaviors with the TopicCard usage in MyQuestsPageView
    const [languageModalOpen, setLanguageModalOpen] = useState(false);
    const selectLanguages = () => {
        if (props.languageSelectionBackendData === undefined) {
            props.loadAvailableLanguages().then(() => setLanguageModalOpen(true));
        }
        else if (props.languageSelectionBackendData !== 'loading') {
            setLanguageModalOpen(true);
        }
        if (props.kind === 'no content available') {
            analytics.onSelectLanguagesClicked();
        }
        else {
            analytics.onEditLanguagesClicked();
        }
    };
    const onActivityClicked = (questId) => (activity, courseId, category, activityNr) => {
        var _a;
        navigate.to({
            pathname: lmsQuestActivityPath(questId, courseId, category, activity.kind, activityNr),
            search: '',
            hash: '',
        });
        activityAnalytics.onClickActivity(activity, category);
        if ((_a = window.top) === null || _a === void 0 ? void 0 : _a.opener) {
            window.top.opener.postMessage({ id: questId, state: 'ENROLL' }, '*');
        }
    };
    return (_jsxs(WithFooter, { wrapperComponent: "main", children: [_jsxs(MyQuestsFullWidthBackgroundContainer, { children: [_jsxs(MyQuestsContainer, { display: "flex", flexDirection: "row", alignItems: "center", p: 10, children: [_jsx(Box, { sx: (theme) => ({
                                    borderRadius: theme.shape.border.radius.md,
                                    bgcolor: theme.palette.container.fill.card2,
                                    p: 7,
                                    border: `1px solid ${theme.palette.container.border.default}`,
                                }), children: props.kind !== 'skeleton' ? (_jsx(Box, { component: "img", src: props.quest.imageUrl, alt: t('pages.learn.components.questBadge'), sx: (theme) => ({
                                        width: theme.typography.pxToRem(140),
                                        height: theme.typography.pxToRem(140),
                                        border: `1px solid ${theme.palette.container.border.default}`,
                                        borderRadius: theme.shape.border.radius.md,
                                        display: 'block',
                                    }) })) : (_jsx(Skeleton, { variant: "rounded", sx: (theme) => ({ width: theme.typography.pxToRem(140), height: theme.typography.pxToRem(140) }) })) }), _jsx(Stack, { p: 7, gap: 5, children: props.kind !== 'skeleton' ? (_jsxs(_Fragment, { children: [_jsx(Typography, { variant: "h3", component: "h1", children: props.quest.name }), _jsx(Button, { variant: "text", disabled: props.languageSelectionBackendData === 'loading', onClick: selectLanguages, children: _jsx(Trans, { i18nKey: "pages.myQuests.languages.edit" }) })] })) : (_jsxs(_Fragment, { children: [_jsx(Skeleton, { variant: "rounded", sx: (theme) => ({
                                                width: theme.typography.pxToRem(250),
                                                height: theme.typography.pxToRem(32),
                                                transform: 'unset',
                                            }) }), _jsx(Skeleton, { variant: "rounded", sx: (theme) => ({
                                                width: theme.typography.pxToRem(150),
                                                height: theme.typography.pxToRem(36),
                                                transform: 'unset',
                                            }) })] })) })] }), props.kind === 'no content available' && _jsx(Divider, { "aria-hidden": true })] }), props.kind === 'no content available' ? (_jsxs(MyQuestsContainer, { py: 25, textAlign: "center", children: [_jsx(Typography, { variant: "h3", component: "h2", children: _jsx(Trans, { i18nKey: "pages.lms.noContentAvailable.title" }) }), _jsx(Typography, { variant: "subtitle1", component: "p", sx: (theme) => ({
                            color: theme.palette.text.soft,
                            maxWidth: theme.typography.pxToRem(366),
                            mx: 'auto',
                            mt: 2,
                            mb: 5,
                        }), children: _jsx(Trans, { i18nKey: "pages.lms.noContentAvailable.description" }) }), _jsx(Button, { variant: "contained", disabled: props.languageSelectionBackendData === 'loading', onClick: selectLanguages, children: _jsx(Trans, { i18nKey: "pages.myQuests.languages.select" }) })] })) : props.kind === 'skeleton' ? (_jsxs(_Fragment, { children: [_jsx(TrainingInformation, { skeleton: true }), _jsxs(MyQuestsContainer, { children: [_jsx(TopicCardSkeleton, {}), _jsx(TopicCardSkeleton, { opacity: ".66" }), _jsx(TopicCardSkeleton, { opacity: ".33" })] })] })) : props.kind === 'ok' ? (_jsxs(_Fragment, { children: [_jsx(TrainingInformation, Object.assign({}, Helpers.trainingInformationPropsFor(props.topics, null), { variant: "nonSpecific" })), _jsx(MyQuestsContainer, { children: props.topics.map((task) => (_createElement(TopicCard, Object.assign({}, task, { key: task.kind === 'practical' ? task.category : task.stableTopicId, onActivityClicked: onActivityClicked(props.quest.id), highlightedActivity: highlightedActivity, activityToScrollInView: activityToScrollInView, analyticsScope: "lmsQuest", showRelatedQuests: false })))) })] })) : (assertNever(props, 'invalid object')), _jsx(MyQuestsLanguageModal, { open: languageModalOpen, backendData: props.languageSelectionBackendData, saveSelectedLanguages: props.saveSelectedLanguages, close: () => setLanguageModalOpen(false) })] }));
};
