export const deduplicateAndSortTags = (tags) => {
    // Use a Map to track the first occurrence of each tag, case-insensitively
    const uniqueTagsMap = new Map();
    tags.forEach((tag) => {
        const lowerCaseTag = tag.toLowerCase();
        if (!uniqueTagsMap.has(lowerCaseTag)) {
            uniqueTagsMap.set(lowerCaseTag, tag); // Store the first occurrence
        }
    });
    // Extract deduplicated tags (preserve first-case appearance)
    const deduplicatedTags = Array.from(uniqueTagsMap.values());
    // Sort with numeric-aware comparison
    return deduplicatedTags.sort((a, b) => {
        // Attempt to parse both as numbers
        const aNumber = parseFloat(a);
        const bNumber = parseFloat(b);
        // If both are valid numbers, sort numerically
        if (!isNaN(aNumber) && !isNaN(bNumber)) {
            return aNumber - bNumber;
        }
        // Otherwise, sort as case-insensitive strings
        return a.toLowerCase().localeCompare(b.toLowerCase());
    });
};
