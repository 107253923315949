import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Typography, Stack } from '@mui/material';
import { WatchLater } from '@mui/icons-material';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import { UserManagementModal } from '../UserManagementModal';
export const BulkActionsAsyncDialog = (props) => {
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.userManagement.common' });
    return (_jsx(UserManagementModal, { title: t('bulkChangeTitle'), icon: _jsx(WatchLater, { sx: { fontSize: '56px', color: (theme) => theme.palette.text.soft } }), footer: _jsx(Stack, { direction: "row", justifyContent: "flex-end", children: _jsx(Button, { variant: "contained", onClick: props.onClose, children: t('okay') }) }), children: _jsxs(Stack, { alignItems: "center", gap: 2, children: [_jsx(Typography, { align: 'center', children: t('bulkChangeDescription') }), _jsx(Typography, { align: 'center', color: 'text.soft', children: t('bulkChangeLicenseDescription') })] }) }));
};
