import { useMemo } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useUserApis } from './useUserApis';
import { useUsers } from './useUsers';
import { useUsersQueryOptions } from './useUsersQueryOptions';
import { mergeUsers } from '../utils/mergeUsers';
const createFallbackUser = (user) => {
    if (!user)
        return;
    return Object.assign(Object.assign({}, user), { properties: {
            _cid: user.properties._cid,
            _tid: user.properties._tid,
            tags: user.properties.tags,
            profile: Object.assign(Object.assign({}, user.properties.profile), { timezone: '', isMetricsPublic: true }),
        } });
};
export const useUserDetails = (id) => {
    const { getUser, updateUser } = useUserApis();
    const { users } = useUsers();
    const { onMutate, onSettled, onError } = useUsersQueryOptions();
    const client = useQueryClient();
    const { data, isLoading, isError, error } = useQuery({
        queryKey: ['user-details', id],
        queryFn: () => getUser(id),
        refetchOnMount: 'always',
    });
    const { mutateAsync, isPending } = useMutation({
        mutationFn: updateUser,
        onMutate: (user) => onMutate((prev) => prev.map((u) => (u._id === user._id ? mergeUsers(u, user) : u))),
        onSettled: () => {
            onSettled();
            client.invalidateQueries({ queryKey: ['user-details', id] });
        },
        onError,
    });
    const found = useMemo(() => users.find((user) => user._id === id), [users, id]);
    return {
        user: data !== null && data !== void 0 ? data : createFallbackUser(found),
        updateUser: mutateAsync,
        isLoading,
        isUpdating: isPending,
        isError,
        error,
    };
};
