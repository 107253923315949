import { tagList } from './tagList';
import { teamList } from './teamList';
import { NO_ACTIVITY_USER_COUNT, USER_COUNT, userList } from './users';
const generateMonthIntervals = () => {
    const now = new Date();
    const currentMonth = now.getMonth();
    const currentYear = now.getFullYear();
    const intervals = [];
    for (let i = 3; i >= 0; i--) {
        const date = new Date(currentYear, currentMonth - i, 1);
        const startDate = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().split('T')[0];
        const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().split('T')[0];
        intervals.push({ __typename: 'OnboardingAndEngagementInterval', endDate, startDate });
    }
    return intervals;
};
const intervals = generateMonthIntervals();
const learnerEdges = [];
for (let i = 0; i < USER_COUNT; i++) {
    const assessmentsTimeSpent = Math.floor(Math.random() * 60);
    const coursesTimeSpent = Math.floor(Math.random() * 60);
    const exploreOrTrainingTimeSpent = Math.floor(Math.random() * 60);
    const questsTimeSpent = Math.floor(Math.random() * 60);
    const tournamentsTimeSpent = Math.floor(Math.random() * 60);
    learnerEdges.push({
        __typename: 'OnboardingAndEngagementLearnerEdge',
        cursor: '',
        node: Object.assign(Object.assign({ __typename: 'OnboardingAndEngagementLearner' }, userList[i]), { assessmentsTimeSpent,
            coursesTimeSpent,
            exploreOrTrainingTimeSpent,
            questsTimeSpent, skillLevel: userList[i].trustScore, status: userList[i].userStatus, totalTimeSpent: assessmentsTimeSpent + coursesTimeSpent + exploreOrTrainingTimeSpent + questsTimeSpent, tournamentsTimeSpent }),
    });
}
export const onboardingAndEngagement = {
    __typename: 'OnboardingAndEngagementResponse',
    report: {
        __typename: 'OnboardingAndEngagementReportResponse',
        activeLearnersUsage: {
            __typename: 'OnboardingAndEngagementActiveLearnersUsage',
            count: [287, 311, 144, 299],
            intervals: intervals,
        },
        activityBreakdown: {
            __typename: 'OnboardingAndEngagementActivityBreakdown',
            intervals: intervals,
            series: [
                {
                    __typename: 'OnboardingAndEngagementActivityBreakdownSeries',
                    activity: 'Quests',
                    minutes: [0, 1540, 17820, 15460],
                },
                {
                    __typename: 'OnboardingAndEngagementActivityBreakdownSeries',
                    activity: 'ExploreOrTraining',
                    minutes: [2011, 1499, 1735, 1399],
                },
                {
                    __typename: 'OnboardingAndEngagementActivityBreakdownSeries',
                    activity: 'Tournaments',
                    minutes: [6340, 0, 5942, 0],
                },
            ],
        },
        filter: {
            __typename: 'OnboardingAndEngagementReportFilter',
            endTime: undefined,
            startTime: undefined,
            tags: tagList,
            teamIds: teamList,
        },
        lastLearningActivity: {
            __typename: 'OnboardingAndEngagementLastLearningActivity',
            nineToTwelveMonthsCount: 21,
            noActivityCount: NO_ACTIVITY_USER_COUNT,
            overTwelveMonthsCount: 11,
            sixToNineMonthsCount: 45,
            threeToSixMonthsCount: 97,
            zeroToThreeMonthsCount: 182,
        },
        learners: {
            __typename: 'OnboardingAndEngagementLearnerConnection',
            edges: learnerEdges,
            pageInfo: {
                __typename: 'PageInfo',
                endCursor: '',
                hasNextPage: false,
                hasPreviousPage: false,
                startCursor: '',
            },
        },
        statistics: {
            __typename: 'OnboardingAndEngagementStatistics',
            activeCount: 356,
            enabledCount: 406,
            newCount: 64,
        },
    },
};
