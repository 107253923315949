import angular from 'angular';
import MODULE from './module';
import wrapperTemplate from './TrustAgent.html';
import { USER_ROLES as userRoles } from '../auth/constants';
import { loadRemoteEntry, loadRemoteModule } from '@angular-architects/module-federation';

const app = angular.module(MODULE);

const LOADING_STATE = {
  loading: 0,
  loaded: 1,
  failed: 2,
};

app.run([
  '$window',
  '$rootScope',
  function ($window, $rootScope) {
    let dependenciesLoaded = false;

    $rootScope.$watch('Session.user', async function (newValue) {
      if (!!newValue && !dependenciesLoaded) {
        const trustAgentUrl = $window.SCW_ENV.COMMIT_INSIGHTS_FRONTEND_URL;
        $rootScope.trustAgentEntryUrl = `${trustAgentUrl}/assets/remoteEntry.js`;
        try {
          await loadRemoteEntry({ type: 'module', remoteEntry: $rootScope.trustAgentEntryUrl });
        } catch (e) {
          console.log('failed to load remote module:', e);
        }

        dependenciesLoaded = true;
      }
    });
  },
]);

app.controller('TrustAgentWrapperController', [
  '$window',
  '$scope',
  '$rootScope',
  '$state',
  'AuthService',
  'LearningModulesApi',
  '$translate',
  function ($window, $scope, $rootScope, $state, AuthService, LearningModulesApi, $translate) {
    $rootScope.appLoaded = true;
    $rootScope.removePreloading = true;

    $scope.LOADING_STATE = LOADING_STATE;
    $scope.trustAgentModuleState = LOADING_STATE.loading;
    $scope.trustAgentAppEnabled = true;

    this.$onInit = async function () {
      const session = $rootScope.Session;

      await checkRootStateAndRedirect(session);
    };

    const checkRootStateAndRedirect = async (session) => {
      if (session?.getUser()) {
        $scope.trustAgentAppEnabled = $rootScope.isAuthorized([userRoles.companyAdmin]);
        $scope.sessionId = session.getSessionId();
        $scope.userId = session.getUser()._id;
        $scope.companyId = session.getUser().properties._cid;
        $scope.envName = $window.SCW_ENV.ENV_NAME; // local, uat, sit, prod-us
        $scope.portalBackendUrl = $window.SCW_ENV.ApiEndpoint;
        $scope.i18nLanguage = $translate.use();
        $scope.role = AuthService.mainRole(session.getUser());
        try {
          $scope.legacyReporting = await LearningModulesApi.isLearningModuleEnabled('legacyReporting');
        } catch (_) {
          $scope.legacyReporting = false;
        }
        loadTrustAgent();
      } else {
        $rootScope.requestedState = $state.current.name;
        $rootScope.requestedStateParams = JSON.parse(JSON.stringify($state.params));
        $rootScope.defaultRedirects();
      }
    };

    const loadTrustAgent = () => {
      loadRemoteModule({
        type: 'module',
        remoteEntry: $rootScope.trustAgentEntryUrl,
        remoteName: 'trustAgent',
        exposedModule: './TrustAgent',
      })
        .then(() => {
          console.debug('Loaded Trust Agent App Module Successfully!');
          $scope.trustAgentModuleState = $scope.trustAgentAppEnabled && LOADING_STATE.loaded;
        })
        .catch((err) => {
          console.error('Error loading trust-agent module', err);
          $scope.trustAgentModuleState = LOADING_STATE.failed;
          throw err;
        });
    };

    $scope.$on('$stateChangeStart', function (event, toState, toParams) {
      if (toState.name === 'trust-agent') {
        event.preventDefault();
        $state.go(
          'trust-agent',
          { path: toParams.path },
          {
            notify: false,
            reload: true,
            location: false,
            inherit: false,
          }
        );
      }
    });
  },
]);

/*
 * React element moved into directive to avoid React app picking up DOM element before AngularJS expression evaluation
 * Previously resulted in React app receiving "{{envName}}" as the envName instead of e.g. "prod-us"
 */
app.directive('trustAgentWrapper', [
  function () {
    return {
      restrict: 'E',
      template:
        '<trust-agent data-session-id="{{sessionId}}" data-env-name="{{envName}}" data-portal-backend-url="{{portalBackendUrl}}"  data-role="{{role}}" data-i18n-language="{{i18nLanguage}}" data-legacy-reporting="{{legacyReporting}}" data-user-id={{userId}} data-company-id={{companyId}}></trust-agent>',
    };
  },
]);

app.config([
  '$stateProvider',
  '$urlRouterProvider',
  function ($stateProvider, $urlRouterProvider) {
    $stateProvider.state('trust-agent', {
      url: '/trust-agent/{path:any}',
      controller: 'TrustAgentWrapperController',
      templateUrl: wrapperTemplate,
      reloadOnSearch: false,
    });
    $urlRouterProvider.when('/commit-insights', 'trust-agent/');
    $urlRouterProvider.when('/commit-insights/{path:any}', 'trust-agent/');
  },
]);
