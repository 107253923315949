const columnVisibilityModel = {
    name: true,
    createdAt: true,
    teamSize: true,
    status: true,
};
export const createGridProps = (total = 0) => {
    return {
        columnVisibilityModel,
        initialState: {
            pagination: {
                paginationModel: { pageSize: 25, page: 0 },
                rowCount: total,
            },
            pinnedColumns: {
                right: ['actions'],
            },
        },
        disableColumnSelector: false,
        rowSelection: false,
        autoHeight: true,
    };
};
