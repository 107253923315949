import { jsx as _jsx } from "react/jsx-runtime";
import { Dialog } from '../../../../../common/Dialog';
import { SingleTeamEnableDialog } from '../SingleTeamEnableDialog';
import { DisableTeamDialog } from '../SingleTeamDisableDialog';
import { SingleTeamDeleteDialog } from '../SingleTeamDeleteDialog';
import { SingleTeamEditDialog } from '../SingleTeamEditDialog';
const modalComponents = {
    TEAM_ENABLE: SingleTeamEnableDialog,
    TEAM_DISABLE: DisableTeamDialog,
    TEAM_DELETE: SingleTeamDeleteDialog,
    TEAM_EDIT: SingleTeamEditDialog,
};
export const SingleActionModal = ({ onClose, onConfirm, teamId, action }) => {
    if (!action || !teamId)
        return null;
    const ContentComponent = modalComponents[action];
    return (_jsx(Dialog, { open: true, onClose: onClose, children: _jsx(ContentComponent, { teamId: teamId, onClose: onClose, onConfirm: onConfirm }) }));
};
