import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, Typography, Grid } from '@mui/material';
import { Badge, Tooltip } from '@securecodewarrior/design-system-react/lib/wanda';
import { Chip } from '../../../../../common/components/atoms/Chip/Chip';
import { useRef, useState, useEffect } from 'react';
export const TagCell = ({ tags }) => {
    const maxDisplayTags = 1;
    const hasBadge = tags.length > maxDisplayTags;
    const textRef = useRef(null);
    const [isOverflowing, setIsOverflowing] = useState(false);
    useEffect(() => {
        if (textRef.current) {
            setIsOverflowing(textRef.current.scrollWidth > textRef.current.clientWidth);
        }
    }, [tags]);
    if (tags.length === 0) {
        return null;
    }
    const shouldShowTooltip = hasBadge || isOverflowing;
    const content = (_jsx(Stack, { direction: "row", spacing: 1, sx: {
            flexWrap: 'wrap',
            maxWidth: '100%',
            overflow: 'hidden',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'flex-start',
        }, children: _jsxs(Stack, { direction: "row", spacing: 1, onClick: (e) => e.stopPropagation(), children: [_jsx(Chip, { children: _jsx(Typography, { ref: textRef, variant: "caption", sx: {
                            maxWidth: hasBadge ? '33px' : '100%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            display: 'inline-block',
                            width: '100%',
                        }, children: tags[0] }) }), hasBadge && _jsxs(Badge, { children: ["+", tags.length - maxDisplayTags] })] }) }));
    if (!shouldShowTooltip) {
        return content;
    }
    return (_jsx(Tooltip, { title: _jsx(Grid, { container: true, spacing: 1, sx: { p: 1, maxWidth: '300px' }, children: tags.map((tag) => (_jsx(Grid, { item: true, children: _jsx(Chip, { children: _jsx(Typography, { variant: "caption", children: tag }) }) }, tag))) }), arrow: true, placement: "top-start", children: content }));
};
