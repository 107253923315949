import angular from 'angular';
import Chart from 'chart.js';
import moment from 'moment-timezone';
import MODULE from './module';
import viewOptionsDialogTemplate from './assessments.view.options-dialog.html';
import inviteOptionsDialogTemplate from './assessments.invite.options-dialog.html';
import csvExportTemplate from '../assessments.csv.export.info.html';
import { getLatestAttempt, mapDesignSystemUser } from '../utils';
import { AssessmentNavigationService } from '../assessmentNavigationService';

const app = angular.module(MODULE);

/****** view ******/

app.controller('AssessmentsViewController', [
  '$scope',
  '$rootScope',
  '$state',
  '$stateParams',
  '$q',
  '$timeout',
  '$translate',
  '$swal',
  '$window',
  'LanguageUtils',
  'AuthService',
  'USER_ROLES',
  'Session',
  'ErrorHandler',
  'AssessmentsApiService',
  'AnalyticsService',
  'AnalyticsEvents',
  'LmsIntegrationService',
  '$location',
  function (
    $scope,
    $rootScope,
    $state,
    $stateParams,
    $q,
    $timeout,
    $translate,
    $swal,
    $window,
    LanguageUtils,
    AuthService,
    USER_ROLES,
    Session,
    ErrorHandler,
    AssessmentsApiService,
    AnalyticsService,
    AnalyticsEvents,
    LmsIntegrationService,
    $location
  ) {
    const isIE = /Trident|MSIE/.test(window.navigator.userAgent);

    $scope.assessmentActions = { open: false };
    $scope.attemptActions = { open: false };
    $scope.inviteParticipantsSx = { borderTopRightRadius: 0, borderBottomRightRadius: 0 };
    $scope.designUplift = undefined;
    $scope.assessmentNavigationService = new AssessmentNavigationService();
    $scope.assessmentNavigationService.setFrom($location.search());
    $scope.navigationDetails = $scope.assessmentNavigationService.getReturnDetails();

    $scope.getReturnState = () => {
      if ($scope.navigationDetails.param) {
        return `${$scope.navigationDetails.state}({ programId: '${$scope.navigationDetails.param}' })`;
      }

      return $scope.navigationDetails.state;
    };

    $scope.designUplift = $scope.user.role === USER_ROLES.player || !!$stateParams.ownResult;

    const toggleAssessmentActions = () => ($scope.assessmentActions = { status: !$scope.assessmentActions.open });
    const toggleAttemptActions = () => ($scope.attemptActions = { open: !$scope.attemptActions.open });

    $scope.levelGroupingFeatureEnabled = $rootScope.levelGroupingFeatureEnabled;

    $scope.TAB_NAMES = {
      PARTICIPANT: 0,
      PENDING: 1,
      COMPLETED: 2,
    };

    $scope.startOrResumeButton = function (options) {
      $state.go('assessments.view.attempt', options, { reload: 1 });
    };

    $scope.cancelButton = function () {
      window.history.back();
    };

    $scope.publicAssessments = [];

    $scope.tabsList = {
      activeIndex: AuthService.isAuthorized([USER_ROLES.companyAdmin, USER_ROLES.manager]) ? 0 : 1,
    };

    $scope.isArray = angular.isArray;

    $scope.now = function () {
      return Date.now();
    };

    $scope.getAttemptsLeft = function () {
      if ($scope.assessment.retriesAllowed) {
        if ($scope.assessment.maxRetries && $scope.assessment.attempts.completed) {
          return $scope.assessment.maxRetries + 1 - $scope.assessment.attempts.completed.length;
        }
        return $scope.assessment.maxRetries + 1;
      } else {
        if ($scope.assessment.attempts.completed && $scope.assessment.attempts.completed.length > 0) {
          return 0;
        }
        return 1;
      }
    };

    $scope.downloadLmsPackage = (version) => {
      AssessmentsApiService.getScormPackageLink($scope.assessment._id, version)
        .then((response) => {
          AssessmentsApiService.performDownload(response);
        })
        .catch((response) => {
          $translate(['ERROR_RETRIEVING_LMS_SCORM_PACKAGE']).then(function (translations) {
            ErrorHandler.addHttpError(translations.ERROR_RETRIEVING_LMS_SCORM_PACKAGE, response);
          });
        })
        .finally(() => {
          AssessmentsApiService.removeLoading();
          toggleAssessmentActions();
        });
    };

    function downloadPDF(content) {
      $translate(['PDF_CERTIFICATE_READY', 'PDF_CERTIFICATE_READY_DESC_WITH_SWAL_CLOSE_BUTTON_SAVE_TARGET_AS'], {
        content: content,
      }).then(function (translations) {
        if (window.navigator.msSaveBlob && isIE) {
          // typical IE - freaks out and redirects instantly to about:blank if we try to do this in same tab, cancelling the download
          $swal({
            title: translations.PDF_CERTIFICATE_READY,
            text: translations.PDF_CERTIFICATE_READY_DESC_WITH_SWAL_CLOSE_BUTTON_SAVE_TARGET_AS,
            type: 'success',
            buttons: {
              confirm: {
                className: 'success',
              },
            },
            html: true,
            showCancelButton: false,
            showConfirmButton: false,
            keyResolution: true,
          });
        } else {
          document.location.href = content;
          swal.close();
        }
      });
    }

    $scope.downloadSampleCertificate = function (_assessment, _template) {
      $translate([
        'ERROR_RETRIEVING_CERTIFICATE',
        'GENERATING_CERTIFICATE',
        'PLEASE_GIVE_SOME_TIME_TO_GENERATE_CERTIFICATE',
      ]).then(function (translations) {
        AssessmentsApiService.addLoading();
        AssessmentsApiService.downloadSampleCertificate(_assessment, _template)
          .then(function (R) {
            downloadPDF(R.body.url);
          })
          .catch(function (response) {
            ErrorHandler.addHttpError(translations.ERROR_RETRIEVING_CERTIFICATE, response);
          })
          .finally(function () {
            AssessmentsApiService.removeLoading();
          });

        $swal({
          title: translations.GENERATING_CERTIFICATE,
          text: translations.PLEASE_GIVE_SOME_TIME_TO_GENERATE_CERTIFICATE,
          type: 'info',
          showCancelButton: false,
          showConfirmButton: false,
          keyResolution: true,
        });
      });
    };

    $scope.displayTime = $rootScope.utility.humanizeDuration;

    $scope.categoryTypes = {
      web: 'Web Application',
      mobile: 'Mobile Application',
    };

    $scope.isShowingAttemptViewInfo = false;

    const tz = moment.tz.guess();
    $scope.downloadSummaryCsvReport = function () {
      AssessmentsApiService.addLoading(); // Adding the default behaviour as is the case with the "getAllSummaryCsvReport" as they tend to do the same thing
      AssessmentsApiService.getSummaryAssessmentReport($scope.assessment._id, tz)
        .then(function (data) {
          if (data.url) {
            //the url would be returned if the csv has not been chunked
            $translate(
              [
                'CSV_REPORT_READY',
                'YOUR_REQUESTED_CSV_EXPORT_IS_NOW_READY_FOR_YOU_TO_DOWNLOAD_WITH_SWAL_CLOSE_BUTTON_SAVE_TARGET_AS',
              ],
              { dataUrl: data.url }
            ).then(function (translations) {
              if (window.navigator.msSaveBlob && isIE) {
                // typical IE - freaks out and redirects instantly to about:blank if we try to do this in same tab, cancelling the download
                $swal({
                  title: translations.CSV_REPORT_READY,
                  text: translations.YOUR_REQUESTED_CSV_EXPORT_IS_NOW_READY_FOR_YOU_TO_DOWNLOAD_WITH_SWAL_CLOSE_BUTTON_SAVE_TARGET_AS,
                  type: 'success',
                  html: true,
                  showCancelButton: false,
                  showConfirmButton: false,
                  keyResolution: true,
                });
              } else {
                document.location.href = data.url;
                swal.close();
              }
            });
          } else {
            // if csv is currently being generated
            if (data.isBeingGenerated) {
              $translate([
                'CANNOT_GENERATE_CSV_REPORT',
                'YOU_CURRENTLY_HAVE_A_SUMMARY_REPORT_CSV_BEING_GENERATED',
                'OK',
              ]).then(function (translations) {
                $swal({
                  title: translations.CANNOT_GENERATE_CSV_REPORT,
                  text: translations.YOU_CURRENTLY_HAVE_A_SUMMARY_REPORT_CSV_BEING_GENERATED,
                  type: 'info',
                  html: true,
                  showCancelButton: false,
                  showConfirmButton: true,
                  keyResolution: true,
                  confirmButtonText: translations.OK,
                });
              });
            } else {
              $translate(['SUCCESSFULLY_REQUESTED_CSV_FILE', 'YOUR_CSV_FILE_IS_CURRENTLY_BEING_GENERATED', 'OK']).then(
                function (translations) {
                  $swal({
                    title: translations.SUCCESSFULLY_REQUESTED_CSV_FILE,
                    text: translations.YOUR_CSV_FILE_IS_CURRENTLY_BEING_GENERATED,
                    type: 'success',
                    html: true,
                    showCancelButton: false,
                    showConfirmButton: true,
                    confirmButtonText: translations.OK,
                    keyResolution: true,
                  });
                }
              );
            }
          }
        })
        .catch(function (response) {
          $translate(['ERROR_RETRIEVING_SUMMARY_REPORT_CSV']).then(function (translations) {
            ErrorHandler.addHttpError(translations.ERROR_RETRIEVING_SUMMARY_REPORT_CSV, response);
          });
        })
        .finally(function () {
          AssessmentsApiService.removeLoading();
          toggleAttemptActions();
        });

      $translate(['GENERATING_CSV_REPORT', 'PLEASE_GIVE_SOME_TIME_TO_GENERATE_REQUESTED_CSV_EXPORT']).then(
        function (translations) {
          $swal({
            title: translations.GENERATING_CSV_REPORT,
            text: translations.PLEASE_GIVE_SOME_TIME_TO_GENERATE_REQUESTED_CSV_EXPORT,
            type: 'info',
            showCancelButton: false,
            showConfirmButton: false,
            keyResolution: true,
          });
        }
      );
    };

    $scope.showCsvDownloadModal = () => {
      const scope = $scope.$new();
      scope.options = $scope.getCsvModalParams();
      $swal({
        title: $translate.instant('ASSESSMENT_EXPORTS'),
        templateUrl: csvExportTemplate,
        showConfirmButton: false,
        showCancelButton: false,
        allowOutsideClick: true,
        keyResolution: true,
        scope: scope,
      });
    };

    $scope.getCsvModalParams = () => {
      return [
        getSingleAssessmentResultsCsvParams(),
        getSingleAssessmentSummaryCsvParams(),
        getSingleAssessmentDetailedCsvParams(),
      ].filter(angular.isDefined);
    };

    const getSingleAssessmentResultsCsvParams = () => {
      return {
        name: 'ASSESSMENT_RESULTS_CSV',
        description: 'ASSESSMENT_SINGLE_RESULTS_CSV_DESCRIPTION',
        resolution: $scope.downloadSingleResultsCsvReportV3,
      };
    };

    const getSingleAssessmentDetailedCsvParams = () => {
      return {
        name: 'ASSESSMENT_DETAILED_CSV',
        description: 'ASSESSMENT_SINGLE_DETAILED_CSV_DESCRIPTION',
        resolution: $scope.downloadDetailedCsvReport,
      };
    };

    const getSingleAssessmentSummaryCsvParams = () => {
      return {
        name: 'ASSESSMENT_PROGRESS_CSV',
        description: 'ASSESSMENT_SINGLE_PROGRESS_CSV_DESCRIPTION',
        resolution: $scope.downloadSingleSummaryCsvReportV3,
      };
    };

    $scope.downloadSingleSummaryCsvReportV3 = function () {
      AssessmentsApiService.addLoading();
      AssessmentsApiService.getSingleSummaryCsvReportV3($scope.assessment._assessment, tz)
        .then(function (response) {
          AssessmentsApiService.performDownload(response);
          swal.close();
        })
        .catch(function (response) {
          $translate(['ERROR_RETRIEVING_SUMMARY_REPORT_CSV']).then(function (translations) {
            ErrorHandler.addHttpError(translations.ERROR_RETRIEVING_SUMMARY_REPORT_CSV, response);
          });
        })
        .finally(function () {
          AssessmentsApiService.removeLoading();
          toggleAttemptActions();
        });

      $translate(['GENERATING_CSV_REPORT', 'PLEASE_GIVE_SOME_TIME_TO_GENERATE_REQUESTED_CSV_EXPORT']).then(
        function (translations) {
          swal({
            title: translations.GENERATING_CSV_REPORT,
            text: translations.PLEASE_GIVE_SOME_TIME_TO_GENERATE_REQUESTED_CSV_EXPORT,
            type: 'info',
            showCancelButton: false,
            showConfirmButton: false,
          });
        }
      );
    };

    $scope.downloadSingleResultsCsvReportV3 = function () {
      AssessmentsApiService.addLoading();
      AssessmentsApiService.getSingleResultsCsvReportV3($scope.assessment._assessment, tz)
        .then(function (response) {
          AssessmentsApiService.performDownload(response);
          swal.close();
        })
        .catch(function (response) {
          $translate(['ERROR_RETRIEVING_RESULTS_REPORT_CSV']).then(function (translations) {
            ErrorHandler.addHttpError(translations.ERROR_RETRIEVING_RESULTS_REPORT_CSV, response);
          });
        })
        .finally(function () {
          AssessmentsApiService.removeLoading();
          toggleAttemptActions();
        });

      $translate(['GENERATING_CSV_REPORT', 'PLEASE_GIVE_SOME_TIME_TO_GENERATE_REQUESTED_CSV_EXPORT']).then(
        function (translations) {
          swal({
            title: translations.GENERATING_CSV_REPORT,
            text: translations.PLEASE_GIVE_SOME_TIME_TO_GENERATE_REQUESTED_CSV_EXPORT,
            type: 'info',
            showCancelButton: false,
            showConfirmButton: false,
          });
        }
      );
    };

    $scope.downloadDetailedCsvReport = function () {
      AssessmentsApiService.addLoading(); // Adding the default behaviour as is the case with the "getAllSummaryCSVReport" as they tend to do the same thing

      AssessmentsApiService.getDetailedAssessmentReport($scope.assessment._id, tz)
        .then((response) => {
          const resHeaders = response.headers();

          const filename = resHeaders['x-filename'];
          const contentType = resHeaders['content-type'];

          const blob = new Blob([response.data], { type: contentType });

          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            // IE 11
            window.navigator.msSaveOrOpenBlob(blob, filename);
          } else {
            const a = document.createElement('a');
            const url = window.URL.createObjectURL(blob);
            try {
              a.setAttribute('href', url);
              a.setAttribute('download', filename);

              const clickEvent = new MouseEvent('click', {
                view: window,
                bubbles: false,
                cancelable: false,
              });

              a.dispatchEvent(clickEvent);
            } finally {
              URL.revokeObjectURL(url);
              a.remove();
            }
          }
        })
        .catch(function (response) {
          $translate(['ERROR_RETRIEVING_DETAILED_REPORT_CSV']).then(function (translations) {
            ErrorHandler.addHttpError(translations.ERROR_RETRIEVING_DETAILED_REPORT_CSV, response);
          });
        })
        .finally(function () {
          AssessmentsApiService.removeLoading();
          toggleAttemptActions();
          swal.close();
        });

      $translate(['GENERATING_CSV_REPORT', 'PLEASE_GIVE_SOME_TIME_TO_GENERATE_REQUESTED_CSV_EXPORT']).then(
        function (translations) {
          $swal({
            title: translations.GENERATING_CSV_REPORT,
            text: translations.PLEASE_GIVE_SOME_TIME_TO_GENERATE_REQUESTED_CSV_EXPORT,
            type: 'info',
            showCancelButton: false,
            showConfirmButton: false,
            keyResolution: true,
          });
        }
      );
    };

    $scope.showSearch = false;
    $scope.toggleSearch = function () {
      $scope.showSearch = !$scope.showSearch;
    };

    $scope.assessmentInfo = {
      // Number of actual pending attempts. -1 to prevent UI glitches on load
      numberOfPendingAttempts: -1,

      // This flag indicates if we are resetting the Pending Attempt search
      resettingPendingAttemptSearch: false,

      // Total number of participant attempts for the current assessment
      // -1 to prevent rendering issues when first loading
      numberOfParticipantAttempts: -1,

      // This flag indicates if we are resetting the Completed Results search
      resettingCompletedAttemptSearch: false,

      // Total number of completed participant attempts for the current assessment
      numberOfCompletedAttempts: -1,

      // This flag indicates if we are resetting the All Attempt search
      resettingAllAttemptSearch: false,

      //Total number of pending, in-progress and completed attempts for the current assessment(regular + self assessment)
      numberOfAllAttempts: -1,
    };

    $scope.attemptSearch = { search: '' };
    $scope.pendingAttemptSearch = { search: '' };
    $scope.completedAttemptSearch = { search: '' };
    $scope.allAttemptSearch = { search: '' };

    $scope.sorting = {
      sortParticipantAttemptsBy: 'started',
      sortParticipantAttemptsByParam: '-started',
      sortParticipantAttemptsReverse: true,
      sortCompletedAttemptsBy: 'completed',
      sortCompletedAttemptsByParam: '-completed',
      sortCompletedAttemptsReverse: true,
      sortPendingAttemptsBy: 'deadline',
      sortPendingAttemptsByParam: '-deadline',
      sortPendingAttemptsReverse: true,
      sortAllAttemptsBy: 'started',
      sortAllAttemptsByParam: '-started',
      sortAllAttemptsReverse: true,
    };

    $scope.setSortParticipantAttemptsBy = function (s) {
      if ($scope.sorting.sortParticipantAttemptsBy == s) {
        $scope.sorting.sortParticipantAttemptsReverse = !$scope.sorting.sortParticipantAttemptsReverse;
        $scope.sorting.sortParticipantAttemptsByParam = ($scope.sorting.sortParticipantAttemptsReverse ? '-' : '') + s;
      } else {
        $scope.sorting.sortParticipantAttemptsBy = s;
        $scope.sorting.sortParticipantAttemptsByParam = s;
        $scope.sorting.sortParticipantAttemptsReverse = false;
      }
      getParticipantAttempts($scope.assessment);
    };

    $scope.setSortCompletedAttemptsBy = function (s) {
      if ($scope.sorting.sortCompletedAttemptsBy == s) {
        $scope.sorting.sortCompletedAttemptsReverse = !$scope.sorting.sortCompletedAttemptsReverse;
        $scope.sorting.sortCompletedAttemptsByParam = ($scope.sorting.sortCompletedAttemptsReverse ? '-' : '') + s;
      } else {
        $scope.sorting.sortCompletedAttemptsBy = s;
        $scope.sorting.sortCompletedAttemptsByParam = s;
        $scope.sorting.sortCompletedAttemptsReverse = false;
      }
      getCompletedAttempts($scope.assessment);
    };

    $scope.participantAttemptsPage = {
      filter: '',
      options: { page: 1, size: $rootScope.pagerPrefs.itemsPerPage },
    };
    $scope.pendingAttemptsPage = {
      filter: '',
      options: { page: 1, size: $rootScope.pagerPrefs.itemsPerPage },
    };
    $scope.completedAttemptsPage = {
      filter: '',
      options: { page: 1, size: $rootScope.pagerPrefs.itemsPerPage },
    };
    $scope.allAttemptsPage = {
      filter: '',
      options: { page: 1, size: $rootScope.pagerPrefs.itemsPerPage },
    };

    $scope.$watch('pagerPrefs.itemsPerPage', function (newVal, oldVal) {
      if (newVal != oldVal && $state.current.name == 'assessments.view') {
        $scope.participantAttemptsPage.options.page = 1;
        $scope.participantAttemptsPage.options.size = newVal;
        getParticipantAttempts($scope.assessment);

        $scope.pendingAttemptsPage.options.page = 1;
        $scope.pendingAttemptsPage.options.size = newVal;
        getPendingAttempts($scope.assessment);

        $scope.completedAttemptsPage.options.page = 1;
        $scope.completedAttemptsPage.options.size = newVal;
        getCompletedAttempts($scope.assessment);

        $scope.allAttemptsPage.options.page = 1;
        $scope.allAttemptsPage.options.size = newVal;
        getAllAttempts($scope.assessment);
      }
    });

    $scope.participantAttemptsPagerChanged = function (_event, page) {
      $scope.participantAttemptsPage.options.page = page;
      getParticipantAttempts($scope.assessment);
    };
    $scope.completedAttemptsPagerChanged = function () {
      getCompletedAttempts($scope.assessment);
    };
    $scope.allAttemptsPagerChanged = function (_event, page) {
      $scope.allAttemptsPage.options.page = page;
      getAllAttempts($scope.assessment);
    };

    $scope.resetParticipantAttemptSearch = function () {
      $scope.attemptSearch.search = '';
      $scope.attemptSearch.mode = '';
      $timeout(function () {
        $scope.newParticipantAttemptSearch();
      }, 100);
    };

    $scope.ui = $scope.ui || {};
    $scope.ui.participantSearchText = '';
    $scope.newParticipantAttemptSearch = function (searchTerm) {
      // Safeguard for undefined searchTerm
      if (searchTerm != void 0) {
        $scope.attemptSearch.mode = '';
        $scope.attemptSearch.search = searchTerm;
      }
      $scope.participantAttemptsPage.options.page = 1;
      getParticipantAttempts($scope.assessment);
    };

    $scope.searchDeveloperOnClick = {
      onClick: function () {
        $scope.newParticipantAttemptSearch($scope.ui.participantSearchText);
      },
    };

    $scope.newCompletedAttemptSearch = function (searchTerm) {
      // Safeguard for undefined searchTerm
      if (searchTerm != void 0) {
        $scope.completedAttemptSearch.search = searchTerm;
      }
      $scope.completedAttemptsPage.options.page = 1;
      getCompletedAttempts($scope.assessment);
    };

    $scope.newAllAttemptSearch = function (searchTerm) {
      // Safeguard for undefined searchTerm
      if (searchTerm != void 0) {
        $scope.allAttemptSearch.search = searchTerm;
      }
      $scope.allAttemptsPage.options.page = 1;
      getAllAttempts($scope.assessment);
    };

    $scope.searchByInviterOnClick = {
      onClick: function () {
        $scope.newAllAttemptSearch();
      },
    };

    function getParticipantAttempts(assessmentObj) {
      const mode = $scope.attemptSearch.mode;
      const search = $scope.attemptSearch.search;
      AssessmentsApiService.getAttemptsList(
        assessmentObj._id,
        {
          mode: mode,
          search: search,
        },
        $scope.participantAttemptsPage.options,
        $scope.sorting.sortParticipantAttemptsByParam
      ).then(function (attempts) {
        $scope.participantAttemptsPage.options = attempts;
        assessmentObj.attempts.participant = attempts.data;

        // Update numberOfParticipantAttempts if this was an all_assessments search (mode is undefined)
        if (!mode && search.length == 0) {
          $scope.assessmentInfo.numberOfParticipantAttempts = attempts.data.length;
        }

        for (let i = 0; i < attempts.data.length; i++) {
          const attempt = attempts.data[i];
          $scope.processAttempt(attempt);
        }
      });
    }

    function getPendingAttempts(assessmentObj) {
      const mode = AssessmentsApiService.attemptFilters.unfinished;
      const search = $scope.pendingAttemptSearch.search;

      return $q
        .all([
          AssessmentsApiService.getMyAttemptsList(
            assessmentObj._id,
            {
              mode: mode,
              search: search,
            },
            $scope.pendingAttemptsPage.options,
            $scope.sorting.sortPendingAttemptsByParam
          ),
        ])
        .then(function (data) {
          const attempts = data[0];
          let publicAssessments = angular.copy($scope.publicAssessments);

          attempts.data = _.map(attempts.data, function (element) {
            return _.extend({}, element, { selfAssess: false });
          });

          publicAssessments = publicAssessments.filter(function (assessment) {
            return assessmentObj._id == assessment._id;
          });

          publicAssessments = publicAssessments.map(function (assessment) {
            return {
              assessment: assessment,
              author: assessment.author,
              started: null,
              status: assessment.status === 'closed' ? 'expired' : 'pending',
              progress: {
                accuracy: 0,
                completed: 0,
                correct: 0,
                incorrect: 0,
                numberOfChallenges: 1,
              },
              language: [],
              score: 0,
              deadline: null,
              selfAssess: true,
            };
          });

          attempts.data = attempts.data.concat(publicAssessments);

          // Filter out superseded pending attempts
          attempts.data = attempts.data.filter(function (attempt) {
            return !(attempt.assessment.status === 'disabled' && attempt.status === 'pending');
          });

          $scope.pendingAttemptsPage.options = attempts;
          assessmentObj.attempts.pending = attempts.data;
          for (let i = 0; i < attempts.data.length; i++) {
            const attempt = attempts.data[i];
            $scope.processAttempt(attempt);
          }

          // Update number of pending attempts if search is blank (eg, get all pending attempts)
          if ($scope.pendingAttemptSearch.search != void 0 && $scope.pendingAttemptSearch.search.length == 0) {
            $scope.assessmentInfo.numberOfPendingAttempts = attempts.data.length;
          }

          // If we were resetting the pending attempt search results, indicate we are finished
          if ($scope.assessmentInfo.resettingPendingAttemptSearch) {
            $scope.assessmentInfo.resettingPendingAttemptSearch = false;
          }
        });
    }

    function getCompletedAttempts(assessmentObj) {
      const mode = AssessmentsApiService.attemptFilters.completed_all;
      const search = $scope.completedAttemptSearch.search;
      return AssessmentsApiService.getMyAttemptsList(
        assessmentObj._id,
        {
          mode: mode,
          search: search,
        },
        $scope.completedAttemptsPage.options,
        $scope.sorting.sortCompletedAttemptsByParam
      ).then(function (attempts) {
        $scope.completedAttemptsPage.options = attempts;
        assessmentObj.attempts.completed = attempts.data;
        for (let i = 0; i < attempts.data.length; i++) {
          const attempt = attempts.data[i];
          $scope.processAttempt(attempt);
        }

        // If we are searching for all results, store the value for reference
        if ($scope.completedAttemptSearch.search != void 0 && $scope.completedAttemptSearch.search.length == 0) {
          $scope.assessmentInfo.numberOfCompletedAttempts = attempts.data.length;
        }

        // If we were resetting the pending attempt search results, indicate we are finished
        if ($scope.assessmentInfo.resettingCompletedAttemptSearch) {
          $scope.assessmentInfo.resettingCompletedAttemptSearch = false;
        }
      });
    }

    function getAllAttempts(assessmentObj) {
      const mode = AssessmentsApiService.attemptFilters.all_not_self_assess;
      const search = $scope.allAttemptSearch.search;

      $scope.attemptsPromise = $q
        .all([
          AssessmentsApiService.getMyAttemptsList(
            assessmentObj._id,
            {
              mode: mode,
              search: search,
            },
            $scope.allAttemptsPage.options,
            $scope.sorting.sortAllAttemptsByParam
          ),
        ])
        .then(function (data) {
          const attempts = data[0];
          let publicAssessments = angular.copy($scope.publicAssessments);

          attempts.data = _.map(attempts.data, function (element) {
            return _.extend({}, element, { selfAssess: false });
          });

          publicAssessments = publicAssessments.filter(function (assessment) {
            return assessmentObj._id == assessment._id;
          });

          publicAssessments = publicAssessments.map(function (assessment) {
            return {
              assessment: assessment,
              author: assessment.author,
              started: null,
              status: assessment.status === 'closed' ? 'expired' : 'pending',
              progress: {
                accuracy: 0,
                completed: 0,
                correct: 0,
                incorrect: 0,
                numberOfChallenges: 1,
              },
              language: [],
              score: 0,
              deadline: null,
              selfAssess: true,
            };
          });

          attempts.data = attempts.data.concat(publicAssessments);

          // Filter out superseded pending attempts
          attempts.data = attempts.data.filter(function (attempt) {
            return !(attempt.assessment.status === 'disabled' && attempt.status === 'pending');
          });

          assessmentObj.attempts.all = attempts.data;
          for (let i = 0; i < attempts.data.length; i++) {
            const attempt = attempts.data[i];
            $scope.processAttempt(attempt);
          }

          // Update number of all attempts if search is blank (eg, get all attempts)
          if ($scope.allAttemptSearch.search != undefined && $scope.allAttemptSearch.search.length == 0) {
            $scope.assessmentInfo.numberOfAllAttempts = attempts.data.length;
          }

          // If we were resetting the all attempt search results, indicate we are finished
          if ($scope.assessmentInfo.resettingAllAttemptSearch) {
            $scope.assessmentInfo.resettingAllAttemptSearch = false;
          }

          return assessmentObj.attempts.all;
        });

      return $scope.attemptsPromise;
    }

    $scope.getAllAttempts = getAllAttempts;

    function processAssessmentData(assessmentObj) {
      if (assessmentObj.timeLimit) {
        assessmentObj.displayTimeLimit = $rootScope.utility.longFormatDuration(assessmentObj.timeLimit);
      } else {
        $translate(['NO_TIME_LIMIT']).then(function (translations) {
          assessmentObj.displayTimeLimit = translations.NO_TIME_LIMIT;
        });
      }

      if (assessmentObj.difficulty == 'easy') {
        assessmentObj.difficulties = [true];
      } else if (assessmentObj.difficulty == 'medium') {
        assessmentObj.difficulties = [true, true];
      } else if (assessmentObj.difficulty == 'hard') {
        assessmentObj.difficulties = [true, true, true];
      }

      assessmentObj.attempts = {};
      if (!AuthService.isAuthorized(USER_ROLES.admin)) {
        if (AuthService.isAuthorized([USER_ROLES.companyAdmin, USER_ROLES.manager])) {
          getParticipantAttempts(assessmentObj);
        }

        if (AuthService.isAuthorized([USER_ROLES.player, USER_ROLES.assessmentUser, USER_ROLES.companyAdmin])) {
          if (assessmentObj.selfAssess) {
            AssessmentsApiService.getPublicAssessmentsList(assessmentObj._id, null, null, null).then(function (res) {
              $scope.publicAssessments = res.data;
              initAttempts(assessmentObj);
            });
          } else {
            initAttempts(assessmentObj);
          }
        }
      }
      $scope.languages = '';
      for (let index = 0; index < assessmentObj.languages.length; ++index) {
        $scope.languages += LanguageUtils.languageName(assessmentObj.languages[index]);
        if (index < assessmentObj.languages.length - 1) {
          $scope.languages += ', ';
        }
      }
      $scope.assessment = assessmentObj;
    }

    function initAttempts(assessmentObj) {
      $scope.assessmentPromise = $q
        .all([getPendingAttempts(assessmentObj), getCompletedAttempts(assessmentObj)])
        .then(function () {
          // Find Latest Assessment Attempt:
          $scope.latestAttempt = getLatestAttempt(assessmentObj) ?? {};

          // Get All Attempts(pending, in_progress, done (regular and self assess))
          getAllAttempts(assessmentObj);

          return assessmentObj;
        });
    }

    $scope.viewAttempt = function (attempt, $event) {
      if (!AuthService.isAuthorized([USER_ROLES.companyAdmin, USER_ROLES.manager])) {
        // Developer
        const publicNotStarted = attempt.selfAssess && !attempt.started;
        if ($scope.assessment.selfAssess && attempt.status != 'done') {
          $scope.createAssessmentAttempt($stateParams.assessmentId, $event);
        } else {
          $state.go('assessments.view.attempt', {
            assessmentId: $stateParams.assessmentId,
            attemptId: attempt._id,
            publicNotStarted: publicNotStarted,
          });
        }
      } else {
        // Team Manager, Company Admin
        $state.go('assessments.view.attempt', {
          assessmentId: $stateParams.assessmentId,
          attemptId: attempt._id,
        });
      }
    };

    $scope.goBack = function () {
      if ($state.current.name == 'assessments.view') {
        $state.go('assessments.list');
      } else if ($state.current.name == 'assessments.view.edit') {
        $scope.editing = false;
        $state.go('assessments.view', { assessmentId: $stateParams.assessmentId }, { reload: 1 });
      } else if (
        [
          'assessments.view.invite',
          'assessments.view.attempt',
          'assessments.view.inviteTeams',
          'assessments.view.inviteCompany',
          'assessments.view.inviteTeam',
        ].indexOf($state.current.name) >= 0
      ) {
        if ($scope.assessmentsData.fromRecentActivity) {
          $scope.assessmentsData.fromRecentActivity = false;
          $state.go('assessments.list');
        } else {
          $state.go('assessments.view', { assessmentId: $stateParams.assessmentId }, { reload: 1 });
        }
      }
    };

    $scope.tabset = [false, false, false];
    $scope.setTab = function (number) {
      $scope.tabset = [false, false, false];
      $scope.tabset[number] = true;
    };

    if ($scope.isAuthorized([$scope.userRoles.reseller, $scope.userRoles.companyAdmin, $scope.userRoles.manager])) {
      $scope.setTab(0);
    } else {
      $scope.setTab(1);
    }

    $scope.closeAssessment = function () {
      //var message = "Once you end this assessment, participants will no longer be able to take it.";
      $translate([
        'OK',
        'ARE_YOU_SURE',
        'END_ASSESSMENT_DESC',
        'END_ASSESSMENT',
        'CANCEL',
        'ASSESSMENT_CLOSED',
        'SUCCESSFULLY_CLOSED_ASSESSMENT',
        'ERROR_CLOSING_ASSESSMENT',
      ]).then(function (translations) {
        $swal({
          title: translations.ARE_YOU_SURE,
          text: translations.END_ASSESSMENT_DESC,
          type: 'warning',
          html: true,
          showCancelButton: true,
          confirmButtonColor: 'var(--color-scw-red)',
          confirmButtonText: translations.END_ASSESSMENT,
          cancelButtonText: translations.CANCEL,
          keyResolution: true,
        }).then(function (isConfirm) {
          if (isConfirm) {
            AssessmentsApiService.closeAssessment($scope.assessment)
              .then(function () {
                $swal({
                  title: translations.ASSESSMENT_CLOSED,
                  text: translations.SUCCESSFULLY_CLOSED_ASSESSMENT,
                  type: 'success',
                  confirmButtonText: translations.OK,
                  keyResolution: true,
                });
                $state.go('assessments.list');
              })
              .catch(function (response) {
                ErrorHandler.addHttpError(translations.ERROR_CLOSING_ASSESSMENT, response);
              });
          }
        });
      });
    };

    $scope.editAssessment = function () {
      $scope.editing = true;
      $state.go('assessments.view.edit', { assessmentId: $scope.assessment._id });
    };

    $scope.cloneAssessment = function () {
      AssessmentsApiService.cloneAssessment($scope.assessment._id).then(function (assessment) {
        $state.go('assessments.view.edit', { assessmentId: assessment._id });
      });
    };

    $scope.deleteAssessment = function () {
      $translate([
        'CANNOT_DELETE_ASSESSMENT_WITH_ATTEMPTS_AGAINST_IT',
        'ASSESSMENTS_CREDITS_FOR_ATTEMPTS_STARTED_WILL_NOT_BE_REFUNDED',
        'ALL_PREVIOUS_AND_PENDING_ASSESSMENT_ATTEMPTS_WILL_BE_LOST_IF_YOU_PROCEED',
        'ARE_YOU_SURE',
        'DELETE',
        'CANCEL',
        'ERROR_DELETING_ASSESSMENT',
        'PROGRAMS_CONTAIN_ASSESSMENT_ERROR',
      ]).then(function (translations) {
        if ($scope.assessment.attempts.length > 0) {
          ErrorHandler.addError(translations.CANNOT_DELETE_ASSESSMENT_WITH_ATTEMPTS_AGAINST_IT);
          return;
        }
        const LicenseType = $scope.metadata.management.moduleLicenses.types;
        let message =
          $scope.allowances.license == LicenseType.USAGE
            ? translations.ASSESSMENTS_CREDITS_FOR_ATTEMPTS_STARTED_WILL_NOT_BE_REFUNDED
            : '';
        message += translations.ALL_PREVIOUS_AND_PENDING_ASSESSMENT_ATTEMPTS_WILL_BE_LOST_IF_YOU_PROCEED;
        $swal({
          title: translations.ARE_YOU_SURE,
          text: message,
          type: 'warning',
          html: true,
          showCancelButton: true,
          confirmButtonColor: 'var(--color-scw-red)',
          confirmButtonText: translations.DELETE,
          cancelButtonText: translations.CANCEL,
          keyResolution: true,
        }).then(function (isConfirm) {
          if (isConfirm) {
            AssessmentsApiService.deleteAssessment($scope.assessment)
              .then(function () {
                $state.go('assessments.list');
              })
              .catch(function (response) {
                let errorMessage = translations.ERROR_DELETING_ASSESSMENT;
                let responseText = response;
                const errorParts = response?.data?.error?.split('|||');
                if (errorParts[0] === 'PROGRAM_CONTAINS' && errorParts[1]) {
                  errorMessage = translations.PROGRAMS_CONTAIN_ASSESSMENT_ERROR + ' ' + errorParts[1];
                  responseText = null;
                }

                ErrorHandler.addHttpError(errorMessage, responseText);
              });
          }
        });
      });
    };

    $scope.removePendingAttempts = function () {
      $translate([
        'DELETE',
        'CANCEL',
        'ARE_YOU_SURE',
        'ALL_PENDING_ATTEMPTS_WILL_BE_LOST_IF_YOU_PROCEED',
        'ERROR_REMOVING_PENDING_ASSESSMENT_ATTEMPTS_END_DATE_PASSED',
        'ERROR_REMOVING_PENDING_ATTEMPTS_FOR_THIS_ASSESSMENT',
      ]).then(function (translations) {
        const message = translations.ALL_PENDING_ATTEMPTS_WILL_BE_LOST_IF_YOU_PROCEED;
        $swal({
          title: translations.ARE_YOU_SURE,
          text: message,
          type: 'warning',
          html: true,
          showCancelButton: true,
          confirmButtonColor: 'var(--color-scw-red)',
          confirmButtonText: translations.DELETE,
          cancelButtonText: translations.CANCEL,
          keyResolution: true,
        }).then(function (isConfirm) {
          if (isConfirm) {
            AssessmentsApiService.removePendingAttempts($scope.assessment)
              .then(function (data) {
                $translate(
                  ['OK', 'PENDING_ATTEMPTS_REMOVED', 'NUM_PENDING_ATTEMPTS_HAS_BEEN_REMOVED_FOR_THIS_ASSESSMENT'],
                  { dataDeleted: data.deleted }
                ).then(function (translations) {
                  $swal({
                    title: translations.PENDING_ATTEMPTS_REMOVED,
                    text: translations.NUM_PENDING_ATTEMPTS_HAS_BEEN_REMOVED_FOR_THIS_ASSESSMENT,
                    type: 'success',
                    html: false,
                    confirmButtonText: translations.OK,
                    keyResolution: true,
                  });
                });
                $state.go('assessments.view', { assessmentId: $stateParams.assessmentId }, { reload: 1 });
              })
              .catch(function (response) {
                if (response.data.error === 'Forbidden' && $scope.assessment.endDate < Date.now()) {
                  ErrorHandler.addHttpError(translations.ERROR_REMOVING_PENDING_ASSESSMENT_ATTEMPTS_END_DATE_PASSED);
                } else {
                  ErrorHandler.addHttpError(translations.ERROR_REMOVING_PENDING_ATTEMPTS_FOR_THIS_ASSESSMENT, response);
                }
              });
          }
        });
      });
    };

    function checkTimeCriteria(assessment) {
      return (
        (assessment.startDate ? assessment.startDate < Date.now() : true) &&
        (assessment.endDate ? assessment.endDate > Date.now() : true)
      );
    }

    function checkRetryCriteria(assessment, latestAttempt) {
      const currentRetries = assessment.attempts.completed ? assessment.attempts.completed.length : 0;
      const retryWaitingHours = assessment.retryWaitingHours || 0;
      const waitTime = new Date(latestAttempt.completed);

      waitTime.setHours(waitTime.getHours() + retryWaitingHours);

      return (
        assessment.retriesAllowed &&
        (!assessment.maxRetries || currentRetries < assessment.maxRetries + 1) &&
        Date.now() > waitTime
      );
    }

    function checkSuccessRatioCriteria(assessment, latestAttempt) {
      return !assessment.successRatio || latestAttempt.score < assessment.successRatio;
    }

    $scope.canRetakeAssessment = function () {
      if (!$scope.assessment || !$scope.latestAttempt) {
        return false;
      }

      return (
        $scope.assessment.status == 'enabled' &&
        checkTimeCriteria($scope.assessment) &&
        checkRetryCriteria($scope.assessment, $scope.latestAttempt) &&
        ['done'].indexOf($scope.latestAttempt.status) >= 0 &&
        checkSuccessRatioCriteria($scope.assessment, $scope.latestAttempt)
      );
    };
    $scope.assessmentChildren = [
      {
        text: $translate.instant('CLONE'),
        icon: 'copy',
        onClick: () => {
          $scope.cloneAssessment();
          AnalyticsService.logEvent('assessmentView-button-clone');
        },
        isVisible: () => $scope.isShowingEdit() || $scope.assessmentChecks.userIsTeamManager(),
      },
      {
        text: $translate.instant('DELETE'),
        icon: 'bin',
        onClick: () => {
          $scope.deleteAssessment();
          AnalyticsService.logEvent('assessmentView-button-certificates');
        },
        isVisible: $scope.isShowingEdit,
      },
      {
        text: $translate.instant('END'),
        icon: 'power-off',
        onClick: () => {
          $scope.closeAssessment();
          AnalyticsService.logEvent('assessmentView-button-certificates');
        },
        isVisible: () => $scope.isShowingEdit() && $scope.assessment?.status != 'closed',
      },
      {
        text: $translate.instant('ASSESSMENTSVIEW_DOWNLOAD_LMS_SCORM_PACKAGE'),
        icon: 'download',
        onClick: () => {
          $scope.downloadLmsPackage('1.2'); // Directly passing SCORM version 1.2
        },
        isVisible: () => $scope.isShowingEdit() && $scope.assessment.lmsIntegrated,
      },
      {
        text: $translate.instant('ASSESSMENTSVIEW_DOWNLOAD_LMS_SCORM_PACKAGE_V2_2004'),
        icon: 'download',
        onClick: () => {
          $scope.downloadLmsPackage('2004'); // Directly passing SCORM version 2004
        },
        isVisible: () => $scope.isShowingEdit() && $scope.assessment.lmsIntegrated,
      },
    ];
    $scope.menuChildren = [
      {
        text: $translate.instant('DELETE_PENDING_INVITES'),
        icon: 'bin',
        onClick: () => {
          $scope.removePendingAttempts();
          AnalyticsService.logEvent('assessmentView-button-removePendingAttempts');
        },
        isVisible: () =>
          $scope.isAuthorized([$scope.userRoles.companyAdmin, $scope.userRoles.manager]) &&
          $scope.assessment.status != 'disabled',
      },
      {
        text: $translate.instant('SEND_REMINDER_EMAIL'),
        icon: 'mission-control',
        onClick: () => {
          $scope.sendReminderEmail();
          AnalyticsService.logEvent('assessmentView-button-sendReminderEmail');
        },
        isVisible: () =>
          $scope.assessment.status != 'closed' &&
          !(
            $scope.assessment.status == 'disabled' ||
            ($scope.assessment.endDate && $scope.assessment.endDate < $scope.now())
          ) &&
          !$scope.assessment.lmsIntegrated,
      },
      {
        text: $translate.instant('DOWNLOAD_CSV'),
        icon: 'download',
        onClick: () => {
          $scope.attemptActions.open = false;
          $scope.showCsvDownloadModal();
          AnalyticsService.logEvent('assessmentView-button-downloadStatusCSV');
        },
        isVisible: () =>
          $scope.isAuthorized([$scope.userRoles.companyAdmin, $scope.userRoles.manager]) && $scope.assessment,
      },
    ];

    $scope.canStartAssessment = function () {
      if (!$scope.assessment || !$scope.latestAttempt) {
        return false;
      }

      return (
        $scope.assessment.status == 'enabled' &&
        $scope.assessment.selfAssess &&
        checkTimeCriteria($scope.assessment) &&
        !$scope.latestAttempt.hasOwnProperty('_id') &&
        ['pending'].indexOf($scope.latestAttempt.status) >= 0
      );
    };

    $scope.canStartOrResumeAssessment = function () {
      if (!$scope.assessment || !$scope.latestAttempt || !$scope.latestAttempt.hasOwnProperty('_id')) {
        return false;
      }

      return (
        $scope.assessment.status == 'enabled' &&
        checkTimeCriteria($scope.assessment) &&
        ['pending', 'in_progress'].indexOf($scope.latestAttempt.status) >= 0
      );
    };

    $scope.createAssessmentAttempt = function (assessmentId, $event) {
      $event.stopPropagation();

      $translate(['ERROR_TAKING_ASSESSMENT', 'MODULE_DISABLED_FOR_USER_CONTEXT']).then(function (translations) {
        AssessmentsApiService.createAttempt(assessmentId, null, null, null)
          .then(function (data) {
            if ($scope.assessment.lmsIntegrated && LmsIntegrationService.isOpenedByLms()) {
              LmsIntegrationService.sendMessage({ id: getLMSIdentifier(), state: 'ENROLL' });
            }

            // unpack _assessment and _attempt
            $state.go('assessments.view.attempt', {
              assessmentId: data._assessment,
              attemptId: data._id,
            });
          })
          .catch(function (e) {
            if (_.get(e, 'data.error').indexOf(translations.MODULE_DISABLED_FOR_USER_CONTEXT) > -1) {
              return $state.go('assessments-not-licensed');
            }

            ErrorHandler.addHttpError(translations.ERROR_TAKING_ASSESSMENT, e);
          });
      });
    };

    $scope.assessmentChecks = {
      userIsAuthor: function () {
        return Session.user._id == $scope.assessment._author;
      },
      assessmentOwnedByCompany: function () {
        const _companyID = [];

        // This following case covers when the team is a standalone team
        if (Session.user.properties.company) {
          _companyID.push(Session.user.properties.company._id);
        }
        if (Session.user.properties.team) {
          _companyID.push(Session.user.properties.team._id);
        }
        return $scope.assessment && _companyID.indexOf($scope.assessment._owner) >= 0;
      },
      assessmentOwnedByTeam: function () {
        return [Session.user.properties.team._id].indexOf($scope.assessment._owner) >= 0;
      },
      userIsAuthorAndCompanyAdmin: function () {
        return (
          AuthService.isAuthorized([USER_ROLES.companyAdmin]) &&
          $scope.assessment &&
          ($scope.assessmentChecks.assessmentOwnedByCompany() || $scope.assessmentChecks.userIsAuthor())
        );
      },
      userIsAuthorAndTeamManager: function () {
        return (
          AuthService.isAuthorized([USER_ROLES.manager]) &&
          Session.user.properties._cid &&
          $scope.assessment &&
          $scope.assessmentChecks.assessmentOwnedByCompany() &&
          $scope.assessmentChecks.userIsAuthor()
        );
      },
      userIsTeamManager: function () {
        return (
          AuthService.isAuthorized([USER_ROLES.manager]) &&
          Session.user.properties._cid &&
          $scope.assessment &&
          $scope.assessmentChecks.assessmentOwnedByCompany()
        );
      },
      userIsStandaloneTeamManager: function () {
        return (
          AuthService.isAuthorized([USER_ROLES.manager]) &&
          !Session.user.properties._cid &&
          $scope.assessment &&
          $scope.assessmentChecks.assessmentOwnedByTeam()
        );
      },
    };

    $scope.isShowingEdit = function () {
      return (
        Session.user &&
        Session.user.properties &&
        (AuthService.isAuthorized(USER_ROLES.admin) || // SCW admin
          $scope.assessmentChecks.userIsAuthorAndCompanyAdmin() || // company admin
          $scope.assessmentChecks.userIsAuthorAndTeamManager() || // team manager in company
          $scope.assessmentChecks.userIsStandaloneTeamManager()) // standalone team manager
      );
    };

    $scope.removeMemberFromAssessment = function (assessmentAttempt) {
      $translate(
        [
          'ASSESSMENTS_CREDITS_FOR_ATTEMPTS_STARTED_WILL_NOT_BE_REFUNDED',
          'ALL_INFO_RELATED_TO_ASSESSMENT_ATTEMPT_WILL_BE_LOST_REMOVE_USER',
          'ARE_YOU_SURE',
          'REMOVE',
          'CANCEL',
          'ERROR_REMOVING_ASSESSMENT_ATTEMPT_END_DATE_PASSED',
          'ERROR_REMOVING_ASSESSMENT_ATTEMPT',
        ],
        { displayName: assessmentAttempt.user.displayName }
      ).then(function (translations) {
        const LicenseType = $scope.metadata.management.moduleLicenses.types;
        let message;
        if ($scope.allowances.license == LicenseType.USAGE) {
          message = translations.ASSESSMENTS_CREDITS_FOR_ATTEMPTS_STARTED_WILL_NOT_BE_REFUNDED;
        } else {
          message = '';
        }
        message += translations.ALL_INFO_RELATED_TO_ASSESSMENT_ATTEMPT_WILL_BE_LOST_REMOVE_USER;
        $swal({
          title: translations.ARE_YOU_SURE,
          text: message,
          type: 'warning',
          html: false,
          showCancelButton: true,
          confirmButtonColor: 'var(--color-scw-red)',
          confirmButtonText: translations.REMOVE,
          cancelButtonText: translations.CANCEL,
          keyResolution: true,
        }).then(function (isConfirm) {
          if (isConfirm) {
            AssessmentsApiService.deleteAssessmentAttempt($scope.assessment._id, assessmentAttempt._id)
              .then(function () {
                $scope.getAssessmentPromise = getAssessmentData();
              })
              .catch(function (response) {
                if (response.data.error === 'Forbidden' && $scope.assessment.endDate < Date.now()) {
                  ErrorHandler.addHttpError(translations.ERROR_REMOVING_ASSESSMENT_ATTEMPT_END_DATE_PASSED);
                } else {
                  ErrorHandler.addHttpError(translations.ERROR_REMOVING_ASSESSMENT_ATTEMPT, response);
                }
              });
          }
        });
      });
    };

    $scope.makeInertToClick = function (event) {
      event.stopPropagation();
    };

    $scope.resumeAssessment = function (attempt, $event) {
      $event.stopPropagation();
      if (attempt.language) {
        if (AuthService.isFeatureEnabled('assessments') || attempt._user == Session.user._id + '') {
          $state.go('assessments.take', {
            _assessment: $scope.assessment._id,
            _attempt: attempt._id,
          });
        } else {
          $state.go('assessments-not-licensed');
        }
      } else {
        $scope.viewAttempt(attempt, $event);
      }
    };

    $scope.sendReminderEmail = function () {
      $translate([
        'ARE_YOU_SURE',
        'SEND_EMAIL_TO_PARTICIPANTS_HAVE_NOT_STARTED_ASSESSMENT',
        'SEND_REMINDER_EMAIL',
        'CANCEL',
        'REMINDER_EMAILS_SCHEDULED',
        'PARTICIPANTS_WILL_RECEIVE_REMINDER_EMAIL_FOR_ASSESSMENT',
        'OK',
        'ERROR_SENDING_REMINDER_EMAILS',
      ]).then(function (translations) {
        $swal({
          title: translations.ARE_YOU_SURE,
          text: translations.SEND_EMAIL_TO_PARTICIPANTS_HAVE_NOT_STARTED_ASSESSMENT,
          type: 'warning',
          html: false,
          showCancelButton: true,
          confirmButtonColor: 'var(--color-scw-red)',
          confirmButtonText: translations.SEND_REMINDER_EMAIL,
          cancelButtonText: translations.CANCEL,
          keyResolution: true,
        }).then(function (isConfirm) {
          if (isConfirm) {
            AssessmentsApiService.sendReminderEmail($scope.assessment._id)
              .then(function () {
                $swal({
                  title: translations.REMINDER_EMAILS_SCHEDULED,
                  text: translations.PARTICIPANTS_WILL_RECEIVE_REMINDER_EMAIL_FOR_ASSESSMENT,
                  type: 'success',
                  html: false,
                  confirmButtonText: translations.OK,
                });
              })
              .catch(function (response) {
                ErrorHandler.addHttpError(translations.ERROR_SENDING_REMINDER_EMAILS, response);
              });
          }
        });
      });
    };

    $scope.showAssessmentActions = function () {
      $swal({
        title: $translate.instant('Assessments.Management.View.OptionsDialog.title'),
        templateUrl: viewOptionsDialogTemplate,
        showConfirmButton: false,
        showCancelButton: false,
        allowOutsideClick: true,
        scope: $scope.$new(),
        keyResolution: true,
      }).then(function (resolution) {
        switch (resolution) {
          case 'edit':
            return $timeout($scope.editAssessment, 100);
          case 'clone':
            return $timeout($scope.cloneAssessment, 100);
          case 'delete':
            return $timeout($scope.deleteAssessment, 100);
          case 'end':
            return $timeout($scope.closeAssessment, 100);
          case 'deletePendingInvites':
            return $timeout($scope.removePendingAttempts, 100);
          case 'sendReminderEmail':
            return $timeout($scope.sendReminderEmail, 100);
          case 'detailedCSVReport':
            return $timeout($scope.downloadDetailedCsvReport, 100);
          case 'resultsCSVReport':
            return $timeout($scope.downloadSingleResultsCsvReportV3, 100);
          case 'summaryCSVReportV3':
            return $timeout($scope.downloadSingleSummaryCsvReportV3, 100);
          case 'summaryCSVReport':
            return $timeout($scope.downloadSummaryCsvReport, 100);
          default:
            break;
        }
      });
    };

    $scope.showInviteOptions = function () {
      $swal({
        title: $translate.instant('Assessments.Management.Invite.OptionsDialog.title'),
        templateUrl: inviteOptionsDialogTemplate,
        showConfirmButton: false,
        showCancelButton: false,
        scope: $scope.$new(),
        keyResolution: true,
      }).then(function (resolution) {
        switch (resolution) {
          case 'participants':
            return $scope.goToInvite();
          case 'team':
            return $scope.goToInviteTeam();
          case 'teams':
            return $scope.goToInviteTeams();
          case 'company':
            return $scope.goToInviteCompany();
          default:
            break;
        }
      });
    };

    $scope.goToInvite = function () {
      if (AuthService.isFeatureEnabled('assessments')) {
        $state.go('assessments.view.invite', { assessmentId: $stateParams.assessmentId });
      } else {
        $state.go('assessments-not-licensed');
      }
    };

    $scope.goToInviteTeams = function () {
      if (AuthService.isFeatureEnabled('assessments')) {
        $state.go('assessments.view.inviteTeams', { assessmentId: $stateParams.assessmentId });
      } else {
        $state.go('assessments-not-licensed');
      }
    };

    $scope.goToInviteCompany = function () {
      if (AuthService.isFeatureEnabled('assessments')) {
        $state.go('assessments.view.inviteCompany', { assessmentId: $stateParams.assessmentId });
      } else {
        $state.go('assessments-not-licensed');
      }
    };

    $scope.goToInviteTeam = function () {
      if (AuthService.isFeatureEnabled('assessments')) {
        $state.go('assessments.view.inviteTeam', { assessmentId: $stateParams.assessmentId });
      } else {
        $state.go('assessments-not-licensed');
      }
    };

    $scope.isShowingChildView = function () {
      return $state.current.name !== 'assessments.view';
    };

    $scope.hasAbandoned = function (attempt) {
      if (!attempt || !attempt.progress || !attempt.progress.statuses) return false;
      return attempt.progress.statuses.includes('abandoned');
    };

    $scope.init = function () {
      $scope.viewStarted = new Date();

      if ($state.current.name == 'assessments.view.edit') {
        $scope.editing = true;
      }

      $scope.getAssessmentPromise = getAssessmentData();
    };

    $scope.$on('$destroy', function () {
      logAmplitudeEvent(new Date());
    });

    function logAmplitudeEvent(endTime) {
      const duration = parseInt(Math.abs(endTime - $scope.viewStarted.getTime()) / 1000);
      const eventProps = {
        assessment_id: $scope.assessment._id,
        assessment_name: $scope.assessment.name,
        assessment_languages: $scope.assessment.languages.map((language) => `${language._id}:${language._framework}`),
        time_spent_on_assessments_recent_activity_seconds: duration,
      };
      AnalyticsService.logEvent(AnalyticsEvents.Assessments.DETAILS_RECENT_ACTIVITY, eventProps);
    }

    function getAssessmentData() {
      if (AuthService.isAuthorized([USER_ROLES.reseller, USER_ROLES.companyAdmin, USER_ROLES.manager])) {
        getDeveloperProgressSummary();
      }

      return AssessmentsApiService.getAssessmentData(
        $stateParams.assessmentId,
        $scope.attemptSearch.search,
        $scope.participantAttemptsPage.options
      )
        .then(function (data) {
          processAssessmentData(data);
        })
        .catch(function (response) {
          $translate(['ERROR_LOADING_ASSESSMENT', 'UNABLE_TO_LOAD_REQUESTED_ASSESSMENT_CONTACT_HOST']).then(
            function (translations) {
              if (response.status == 404) {
                ErrorHandler.addError(translations.UNABLE_TO_LOAD_REQUESTED_ASSESSMENT_CONTACT_HOST);
              } else {
                ErrorHandler.addHttpError(translations.ERROR_LOADING_ASSESSMENT, response);
              }
            }
          );
          $state.go('assessments.list');
        });
    }

    $scope.getAssessmentData = getAssessmentData;

    function getDeveloperProgressSummary() {
      AssessmentsApiService.getDeveloperProgressSummary($stateParams.assessmentId)
        .then(function (data) {
          $scope.developerProgressSummary = data;
        })
        .catch(function (response) {
          $translate(['ERROR_LOADING_DEVELOPER_PROGRESS_SUMMARY']).then(function (translations) {
            ErrorHandler.addHttpError(translations.ERROR_LOADING_DEVELOPER_PROGRESS_SUMMARY, response);
          });
        });
    }

    const getLMSIdentifier = () => {
      return $window.sessionStorage.getItem('lms_course_id')
        ? $window.sessionStorage.getItem('lms_course_id')
        : $scope.assessment._id;
    };
    $scope.getLMSIdentifier = getLMSIdentifier;

    $scope.init();

    $scope.$watch('assessment', () => {
      if (!$scope.assessment) {
        return;
      }
      if ($scope.assessment.lmsIntegrated && LmsIntegrationService.isOpenedByLms()) {
        LmsIntegrationService.sendMessage({ id: getLMSIdentifier(), state: 'START' });
      }
    });
  },
]);

/****** invite ******/

app.controller('AssessmentsInviteController', [
  'LanguageUtils',
  '$timeout',
  '$scope',
  '$rootScope',
  '$state',
  '$stateParams',
  '$window',
  '$translate',
  '$swal',
  'AuthService',
  'USER_ROLES',
  'Session',
  'ErrorHandler',
  'AssessmentsApiService',
  'AdminApiService',
  function (
    LanguageUtils,
    $timeout,
    $scope,
    $rootScope,
    $state,
    $stateParams,
    $window,
    $translate,
    $swal,
    AuthService,
    USER_ROLES,
    Session,
    ErrorHandler,
    AssessmentsApiService,
    AdminApiService
  ) {
    $scope.forms = {};
    $scope.inviteData = {};
    $scope._inviteTeams = [];
    $scope.saveSearchTerm = '';

    $scope.TAB_NAMES = {
      SELECT: 0,
      UPLOAD: 1,
    };

    $scope.tabsList = {
      activeIndex: 0,
    };

    $scope.searchText = {
      inviteUser: '',
      inviteTeam: '',
    };

    $scope.sorting = {
      sortUsersBy: 'name',
      sortUsersByParam: 'name',
      sortUsersReverse: false,
      sortTeamsBy: 'name',
      sortTeamsByParam: 'name',
      sortTeamsReverse: false,
    };
    $scope.metadata = metadata;

    $scope.goBack = function () {
      $state.go('assessments.view', {
        assessmentId: $stateParams.assessmentId,
      });
    };

    $scope.bulkInvite = function (file) {
      const reader = new FileReader();
      if (file) {
        $scope.isInvalidFile = false;

        reader.readAsText(file);
        reader.onloadend = loadHandler;
      }

      function loadHandler() {
        const content = reader.result;
        addListToInvitees(getUsers(content));
        $scope.$apply();
      }

      // does a global regex search for anything that matches an email
      function getUsers(data) {
        const EMAIL_REGEXP = /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/gi;

        let matches = {},
          match = null;
        let numMatches = 0;
        while ((match = EMAIL_REGEXP.exec(data))) {
          const email = match[0];
          matches[email] = { email: email };
          numMatches++;
        }

        if (numMatches === 0) {
          $scope.isInvalidFile = true;
        }

        return matches;
      }
    };

    $scope.chooseLanguage = function (languages) {
      $scope.inviteData.languageFramework = languages;
    };

    if ($scope.assessment) {
      if ($scope.assessment.categoryType) {
        $scope.languages = LanguageUtils.languageList({ $$categoryType: $scope.assessment.categoryType });
      } else {
        $scope.chooseLanguage($scope.assessment.languages);
      }
    } else {
      $scope.$watch('assessment', function (n, _o) {
        if (!n) {
          return;
        }
        if (n.categoryType) {
          $scope.languages = LanguageUtils.languageList({ $$categoryType: $scope.assessment.categoryType });
        } else {
          $scope.chooseLanguage(n.languages);
        }
      });
    }

    $scope.isLanguageAvailable = function (lang) {
      const user = $scope.Session.user;
      const scopes = [user.properties.team, user.properties.company, user];
      return LanguageUtils.isAvailable(scopes, lang);
    };

    $scope.setSortUsersBy = function (s) {
      if ($scope.sorting.sortUsersBy == s) {
        $scope.sorting.sortUsersReverse = !$scope.sorting.sortUsersReverse;
        $scope.sorting.sortUsersByParam = ($scope.sorting.sortUsersReverse ? '-' : '') + s;
      } else {
        $scope.sorting.sortUsersBy = s;
        $scope.sorting.sortUsersByParam = s;
        $scope.sorting.sortUsersReverse = false;
      }
      // $scope.initInviteUsers();
    };

    $scope.setSortTeamsBy = function (s) {
      if ($scope.sorting.sortTeamsBy == s) {
        $scope.sorting.sortTeamsReverse = !$scope.sorting.sortTeamsReverse;
        $scope.sorting.sortTeamsByParam = ($scope.sorting.sortTeamsReverse ? '-' : '') + s;
      } else {
        $scope.sorting.sortTeamsBy = s;
        $scope.sorting.sortTeamsByParam = s;
        $scope.sorting.sortTeamsReverse = false;
      }
      $scope.initInviteTeams();
    };

    $scope.inviteUserPage = {
      filter: '',
      options: { page: 1, size: 12 },
    };
    $scope.inviteTeamPage = {
      filter: '',
      options: { page: 1, size: $rootScope.pagerPrefs.itemsPerPage },
    };

    $scope.showUploadFile = false;
    $scope.toggleShowUploadFile = function () {
      $scope.showUploadFile = !$scope.showUploadFile;
    };

    $scope.$watch('pagerPrefs.itemsPerPage', function (newVal, oldVal) {
      if (newVal != oldVal) {
        switch ($state.current.name) {
          case 'assessments.view.inviteTeams':
            $scope.inviteTeamPage.options.page = 1;
            $scope.inviteTeamPage.options.size = newVal;
            $scope.initInviteTeams();
            break;
          case 'assessments.view.inviteCompany':
            break;
          case 'assessments.view.inviteTeam':
            break;
          default:
            $scope.inviteUserPage.options.page = 1;
            $scope.inviteUserPage.options.size = newVal;
            // $scope.initInviteUsers();
            break;
        }
      }
    });

    $scope.ui = {
      selectedUsersList: { expanded: false },
    };

    $scope.allInvitees = [];
    $scope.allInviteesIdx = [];

    function addListToInvitees(list) {
      let i;
      for (i in list) {
        const item = list[i];
        if ($scope.allInviteesIdx[item.email]) {
          continue;
        }

        $scope.allInviteesIdx[item.email] = item;
        $scope.allInvitees.push(item);
      }
    }

    $scope.showInviteesInList = function () {
      $scope.inviteeSearchResults = $scope.allInvitees;
    };

    // Adds an invitee if not already added
    $scope.addInvitee = function (user) {
      const found = $scope.allInviteesIdx[user.email];
      if (!found) {
        // :
        $scope.allInvitees.splice(0, 0, user);
        $scope.allInviteesIdx[user.email] = user;
      }
    };

    $scope.doToggleInvitee = function (user) {
      const found = $scope.allInviteesIdx[user.email];
      if (!found) {
        // :
        $scope.allInvitees.splice(0, 0, user);
        $scope.allInviteesIdx[user.email] = user;

        // commented out: just leave it in the list...
        // var inSearch = $scope.inviteeSearchResults.indexOf(user);
        // $scope.inviteeSearchResults.splice(inSearch,1);
      } else {
        // :
        const idx = $scope.allInvitees.indexOf(found);
        delete $scope.allInviteesIdx[user.email];
        $scope.allInvitees.splice(idx, 1);
      }
    };

    $scope.toggleInvitee = function (user) {
      if (user.triggerAll) {
        const searchResults = user;

        if (searchResults.pages == 1) {
          addListToInvitees(searchResults.data);
        } else {
          const query = user.query;
          const term = user.term;
          const options = user;
          const sort = user.sort;
          options.size = user.total;
          AdminApiService.addLoading();
          AdminApiService.getUsers(query, term, options, sort)
            .then(function (data) {
              const list = JSON.parse(JSON.stringify(data.data)) || [];
              addListToInvitees(list);
              user.triggerAll = false;
            })
            .finally(function () {
              AdminApiService.removeLoading();
            });
        }

        const optidx = $scope.inviteeSearchResults.indexOf(user);
        $scope.inviteeSearchResults.splice(optidx, 1);
        return;
      }

      // is already invited?
      const found = $scope.allInviteesIdx[user.email];
      if (!found) {
        // : no
        if ($window.emailRegex.test(user.email)) {
          $scope.doToggleInvitee(user);
        }
      } else {
        // : yes
        $scope.doToggleInvitee(user);
        // re-fetch search if invited list is empty now.
        if ($scope.allInvitees == $scope.inviteeSearchResults && $scope.allInvitees.length < 1) {
          $scope.inviteeSearch();
        }
      }
    };

    $scope.resetExternal = function () {
      $scope.forms.inviteExternalActive = false;
      $scope.forms.inviteExternalEmail = '';
    };

    $scope.removeAllInviteesWarning = false;
    $scope.removeAllInvitees = function () {
      if ($scope.removeAllInviteesWarning) {
        $scope.allInvitees = [];
        $scope.allInviteesIdx = [];
        $scope.removeAllInviteesWarning = false;
        return;
      }

      $scope.removeAllInviteesWarning = true;
      $timeout(function () {
        $scope.removeAllInviteesWarning = false;
      }, 1500);
    };

    $scope.inviteeSearchACResults = [];
    $scope.inviteeSearchAutocomplete = function (term) {
      $translate(
        [
          'TYPE_SOMETHING_TO_SEARCH_FOR',
          'YOU_CAN_SEARCH_USERS_BY_NAME_EMAIL_AND_TAGS',
          'SEARCH_USERS_MATCHING_TERM',
          'SEARCH_APPLIES_TO_NAME_EMAIL_AND_TAGS',
        ],
        { term: term }
      ).then(function (translations) {
        if (!term) {
          $scope.inviteeSearchACResults = [
            {
              display: translations.TYPE_SOMETHING_TO_SEARCH_FOR,
              subDisplay: translations.YOU_CAN_SEARCH_USERS_BY_NAME_EMAIL_AND_TAGS,
              term: '',
            },
          ];
          return;
        }

        $scope.inviteeSearchACResults = [
          {
            display: translations.SEARCH_USERS_MATCHING_TERM,
            subDisplay: translations.SEARCH_APPLIES_TO_NAME_EMAIL_AND_TAGS,
            term: term,
          },
        ];
        if ($window.emailRegex.test(term)) {
          if (!$scope.allInviteesIdx[term]) {
            const external = { email: term, display: 'Invite user with email: ' };
            $scope.inviteeSearchACResults.splice(0, 0, external);
          }
        }
      });
    };
    $scope.inviteeSearchACSelected = function (obj, _test) {
      // If this is a search for an internal developer
      if (typeof obj.term != 'undefined') {
        $scope.saveSearchTerm = obj.term;
        $scope.inviteUserPage.options.page = 1;
        $scope.inviteeSearch(obj.term).then(function (results) {
          const invitee = results.length && results[0].email == obj.email ? results[0] : obj;
          $scope.toggleInvitee(invitee);
        });
      } else {
        // Add an external developer
        $scope.toggleInvitee(obj);
        // Clear any previous searches
        $scope.clearSearch();
      }

      // if (obj.email && !$scope.allInviteesIdx[obj.email]) {
      // 	$scope.inviteeSearch(obj.email).then(function(results) {
      // 		results.length && results[0].email == obj.email ? $scope.toggleInvitee(results[0]) : $scope.toggleInvitee(obj);
      // 	});
      // }
    };

    $scope.clearSearch = function () {
      if ($scope.forms.invitees) {
        $scope.forms.invitees.term = '';
        $scope.inviteeSearchACSelected({ term: '' });
      }

      $scope.saveSearchTerm = '';
    };

    // Flag to indicate to the UI that a search is being made
    $scope.performingSearch = false;

    $scope.inviteeSearchResults = [];
    $scope.inviteeSearch = function (term) {
      const UserStatus = $scope.metadata.constants.user.status;
      const query = {
        status: [UserStatus.ENABLED, UserStatus.REGISTERED, UserStatus.INVITED],
      };

      if (AuthService.isAuthorized([USER_ROLES.admin, USER_ROLES.reseller, USER_ROLES.companyAdmin])) {
        query['properties._cid'] = Session.user.properties._cid;
      } else {
        query['properties._tid'] = Session.user.properties._tid;
      }

      // Flag to indicate to UI that a search is being done
      $scope.performingSearch = true;
      AdminApiService.addLoading();
      const options = JSON.parse(JSON.stringify($scope.inviteUserPage.options));
      const sorting = JSON.parse(JSON.stringify($scope.sorting.sortUsersByParam));
      return AdminApiService.getUsers(query, term, options, sorting)
        .then(function (data) {
          $scope.performingSearch = false;
          $scope.inviteUserPage.options = data;
          $scope.triggerAllOptions = JSON.parse(JSON.stringify(data));
          $scope.triggerAllOptions.query = query;
          $scope.triggerAllOptions.term = term;
          $scope.triggerAllOptions.triggerAll = true;

          return ($scope.inviteeSearchResults = JSON.parse(JSON.stringify(data.data)) || []);
        })
        .catch(function (response) {
          $translate(['ERROR_LOADING_USER_LIST']).then(function (translation) {
            ErrorHandler.addHttpError(translation.ERROR_LOADING_USER_LIST, response);
          });
        })
        .finally(function () {
          AdminApiService.removeLoading();
        });
    };

    $scope.newInviteeSearch = function (term) {
      $scope.saveSearchTerm = term;
      $scope.inviteUserPage.options.page = 1; // this will trigger a double retrieve in some cases due to the $watch above
      $scope.inviteeSearch(term);
    };

    $scope.inviteUserPagination = function (impact) {
      $scope.inviteUserPage.options.page = $scope.inviteUserPage.options.page + impact;
      $scope.inviteeSearch($scope.saveSearchTerm);
    };

    $scope.inviteUserPagerChanged = function (_event, page) {
      $scope.inviteUserPage.options.page = page;
      $scope.inviteeSearch($scope.saveSearchTerm);
    };

    $scope.inviteTeamPagerChanged = function (_event, page) {
      $scope.inviteTeamPage.options.page = page;
      $scope.initInviteTeams();
    };

    $scope.newInviteUserSearch = function () {
      $scope.inviteUserPage.options.page = 1; // this will trigger a double retrieve in some cases due to the $watch above
      $scope.inviteeSearch($scope.searchText.inviteUser);
      // $scope.initInviteUsers();
    };
    $scope.newInviteTeamSearch = function () {
      $scope.inviteTeamPage.options.page = 1; // this will trigger a double retrieve in some cases due to the $watch above
      $scope.initInviteTeams();
    };

    $scope.$watch('forms.checkboxAllTeams', function (_n, _o) {});

    $scope.forms.checkboxAllTeams = false;
    $scope.teamCheckboxAllChanged = function () {
      let i, team;
      $scope.forms.checkboxAllTeams = !$scope.forms.checkboxAllTeams;
      for (i = 0; i < $scope._inviteTeams.length; i++) {
        team = $scope._inviteTeams[i];
        team.selected = $scope.forms.checkboxAllTeams;

        if ($scope.forms.checkboxAllTeams) {
          $scope.teamsToInviteCheckboxVals[team._id] = true;
        } else {
          delete $scope.teamsToInviteCheckboxVals[team._id];
        }
      }
    };

    // exposed so we can get the array of currently selected values
    $scope.keys = Object.keys;

    // keep a map of checkbox vals maintained across pages
    $scope.teamsToInviteCheckboxVals = {};
    $scope.teamCheckboxChanged = function (team) {
      team.selected = !team.selected;
      if (team.selected) {
        $scope.teamsToInviteCheckboxVals[team._id] = true;
      } else {
        delete $scope.teamsToInviteCheckboxVals[team._id];
      }
    };

    $scope.userCheckboxAllChanged = function () {
      if ($scope.internalUserList) {
        for (let i = 0; i < $scope.internalUserList.length; i++) {
          const user = $scope.internalUserList[i];
          user.checkbox = $scope.forms.checkboxAll;

          if ($scope.forms.checkboxAll) {
            $scope.usersToInviteCheckboxVals[user._id] = true;
          } else {
            delete $scope.usersToInviteCheckboxVals[user._id];
          }
        }
      }
    };

    // keep a map of checkbox vals maintained across pages
    $scope.usersToInviteCheckboxVals = {};
    $scope.userCheckboxChanged = function (user) {
      if (user.checkbox) {
        $scope.usersToInviteCheckboxVals[user._id] = true;
      } else {
        delete $scope.usersToInviteCheckboxVals[user._id];
        $scope.forms.checkboxAll = false;
      }
    };

    // The number of external user emails to show before showing the "Show All" toggle
    $scope.maxExternalUsersToShow = 20;

    // Compute external users list when searches are made
    $scope.externalUsers = function () {
      return _.filter($scope.allInvitees, function (u) {
        return !u._id;
      }).reverse();
    };

    // ========================================================================
    // This method invites all _targets_ set in $scope.allInvitees without
    // discrimination on wether they are internal or external users.
    // If users are internal, _id will be sent in the service message,
    // otherwise the email will be sent.
    // ========================================================================
    $scope.inviteAllTargets = function () {
      const targets = [];
      let idx;
      for (idx in $scope.allInvitees) {
        const target = $scope.allInvitees[idx];
        targets.push(target._id || target.email);
      }

      delete $scope.inviteData.languageFramework.categoryType;

      function _invite() {
        $rootScope.disableButton('assessmentInviteInternalUser');
        $translate([
          'OK',
          'INVITATIONS_ARE_SCHEDULED',
          'PARTICIPANTS_WILL_RECEIVE_AN_INVITATION_TO_THIS_ASSESSMENT_SHORTLY',
          'INSUFFICIENT_CHALLENGES_FOR_ASSESSMENT_CRITERIA_CONTACT_SUPPORT',
          'ERROR_INVITING_USER',
        ]).then(function () {
          AssessmentsApiService.inviteTargets($stateParams.assessmentId, targets, $scope.inviteData.languageFramework)
            .then(function () {
              $swal({
                title: $translate.instant('INVITATIONS_ARE_SCHEDULED'),
                text: $translate.instant('PARTICIPANTS_WILL_RECEIVE_AN_INVITATION_TO_THIS_ASSESSMENT_SHORTLY'),
                type: 'success',
                html: false,
                confirmButtonText: $translate.instant('OK'),
                keyResolution: true,
              }).then(function () {
                $state.go('assessments.view', { assessmentId: $stateParams.assessmentId }, { reload: 1 });
              });
            })
            .catch(function (response) {
              if (response.status == 500) {
                ErrorHandler.addError(
                  $translate.instant('INSUFFICIENT_CHALLENGES_FOR_ASSESSMENT_CRITERIA_CONTACT_SUPPORT')
                );
              } else {
                ErrorHandler.addHttpError($translate.instant('ERROR_INVITING_USER'), response);
              }
            })
            .finally(function () {
              $rootScope.enableButton('assessmentInviteInternalUser');
            });
        });
      }

      if ($scope.allowances.available < targets.length) {
        $translate([
          'OK',
          'CANCEL',
          'COMPANY_LICENSE_EXCEEDED',
          'ABOUT_TO_TO_EXCEED_COMPANY_LICENSE_NOTIFICATION_WILL_BE_SENT_TO_COMPANY_ADMIN',
        ]).then(function (translations) {
          $swal({
            title: translations.COMPANY_LICENSE_EXCEEDED,
            text: translations.ABOUT_TO_TO_EXCEED_COMPANY_LICENSE_NOTIFICATION_WILL_BE_SENT_TO_COMPANY_ADMIN,
            type: 'info',
            showCancelButton: true,
            confirmButtonText: translations.OK,
            cancelButtonText: translations.CANCEL,
            keyResolution: true,
          }).then(function (isConfirm) {
            if (isConfirm) {
              _invite();
            }
          });
        });
      } else {
        _invite();
      }
    };

    $scope.inviteWithSearch = function (data) {
      const searchObj = {
        query: {
          ...(data.query.roles && { roles: data.query.roles }),
          status: data.query.status,
        },
        filter: data.term,
      };

      const total = data.total;

      const languages = [...$scope.inviteData.languageFramework];

      function _invite(searchObj, languages) {
        $rootScope.disableButton('assessmentInviteInternalUser');
        AssessmentsApiService.inviteTargetsWithSearch($stateParams.assessmentId, searchObj, languages)
          .then(function () {
            $swal({
              title: $translate.instant('INVITATIONS_ARE_SCHEDULED'),
              text: $translate.instant('PARTICIPANTS_WILL_RECEIVE_AN_INVITATION_TO_THIS_ASSESSMENT_SHORTLY'),
              type: 'success',
              html: false,
              confirmButtonText: $translate.instant('OK'),
              keyResolution: true,
            }).then(function () {
              $state.go('assessments.view', { assessmentId: $stateParams.assessmentId }, { reload: 1 });
            });
          })
          .catch(function (response) {
            if (response.status == 500) {
              ErrorHandler.addError(
                $translate.instant('INSUFFICIENT_CHALLENGES_FOR_ASSESSMENT_CRITERIA_CONTACT_SUPPORT')
              );
            } else {
              ErrorHandler.addHttpError($translate.instant('ERROR_INVITING_USER'), response);
            }
          })
          .finally(function () {
            $rootScope.enableButton('assessmentInviteInternalUser');
          });
      }

      if ($scope.allowances.available < total) {
        $swal({
          title: $translate.instant('COMPANY_LICENSE_EXCEEDED'),
          text: $translate.instant('ABOUT_TO_TO_EXCEED_COMPANY_LICENSE_NOTIFICATION_WILL_BE_SENT_TO_COMPANY_ADMIN'),
          type: 'info',
          showCancelButton: true,
          confirmButtonText: $translate.instant('OK'),
          cancelButtonText: $translate.instant('CANCEL'),
          keyResolution: true,
        }).then(function (isConfirm) {
          if (isConfirm) {
            _invite(searchObj, languages);
          }
        });
      } else {
        $swal({
          title: $translate.instant('Assessments.Management.Invite.WithSearch.Warning.title'),
          text: $translate.instant('Assessments.Management.Invite.WithSearch.Warning.text'),
          type: 'info',
          showCancelButton: true,
          confirmButtonText: $translate.instant('YES'),
          cancelButtonText: $translate.instant('NO'),
          keyResolution: true,
        }).then(function (isConfirm) {
          if (isConfirm) {
            _invite(searchObj, languages);
          }
        });
      }
    };

    // ============================================================
    // Perform the actual invite operation on :what with :params.
    // ============================================================
    $scope.doInvite = function (what, params) {
      $translate([
        'INVITE_TEAM_MESSAGE',
        'INVITE_TEAMS_MESSAGE',
        'INVITE_COMPANY_MESSAGE',
        'ARE_YOU_SURE',
        'INVITE',
        'CANCEL',
        'INVITATIONS_ARE_SCHEDULED',
        'PARTICIPANTS_WILL_RECEIVE_AN_INVITATION_TO_THIS_ASSESSMENT_SHORTLY',
        'OK',
        'ERROR_INVITING_USERS',
      ]).then(function (translations) {
        const message = {
          team: translations.INVITE_TEAM_MESSAGE,
          teams: translations.INVITE_TEAMS_MESSAGE,
          company: translations.INVITE_COMPANY_MESSAGE,
        };
        $swal({
          title: translations.ARE_YOU_SURE,
          text: message[what],
          type: 'warning',
          html: false,
          showCancelButton: true,
          confirmButtonColor: 'var(--color-scw-red)',
          confirmButtonText: translations.INVITE,
          cancelButtonText: translations.CANCEL,
          keyResolution: true,
        }).then(function (isConfirm) {
          if (isConfirm) {
            const options = { teams: 'inviteTeams', company: 'inviteCompany', team: 'inviteTeam' };
            const inviteFunction = AssessmentsApiService[options[what]];
            inviteFunction($stateParams.assessmentId, params, $scope.inviteData.languageFramework)
              .then(function () {
                swal(
                  {
                    title: translations.INVITATIONS_ARE_SCHEDULED,
                    text: translations.PARTICIPANTS_WILL_RECEIVE_AN_INVITATION_TO_THIS_ASSESSMENT_SHORTLY,
                    type: 'success',
                    html: false,
                    confirmButtonText: translations.OK,
                  },
                  function () {
                    $state.go('assessments.view', { assessmentId: $stateParams.assessmentId }, { reload: 1 });
                  }
                );
              })
              .catch(function (response) {
                ErrorHandler.addHttpError(translations.ERROR_INVITING_USERS, response);
              });
          }
        });
      });
    };

    $scope.inviteEntireCompany = function () {
      delete $scope.inviteData.languageFramework.categoryType;
      $scope.doInvite('company');
    };

    $scope.inviteEntireTeam = function () {
      delete $scope.inviteData.languageFramework.categoryType;
      $scope.doInvite('team');
    };

    $scope.inviteSelectedTeams = function () {
      const teamsToInviteArray = Object.keys($scope.teamsToInviteCheckboxVals);

      if (teamsToInviteArray.length < 1) {
        $translate(['NO_TEAMS_SELECTED']).then(function (translations) {
          ErrorHandler.addError(translations.NO_TEAMS_SELECTED);
        });
        return;
      }

      delete $scope.inviteData.languageFramework.categoryType;

      $scope.doInvite('teams', teamsToInviteArray);
    };

    $scope.initLanguages = function () {
      $scope.languageFrameworkTypes = [];
      let i;
      for (i = 0; i < Session.user.properties.languages.length; i++) {
        const l = Session.user.properties.languages[i];
        if (l.status == 'available' || l.status == 'active') {
          $scope.languageFrameworkTypes.push(l.language);
        }
      }
      for (i = 0; i < $scope.languageFrameworkTypes.length; i++) {
        const lft = $scope.languageFrameworkTypes[i];
        lft.categoryType = $scope.metadata.languages[lft._id].framework[lft._framework].categoryType;
      }
    };

    $scope.initInviteTeams = function () {
      // fetch users that this user is able to invite
      let query;
      if (AuthService.isAuthorized([USER_ROLES.admin, USER_ROLES.reseller, USER_ROLES.companyAdmin])) {
        query = {
          _cid: $scope.assessment._owner,
        };
      }

      AdminApiService.addLoading();
      AdminApiService.getTeamList(
        query,
        $scope.searchText.inviteTeam,
        $scope.inviteTeamPage.options,
        $scope.sorting.sortTeamsByParam
      )
        .then(function (data) {
          $scope.inviteTeamPage.options = data;

          $scope.forms.checkboxAllTeams = true;
          data.data.forEach(function (team) {
            team.selected = $scope.teamsToInviteCheckboxVals[team._id];

            // initially set 'Select All' checkbox to ticked but if any team in the list is not selected, untick it
            if (!team.selected) {
              $scope.forms.checkboxAllTeams = false;
            }
          });

          $scope._inviteTeams = data.data;
        })
        .catch(function (response) {
          $translate(['ERROR_LOADING_USER_LIST']).then(function (translation) {
            ErrorHandler.addHttpError(translation.ERROR_LOADING_USER_LIST, response);
          });
        })
        .finally(function () {
          AdminApiService.removeLoading();
        });
    };

    $scope.getAssessmentPromise.then(function () {
      $scope.initLanguages();
      switch ($state.current.name) {
        case 'assessments.view.inviteTeams':
          $scope.initInviteTeams();
          break;
        case 'assessments.view.inviteCompany':
          break;
        case 'assessments.view.inviteTeam':
          break;
        default:
          $scope.newInviteUserSearch();
          break;
      }
    });
  },
]);

/****** view attempt ******/
app.controller('AssessmentsViewAttemptController', [
  '$scope',
  '$rootScope',
  '$state',
  '$stateParams',
  '$log',
  '$filter',
  '$window',
  'AuthService',
  'Session',
  'ErrorHandler',
  'AssessmentsApiService',
  '$translate',
  '$swal',
  'LmsIntegrationService',
  'USER_ROLES',
  function (
    $scope,
    $rootScope,
    $state,
    $stateParams,
    $log,
    $filter,
    $window,
    AuthService,
    Session,
    ErrorHandler,
    AssessmentsApiService,
    $translate,
    $swal,
    LmsIntegrationService,
    USER_ROLES
  ) {
    // Fallback due to controller-flow dependencies from previous version.
    // if (!$scope.getAssessmentPromise) {
    // 	angular.extend(this, $controller('AssessmentsViewController', {$scope: $scope}));
    // }

    const dsUser = mapDesignSystemUser(Session.user);

    $scope.designUplift = dsUser.role === USER_ROLES.player || !!$stateParams.ownResult;

    /**
     * user-accessible functions
     */
    $scope.takeAssessment = takeAssessment; // take the assessment
    $scope.now = Date.now; // utility funciton to access current timestamp
    $scope.status = attemptStatus;
    $scope.progress = attemptProgress;
    $scope.timeUsage = attemptTimeUsage;
    $scope.inferColour = inferColour;
    $scope.viewCategoryDetail = viewCategoryDetail;
    $scope.subcategoryName = resolveSubcategoryName;
    $scope.cblOutcome = cblOutcome;
    $scope.cblOutcomeTooltip = cblOutcomeTooltip;
    $scope.difficulty = difficulty;
    $scope.canView = canView;
    $scope.downloadCertificate = downloadCertificate;
    $scope.hasCertificateAvailable = hasCertificateAvailable;
    $scope.chooseLanguage = chooseLanguage;
    $scope.displayTime = $rootScope.utility.humanizeDuration;

    // function for making the bars the same colour - currently set to red
    // this is required as the color configuration for angular-charts doesn't
    // seem to work at all as documented
    $scope.getColor = function () {
      return {
        backgroundColor: 'rgba(236,124,1, 0.2)',
        pointBackgroundColor: 'rgba(236,124,1, 1)',
        pointHoverBackgroundColor: 'rgba(236,124,1, 0.8)',
        borderColor: 'rgba(236,124,1, 1)',
        pointBorderColor: '#fff',
        pointHoverBorderColor: 'rgba(236,124,1, 1)',
      };
    };

    /**
     * Writing a polyfill that meets the function needs for checking number
     * as Number.isNaN has issues on IE.
     * @param assessment
     * @returns {number}
     */
    $scope.determineAttemptsRemaining = function (assessment) {
      const { maxRetries, attempts } = assessment ?? { maxRetries: 0, attempts: 0 };
      return (maxRetries ?? 0) + 1 - (typeof attempts === 'number' && attempts === attempts ? attempts : 0);
    };

    $scope.getAttemptsLeft = function () {
      const attemptsLeft = $scope.determineAttemptsRemaining($scope.assessment);
      return attemptsLeft >= 0 ? attemptsLeft : 0;
    };

    function setAssessmentAttemptLimitsText() {
      if ($scope.assessment.retriesAllowed) {
        if ($scope.assessment.maxRetries > 0 && $scope.assessment.retryWaitingHours > 0) {
          $scope.assessment.attemptLimits = 'ASSESSMENTSVIEWATTEMPT_LIMITED_TIME_AND_ATTEMPTS';
        } else if ($scope.assessment.maxRetries > 0) {
          $scope.assessment.attemptLimits = 'ASSESSMENTSVIEWATTEMPT_LIMITED_ATTEMPTS';
        } else if ($scope.assessment.retryWaitingHours > 0) {
          $scope.assessment.attemptLimits = 'ASSESSMENTSVIEWATTEMPT_LIMITED_TIME';
        } else {
          $scope.assessment.attemptLimits = 'ASSESSMENTSVIEWATTEMPT_UNLIMITED_ATTEMPTS';
        }
      } else {
        $scope.assessment.attemptLimits = 'ASSESSMENTSVIEWATTEMPT_SINGLE_ATTEMPT';
      }
    }

    /**
     * The attemptId is being passed as a filter, to filter the list of attempts returned just for the user. The
     * call would filter attempts on the assessments to done completed by the user associated with the attempt
     * @param assessmentId
     * @param attemptId
     */
    $scope.getCompletedAttemptsCount = function (assessmentId, attemptId) {
      AssessmentsApiService.getAttemptsList(assessmentId, { mode: 'completed_all', attemptId }).then(
        function (attempts) {
          $scope.inviteCourseId = attempts._inviteCourse;
          $scope.inviteCourseName = attempts.inviteCourseName;
          $scope.assessment.attempts = Array.isArray(attempts?.data) ? attempts.total : 0;
          $scope.hasSucceededAttempt = (Array.isArray(attempts?.data) ? attempts.data : []).some((attempt) => {
            return attempt.score * 100 >= $scope.assessment.successRatio;
          });
        }
      );
    };

    function chooseLanguage(lang) {
      $scope.languageSelected = lang;
    }

    function hasCertificateAvailable() {
      return $scope.assessment && $scope.assessment.emitsCertificate && attemptStatus() == 'succeeded';
    }

    function canView() {
      const assessment = $scope.assessment;
      const attempt = $scope.attempt;

      if (!assessment || !attempt) {
        return false;
      }

      const authUser = $scope.Session.user._id;
      const attemptUser = attempt.user._id;
      const inconclusive = ['pending'].indexOf(attempt && attempt.status) > -1;

      return (
        assessment && // exists
        !inconclusive && // is not pending
        attempt && // exists
        attempt.started && // started or expired
        attempt.progress &&
        attempt.progress.completed > 0 && // enough progress or done
        !attempt.neverStarted && // started by player (started != completed)
        (attemptUser != authUser ||
          attempt.completed ||
          (attempt.assessment.endDate && attempt.assessment.endDate < Date.now()) ||
          (attempt.status == 'in_progress' && attempt.deadline && attempt.deadline < Date.now()))
      ); // not MY attempt OR the attempt is completed OR assessment is in progress but has expired OR in progress but past deadline
    }

    function cblOutcome(logEntry, mode) {
      const CBL = {
        L1: ['identify'],
        L2: ['locate'],
        L3: ['solve'],
        L4: ['identify', 'solve'],
        L5: ['locate', 'solve'],
      };
      const modeIdx = CBL[logEntry.cbl].indexOf(mode);
      if (modeIdx < 0) {
        return undefined;
      }

      return (((logEntry || {}).stages || {})[modeIdx] || {}).status || false;
    }

    function cblOutcomeTooltip(logEntry, mode) {
      const retVal = cblOutcome(logEntry, mode);

      if (logEntry.started) {
        switch (retVal) {
          case 'in_progress':
            return $translate.instant('IN_PROGRESS');
          case 'correct':
            return $translate.instant('CORRECT');
          case 'incorrect':
            return $translate.instant('INCORRECT');
          case 'out_of_reach':
            return $translate.instant('OUT_OF_TIME');
          default:
            return '';
        }
      }

      return '';
    }

    function viewCategoryDetail(element, evt) {
      $log.debug('Clicked on bar-chart element: ', element, evt);
      // Don't update the detailed info as there were no element selected
      if (!element || element.length < 1) {
        return;
      }

      let catidx;
      if ($scope.subcategory_detail) {
        $scope.$apply(function () {
          $scope.subcategory_detail = null;
        });
      }

      if (element && element[0]) {
        if (typeof element == 'string') {
          catidx = element;
        } else {
          catidx =
            $scope.attempt.performance.subcategoriesMap.hasOwnProperty(element[0]._model.label) &&
            $scope.attempt.performance.subcategoriesMap[element[0]._model.label];
        }
      }

      if (catidx) {
        const attempt = $scope.attempt;
        $scope.subcategory_detail = angular.extend(
          {
            label: resolveSubcategoryName(catidx, attempt.assessment),
          },
          attempt.metrics.subcategories[catidx]
        );
        if (!$scope.$$phase) {
          $scope.$apply(); // since sometimes triggered by event handler not Angular
        }
      }
    }

    function attemptTimeUsage() {
      if (!$scope.attempt || !$scope.assessment || !$scope.assessment.timeLimit) {
        return 'N/A';
      }

      const allowed = $scope.assessment.timeLimit;
      const used = ($scope.attempt.completed || Date.now()) - $scope.attempt.started;
      return Math.floor((1 - used / allowed) * 100);
    }

    function inferColour(value, inverse) {
      const colours = [
        '#E24648', // first color = red;
        '#ee8800', // second color = orange
        '#27a872', // third color = green;
      ];

      if (value == undefined) {
        return 'rgba(255,255,255,0.5)';
      }
      // if (value === 0) return ["#a00", "#a00"];
      // in 10 scales, bring down to percentaje
      while (value > 1) {
        value /= 10;
      }

      let idx = Math.floor(value / 0.35);
      if (inverse) {
        idx = colours.length - 1 - idx;
      }
      return colours[idx];
    }

    function attemptProgress() {
      if (!$scope.attempt || !$scope.assessment) {
        return 0;
      }

      let completed = 0;
      let total = 0;

      $scope.attempt.log.forEach(function (entry) {
        entry.stages.forEach(function (stage) {
          if (stage.status == 'correct' || stage.status == 'incorrect') {
            completed++;
          }

          total++;
        });

        // This hack is to get correct stats for 'completion' when the challenge is incomplete.
        // This needs to be done because when user starts a particular challenge in the assessment
        // attempt all the stages for the challenge are deleted from attempt.log and as the user
        // progresses through each stage that stage is added to attempt.log. So if the challenge
        // was left incomplete with the user still at first stage, there will be no entry for
        // second stage in attempt.log.
        // Thus, this minor workaround will add 1 more stage to the total if there is only 1 stage
        // in attempt.log for that challenge.
        if (entry.stages.length == 1) {
          total++;
          entry.stages.push({
            status: 'out_of_reach',
            points: 0,
            started: null,
          });
        }
      });

      return Math.floor((completed / total) * 100);
    }

    function attemptStatus() {
      if (!$scope.attempt || !$scope.assessment) {
        return null;
      }

      let status = 'pending';
      if ($scope.attempt.started) {
        status = 'in-progress';
        if ($scope.attempt.completed) {
          status = 'completed';
          const abandoned = $scope.attempt.progress?.statuses?.includes('abandoned');
          if ($scope.assessment.successRatio || abandoned) {
            status = 'failed';
            if ($scope.attempt.successRatio >= $scope.assessment.successRatio && !abandoned) {
              status = 'succeeded';
            }
          }
        }
      }
      return status;
    }

    $scope.shouldShowRetakeTooltip = function () {
      const canRetake = $scope.assessment && $scope.assessment.retriesAllowed && $scope.getAttemptsLeft() > 0;
      // While one can retake an assessment when they already have a succeeded attempt, we probably shouldn't show
      // the tooltip to encourage them to actually do that.
      const hasNoSucceededAttempt = $scope.hasSucceededAttempt === false;
      return canRetake && attemptStatus() === 'failed' && hasNoSucceededAttempt;
      // not checking assessment time limit/deadline, user will see that when they would actually try to retake
    };

    $scope.shouldShowRetryWaitingTimeInfo = function () {
      const a = $scope.assessment;
      const hasWaitingHours = a && a.retriesAllowed && a.retryWaitingHours > 0;
      return hasWaitingHours && $scope.getAttemptsLeft() > 0 && attemptStatus() === 'failed';
    };

    /**
     * Take an assessment (using authenticated user).
     * @return {[type]} [description]
     */
    function takeAssessment() {
      const assessment = $scope.assessment;
      const attempt = $scope.attempt;
      const languages = $scope.languages;
      const languageSelected = $scope.languageSelected;

      if (attempt.language) {
        if (AuthService.isFeatureEnabled('assessments') || attempt._user == Session.user._id + '') {
          $state.go('assessments.take', {
            _assessment: assessment._id,
            _attempt: attempt._id,
          });
        } else {
          $state.go('assessments-not-licensed');
        }
      } else {
        if (languages && languages.length) {
          AssessmentsApiService.setAssessmentAttemptLanguage(assessment._id, attempt._id, languageSelected)
            .then(function (attempt) {
              if (AuthService.isFeatureEnabled('assessments') || attempt._user == Session.user._id + '') {
                $state.go('assessments.take', {
                  _assessment: assessment._id,
                  _attempt: attempt._id,
                });
              } else {
                $state.go('assessments-not-licensed');
              }
            })
            .catch(function (response) {
              $translate(['ERROR_UPDATING_ASSESSMENT_ATTEMPT']).then(function (translations) {
                ErrorHandler.addHttpError(translations.ERROR_UPDATING_ASSESSMENT_ATTEMPT, response);
                $state.go('assessments.view', $stateParams);
              });
            });
        } else {
          $translate(['NO_LANGUAGE_ACCESSIBLE_TO_TEAM']).then(function (translations) {
            ErrorHandler.addHttpError(translations.NO_LANGUAGE_ACCESSIBLE_TO_TEAM);
            $state.go('assessments.view', $stateParams);
          });
        }
      }
    }

    /**
     * Return the category type for a language
     * @param {Object} language
     * @return {String} category type or null if not found
     */
    function resolveLanguageCategoryType(resolveMe) {
      const language = $scope.metadata.languages[resolveMe._id];
      if (!language) {
        return null;
      }

      const framework = language.framework[resolveMe._framework];
      if (!framework) {
        return null;
      }

      return framework.categoryType;
    }

    function difficulty(logEntry) {
      const difficulty = logEntry.challenge.difficulty;

      return difficulty <= 33 ? 'Easy' : difficulty <= 66 ? 'Medium' : 'Hard';
    }

    /**
     * Download this attempt certificate.
     * @param  {[type]} assessment [description]
     * @param  {[type]} attempt    [description]
     * @return {[type]}            [description]
     */
    function downloadCertificate(assessment, attempt) {
      $translate([
        'ERROR_RETRIEVING_CERTIFICATE',
        'GENERATING_CERTIFICATE',
        'PLEASE_GIVE_SOME_TIME_TO_GENERATE_CERTIFICATE',
      ]).then(function (translations) {
        let _assessment, _attempt;
        _assessment = assessment._id || assessment;
        _attempt = attempt._id || attempt;
        $log.debug('Requesting assessment certificate for ', _assessment, _attempt);

        AssessmentsApiService.addLoading();
        AssessmentsApiService.downloadAssessmentCertificate(_assessment, _attempt)
          .then(function (R) {
            downloadPDF(R.body.url);
          })
          .catch(function (response) {
            ErrorHandler.addHttpError(translations.ERROR_RETRIEVING_CERTIFICATE, response);
          })
          .finally(function () {
            AssessmentsApiService.removeLoading();
          });

        $swal({
          title: translations.GENERATING_CERTIFICATE,
          text: translations.PLEASE_GIVE_SOME_TIME_TO_GENERATE_CERTIFICATE,
          type: 'info',
          showCancelButton: false,
          showConfirmButton: false,
          keyResolution: true,
        });
      });
    }

    function downloadPDF(content) {
      const isIE = /Trident|MSIE/.test(window.navigator.userAgent);
      $translate(
        [
          'PDF_CERTIFICATE_READY',
          'ERROR_RETRIEVING_CERTIFICATE',
          'PDF_CERTIFICATE_READY_DESC_WITH_SWAL_CLOSE_BUTTON_SAVE_TARGET_AS',
        ],
        { content: content }
      ).then(function (translations) {
        if (window.navigator.msSaveBlob && isIE) {
          // typical IE - freaks out and redirects instantly to about:blank if we try to do this in same tab, cancelling the download
          $swal({
            title: translations.PDF_CERTIFICATE_READY,
            text: translations.PDF_CERTIFICATE_READY_DESC_WITH_SWAL_CLOSE_BUTTON_SAVE_TARGET_AS,
            type: 'success',
            html: true,
            showCancelButton: false,
            showConfirmButton: false,
            keyResolution: true,
          });
        } else {
          document.location.href = content;
          swal.close();
        }
      });
    }

    /**
     * Return a subcategory name from metadata
     * @param  {String} subcat in cat$subcat format
     * @return {String}
     */
    function resolveSubcategoryName(subcat, assessment) {
      if (!assessment) {
        assessment = $scope.assessment;
      }
      if (typeof subcat == 'object') {
        subcat = subcat._id + '$' + subcat._sub;
      }

      const parts = subcat.split('$');
      let type = assessment.categoryType;

      if (!type) {
        // compatability with PORTAL-729 where assessments no longer have categoryType
        // Select category type using the first language
        type = resolveLanguageCategoryType(assessment.languages[0]);
      }

      const category = $rootScope.metadata.categories[type][parts[0]];
      const subcategory = category.subitem[parts[1]];

      return subcategory.name;
    }

    /**
     * Iniialize this controller
     * @return {[type]} [description]
     */
    (function init() {
      $scope.attemptPromise = new Promise((resolve, reject) => {
        AssessmentsApiService.getAssessmentData($stateParams.assessmentId, null, null)
          .then(function (data) {
            AuthService.refreshUser().then(function (refreshedData) {
              Session.updateUser(refreshedData);

              $log.debug('Got data for ASSESSMENT ' + $stateParams.assessmentId, data);
              data.metrics = data.metrics || {};
              data.metrics.average_score = $rootScope.utility.floorTwoDecimalPlacesValue(data.metrics.average_score);
              data.metrics.average_score_display = $filter('number')(data.metrics.average_score);
              $scope.assessment = data;
              $scope.getCompletedAttemptsCount($scope.assessment._id, $stateParams.attemptId);
              $scope.languages = $scope.languages || data.languages;
              // Filter attempt languages depending upon enabled languages for team
              if ($scope.Session.user.properties._tid) {
                const teamLanguages = $scope.Session.user.properties.team.languages;
                $scope.teamLanguages = teamLanguages;
                if ($scope.teamLanguages && $scope.teamLanguages.length) {
                  $scope.languages = _.filter($scope.languages, function (language) {
                    return teamLanguages.some(function (teamLanguage) {
                      return teamLanguage._id == language._id && teamLanguage._framework == language._framework;
                    });
                  });
                }
              }

              if ($stateParams.publicNotStarted === 'false' || !$stateParams.publicNotStarted) {
                AssessmentsApiService.getAssessmentAttempt($stateParams.assessmentId, $stateParams.attemptId)
                  .then(function (attempt) {
                    if (!$scope.assessment) {
                      $scope.assessment = attempt.assessment;
                    }

                    $scope.isAttemptUser = Session.user._id === attempt._user;

                    if (attempt.language && attempt.language._framework && attempt.language._id) {
                      $rootScope.assessments_language = {
                        _language: attempt.language._id,
                        _framework: attempt.language._framework,
                      };
                    }

                    if (
                      !attempt.language &&
                      $scope.languages &&
                      $scope.languages.length === 1 &&
                      $scope.isAttemptUser
                    ) {
                      const selectLanguage = $scope.languages[0];
                      AssessmentsApiService.setAssessmentAttemptLanguage(
                        $stateParams.assessmentId,
                        $stateParams.attemptId,
                        selectLanguage
                      )
                        .then(function () {
                          $state.go(
                            'assessments.view',
                            {
                              assessmentId: $stateParams.assessmentId,
                            },
                            { reload: true }
                          );
                        })
                        .catch(function (response) {
                          reject(response);
                          $translate(['ERROR_UPDATING_ASSESSMENT_ATTEMPT']).then(function (translations) {
                            ErrorHandler.addHttpError(translations.ERROR_UPDATING_ASSESSMENT_ATTEMPT, response);
                            $state.go('assessments.view', $stateParams);
                          });
                        });
                    } else {
                      $log.debug('Got data for ATTEMPT  ' + $stateParams.attemptId, attempt);
                      if (!attempt.successRatio && attempt.points.earned) {
                        attempt.successRatio = $scope.utility.floorTwoDecimalPlaces(
                          attempt.points.earned,
                          attempt.points.max
                        ); // PORTAL-2875 - Fix attempt scoring rounding errors
                      }
                      $log.debug('Got assessment attempt data: ', attempt);

                      const overrides = [{ options: { maintainAspectRatio: false } }];
                      Chart.defaults.global.defaultFontColor = '#c8c8c8';

                      const options = {
                        scales: {
                          xAxes: [
                            {
                              ticks: {
                                autoSkip: false,
                                maxRotation: 25,
                                minRotation: 25,
                              },
                            },
                          ],
                          yAxes: [
                            {
                              ticks: {
                                beginAtZero: true,
                                userCallback: function (value, _index, _values) {
                                  return $filter('number')(value, 1);
                                },
                              },
                            },
                          ],
                        },
                        layout: {
                          padding: {
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 10,
                          },
                        },
                      };

                      const subcategories = {
                        data: [],
                        series: ['Performance'],
                        labels: [],
                        colors: [], // colours for angular-charts doesn't seem to be working
                        overrides: overrides,
                        options: options,
                      };
                      const subcategoriesMap = {};
                      let _subcat, subcategory, value;

                      attempt.metrics = attempt.metrics || {}; // PORTAL-1994 - fixes error condition when user has not started any challenge stage yet so the metrics property does not exist

                      let initialCategoryDetailSet = false;
                      let initialCategoryDetail = null;
                      for (_subcat in attempt.metrics.subcategories) {
                        subcategory = attempt.metrics.subcategories[_subcat];
                        value = subcategory.success;

                        if (Array.isArray(value) && value.length === 0) {
                          value = 0;
                        }

                        if (!isNaN(value)) {
                          if (!initialCategoryDetailSet && value > 0) {
                            initialCategoryDetailSet = true;
                            initialCategoryDetail = _subcat;
                          }

                          subcategories.data.push(value);

                          const _label = resolveSubcategoryName(_subcat, attempt.assessment);
                          subcategories.labels.push(_label);

                          if (!subcategoriesMap.hasOwnProperty(_subcat)) {
                            subcategoriesMap[_label] = _subcat;
                          }
                        }
                      }

                      attempt.performance = {
                        subcategories: subcategories,
                        subcategoriesMap: subcategoriesMap,
                      };

                      attempt.remainingTime =
                        attempt.assessment.timeLimit - ((attempt.completed || Date.now()) - attempt.started);
                      attempt.neverStarted = attempt.started == attempt.completed;

                      let logidx;
                      for (logidx in attempt.log) {
                        const log = attempt.log[logidx];
                        if (log.stages) {
                          log.points = 0;
                          let stageidx;
                          for (stageidx in log.stages) {
                            log.points += log.stages[stageidx].points;
                          }
                        }
                      }

                      $scope.attempt = attempt;
                      setAssessmentAttemptLimitsText();
                      $scope.languages = $scope.languages || attempt.assessment.languages;
                      resolve(attempt);

                      // assign an initial subcategory metric detail
                      if (initialCategoryDetail) {
                        $scope.viewCategoryDetail(initialCategoryDetail);
                      }
                    }
                  })
                  .catch(function (response) {
                    reject(response);
                    $translate([
                      'UNABLE_TO_LOAD_REQUESTED_ASSESSMENT_ATTEMPT_CONTACT_ASSESSMENT_HOST',
                      'ERROR_LOADING_ASSESSMENT_ATTEMPT',
                    ]).then(function (translations) {
                      if (response.status == 404) {
                        ErrorHandler.addError(
                          translations.UNABLE_TO_LOAD_REQUESTED_ASSESSMENT_ATTEMPT_CONTACT_ASSESSMENT_HOST
                        );
                      } else {
                        ErrorHandler.addHttpError(translations.ERROR_LOADING_ASSESSMENT_ATTEMPT, response);
                        $state.go('assessments.view', $stateParams);
                        reject(response);
                      }
                    });
                  }); // getAssessmentAttempt
              } else {
                AssessmentsApiService.createAttempt($stateParams.assessmentId, null, null, null)
                  .then(function (attempt) {
                    resolve(attempt); // TODO not really needed
                    $state.go('assessments.view.attempt', {
                      assessmentId: attempt._assessment,
                      attemptId: attempt._id,
                      publicNotStarted: false,
                    });
                  })
                  .catch(function (response) {
                    $translate([
                      'UNABLE_TO_LOAD_REQUESTED_ASSESSMENT_ATTEMPT_CONTACT_ASSESSMENT_HOST',
                      'ERROR_LOADING_ASSESSMENT_ATTEMPT',
                    ]).then(function (translations) {
                      if (response.status == 404) {
                        ErrorHandler.addError(
                          translations.UNABLE_TO_LOAD_REQUESTED_ASSESSMENT_ATTEMPT_CONTACT_ASSESSMENT_HOST
                        );
                        reject(response);
                      } else {
                        ErrorHandler.addHttpError(translations.ERROR_LOADING_ASSESSMENT_ATTEMPT, response);
                        $state.go('assessments.view', $stateParams);
                      }
                    });
                  }); // createAttempt
              }
            }); // refreshUser
          })
          .catch(function (response) {
            $translate(['ERROR_RETRIEVING_ASSESSMENT']).then(function (translations) {
              ErrorHandler.addHttpError(translations.ERROR_RETRIEVING_ASSESSMENT, response);
              $state.go('assessments.view', $stateParams);
            });
          });
      });
    })();

    $scope.$watch('attempt', () => {
      if (!$scope.attempt) {
        return;
      }

      if ($scope.attempt.status !== 'done') {
        return;
      }
      if ($scope.assessment.lmsIntegrated && LmsIntegrationService.isOpenedByLms()) {
        $window.CancelLmsWatchdogInterval();
        LmsIntegrationService.sendMessage({
          id: getLMSIdentifier(),
          state: 'END',
          points: $scope.attempt.points,
          successRatio: $scope.assessment.successRatio,
        });

        $translate(['ASSESSMENTSVIEW_LMS_CLOSE_WINDOW', 'ASSESSMENTSVIEW_LMS_CLOSE_WINDOW_DESCRIPTION', 'OK']).then(
          function (translations) {
            $swal({
              title: translations.ASSESSMENTSVIEW_LMS_CLOSE_WINDOW,
              text: translations.ASSESSMENTSVIEW_LMS_CLOSE_WINDOW_DESCRIPTION,
              type: 'info',
              html: true,
              showCancelButton: false,
              showConfirmButton: true,
              keyResolution: true,
              confirmButtonText: translations.OK,
            });
          }
        );
      }
    });

    const shouldCheckLmsOpener = () => {
      //Company admins & Team admins should be able to check the attempts of their company/team members
      if ($scope.Session.user._id !== $scope.attempt.user._id) {
        return false;
      }
      //Everyone should be able to view their done attempts
      if ($scope.attempt.status === 'done') {
        return false;
      }

      return true;
    };

    const getLMSIdentifier = () => {
      return $window.sessionStorage.getItem('lms_course_id')
        ? $window.sessionStorage.getItem('lms_course_id')
        : $scope.assessment._id;
    };

    $scope.lmsCheckOk = () => {
      if (shouldCheckLmsOpener() && $scope.assessment.lmsIntegrated && !LmsIntegrationService.isOpenedByLms()) {
        return false;
      }
      return true;
    };
  },
]);
