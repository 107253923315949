import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Typography, Stack } from '@mui/material';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import { CheckCircleRounded as SuccessIcon } from '@mui/icons-material';
import { CloseIconButton } from '../../../../../common/components/atoms/CloseIconButton';
import RoleDropdown from '../../../../../common/components/molecules/RoleDropdown';
import { UserManagementModal } from './UserManagementModal';
import { DialogSection } from '../../../../common/Dialog';
import { BulkActionsAsyncDialog } from './BulkActionsAsyncDialog';
const BulkChangeRolePending = ({ onConfirm, onClose, userCount, role, setRole, }) => {
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.userManagement' });
    return (_jsxs(_Fragment, { children: [_jsxs(DialogSection, { sx: { gap: 5 }, children: [_jsx(CloseIconButton, { onClick: onClose, sx: {
                            position: 'absolute',
                            background: 'transparent',
                            top: (theme) => theme.spacing(3),
                            right: (theme) => theme.spacing(3),
                            '&:hover': {
                                background: 'transparent',
                            },
                        } }), _jsx(Typography, { variant: "h3", children: t('changeRolesDialog.title', { count: userCount }) }), _jsx(Typography, { children: t('changeRolesDialog.description') }), _jsxs(Stack, { gap: 1, children: [_jsx(RoleDropdown, { role: role, onRoleChange: (role) => setRole(role), includeCompanyAdmin: false }), _jsx(Typography, { variant: "caption", children: t('changeRolesDialog.helperText') })] })] }), _jsx(DialogSection, { footer: true, children: _jsxs(Stack, { direction: "row", alignItems: "center", justifyContent: "space-between", children: [_jsx(Button, { onClick: onClose, children: t('common.cancel') }), _jsx(Button, { variant: "contained", onClick: () => {
                                if (!role)
                                    return;
                                onConfirm({ selectedAction: 'changeRole', role });
                            }, children: t('changeRolesDialog.confirm', { count: userCount }) })] }) })] }));
};
const BulkChangeRoleSuccess = ({ onClose, userCount, updatedCount }) => {
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.userManagement' });
    const skippedCount = userCount - (updatedCount !== null && updatedCount !== void 0 ? updatedCount : 0);
    return (_jsx(UserManagementModal, { title: t('changeRolesDialog.successTitle', { count: updatedCount }), icon: _jsx(SuccessIcon, { sx: { fontSize: '56px', color: (theme) => theme.palette.text.status.success } }), footer: _jsx(Stack, { direction: "row", justifyContent: "flex-end", children: _jsx(Button, { variant: "contained", onClick: onClose, children: t('common.okay') }) }), children: _jsx(Stack, { children: skippedCount > 0 && (_jsx(Typography, { textAlign: "center", children: t('changeRolesDialog.isCompanyAdmin', {
                    count: skippedCount,
                }) })) }) }));
};
export const ChangeRolesDialog = ({ onClose, onConfirm, userCount, updatedCount, selectedUserIds, goneAsync, }) => {
    const [role, setRole] = useState(undefined);
    return goneAsync ? (_jsx(BulkActionsAsyncDialog, { onClose: onClose })) : (updatedCount || updatedCount === 0) && role ? (_jsx(BulkChangeRoleSuccess, { onClose: onClose, userCount: userCount, updatedCount: updatedCount, selectedUserIds: selectedUserIds })) : (_jsx(BulkChangeRolePending, { onClose: onClose, onConfirm: onConfirm, userCount: userCount, role: role, setRole: setRole }));
};
