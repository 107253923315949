import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useRef } from 'react';
import { TextField, useTheme } from '@mui/material';
import EmailPill from '../../atoms/EmailPill/EmailPill';
import { useTranslation } from 'react-i18next';
import { validateEmail } from '../../../../portal-frontend/components/emailValidation';
import { StyledContainer, StyledInputWrapper, Title } from './EmailInput.styled';
const EmailInput = ({ initialEmails = [], disabled = false, style, onChange }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const textFieldRef = useRef(null);
    const [emails, setEmails] = useState([...new Set(initialEmails)]);
    const [inputValue, setInputValue] = useState('');
    const [selectAll, setSelectAll] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [emailsIssue, setEmailsIssue] = useState(false);
    const processEmails = (input) => {
        const parts = input.split(/[\s,]+/g);
        const validEmailsSet = new Set(emails);
        const validEmails = [];
        const invalidEmails = [];
        parts.forEach((email) => {
            const trimmedEmail = email.trim();
            if (!trimmedEmail) {
                return;
            }
            if (validateEmail(trimmedEmail)) {
                validEmailsSet.add(trimmedEmail);
            }
            else {
                invalidEmails.push(trimmedEmail);
            }
        });
        validEmails.push(...validEmailsSet);
        return { validEmails, invalidEmails };
    };
    const handleInputChange = (e) => {
        const { value } = e.target;
        setInputValue(value);
        setSelectedIndex(null);
        setSelectAll(false);
        if (!disabled && (value.includes(',') || value.includes(' '))) {
            const { validEmails, invalidEmails } = processEmails(value);
            if (validEmails.length > 0) {
                const updatedEmails = Array.from(new Set([...emails, ...validEmails]));
                setEmails(updatedEmails);
                onChange === null || onChange === void 0 ? void 0 : onChange(updatedEmails);
            }
            setInputValue(invalidEmails.join(', '));
        }
    };
    const handleSelectAll = () => {
        setSelectAll(true);
        setSelectedIndex(null);
        if (textFieldRef.current) {
            textFieldRef.current.focus();
            textFieldRef.current.setSelectionRange(0, inputValue.length);
        }
    };
    const handleDeleteAll = () => {
        setEmails([]);
        setInputValue('');
        onChange === null || onChange === void 0 ? void 0 : onChange([]);
        setSelectAll(false);
        setSelectedIndex(null);
    };
    const handleSubmitEmails = () => {
        const { validEmails, invalidEmails } = processEmails(inputValue);
        if (validEmails.length > 0) {
            const updatedEmails = Array.from(new Set([...emails, ...validEmails]));
            setEmails(updatedEmails);
            onChange === null || onChange === void 0 ? void 0 : onChange(updatedEmails);
        }
        setInputValue(invalidEmails.join(', '));
    };
    const handleSelectAllKey = (e) => {
        if (e.key.toLowerCase() === 'a' && (e.ctrlKey || e.metaKey)) {
            e.preventDefault();
            handleSelectAll();
            return true;
        }
        return false;
    };
    const handleDeleteOrBackspaceKey = (e) => {
        if ((e.key === 'Delete' || e.key === 'Backspace') && selectAll) {
            e.preventDefault();
            handleDeleteAll();
            return true;
        }
        return false;
    };
    const handleBackspaceWithoutInput = (e) => {
        if (e.key === 'Backspace' && !inputValue && !selectAll) {
            if (selectedIndex === null && emails.length > 0) {
                setSelectedIndex(emails.length - 1);
            }
            else if (selectedIndex !== null) {
                const updatedEmails = emails.filter((_, index) => index !== selectedIndex);
                setEmails(updatedEmails);
                onChange === null || onChange === void 0 ? void 0 : onChange(updatedEmails);
                setSelectedIndex(updatedEmails.length > 0 ? Math.max(0, selectedIndex - 1) : null);
            }
            e.preventDefault();
            return true;
        }
        return false;
    };
    const handleArrowKeys = (e) => {
        if (!inputValue) {
            const isNotEmpty = emails.length > 0;
            const FIRST_INDEX = 0;
            const LAST_INDEX = emails.length - 1;
            const updateSelectedIndex = (newIndex) => {
                if (isNotEmpty && newIndex !== null) {
                    setSelectedIndex(newIndex);
                }
            };
            const keyActions = {
                ArrowUp: () => updateSelectedIndex(FIRST_INDEX),
                ArrowDown: () => updateSelectedIndex(LAST_INDEX),
                ArrowLeft: () => updateSelectedIndex(selectedIndex !== null && selectedIndex > FIRST_INDEX ? selectedIndex - 1 : selectedIndex),
                ArrowRight: () => updateSelectedIndex(selectedIndex !== null && selectedIndex < LAST_INDEX ? selectedIndex + 1 : selectedIndex),
            };
            if (keyActions[e.key]) {
                keyActions[e.key]();
                e.preventDefault();
                return true;
            }
        }
        return false;
    };
    const handleSubmitEmailsKey = (e) => {
        if (e.key === 'Tab' && !disabled) {
            if (inputValue.trim()) {
                const { validEmails } = processEmails(inputValue);
                if (validEmails.length > 0) {
                    e.preventDefault();
                    handleSubmitEmails();
                    return true;
                }
            }
            return false;
        }
        if (['Enter', ',', ' '].includes(e.key) && !disabled) {
            e.preventDefault();
            handleSubmitEmails();
            return true;
        }
        return false;
    };
    const handleKeyDown = (e) => {
        if ((inputValue && (e.key === 'ArrowLeft' || e.key === 'ArrowRight')) ||
            handleSelectAllKey(e) ||
            handleDeleteOrBackspaceKey(e) ||
            handleBackspaceWithoutInput(e) ||
            handleArrowKeys(e) ||
            handleSubmitEmailsKey(e)) {
            return;
        }
    };
    const handleDelete = (emailToDelete) => {
        const updatedEmails = emails.filter((email) => email !== emailToDelete);
        setEmails(updatedEmails);
        onChange === null || onChange === void 0 ? void 0 : onChange(updatedEmails);
        setSelectAll(false);
        setSelectedIndex(null);
    };
    const handleDeselectAll = (event) => {
        if (textFieldRef.current && textFieldRef.current.contains(event.target)) {
            return;
        }
        setSelectAll(false);
        setSelectedIndex(null);
        if (textFieldRef.current) {
            textFieldRef.current.focus();
            textFieldRef.current.setSelectionRange(inputValue.length, inputValue.length);
        }
    };
    const handleBlur = () => {
        if (!disabled && inputValue.trim()) {
            handleSubmitEmails();
        }
    };
    const handleEmailBlur = () => {
        // Check for empty usernames
        if (emails.length > 0) {
            setEmailsIssue(false);
        }
        else {
            setEmailsIssue(true);
        }
    };
    return (_jsxs(StyledContainer, { "data-disabled": disabled, style: style, onClick: handleDeselectAll, onBlur: handleEmailBlur, sx: {
            borderColor: emailsIssue ? (theme) => theme.palette.text.status.error : '',
        }, children: [_jsxs(Title, { children: [t('components.molecules.emailInput.title'), _jsx("span", { style: { color: 'red' }, children: "*" }), ":"] }), _jsxs(StyledInputWrapper, { children: [emails.map((email, index) => (_jsx(EmailPill, { email: email, onDelete: handleDelete, disabled: disabled, selected: selectAll || selectedIndex === index }, email))), _jsx(TextField, { value: inputValue, onChange: handleInputChange, onKeyDown: handleKeyDown, onBlur: handleBlur, inputRef: textFieldRef, variant: "outlined", fullWidth: false, inputProps: {
                            sx: {
                                padding: theme.spacing(1, 0),
                            },
                        }, sx: {
                            flex: '1 1 auto',
                            minWidth: '120px',
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    border: 'none',
                                },
                            },
                        }, disabled: disabled })] })] }));
};
export default EmailInput;
