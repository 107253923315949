export const statusKeys = ['all', 'active', 'scheduled', 'finished', 'archived', 'draft'];
export const questStatusToBadgeColorMap = {
    active: 'pink',
    scheduled: 'gold',
    finished: 'blue',
    archived: 'default',
    draft: 'default',
};
export const questsAdminUrls = {
    listPage: `/admin/quests`,
    createPage: `/admin/quests/create`,
    createLmsPage: `/admin/quests/create/lms`,
    detailsPage: (questId) => `/admin/quests/${questId}`,
};
