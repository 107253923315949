import { badgeImageData } from './staticContent';
import { COMPLETED_LEARNERS, EXCLUDED_LEARNERS, IN_PROGRESS_LEARNERS, MATCHED_LEARNERS, NOT_STARTED_LEARNERS, PENDING_LEARNERS, TOTAL_LEARNERS, USER_COUNT, userList, } from './users';
if (USER_COUNT !== TOTAL_LEARNERS) {
    throw new Error('USER_COUNT must equal to TOTAL_LEARNERS');
}
const questOne = {
    __typename: 'QuestDetails',
    badge: badgeImageData,
    // endDate should automatically update to be today + 6months
    endDate: new Date(Date.now() + 15778476000).toISOString(),
    isMandatory: true,
    name: 'Introductory Quest: Top 3 Vulnerabilities',
    objectiveCategories: [
        {
            __typename: 'CourseCategory',
            categoryDotSlug: 'web.access.functionlevel',
            vulnerabilityCategory: {
                __typename: 'VulnerabilityCategory',
                categoryName: 'Access Control',
                categorySlug: 'access',
                subcategoryName: 'Missing Function Level Access Control',
                subcategorySlug: 'functionlevel',
            },
        },
        {
            __typename: 'CourseCategory',
            categoryDotSlug: 'web.misconfig.infoexp',
            vulnerabilityCategory: {
                __typename: 'VulnerabilityCategory',
                categoryName: 'Security Misconfiguration',
                categorySlug: 'misconfig',
                subcategoryName: 'Information Exposure',
                subcategorySlug: 'infoexp',
            },
        },
        {
            __typename: 'CourseCategory',
            categoryDotSlug: 'web.xss.dom',
            vulnerabilityCategory: {
                __typename: 'VulnerabilityCategory',
                categoryName: 'Cross-Site Scripting (XSS)',
                categorySlug: 'xss',
                subcategoryName: 'DOM-Based Cross-Site Scripting',
                subcategorySlug: 'dom',
            },
        },
    ],
    objectiveKinds: ['most-common-vulnerabilities'],
    questRevisionNumber: 1,
    questSourceId: '551cc39a-9e0a-4101-b962-a6fd4ca00263',
    // startDate should automatically update to be today - 6months
    startDate: new Date(Date.now() - 15778476000).toISOString(),
    statistics: {
        __typename: undefined,
        // Potentially not used anymore and can be removed from graphql contract?
        categoryCompletionDenominator: 0,
        categoryCompletionNumerator: 0,
        completedLearners: COMPLETED_LEARNERS,
        excludedLearners: EXCLUDED_LEARNERS,
        inProgressLearners: IN_PROGRESS_LEARNERS,
        matchedLearners: MATCHED_LEARNERS,
        notStartedLearners: NOT_STARTED_LEARNERS,
        pendingLearners: PENDING_LEARNERS,
        // FE has 2 different questIds
        // not sure what this is used for, but the questId in the urlSearchParams uses the source id(UUID)
        questId: 1594,
        totalLearners: TOTAL_LEARNERS,
    },
    status: 'In progress',
    teamsAssigned: [],
};
const questDetails = {
    __typename: 'QuestDetailsConnection',
    edges: [
        {
            __typename: 'QuestDetailsEdge',
            cursor: '',
            node: questOne,
        },
    ],
    pageInfo: {
        __typename: 'PageInfo',
        endCursor: 'c2ltcGxlLWN1cnNvcjE=',
        hasNextPage: false,
        hasPreviousPage: false,
        startCursor: '',
    },
};
const questReportDetails = questOne;
export const buildAssignedLearners = () => {
    const assignedLearners = [];
    // fake the completed learners
    const completedLearnersList = userList.slice(0, COMPLETED_LEARNERS);
    for (const completedLearner of completedLearnersList) {
        const obj = {
            __typename: 'QuestObjectiveAssignedLearnerEdge',
            cursor: '',
        };
        const startDate = new Date(Date.now() - Math.floor(Math.random() * 15778476000)).toISOString();
        const totalTopics = Math.floor(Math.random() * 3) + 1;
        obj.node = Object.assign(Object.assign({}, completedLearner), { __typename: 'QuestObjectiveAssignedLearner', completedDate: new Date(Math.min(Date.now(), new Date(startDate).getTime() + Math.floor(Math.random() * (15778476 - 604800000 + 1)) + 604800000)).toISOString(), completedTopics: totalTopics, questProgress: 100, questStatus: 'Completed', startDate: startDate, totalTopics: totalTopics });
        assignedLearners.push(obj);
    }
    // fake the in progress learners
    const inProgressLearnersList = userList.slice(COMPLETED_LEARNERS, COMPLETED_LEARNERS + IN_PROGRESS_LEARNERS);
    for (const inProgressLearner of inProgressLearnersList) {
        const obj = {
            __typename: 'QuestObjectiveAssignedLearnerEdge',
            cursor: '',
        };
        const startDate = new Date(Date.now() - Math.floor(Math.random() * 15778476000)).toISOString();
        const totalTopics = Math.floor(Math.random() * 2) + 2; // 2 or 3
        const completedTopics = totalTopics - 1;
        obj.node = Object.assign(Object.assign({}, inProgressLearner), { __typename: 'QuestObjectiveAssignedLearner', completedDate: '', completedTopics: completedTopics, questProgress: Math.floor((completedTopics / totalTopics) * 100), questStatus: 'In progress', startDate: startDate, totalTopics: totalTopics });
        assignedLearners.push(obj);
    }
    // fake the not started learners
    const notStartedLearnersList = userList.slice(COMPLETED_LEARNERS + IN_PROGRESS_LEARNERS, COMPLETED_LEARNERS + IN_PROGRESS_LEARNERS + NOT_STARTED_LEARNERS);
    for (const notStartedLearner of notStartedLearnersList) {
        const obj = {
            __typename: 'QuestObjectiveAssignedLearnerEdge',
            cursor: '',
        };
        const startDate = '';
        const totalTopics = Math.floor(Math.random() * 3) + 1;
        const completedTopics = 0;
        obj.node = Object.assign(Object.assign({}, notStartedLearner), { __typename: 'QuestObjectiveAssignedLearner', completedDate: '', completedTopics: completedTopics, questProgress: 0, questStatus: 'Not started', startDate: startDate, totalTopics: totalTopics });
        assignedLearners.push(obj);
    }
    return assignedLearners;
};
export const buildPendingLearners = () => {
    const list = [];
    //fake the pending learners
    const pendingLearnersList = userList.slice(MATCHED_LEARNERS, MATCHED_LEARNERS + PENDING_LEARNERS);
    for (const pendingLearner of pendingLearnersList) {
        const obj = {
            __typename: 'QuestYetToSelectLanguageLearnerEdge',
            cursor: '',
        };
        obj.node = Object.assign(Object.assign({ __typename: 'QuestYetToSelectLanguageLearner' }, pendingLearner), { userStatus: 'enabled' });
        list.push(obj);
    }
    return list;
};
export const buildExcludedLearners = () => {
    const list = [];
    //fake the excluded learners
    const excludedLearnersList = userList.slice(MATCHED_LEARNERS + PENDING_LEARNERS, MATCHED_LEARNERS + PENDING_LEARNERS + EXCLUDED_LEARNERS);
    for (const excludedLearner of excludedLearnersList) {
        const obj = {
            __typename: 'QuestNoApplicableObjectivesLearnerEdge',
            cursor: '',
        };
        obj.node = Object.assign({ __typename: 'QuestNoApplicableObjectivesLearner' }, excludedLearner);
        list.push(obj);
    }
    return list;
};
const questLearners = {
    __typename: 'QuestLearners',
    noApplicableObjectivesLearners: {
        __typename: 'QuestNoApplicableObjectivesLearnerConnection',
        edges: buildExcludedLearners(),
        pageInfo: {
            __typename: 'PageInfo',
            endCursor: 'c2ltcGxlLWN1cnNvcjEx',
            hasNextPage: false,
            hasPreviousPage: false,
            startCursor: '',
        },
    },
    objectiveAssignedLearners: {
        __typename: 'QuestObjectiveAssignedLearnerConnection',
        edges: buildAssignedLearners(),
        pageInfo: {
            __typename: 'PageInfo',
            endCursor: 'c2ltcGxlLWN1cnNvcjEx',
            hasNextPage: false,
            hasPreviousPage: false,
            startCursor: '',
        },
    },
    yetToSelectLanguageLearners: {
        __typename: 'QuestYetToSelectLanguageLearnerConnection',
        edges: buildPendingLearners(),
        pageInfo: {
            __typename: 'PageInfo',
            endCursor: 'c2ltcGxlLWN1cnNvcjEx',
            hasNextPage: false,
            hasPreviousPage: false,
            startCursor: '',
        },
    },
};
const compl1 = {
    assignmentRate: 0,
    completionRate: 0,
    learnersCompleted: 0,
    learnersTotal: Math.floor(MATCHED_LEARNERS * (0.6 + Math.random() * 0.15)),
};
compl1.assignmentRate = (compl1.learnersTotal / TOTAL_LEARNERS) * 100;
compl1.learnersCompleted = Math.floor(compl1.learnersTotal * (0.4 + Math.random() * 0.3));
compl1.completionRate = (compl1.learnersCompleted / compl1.learnersTotal) * 100;
const compl2 = {
    assignmentRate: 0,
    completionRate: 0,
    learnersCompleted: 0,
    learnersTotal: Math.floor(MATCHED_LEARNERS * (0.6 + Math.random() * 0.15)),
};
compl2.assignmentRate = (compl2.learnersTotal / TOTAL_LEARNERS) * 100;
compl2.learnersCompleted = Math.floor(compl2.learnersTotal * (0.4 + Math.random() * 0.3));
compl2.completionRate = (compl2.learnersCompleted / compl2.learnersTotal) * 100;
const compl3 = {
    assignmentRate: 0,
    completionRate: 0,
    learnersCompleted: 0,
    learnersTotal: Math.floor(MATCHED_LEARNERS * (0.6 + Math.random() * 0.15)),
};
compl3.assignmentRate = (compl3.learnersTotal / TOTAL_LEARNERS) * 100;
compl3.learnersCompleted = Math.floor(compl3.learnersTotal * (0.4 + Math.random() * 0.3));
compl3.completionRate = (compl3.learnersCompleted / compl3.learnersTotal) * 100;
const report = {
    __typename: 'QuestReportResponse',
    details: questReportDetails,
    learners: questLearners,
    objectiveCompletion: {
        __typename: 'QuestObjectiveCompletionConnection',
        edges: [
            {
                __typename: 'QuestObjectiveCompletionEdge',
                cursor: '',
                node: {
                    __typename: 'QuestObjectiveCompletion',
                    assignmentRate: compl1.assignmentRate,
                    category: 'Access Control',
                    completionRate: compl1.completionRate,
                    learnersCompleted: compl1.learnersCompleted,
                    learnersTotal: compl1.learnersTotal,
                    subcategory: 'Missing Function Level Access Control',
                },
            },
            {
                __typename: 'QuestObjectiveCompletionEdge',
                cursor: '',
                node: {
                    __typename: 'QuestObjectiveCompletion',
                    assignmentRate: compl2.assignmentRate,
                    category: 'Security Misconfiguration',
                    completionRate: compl2.completionRate,
                    learnersCompleted: compl2.learnersCompleted,
                    learnersTotal: compl2.learnersTotal,
                    subcategory: 'Information Exposure',
                },
            },
            {
                __typename: 'QuestObjectiveCompletionEdge',
                cursor: '',
                node: {
                    __typename: 'QuestObjectiveCompletion',
                    assignmentRate: compl3.assignmentRate,
                    category: 'Cross-Site Scripting (XSS)',
                    completionRate: compl3.completionRate,
                    learnersCompleted: compl3.learnersCompleted,
                    learnersTotal: compl3.learnersTotal,
                    subcategory: 'DOM-Based Cross-Site Scripting',
                },
            },
        ],
        pageInfo: {
            __typename: 'PageInfo',
            endCursor: 'c2ltcGxlLWN1cnNvcjA=',
            hasNextPage: false,
            hasPreviousPage: false,
            startCursor: '',
        },
    },
};
export const quests = { questDetails, report };
