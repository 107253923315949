import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useId, useState } from 'react';
import { InputAdornment, MenuItem, Typography, typographyClasses, outlinedInputClasses } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { BaseSelect } from '@securecodewarrior/design-system-react/lib/base/wanda/Select/Select';
export const FilterDropdown = ({ value, onChange, disabled = false, options, label, fullWidth = false, }) => {
    const [open, setOpen] = useState(false);
    const labelId = useId();
    const handleMenuItemClick = (clickedValue) => {
        onChange(clickedValue);
        setOpen(false);
    };
    const selectedLabel = options[value] || '';
    return (_jsx(BaseSelect, { fullWidth: fullWidth, disabled: disabled, value: value, sx: {
            width: 128,
            [`& .${outlinedInputClasses.input}.MuiSelect-select`]: {
                paddingRight: '15px !important',
            },
        }, SelectProps: {
            labelId,
            onOpen: () => setOpen(true),
            onClose: () => setOpen(false),
            open,
            renderValue: () => (_jsx(Typography, { variant: "body1", sx: {
                    color: 'inherit',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                }, children: selectedLabel })),
        }, InputProps: {
            startAdornment: (_jsx(InputAdornment, { position: "start", sx: Object.assign({ pointerEvents: 'none' }, (disabled ? { color: (theme) => theme.palette.text.disabled } : {})), children: _jsxs(Typography, { variant: "caption", children: [label, ":"] }) })),
        }, children: Object.entries(options).map(([optionValue, optionLabel]) => (_jsxs(MenuItem, { value: optionValue, onClick: () => handleMenuItemClick(optionValue), sx: (theme) => ({
                backgroundColor: 'transparent',
                color: theme.palette.text.soft,
                [`& .${typographyClasses.caption}`]: {
                    fontSize: theme.typography.pxToRem(14),
                },
                '&:hover': {
                    backgroundColor: theme.palette.action.hover,
                },
                '&.Mui-selected': {
                    backgroundColor: 'transparent !important',
                    color: theme.palette.text.primary,
                },
            }), children: [optionLabel, optionValue === value && (_jsx(DoneIcon, { sx: ({ palette }) => ({ fontSize: 18, color: palette.text.title, marginLeft: 'auto' }) }))] }, optionValue))) }));
};
