var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { Typography } from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Trans, useTranslation } from 'react-i18next';
import { TagInput } from '../../../../common/TagInput/TagInput';
import { Link, Button } from '@securecodewarrior/design-system-react/lib/wanda';
import { CloseIconButton } from '../../../../../common/components/atoms/CloseIconButton';
import EmailInput from '../../../../../common/components/molecules/EmailInput/EmailInput';
import { useInviteUsers } from '../../hooks/useInviteUsers';
import { useUsers } from '../../hooks/useUsers';
import { useTeams } from '../../hooks/useTeams';
import RoleDropdown from '../../../../../common/components/molecules/RoleDropdown';
import TeamDropdown from '../../../../../common/components/molecules/TeamDropdown';
import { usePlatformContext } from '../../../../platformContext';
import { Stack } from '@mui/system';
import { UserDialog } from '../../styled/UserDialog';
const MAX_EMAILS = 100;
const InviteUsersModal = () => {
    var _a, _b;
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.userManagement.addUsersDialog' });
    const { inviteUsers, tags, isInviting } = useUsers();
    const { teams } = useTeams();
    const ctx = usePlatformContext();
    const formattedTeams = teams.map((team) => ({
        _id: team._id,
        name: team.name,
        tags: team.tags || [],
    }));
    const { state, actions } = useInviteUsers();
    const _cid = ((_b = (_a = ctx === null || ctx === void 0 ? void 0 : ctx.sessionUser) === null || _a === void 0 ? void 0 : _a.properties) === null || _b === void 0 ? void 0 : _b._cid) || '';
    const [open, setOpen] = useState(false);
    const isButtonDisabled = !(state.emails.length > 0 && state.role && (state.role === 'company admin' || state.team));
    const handleClose = () => {
        if (isInviting)
            return;
        actions.resetState();
        setOpen(false);
    };
    const handleSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        const { emails, role, team, tags } = state;
        if (!role || (role !== 'company admin' && !team))
            return;
        try {
            yield inviteUsers({ emails, role, team, tags, _cid });
        }
        catch (e) {
            console.error('error inviting users: ');
        }
        handleClose();
    });
    return (_jsxs(_Fragment, { children: [_jsx(Button, { variant: "contained", startIcon: _jsx(PersonAddIcon, {}), onClick: () => setOpen(true), children: t('title') }), _jsxs(UserDialog, { open: open, onClose: handleClose, children: [_jsx(CloseIconButton, { onClick: handleClose, "aria-label": t('close'), sx: { position: 'absolute', top: (theme) => theme.spacing(3), right: (theme) => theme.spacing(3) } }), _jsxs(Stack, { paddingY: 8, children: [_jsxs(Stack, { paddingX: 7, paddingBottom: 8, gap: 6, children: [_jsx(Typography, { variant: "h3", children: t('title') }), _jsx(Typography, { variant: "body1", children: t('description') }), _jsxs(Stack, { gap: 1, children: [_jsx(EmailInput, { initialEmails: state.emails, disabled: false, onChange: actions.handleEmailsChange, style: { minHeight: '120px', overflow: 'auto' } }), _jsx(Typography, { variant: "caption", children: t('instructions') })] }), state.emails.length > MAX_EMAILS && (_jsx(Typography, { variant: "body1", sx: (theme) => ({
                                            backgroundColor: theme.palette.badge.accent4.fill,
                                            border: `1px solid ${theme.palette.badge.accent4.border}`,
                                            borderRadius: theme.spacing(3),
                                            color: theme.palette.clickable.default,
                                            padding: theme.spacing(6),
                                        }), children: _jsx(Trans, { i18nKey: "pages.administration.userManagement.addUsersDialog.message", components: {
                                                Link: (_jsx(Link, { href: "https://help.securecodewarrior.com/hc/en-us/articles/360035948632-How-to-Bulk-Import-Users", target: "_blank", rel: "noopener noreferrer", style: { display: 'inline' } })),
                                            } }) })), _jsxs(Stack, { gap: 4, children: [_jsx(Typography, { variant: "body1", children: t('heading') }), _jsxs(Stack, { gap: 3, children: [_jsx(RoleDropdown, { role: state.role, onRoleChange: actions.handleRoleChange }), _jsx(TeamDropdown, { team: state.team, onTeamChange: actions.handleTeamChange, teams: formattedTeams, isRoleAdmin: state.role === 'company admin' }), _jsx(TagInput, { value: state.tags, onChange: actions.handleTagChange, options: tags, disabled: false })] })] })] }), _jsxs(Stack, { direction: "row", justifyContent: "space-between", alignItems: "center", borderTop: ({ palette }) => `1px solid ${palette.container.border.default}`, paddingTop: 7, paddingX: 7, children: [_jsx(Button, { variant: "outlined", onClick: handleClose, children: t('cancel') }), _jsx(Button, { variant: "contained", disabled: isButtonDisabled, onClick: handleSubmit, children: t('addAndInvite') })] })] })] })] }));
};
export default InviteUsersModal;
