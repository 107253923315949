import { onboardingAndEngagement } from './onboardingAndEngagement';
import { quests } from './quests';
import { trustScores } from './trustScores';
// Demo data from reporting-service-frontend repo
// Since we are not genrating types for this data based off the reporting-service graphql schema
// Please link below for changes in data structure when something breaks
// https://github.com/SecureCodeWarrior/reporting-service-frontend/blob/main/src/demo/
export const demoData = {
    quests,
    onboardingAndEngagement,
    trustScores,
};
