import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Skeleton } from '@securecodewarrior/design-system-react';
import { ButtonOptionSelect } from '@securecodewarrior/design-system-react/lib/labs/molecules/ButtonOptionSelect';
import { Box, Divider, Stack, Typography, useTheme } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { P, match } from 'ts-pattern';
import { Header } from '../components/molecules/Header';
import { SectionContainer } from '../components/molecules/SectionContainer';
import { MainContainer } from '../components/molecules/MainContainer';
import Filters, { CustomTabPanel } from '../components/molecules/Filters';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import Table from '../components/organisms/Table';
import { statusKeys, questStatusToBadgeColorMap, questsAdminUrls } from '../constants';
import { t } from 'i18next';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useQuestsAdminApi } from '../hooks/useQuestsAdminApi';
import { Pending, useApiPatternLoad } from '../../Api.hooks';
import { LearningQuestsIcon } from '../components/icons/LearningQuestsIcon';
import { Link, usePlatformNavigate } from '../../navigation';
import { useLocation } from 'react-router-dom';
import SnackbarAlert from '../components/molecules/SnackbarAlert';
import { listQuestAnalytics } from '../analytics/ListQuests.analytics';
import { usePlatformContext } from '../../platformContext';
import { Badge, Button } from '@securecodewarrior/design-system-react/lib/wanda';
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
dayjs.extend(duration);
const questsReportUrl = '/reporting/quests';
const getProgressReportUrl = (questId) => {
    const content = {
        hasSelection: true,
        questIds: [questId],
    };
    return `${questsReportUrl}?content=${encodeURIComponent(JSON.stringify(content))}`;
};
export const ListQuestsRoute = () => {
    return _jsx(ListQuestsLoader, {});
};
export const ListQuestsLoader = () => {
    const api = useQuestsAdminApi();
    const quests = useApiPatternLoad(api.list.query, undefined);
    const { flagsEnabled } = usePlatformContext();
    const lmsEnabled = flagsEnabled.includes('quests-lms');
    return match(quests)
        .with(Pending, () => _jsx(PendingListQuests, { lmsEnabled: lmsEnabled }))
        .with(P.instanceOf(Error), (e) => {
        throw new Error(t('questsAdmin.pages.loadFailedError'), { cause: e });
    })
        .otherwise((data) => _jsx(ListQuests, { quests: data.published, drafts: data.drafts, lmsEnabled: lmsEnabled }));
};
const PendingListQuests = ({ lmsEnabled }) => {
    const theme = useTheme();
    const filterItems = statusKeys.map((key) => ({
        key,
        label: `${t('questsAdmin.entities.quests.status.' + (key === 'draft' ? 'my_drafts' : key))}  `,
    }));
    return (_jsxs(MainContainer, { children: [_jsxs(Header, { children: [_jsxs(Stack, { children: [_jsx(Typography, { variant: "overline", children: t('questsAdmin.pages.list.eyebrowHeading') }), _jsx(Typography, { component: "h1", variant: "h2", children: t('questsAdmin.pages.list.mainHeading') })] }), lmsEnabled && (_jsx(ButtonOptionSelect, { disabled: true, variant: "contained", endIcon: _jsx(ExpandMoreRoundedIcon, {}), options: {
                            platform: t('questsAdmin.pages.list.noQuestsCreated.platform.title'),
                            lms: t('common.lms.lmsQuest'),
                        }, children: t('questsAdmin.pages.list.createQuestButton') })), !lmsEnabled && (_jsx(Button, { variant: "contained", disabled: true, children: t('questsAdmin.pages.list.createNewButton') }))] }), _jsx(Divider, { sx: { borderColor: theme.palette.container.border.default } }), _jsx(Filters, { ariaLabel: t('questsAdmin.pages.list.filtersAriaLabel'), filterItems: filterItems, skeletonMode: true, onSelectFilter: () => {
                    // Do nothing
                } }), _jsx(Divider, { sx: { borderColor: theme.palette.container.border.default } }), _jsxs(SectionContainer, { useContainer: true, isCentered: false, styleOverrides: { gap: theme.spacing(5), paddingTop: theme.spacing(5) }, children: [_jsx(Skeleton, { width: "100%", height: "49px", variant: "rounded", sx: { marginTop: theme.spacing(5) } }), _jsx(Skeleton, { width: "100%", height: "52px", variant: "rounded" }), _jsx(Skeleton, { width: "100%", height: "52px", variant: "rounded" }), _jsx(Skeleton, { width: "100%", height: "52px", variant: "rounded" }), _jsx(Skeleton, { width: "100%", height: "52px", variant: "rounded" }), _jsx(Skeleton, { width: "100%", height: "52px", variant: "rounded" })] })] }));
};
export const ListQuests = ({ quests, drafts, lmsEnabled }) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const theme = useTheme();
    const navigate = usePlatformNavigate();
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const [filterIndex, setFilterIndex] = useState(0);
    const [hasHandledCreatedOrDeletedQuest, setHasHandledCreatedOrDeletedQuest] = useState(false);
    const [hasClearedCreatedQuestClass, setHasClearedCreatedQuestClass] = useState(false);
    const { logAnalyticsEvent } = usePlatformContext();
    const analytics = useMemo(() => listQuestAnalytics(logAnalyticsEvent), [logAnalyticsEvent]);
    const newlyCreatedQuestId = (_b = (_a = location.state) === null || _a === void 0 ? void 0 : _a.newlyCreatedQuestId) !== null && _b !== void 0 ? _b : null;
    const newlyDeletedQuestId = (_d = (_c = location.state) === null || _c === void 0 ? void 0 : _c.newlyDeletedQuestId) !== null && _d !== void 0 ? _d : null;
    const didDeleteDraft = (_f = (_e = location.state) === null || _e === void 0 ? void 0 : _e.deletedDraft) !== null && _f !== void 0 ? _f : false;
    const [snackbarConfig, setSnackbarConfig] = useState({
        message: '',
        severity: 'success',
        open: false,
        key: new Date().getTime(),
    });
    const triggerSnackbar = (message, severity) => {
        setSnackbarConfig({
            message,
            severity,
            open: true,
            key: new Date().getTime(),
        });
    };
    const handleCloseSnackbar = () => {
        setSnackbarConfig((prev) => (Object.assign(Object.assign({}, prev), { open: false })));
    };
    const onSeeReportsClicked = () => {
        window.location.href = `#${questsReportUrl}`;
        analytics.onSeeReportsClicked((quests === null || quests === void 0 ? void 0 : quests.length) || 0);
    };
    const onCreateNewClicked = (kind) => {
        navigate.to({
            pathname: kind === 'platform' ? questsAdminUrls.createPage : questsAdminUrls.createLmsPage,
            hash: '',
            search: '',
        });
        analytics.onCreateNewClicked((quests === null || quests === void 0 ? void 0 : quests.length) || 0, (drafts === null || drafts === void 0 ? void 0 : drafts.length) || 0, kind);
    };
    const onViewQuestClickedAnalytics = (questId) => {
        const quest = quests === null || quests === void 0 ? void 0 : quests.find((g) => g.questId === questId);
        if (quest) {
            analytics.onViewQuestClicked(quest);
        }
    };
    const onSeeQuestReportClickedAnalytics = (questId) => {
        const quest = quests === null || quests === void 0 ? void 0 : quests.find((g) => g.questId === questId);
        if (quest) {
            analytics.onSeeQuestReportClicked(quest);
        }
    };
    const onEditDraftClickedAnalytics = (draftId) => {
        const draft = drafts === null || drafts === void 0 ? void 0 : drafts.find((draft) => draft.questId === draftId);
        if (draft) {
            analytics.onEditDraftClicked(draft);
        }
    };
    useEffect(() => {
        if (newlyCreatedQuestId && !hasHandledCreatedOrDeletedQuest) {
            triggerSnackbar(t('questsAdmin.pages.edit.alerts.questCreatedSuccessfully'), 'success');
            setHasHandledCreatedOrDeletedQuest(true);
            setTimeout(() => {
                setHasClearedCreatedQuestClass(true);
                const highlightedRow = document.querySelector(`tr.is-highlighted`);
                if (highlightedRow) {
                    highlightedRow.scrollIntoView({ block: 'center', behavior: 'smooth' });
                }
            }, 1);
        }
    }, [newlyCreatedQuestId, hasHandledCreatedOrDeletedQuest, t]);
    useEffect(() => {
        if (newlyDeletedQuestId && !hasHandledCreatedOrDeletedQuest) {
            const translationKey = didDeleteDraft
                ? 'questsAdmin.pages.edit.alerts.draftDeletedSuccessfully'
                : 'questsAdmin.pages.edit.alerts.questDeletedSuccessfully';
            triggerSnackbar(t(translationKey), 'success');
            setHasHandledCreatedOrDeletedQuest(true);
        }
    }, [newlyDeletedQuestId, didDeleteDraft, hasHandledCreatedOrDeletedQuest, t]);
    const countByStatusKeyMap = (quests === null || quests === void 0 ? void 0 : quests.reduce((map, quest) => {
        if (quest.status in map) {
            map[quest.status]++;
        }
        else {
            map[quest.status] = 1;
        }
        map['all']++;
        return map;
    }, statusKeys.reduce((acc, key) => (Object.assign(Object.assign({}, acc), { [key]: 0 })), {}))) || {};
    countByStatusKeyMap.draft = (drafts === null || drafts === void 0 ? void 0 : drafts.length) || 0;
    countByStatusKeyMap.all += countByStatusKeyMap.draft;
    const onTabFilterChange = (newIndex) => {
        setFilterIndex(newIndex);
        const newStatus = statusKeys[newIndex];
        analytics.onQuestsListTabFiltered(newStatus, countByStatusKeyMap[newStatus], (quests === null || quests === void 0 ? void 0 : quests.length) || 0);
    };
    const filterItems = statusKeys.map((key) => {
        var _a;
        return ({
            key,
            label: `${t('questsAdmin.entities.quests.status.' + (key === 'draft' ? 'my_drafts' : key))} (${(_a = countByStatusKeyMap[key]) !== null && _a !== void 0 ? _a : 0})`,
        });
    });
    const displayedColumns = [
        {
            key: 'name',
            label: t(`questsAdmin.pages.list.tableHeader.quest`),
        },
        {
            key: 'participants',
            label: t(`questsAdmin.pages.list.tableHeader.participants`),
        },
        {
            key: 'startDate',
            label: t(`questsAdmin.pages.list.tableHeader.starts`),
        },
        {
            key: 'endDate',
            label: t(`questsAdmin.pages.list.tableHeader.ends`),
        },
        {
            key: 'completion',
            label: t(`questsAdmin.pages.list.tableHeader.completion`),
            tooltip: t(`questsAdmin.pages.list.tooltips.completionTableColumn`),
        },
        {
            key: 'status',
            label: t(`questsAdmin.pages.list.tableHeader.status`),
        },
        {
            key: 'actions',
            label: t(`questsAdmin.pages.list.tableHeader.actions`),
        },
    ];
    const rowsPerStatus = statusKeys.map((statusKey) => {
        const publishedQuests = quests
            ? quests === null || quests === void 0 ? void 0 : quests.map((quest, index) => mapQuestToTableRow(quest, displayedColumns, statusKey, index, hasClearedCreatedQuestClass ? null : newlyCreatedQuestId, onSeeQuestReportClickedAnalytics, onViewQuestClickedAnalytics, i18n.language))
            : [];
        publishedQuests.sort((a, b) => a.sortKey.localeCompare(b.sortKey));
        const draftQuests = drafts
            ? drafts.map((draft) => mapDraftToTableRow(draft, displayedColumns, statusKeys[filterIndex], onEditDraftClickedAnalytics, theme))
            : [];
        return [...draftQuests, ...publishedQuests];
    });
    return (_jsxs(MainContainer, { children: [_jsxs(Header, { children: [_jsxs(Stack, { children: [_jsx(Typography, { variant: "overline", sx: { color: theme.palette.text.soft }, children: t('questsAdmin.pages.list.eyebrowHeading') }), _jsx(Typography, { component: "h1", variant: "h2", children: t('questsAdmin.pages.list.mainHeading') })] }), _jsxs(Stack, { direction: "row", spacing: 3, children: [(quests === null || quests === void 0 ? void 0 : quests.length) > 0 && (_jsx(Button, { variant: "outlined", startIcon: _jsx(BarChartRoundedIcon, {}), onClick: onSeeReportsClicked, children: t('questsAdmin.pages.list.seeReports') })), lmsEnabled && ((drafts === null || drafts === void 0 ? void 0 : drafts.length) > 0 || (quests === null || quests === void 0 ? void 0 : quests.length) > 0) && (_jsx(ButtonOptionSelect, { variant: "contained", onChange: onCreateNewClicked, endIcon: _jsx(ExpandMoreRoundedIcon, {}), options: {
                                    platform: t('questsAdmin.pages.list.noQuestsCreated.platform.title'),
                                    lms: t('common.lms.lmsQuest'),
                                }, children: t('questsAdmin.pages.list.createQuestButton') })), !lmsEnabled && (_jsx(Button, { variant: "contained", onClick: () => onCreateNewClicked('platform'), children: t('questsAdmin.pages.list.createNewButton') }))] })] }), (!!(quests === null || quests === void 0 ? void 0 : quests.length) || !!(drafts === null || drafts === void 0 ? void 0 : drafts.length)) && (_jsxs(_Fragment, { children: [_jsx(Filters, { ariaLabel: t('questsAdmin.pages.list.filtersAriaLabel'), filterItems: filterItems, onSelectFilter: onTabFilterChange }), _jsx(Divider, { sx: { borderColor: theme.palette.container.border.default } })] })), _jsx(SectionContainer, { useContainer: true, styleOverrides: { paddingTop: theme.spacing(5) }, isCentered: !((_g = rowsPerStatus[filterIndex]) === null || _g === void 0 ? void 0 : _g.some((row) => row.isVisible)), children: _jsx(_Fragment, { children: statusKeys.map((statusKey, index) => {
                        var _a;
                        return (_jsx(CustomTabPanel, { value: filterIndex, index: index, children: !((_a = rowsPerStatus[index]) === null || _a === void 0 ? void 0 : _a.some((row) => row.isVisible)) ? (_jsx(QuestsMissing, { status: (quests === null || quests === void 0 ? void 0 : quests.length) || (drafts === null || drafts === void 0 ? void 0 : drafts.length) ? statusKeys[index] : 'all', onCreateNewClicked: onCreateNewClicked, lmsEnabled: lmsEnabled })) : (_jsx(Table, { displayedColumns: displayedColumns, rows: rowsPerStatus[index] })) }, `tab-panel-${statusKey}`));
                    }) }) }), _jsx(SnackbarAlert, { message: snackbarConfig.message, severity: snackbarConfig.severity, open: snackbarConfig.open, onClose: handleCloseSnackbar }, snackbarConfig.key)] }));
};
const QuestsMissing = (props) => {
    const { status, onCreateNewClicked } = props;
    const { t } = useTranslation();
    return (_jsxs(Stack, { sx: { alignItems: 'center', textAlign: 'center', paddingTop: 5, paddingBottom: 5 }, children: [_jsx(LearningQuestsIcon, { sx: (theme) => ({
                    fontSize: theme.typography.pxToRem(44),
                    color: theme.palette.clickable.nav.item.active,
                    marginBottom: theme.typography.pxToRem(20),
                }) }), _jsx(Typography, { component: "h2", variant: "h3", marginBottom: (theme) => theme.typography.pxToRem(6), children: t(`questsAdmin.pages.list.noQuestsHeading.${status}`) }), status === 'all' && (_jsxs(_Fragment, { children: [_jsx(Typography, { component: "p", variant: "subtitle1", maxWidth: (theme) => theme.typography.pxToRem(360), marginBottom: (theme) => theme.typography.pxToRem(12), children: t('questsAdmin.pages.list.noQuestsParagraph') }), props.lmsEnabled && (_jsxs(Stack, { marginTop: 9, direction: "row", gap: 4, children: [_jsx(CreateNewQuestCard, { kind: "platform", title: t('questsAdmin.pages.list.noQuestsCreated.platform.title'), description: t('questsAdmin.pages.list.noQuestsCreated.platform.description'), buttonLabel: t('questsAdmin.pages.list.noQuestsCreated.platform.buttonLabel'), onCreateNewClicked: () => onCreateNewClicked('platform') }), _jsx(CreateNewQuestCard, { kind: "lms", title: t('common.lms.lmsQuest'), description: t('questsAdmin.pages.list.noQuestsCreated.lms.description'), descriptionExtra: t('questsAdmin.pages.list.noQuestsCreated.lms.descriptionExtra'), buttonLabel: t('questsAdmin.pages.list.noQuestsCreated.lms.buttonLabel'), onCreateNewClicked: () => onCreateNewClicked('lms') })] })), !props.lmsEnabled && (_jsx(Button, { variant: "contained", onClick: () => onCreateNewClicked('platform'), children: t('questsAdmin.pages.list.createNewButton') }))] })), status !== 'all' && (_jsx(Typography, { component: "p", variant: "subtitle1", maxWidth: (theme) => theme.typography.pxToRem(500), marginBottom: (theme) => theme.typography.pxToRem(12), children: t('questsAdmin.pages.list.noStatusQuestsParagraph.' + status) }))] }));
};
const CreateNewQuestCard = (props) => {
    return (_jsxs(Stack, { padding: 7.5, sx: (theme) => ({
            border: `1px solid ${theme.palette.container.border.default}`,
            borderRadius: `${theme.shape.borderRadius * 3}px`,
            background: theme.palette.container.fill.card1,
            width: '320px',
            minHeight: '310px',
            textAlign: 'left',
        }), children: [_jsxs(Box, { sx: { flex: 1 }, children: [_jsx(Typography, { variant: "h5", component: "label", children: props.title }), _jsx(Typography, { variant: "body1", marginTop: 4, marginBottom: 4, children: _jsx(Trans, { children: props.description }) }), props.descriptionExtra && (_jsx(Typography, { variant: "body1", marginBottom: 4, children: props.descriptionExtra }))] }), _jsx(Divider, { sx: (theme) => ({ borderColor: theme.palette.container.border.default, marginBottom: 5 }) }), _jsx(Button, { variant: "contained", onClick: props.onCreateNewClicked, children: props.buttonLabel })] }));
};
const mapDraftToTableRow = (draft, displayedColumns, statusKey, onEditDraftClickedAnalytics, theme) => {
    const handleClickAnalytics = () => {
        onEditDraftClickedAnalytics(draft.questId);
    };
    const cells = {
        name: (_jsxs("span", { style: {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }, children: [draft.name && (_jsx(Typography, { sx: (theme) => ({
                        color: theme.palette.text.title,
                        fontWeight: 600,
                        fontSize: theme.typography.pxToRem(16),
                    }), children: draft.name })), !draft.name && (_jsxs("span", { style: { display: 'inline-flex' }, children: [_jsxs(Typography, { sx: { fontWeight: 600, fontSize: theme.typography.pxToRem(16), color: theme.palette.text.title }, children: [t('questsAdmin.pages.list.untitledDraft'), " -"] }), _jsx(Typography, { sx: {
                                color: theme.palette.text.title,
                                fontStyle: 'italic',
                                fontWeight: 600,
                                fontSize: theme.typography.pxToRem(16),
                                paddingRight: theme.spacing(1.5),
                                paddingLeft: theme.spacing(1),
                            }, children: dayjs(draft.createdAt).format(' DD MMM YYYY - HH:mm:ss') })] })), draft.isMandatory && (_jsx("span", { style: { marginLeft: theme.spacing(2) }, children: _jsx(Badge, { variant: "rectangle", color: "gold", children: t('questsAdmin.entities.mandatory') }) })), draft.kind === 'lms' && (_jsx("span", { style: { marginLeft: theme.spacing(2) }, children: _jsx(Badge, { variant: "rectangle", color: "default", children: "LMS" }) }))] })),
        participants: '-',
        startDate: '-',
        endDate: '-',
        completion: '-',
        status: (_jsx(Badge, { variant: "rectangle", color: questStatusToBadgeColorMap.draft, children: t('questsAdmin.entities.quests.status.draft') })),
        actions: (_jsx("span", { style: {
                display: 'flex',
                justifyContent: 'space-around',
                gap: theme.typography.pxToRem(10),
            }, children: _jsx(Link, { to: questsAdminUrls.detailsPage(draft.questId), analyticsCallback: handleClickAnalytics, linkProps: {
                    color: 'soft',
                    'aria-label': t('questsAdmin.pages.list.editDraftLinkAriaLabel'),
                }, state: { questName: draft.name, status: 'draft', kind: draft.kind }, children: _jsx(EditIcon, { fontSize: "large", sx: (theme) => ({
                        color: theme.palette.clickable.nav.item.default,
                        verticalAlign: 'middle',
                    }) }) }) })),
    };
    const displayedCells = Object.fromEntries(displayedColumns.map(({ key }) => [key, cells[key]]));
    return Object.assign(Object.assign({}, displayedCells), { isVisible: statusKey === 'draft' || statusKey === 'all', sortKey: 'not used', isHighlighted: false });
};
const mapQuestToTableRow = (quest, displayedColumns, statusKey, index, newlyCreatedQuestId, onSeeQuestReportClickedAnalytics, onViewQuestClickedAnalytics, currentLanguage) => {
    const cells = {
        name: (_jsxs("span", { style: {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }, children: [_jsx(Typography, { sx: (theme) => ({ color: theme.palette.text.title, fontWeight: 600, fontSize: theme.typography.pxToRem(16) }), children: quest.name }), quest.isMandatory && (_jsx("span", { style: { marginLeft: '8px' }, children: _jsx(Badge, { color: "gold", variant: "rectangle", children: t('questsAdmin.entities.mandatory') }) })), quest.kind === 'lms' && (_jsx("span", { style: { marginLeft: '8px' }, children: _jsx(Badge, { color: "default", variant: "rectangle", children: "LMS" }) }))] })),
        participants: '' + quest.participantCount,
        startDate: (_jsx("p", { children: quest.kind === 'platform'
                ? quest.startDate.toLocaleDateString(currentLanguage === 'en-US' ? 'en-GB' : currentLanguage, {
                    day: '2-digit',
                    month: 'long',
                    year: 'numeric',
                })
                : '-' })),
        endDate: (_jsx("p", { children: quest.kind === 'platform'
                ? quest.endDate.toLocaleDateString(currentLanguage === 'en-US' ? 'en-GB' : currentLanguage, {
                    day: '2-digit',
                    month: 'long',
                    year: 'numeric',
                })
                : '-' })),
        completion: quest.completionRate === null
            ? t('questsAdmin.pages.list.notApplicableAbbrevation')
            : Math.round(quest.completionRate * 100) + '%',
        status: (_jsx(Badge, { variant: "rectangle", color: questStatusToBadgeColorMap[quest.status], children: t('questsAdmin.entities.quests.status.' + quest.status) })),
        actions: (_jsxs(Box, { component: "span", sx: (theme) => ({
                display: 'flex',
                justifyContent: 'space-around',
                gap: theme.typography.pxToRem(20),
            }), children: [_jsx(Link, { to: getProgressReportUrl(quest.questId), analyticsCallback: () => {
                        onSeeQuestReportClickedAnalytics(quest.questId);
                    }, linkProps: {
                        color: 'soft',
                        'aria-label': t('questsAdmin.pages.list.seeQuestReportLinkAriaLabel'),
                    }, children: _jsx(BarChartRoundedIcon, { fontSize: "medium", sx: (theme) => ({
                            color: theme.palette.clickable.nav.item.default,
                            verticalAlign: 'middle',
                        }) }) }), _jsx(Link, { to: questsAdminUrls.detailsPage(quest.questId), analyticsCallback: () => {
                        onViewQuestClickedAnalytics(quest.questId);
                    }, linkProps: {
                        color: 'soft',
                        'aria-label': t('questsAdmin.pages.list.viewQuestLinkAriaLabel'),
                    }, state: { questName: quest.name, status: quest.status, kind: quest.kind }, children: _jsx(VisibilityIcon, { fontSize: "large", sx: (theme) => ({
                            color: theme.palette.clickable.nav.item.default,
                            verticalAlign: 'middle',
                        }) }) })] })),
    };
    const displayedCells = Object.fromEntries(displayedColumns.map(({ key }) => [key, cells[key]]));
    return Object.assign(Object.assign({}, displayedCells), { isVisible: statusKey === 'all' ? true : quest.status === statusKey, sortKey: getSortKey(quest), isHighlighted: newlyCreatedQuestId === quest.questId });
};
const maxTimestamp = new Date('9999-12-31T23:59:59.999Z');
const maxTimestampMs = maxTimestamp.getTime();
function getSortKey(quest) {
    const statusPrecedence = {
        draft: '4',
        archived: '3',
        finished: '2',
        scheduled: '1',
        active: '0',
    };
    if (quest.kind === 'platform') {
        return statusPrecedence[quest.status] + quest.endDate.toISOString();
    }
    const invertedTimestamp = maxTimestampMs - quest.createdAt.getTime();
    const timePrecedence = maxTimestamp.toISOString() + new Date(invertedTimestamp).toISOString();
    return statusPrecedence[quest.status] + timePrecedence;
}
