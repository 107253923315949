// Matching rules are (mostly) equivalent to the ones used in https://github.com/SCWContent/scw-pseudocode-vscode/blob/master/scw/syntaxes/scw.tmLanguage.json
const LITERALS = {
    className: 'literal',
    match: '\\b(true|false|none|empty|any|all)\\b',
};
const INPUT_OUTPUT = {
    className: 'built_in',
    match: '\\b(Open|Close|Delete|Display|Fetch|from|Ask|Save it as|Store|(based on))\\b',
};
const KEYWORDS_CONTROL = {
    className: 'keyword',
    match: '\\b(If|if|then|Else|else|(End if)|(End If)|Repeat|repeat|(End Repeat)|(End repeat)|while|times)\\b',
};
const KEYWORDS_FUNCTION = {
    className: 'keyword',
    match: '\\b((Define method)|(End method)|(with arguments)|(with argument)|Return|Call|Cast|cast)\\b',
};
const UTILITY_METHODS = {
    className: 'built_in',
    match: '\\b((size of)|(length of)|contains|contain)\\b',
};
const OPERATORS_TEXT = {
    className: 'operator',
    begin: '\\b((is not equal)|(is less than or equal)|(is greater than or equal)|(is less than)|(is greater than)|(is equal))\\b',
};
const OPERATORS = {
    className: 'operator',
    begin: '(=|!=|<=|>=|<(?!<)|>(?!>))',
};
const LOGICAL_PATTERNS = {
    className: 'operator',
    begin: '\\b(and|or|not)\\b',
};
const OTHER_OPERATORS = {
    className: 'operator',
    begin: '\\b((does not)|exists|exist|with|in|of|is|Set|set|to|match|matches)\\b',
};
const IMPORTS = {
    className: 'keyword',
    match: '\\b(import|as)\\b',
};
const ERROR_HANDLING = {
    className: 'keyword',
    match: '\\b(Throw Error|Try|Catch Error|Finally)\\b',
};
const TYPES = {
    className: 'type',
    match: '\\b(String|string|Boolean|boolean|Integer|integer|Short_Integer|Long_Integer|None|none|Empty|empty|(Object of)|(Objects of))\\b',
};
const KEYWORDS_OBJECT = {
    className: 'keyword',
    match: '\\b((Define object)|(End object)|(Define Object)|(End Object))\\b',
};
const KEYWORDS_VARIABLE = {
    className: 'keyword',
    match: '\\b(Set|to|of)\\b',
};
export default function (hljs) {
    const STRING = {
        className: 'string',
        variants: [hljs.APOS_STRING_MODE, hljs.QUOTE_STRING_MODE],
    };
    return {
        name: 'SCW Pseudocode',
        aliases: ['scw', 'pseudo'],
        case_insensitive: false,
        contains: [
            hljs.COMMENT(/\(--/, /--\)/), // multiline comment
            hljs.COMMENT('--', '$'), // single line comment
            INPUT_OUTPUT,
            KEYWORDS_CONTROL,
            KEYWORDS_FUNCTION,
            KEYWORDS_OBJECT,
            KEYWORDS_VARIABLE,
            UTILITY_METHODS,
            STRING,
            OPERATORS_TEXT,
            OPERATORS,
            OTHER_OPERATORS,
            IMPORTS,
            ERROR_HANDLING,
            TYPES,
            LOGICAL_PATTERNS,
            hljs.NUMBER_MODE,
            LITERALS,
        ],
    };
}
