var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import { useTranslation } from 'react-i18next';
import { useTeamMutation } from '../../../hooks/useTeamMutation';
import { usePlatformContext } from '../../../../../platformContext';
import { TeamFormModal } from '../TeamFormModal';
import { Dialog } from '../../../../../common/Dialog';
const getAllowances = (size) => {
    const teamSize = typeof size === 'number' ? size : 0;
    return {
        allowances: {
            training: {
                license: teamSize === 0 ? 'unlimited' : 'usage',
                granted: teamSize,
            },
            assessments: {
                license: 'unlimited',
                granted: 0,
            },
            tournaments: {
                license: 'unlimited',
                granted: 0,
            },
            sensei: {
                license: 'unlimited',
                granted: 0,
            },
        },
    };
};
export const AddTeamModal = () => {
    const [open, setOpen] = useState(false);
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.teamManagement.addTeamModal' });
    const { mutate } = useTeamMutation();
    const { sessionUser } = usePlatformContext();
    const handleSubmit = (formState) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const { teamName, maximumTeamSize, activationDate, expirationDate } = formState;
        const payload = Object.assign(Object.assign(Object.assign({ name: teamName.trim(), _cid: ((_a = sessionUser === null || sessionUser === void 0 ? void 0 : sessionUser.properties) === null || _a === void 0 ? void 0 : _a._cid) || '' }, getAllowances(maximumTeamSize)), (activationDate ? { activationDate: activationDate.valueOf() } : {})), (expirationDate ? { expirationDate: expirationDate.valueOf() } : {}));
        yield mutate({ type: 'addTeam', payload });
    });
    return (_jsxs(_Fragment, { children: [_jsx(Button, { variant: "contained", startIcon: _jsx(PersonAddIcon, {}), onClick: () => setOpen(true), children: t('title') }), _jsx(Dialog, { open: open, onClose: () => setOpen(false), children: _jsx(TeamFormModal, { onClose: () => setOpen(false), onSubmit: handleSubmit, title: t('title'), submitButtonText: t('submitButtonText'), description: t('description') }) })] }));
};
