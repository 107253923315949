var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Stack, Typography } from '@mui/material';
import { CheckCircleRounded, Error } from '@mui/icons-material';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import { useUsers } from '../../../hooks/useUsers';
import { ActionDialog } from '../../../../common/components/organisms/ActionDialog';
import { DeleteIcon } from '../../../../../common/icons';
import { useTranslation } from 'react-i18next';
const SingleUserDeletePending = ({ onClose, onConfirm, user }) => {
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.userManagement' });
    return (_jsxs(ActionDialog, { title: t('singleUserDeleteDialog.pending.title', { email: user.email }), icon: _jsx(DeleteIcon, { sx: { fontSize: '56px', color: (theme) => theme.palette.text.status.error } }), footer: _jsxs(Stack, { direction: "row", justifyContent: "space-between", children: [_jsx(Button, { variant: "outlined", onClick: onClose, children: t('common.cancel') }), _jsx(Button, { variant: "contained", onClick: onConfirm, color: "warning", children: t('singleUserDeleteDialog.pending.confirm') })] }), children: [_jsx(Typography, { textAlign: "center", children: t('singleUserDeleteDialog.pending.description') }), _jsx(Typography, { textAlign: "center", children: t('singleUserDeleteDialog.pending.warning') })] }));
};
const SingleUserDeleteSuccess = ({ onClose, user }) => {
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.userManagement' });
    return (_jsx(ActionDialog, { title: t('singleUserDeleteDialog.success.title', { email: user.email }), icon: _jsx(CheckCircleRounded, { sx: { fontSize: '56px', color: (theme) => theme.palette.text.status.success } }), footer: _jsx(Stack, { direction: "row", justifyContent: "flex-end", children: _jsx(Button, { variant: "contained", onClick: onClose, children: t('common.okay') }) }) }));
};
const SingleUserDeleteDialogFailure = ({ onClose, user, message }) => {
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.userManagement' });
    return (_jsx(ActionDialog, { title: t('singleUserDeleteDialog.failure.title', { email: user.email }), icon: _jsx(Error, { sx: { fontSize: '56px', color: (theme) => theme.palette.text.status.error } }), footer: _jsx(Stack, { direction: "row", justifyContent: "flex-end", children: _jsx(Button, { variant: "contained", onClick: onClose, children: t('common.okay') }) }), children: _jsxs(Typography, { children: [message, "."] }) }));
};
export const SingleUserDeleteDialog = ({ onClose, onConfirm, userId }) => {
    const { users } = useUsers();
    const user = users.find((u) => u._id === userId);
    const [resultState, setResultState] = useState();
    const [cachedUser, setCachedUser] = useState(user);
    useEffect(() => {
        if (user) {
            setCachedUser(user);
        }
    }, [user]);
    if (!cachedUser) {
        return null;
    }
    const handleConfirm = () => __awaiter(void 0, void 0, void 0, function* () {
        const result = yield onConfirm();
        setResultState(result);
    });
    if (resultState === undefined) {
        return _jsx(SingleUserDeletePending, { onClose: onClose, onConfirm: handleConfirm, user: cachedUser });
    }
    return resultState.updatedCount > 0 ? (_jsx(SingleUserDeleteSuccess, { onClose: onClose, user: cachedUser })) : (_jsx(SingleUserDeleteDialogFailure, { message: resultState.message, onClose: onClose, user: cachedUser }));
};
