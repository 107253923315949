import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Add } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { Card, RadialChart } from '@securecodewarrior/design-system-react/lib/labs';
import { CardTemplate } from '../../../templates/CardTemplate';
import { useDashboardAnalytics } from '../../../hooks/useDashboardAnalytics';
import { useTranslation } from 'react-i18next';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
const createAQuestReportUrl = '#/admin/quests/create/';
export const LearningProgressEmpty = () => {
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.dashboard.learningProgress' });
    const { emitEvent } = useDashboardAnalytics();
    const clickCreateAQuest = (event) => {
        // Prevent the default link navigation
        event.preventDefault();
        emitEvent('clicked_create_a_goal_button');
        window.location.href = createAQuestReportUrl;
    };
    return (_jsxs(CardTemplate, { children: [_jsx(CardTemplate.Header, { children: _jsx(Typography, { variant: "h5", children: t('quest') }) }), _jsxs(CardTemplate.Body, { children: [_jsxs(Stack, { direction: "column", alignItems: "center", justifyContent: "space-between", gap: 4, children: [_jsx(RadialChart, { data: { type: 'percentage', animate: true, name: 'value' }, dataKey: "value", domain: [0, 100] }), _jsx(Card, { level: 2, sx: { padding: (theme) => theme.spacing(2, 3), height: 38, width: 200 } })] }), _jsx(Card, { level: 2, padding: 4, display: "grid", sx: { placeItems: 'center' }, children: _jsxs(Stack, { alignItems: "center", justifyContent: "center", gap: 6, children: [_jsxs(Stack, { direction: "column", gap: 2, alignItems: "center", justifyContent: "center", children: [_jsx(Typography, { variant: "h6", textAlign: "center", children: t('setUpALearningQuest') }), _jsx(Typography, { textAlign: "center", children: t('description') })] }), _jsx(Button, { startIcon: _jsx(Add, {}), variant: "outlined", href: createAQuestReportUrl, onClick: clickCreateAQuest, children: t('createQuest') })] }) })] })] }));
};
