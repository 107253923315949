import { jsx as _jsx } from "react/jsx-runtime";
import { DSThemeProvider } from '@securecodewarrior/design-system-react';
import { featureFlags } from '../../../common';
import { QueryProvider } from '../../queryClient';
import { TeamManagement } from './TeamManagement';
import { usePlatformContext } from '../../platformContext';
import { usePlatformNavigate } from '../../navigation';
import { AdminErrorProvider } from '../common/utils/AdminErrorContext';
export const TeamManagementRoute = () => {
    var _a, _b;
    const navigate = usePlatformNavigate();
    const ctx = usePlatformContext();
    const newTeamManagementPageVisible = ctx.flagsEnabled.includes(featureFlags.NEW_USER_MANAGEMENT_PAGE) &&
        ((_b = (_a = ctx.sessionUser) === null || _a === void 0 ? void 0 : _a.roles) === null || _b === void 0 ? void 0 : _b.includes('company admin'));
    if (!newTeamManagementPageVisible) {
        navigate.toState('home');
        return;
    }
    return (_jsx(QueryProvider, { children: _jsx(DSThemeProvider, { withBackground: true, theme: "light", children: _jsx(AdminErrorProvider, { children: _jsx(TeamManagement, {}) }) }) }));
};
