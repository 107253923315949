import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { WarningRounded } from '@mui/icons-material';
import { Dialog, Stack, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
export const LmsConnectionLostModal = (props) => {
    return (_jsx(Dialog, Object.assign({}, props, { sx: (theme) => ({
            '& .MuiDialog-paper': {
                borderRadius: theme.shape.border.radius.md,
                border: `1px solid ${theme.palette.container.border.default}`,
                background: theme.palette.container.fill.card1,
                py: 8,
            },
        }), children: _jsxs(Stack, { gap: 8, px: 7, pb: 8, children: [_jsxs(Stack, { gap: 4, alignItems: "center", children: [_jsx(WarningRounded, { sx: (theme) => ({ color: theme.palette.badge.error.border, fontSize: theme.typography.pxToRem(56) }) }), _jsx(Typography, { variant: "h3", component: "h1", children: _jsx(Trans, { i18nKey: "pages.lms.connectionLost.title" }) })] }), _jsxs(Stack, { gap: 6, alignItems: "center", textAlign: "center", children: [_jsx(Typography, { variant: "subtitle1", sx: (theme) => ({ color: theme.palette.text.body }), children: _jsx(Trans, { i18nKey: "pages.lms.connectionLost.subtitle" }) }), _jsx(Typography, { children: _jsx(Trans, { i18nKey: "pages.lms.connectionLost.description" }) })] })] }) })));
};
