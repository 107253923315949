import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { QuestMenuItem } from '../atoms/QuestMenuItem';
import { StyledQuestMenu } from '../../styled/QuestMenu.styled';
import { useTranslation } from 'react-i18next';
import { useDashboardAnalytics } from '../../hooks/useDashboardAnalytics';
const createAQuestReportUrl = '/#/admin/quests/create/';
export const QuestMenu = ({ anchorEl, open, onClose, onSelectQuest }) => {
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.dashboard.learningProgress' });
    const { emitEvent } = useDashboardAnalytics();
    function clickCreateAQuest(event) {
        // Prevent the default link navigation
        event.preventDefault();
        // Fire analytics event
        emitEvent('clicked_create_a_goal_button');
        // Redirect to create a quest
        window.location.href = createAQuestReportUrl;
    }
    return (_jsxs(StyledQuestMenu, { id: "quest-menu", anchorEl: anchorEl, open: open, onClose: onClose, anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
        }, transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
        }, children: [_jsx(QuestMenuItem, { label: t('selectQuest'), onClick: onSelectQuest }), _jsx(QuestMenuItem, { label: t('createQuest'), onClick: clickCreateAQuest })] }));
};
