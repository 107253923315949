import { jsx as _jsx } from "react/jsx-runtime";
import { DashboardPage } from './pages/DashboardPage';
import { DashboardProvider } from './context/dashboardContext';
import { DSThemeProvider } from '@securecodewarrior/design-system-react';
import { decideThemeByHashRoute } from '../../utils/themes';
import { useUrqlClient } from '../urqlClient';
import { Provider as UrqlProvider } from 'urql';
import { usePlatformContext } from '../platformContext';
import { usePlatformNavigate } from '../navigation';
import { featureFlags } from '../../common';
export const DashboardRoute = ({ sessionId, reportingApiEndpoint, }) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const navigate = usePlatformNavigate();
    const ctx = usePlatformContext();
    const urqlClient = useUrqlClient(sessionId, reportingApiEndpoint);
    const isAdminDashboardEnabled = ctx.flagsEnabled.includes(featureFlags.DASHBOARD) &&
        ((_b = (_a = ctx.sessionUser) === null || _a === void 0 ? void 0 : _a.roles) === null || _b === void 0 ? void 0 : _b.includes('company admin')) &&
        ((_g = (_f = (_e = (_d = (_c = ctx.sessionUser) === null || _c === void 0 ? void 0 : _c.properties) === null || _d === void 0 ? void 0 : _d.company) === null || _e === void 0 ? void 0 : _e.learningModules) === null || _f === void 0 ? void 0 : _f.quests) !== null && _g !== void 0 ? _g : true);
    if (!isAdminDashboardEnabled) {
        navigate.toState('home');
        return;
    }
    return (_jsx(DSThemeProvider, { theme: decideThemeByHashRoute(window.location.hash), children: _jsx(UrqlProvider, { value: urqlClient, children: _jsx(DashboardProvider, { children: _jsx(DashboardPage, {}) }) }) }));
};
