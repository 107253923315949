import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useApiClient } from '../../ApiContext';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LmsQuestPageView } from './LmsQuestPageView';
import { LmsCompletionPageView } from './LmsCompletionPageView';
import { LmsConnectionLostModal } from './components/LmsConnectionLostModal';
export const LmsQuestPage = () => {
    const { questId } = useParams();
    if (!questId) {
        throw new Error('Missing quest ID');
    }
    useEffect(() => {
        var _a;
        if (!((_a = window.top) === null || _a === void 0 ? void 0 : _a.opener)) {
            throw new Error('This page can only be opened through your LMS!');
        }
        window.top.opener.postMessage({ id: questId, state: 'START' }, '*');
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    const { trpc, handleErrorPromise } = useApiClient();
    const [languageSelectionProps, setLanguageSelectionProps] = useState();
    const [response, setResponse] = useState();
    const [lmsConnectionLost, setLmsConnectionLost] = useState(false);
    useEffect(() => {
        const intervalId = setInterval(() => {
            var _a;
            if (!((_a = window.top) === null || _a === void 0 ? void 0 : _a.opener)) {
                setLmsConnectionLost(true);
            }
        }, 1000);
        return () => clearInterval(intervalId);
    }, []);
    useEffect(() => {
        handleErrorPromise(trpc.myquests.fetchLMSQuest.query({ questId }), (response) => {
            setResponse(response);
            if (response.kind === 'no content available') {
                const { availableLanguages, selectedLanguages } = response;
                setLanguageSelectionProps({ availableLanguages, selectedLanguages });
            }
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    const loadAvailableLanguages = () => {
        setLanguageSelectionProps('loading');
        return handleErrorPromise(trpc.quests.languageSelection.query(), setLanguageSelectionProps);
    };
    const saveSelectedLanguages = (languageIds) => {
        setResponse(undefined);
        handleErrorPromise(trpc.quests.userLanguages.mutate(languageIds), () => {
            handleErrorPromise(trpc.myquests.fetchLMSQuest.query({ questId }), (response) => {
                setResponse(response);
                if (languageSelectionProps && languageSelectionProps !== 'loading') {
                    setLanguageSelectionProps({
                        availableLanguages: languageSelectionProps.availableLanguages,
                        selectedLanguages: languageIds,
                    });
                }
            });
        });
    };
    return (_jsxs(_Fragment, { children: [(response === null || response === void 0 ? void 0 : response.kind) === 'already completed' ? (_jsx(LmsCompletionPageView, { quest: response.quest, onClickReturn: () => window.close() })) : (_jsx(LmsQuestPageView, Object.assign({}, ((response !== null && response !== void 0 ? response : { kind: 'skeleton' })), { languageSelectionBackendData: languageSelectionProps, loadAvailableLanguages: loadAvailableLanguages, saveSelectedLanguages: saveSelectedLanguages }))), _jsx(LmsConnectionLostModal, { open: lmsConnectionLost })] }));
};
