import { ListItem, TextField, autocompleteClasses, styled, } from '@mui/material';
export const CreateNewTag = styled(ListItem)(({ theme }) => ({
    '&:only-child': { borderTop: `unset` },
    borderTop: `1px solid ${theme.palette.container.border.default}`,
    '&:hover': { background: theme.palette.container.fill.card2, cursor: 'pointer' },
    color: theme.palette.text.soft,
}));
export const TagInputOption = styled(ListItem)(({ theme }) => (Object.assign(Object.assign({}, theme.typography.body1), { color: theme.palette.text.soft, '&:hover': {
        background: `${theme.palette.container.fill.card2}`,
    } })));
export const TagInputTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-root': {
        background: theme.palette.container.fill.card1,
        paddingLeft: '51px',
        borderRadius: `${theme.shape.borderRadius * 2}px`,
        paddingBlock: theme.spacing(1),
        gap: theme.spacing(2),
        minHeight: 36,
        '& .MuiInputBase-input': {
            padding: theme.spacing(0),
            '&.MuiOutlinedInput-input::-webkit-input-placeholder, &.MuiOutlinedInput-input::placeholder': {
                color: theme.palette.text.soft,
                opacity: '1 !important',
            },
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${theme.palette.container.border.input}`,
        },
        '&.Mui-disabled': {
            '& .MuiOutlinedInput-notchedOutline': {
                border: `1px solid ${theme.palette.container.border.default}`,
            },
            [`& .${autocompleteClasses.popupIndicator}`]: {
                color: theme.palette.container.border.default,
            },
            '& .MuiTypography-root': {
                color: theme.palette.text.soft,
            },
        },
        '& .MuiTypography-root': {
            color: theme.palette.text.title,
        },
    },
}));
