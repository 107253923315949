import hljs from 'highlight.js/lib/core';
import 'highlight.js/styles/atom-one-dark.css';
import Apex from './languages/apex';
import Pseudocode from './languages/pseudocode';
import Terraform from './languages/terraform';
// Subset of the default syntaxes
import Apache from 'highlight.js/lib/languages/apache';
import Bash from 'highlight.js/lib/languages/bash';
import C from 'highlight.js/lib/languages/c';
import Clojure from 'highlight.js/lib/languages/clojure';
import CPlusPlus from 'highlight.js/lib/languages/cpp';
import CSharp from 'highlight.js/lib/languages/csharp';
import Css from 'highlight.js/lib/languages/css';
import Dart from 'highlight.js/lib/languages/dart';
import Delphi from 'highlight.js/lib/languages/delphi';
import Diff from 'highlight.js/lib/languages/diff';
import Django from 'highlight.js/lib/languages/django';
import Dockerfile from 'highlight.js/lib/languages/dockerfile';
import Dos from 'highlight.js/lib/languages/dos';
import Elixir from 'highlight.js/lib/languages/elixir';
import Elm from 'highlight.js/lib/languages/elm';
import Erb from 'highlight.js/lib/languages/erb';
import Erlang from 'highlight.js/lib/languages/erlang';
import Fortran from 'highlight.js/lib/languages/fortran';
import FSharp from 'highlight.js/lib/languages/fsharp';
import Gherkin from 'highlight.js/lib/languages/gherkin';
import Go from 'highlight.js/lib/languages/go';
import Gradle from 'highlight.js/lib/languages/gradle';
import Groovy from 'highlight.js/lib/languages/groovy';
import Haml from 'highlight.js/lib/languages/haml';
import Handlebars from 'highlight.js/lib/languages/handlebars';
import Haskell from 'highlight.js/lib/languages/haskell';
import Haxe from 'highlight.js/lib/languages/haxe';
import Hy from 'highlight.js/lib/languages/hy';
import Ini from 'highlight.js/lib/languages/ini';
import Java from 'highlight.js/lib/languages/java';
import Javascript from 'highlight.js/lib/languages/javascript';
import Json from 'highlight.js/lib/languages/json';
import Julia from 'highlight.js/lib/languages/julia';
import Kotlin from 'highlight.js/lib/languages/kotlin';
import Less from 'highlight.js/lib/languages/lisp';
import Lisp from 'highlight.js/lib/languages/less';
import Lua from 'highlight.js/lib/languages/lua';
import Makefile from 'highlight.js/lib/languages/makefile';
import Markdown from 'highlight.js/lib/languages/markdown';
import Nginx from 'highlight.js/lib/languages/nginx';
import ObjectiveC from 'highlight.js/lib/languages/objectivec';
import Perl from 'highlight.js/lib/languages/perl';
import Pgsql from 'highlight.js/lib/languages/pgsql';
import Php from 'highlight.js/lib/languages/php';
import PhpTemplate from 'highlight.js/lib/languages/php-template';
import Plaintext from 'highlight.js/lib/languages/plaintext';
import Powershell from 'highlight.js/lib/languages/powershell';
import Profile from 'highlight.js/lib/languages/profile';
import Properties from 'highlight.js/lib/languages/properties';
import Python from 'highlight.js/lib/languages/python';
import R from 'highlight.js/lib/languages/r';
import Ruby from 'highlight.js/lib/languages/ruby';
import Rust from 'highlight.js/lib/languages/rust';
import Scala from 'highlight.js/lib/languages/scala';
import Scss from 'highlight.js/lib/languages/scss';
import Shell from 'highlight.js/lib/languages/shell';
import Sql from 'highlight.js/lib/languages/sql';
import Stylus from 'highlight.js/lib/languages/stylus';
import Swift from 'highlight.js/lib/languages/swift';
import Twig from 'highlight.js/lib/languages/twig';
import Typescript from 'highlight.js/lib/languages/typescript';
import Vbnet from 'highlight.js/lib/languages/vbnet';
import Xml from 'highlight.js/lib/languages/xml';
import Yaml from 'highlight.js/lib/languages/yaml';
// Register default languages
hljs.registerLanguage('apache', Apache);
hljs.registerLanguage('bash', Bash);
hljs.registerLanguage('c', C);
hljs.registerLanguage('clojure', Clojure);
hljs.registerLanguage('cpp', CPlusPlus);
hljs.registerLanguage('csharp', CSharp);
hljs.registerLanguage('css', Css);
hljs.registerLanguage('dart', Dart);
hljs.registerLanguage('delphi', Delphi);
hljs.registerLanguage('diff', Diff);
hljs.registerLanguage('django', Django);
hljs.registerLanguage('dockerfile', Dockerfile);
hljs.registerLanguage('dos', Dos);
hljs.registerLanguage('elixir', Elixir);
hljs.registerLanguage('eln', Elm);
hljs.registerLanguage('erlang', Erlang);
hljs.registerLanguage('erb', Erb);
hljs.registerLanguage('fortran', Fortran);
hljs.registerLanguage('fsharp', FSharp);
hljs.registerLanguage('gherkin', Gherkin);
hljs.registerLanguage('go', Go);
hljs.registerLanguage('gradle', Gradle);
hljs.registerLanguage('groovy', Groovy);
hljs.registerLanguage('haml', Haml);
hljs.registerLanguage('handlebars', Handlebars);
hljs.registerLanguage('haskell', Haskell);
hljs.registerLanguage('haxe', Haxe);
hljs.registerLanguage('hy', Hy);
hljs.registerLanguage('ini', Ini);
hljs.registerLanguage('java', Java);
hljs.registerLanguage('javascript', Javascript);
hljs.registerLanguage('json', Json);
hljs.registerLanguage('julia', Julia);
hljs.registerLanguage('kotlin', Kotlin);
hljs.registerLanguage('less', Less);
hljs.registerLanguage('lisp', Lisp);
hljs.registerLanguage('lua', Lua);
hljs.registerLanguage('makefile', Makefile);
hljs.registerLanguage('markdown', Markdown);
hljs.registerLanguage('nginx', Nginx);
hljs.registerLanguage('objectivec', ObjectiveC);
hljs.registerLanguage('perl', Perl);
hljs.registerLanguage('pgsql', Pgsql);
hljs.registerLanguage('php', Php);
hljs.registerLanguage('php-template', PhpTemplate);
hljs.registerLanguage('plaintext', Plaintext);
hljs.registerLanguage('powershell', Powershell);
hljs.registerLanguage('profile', Profile);
hljs.registerLanguage('properties', Properties);
hljs.registerLanguage('python', Python);
hljs.registerLanguage('r', R);
hljs.registerLanguage('ruby', Ruby);
hljs.registerLanguage('rust', Rust);
hljs.registerLanguage('scala', Scala);
hljs.registerLanguage('scss', Scss);
hljs.registerLanguage('shell', Shell);
hljs.registerLanguage('sql', Sql);
hljs.registerLanguage('stylus', Stylus);
hljs.registerLanguage('swift', Swift);
hljs.registerLanguage('twig', Twig);
hljs.registerLanguage('typescript', Typescript);
hljs.registerLanguage('vbnet', Vbnet);
hljs.registerLanguage('xml', Xml);
hljs.registerLanguage('yaml', Yaml);
hljs.registerLanguage('apex', Apex);
hljs.registerLanguage('pseudocode', Pseudocode);
hljs.registerLanguage('terraform', Terraform);
export default hljs;
