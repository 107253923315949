var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { teamTestData, teamDetailsTestData } from '../__fixtures__/teams';
import { testUserData } from '../__fixtures__/users';
export const MOCK_API_ENDPOINT = 'http://localhost:3000';
const getTeams = {
    default: { kind: 'ok', teams: teamTestData },
    error: { kind: 'error', message: 'An error occurred' },
    minimal: { kind: 'ok', teams: teamTestData.slice(0, 10) },
    empty: { kind: 'ok', teams: [] },
    parseError: {
        kind: 'ok',
        teams: teamTestData.slice(0, 5).map((_a) => {
            var { status: _status } = _a, user = __rest(_a, ["status"]);
            return user;
        }),
    },
};
const getUsersResponseMap = {
    default: { kind: 'ok', users: testUserData.slice(0, 50) },
    error: { kind: 'error', message: 'An error occurred' },
    minimal: { kind: 'ok', users: testUserData.slice(50, 60) },
    empty: { kind: 'ok', users: [] },
    parseError: {
        kind: 'ok',
        users: testUserData.slice(60, 65).map((_a) => {
            var { status: _status } = _a, user = __rest(_a, ["status"]);
            return user;
        }),
    },
};
const rpcUrl = (endpoint) => {
    const url = new URL(endpoint, MOCK_API_ENDPOINT);
    url.searchParams.set('batch', '1');
    url.searchParams.set('input', '{"0":{"json":null,"meta":{"values":["undefined"]}}}');
    return url.toString();
};
export const createMockGetTeamsRequest = (type = 'default') => {
    return {
        url: rpcUrl('/rpc/userManagement.getTeams'),
        method: 'GET',
        status: 200,
        response: { result: { data: { json: getTeams[type], meta: {} } } },
    };
};
export const createMockGetTeamDetailsRequest = (id) => {
    var _a;
    return {
        url: `${MOCK_API_ENDPOINT}/management/admin/teams/${id}`,
        method: 'GET',
        status: 200,
        response: (_a = teamDetailsTestData.find((team) => team._id === id)) !== null && _a !== void 0 ? _a : Object.assign(Object.assign({}, teamDetailsTestData[0]), { _id: id }),
    };
};
export const createMockGetUsersRequest = (type = 'default') => {
    return {
        url: rpcUrl('/rpc/userManagement.getUsers'),
        method: 'GET',
        status: 200,
        response: { result: { data: { json: getUsersResponseMap[type], meta: {} } } },
    };
};
