import { useMemo } from 'react';
import { useUsers } from './useUsers';
import { createGridProps } from '../utils/createGridProps';
import { gridUsers } from '../schema/user';
import { useTeams } from './useTeams';
export const useDataGridData = () => {
    const { users, error, isLoading, isQueryError, isInvitingError, tags, refetch } = useUsers();
    // Prefetch teams data
    const { teams } = useTeams();
    const isMutationError = isInvitingError;
    const gridProps = useMemo(() => createGridProps(users.length), [users.length]);
    return {
        users: gridUsers.parse(users),
        tags,
        teams,
        isLoading,
        isQueryError,
        error,
        isMutationError,
        gridProps,
        refetch,
    };
};
