var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ZodError } from 'zod';
import { queryOptions, useQueryClient } from '@tanstack/react-query';
import { useTrpc } from '../../common/hooks';
export const useUsersQueryOptions = () => {
    const { trpc, ctx } = useTrpc();
    const queryClient = useQueryClient();
    const config = queryOptions({
        queryKey: ['trpc-users-list', ctx === null || ctx === void 0 ? void 0 : ctx.sessionId],
        enabled: Boolean(ctx === null || ctx === void 0 ? void 0 : ctx.sessionId),
        retry: (failureCount, error) => (error instanceof ZodError ? false : failureCount < 3),
        queryFn: () => __awaiter(void 0, void 0, void 0, function* () {
            const response = yield trpc.userManagement.getUsers.query();
            if (response.kind !== 'ok')
                throw new Error(response.message);
            return response.users;
        }),
    });
    const onMutate = (handler) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        yield queryClient.invalidateQueries(config);
        const previous = (_a = queryClient.getQueryData(config.queryKey)) !== null && _a !== void 0 ? _a : [];
        queryClient.setQueryData(config.queryKey, handler(previous));
        return { previous };
    });
    const onSettled = () => __awaiter(void 0, void 0, void 0, function* () {
        yield queryClient.invalidateQueries(config);
    });
    const onError = (_error, _variables, ctx) => __awaiter(void 0, void 0, void 0, function* () {
        if (ctx === null || ctx === void 0 ? void 0 : ctx.previous) {
            queryClient.setQueryData(config.queryKey, ctx.previous);
        }
    });
    return { config, onMutate, onSettled, onError };
};
