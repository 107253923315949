var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { styled, Tooltip as MuiTooltip, tooltipClasses } from '@mui/material';
export const Tooltip = styled((_a) => {
    var { className } = _a, props = __rest(_a, ["className"]);
    return (_jsx(MuiTooltip, Object.assign({}, props, { classes: { popper: className } })));
})(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: Object.assign(Object.assign({ backgroundColor: theme.palette.container.fill.card1, borderRadius: 2 * theme.shape.borderRadius, border: `1px solid ${theme.palette.container.border.default}`, padding: theme.spacing(3) }, theme.typography.body1), { color: theme.palette.text.title, boxShadow: `0px 2px 4px 0px rgba(21, 21, 21, 0.08), 0px 1px 2px 0px, rgba(21, 21, 21, 0.08)` }),
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.container.fill.card1,
        '&::before': {
            border: `1px solid ${theme.palette.container.border.default}`,
        },
    },
}));
export default Tooltip;
