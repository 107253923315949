import { jsx as _jsx } from "react/jsx-runtime";
import { Dialog } from '../../../../common/Dialog';
import { BulkActionType } from '../../hooks/useBulkActions';
import { DeleteUsersDialog } from './DeleteUsersDialog';
import { ChangeTeamsDialog } from './dialogs/ChangeTeamsDialog';
import { DisableUsersDialog } from './DisableUsersDialog';
import { EnableUsersDialog } from './EnableUsersDialog';
import { ManageTagsDialog } from './ManageTagsDialog';
import { ReinviteUsersDialog } from './ReinviteUsersDialog';
import { ResetPasswordsDialog } from './ResetPasswordsDialog';
import { ChangeRolesDialog } from './ChangeRolesDialog';
const modalComponents = {
    [BulkActionType.ENABLE_USERS]: EnableUsersDialog,
    [BulkActionType.DISABLE_USERS]: DisableUsersDialog,
    [BulkActionType.CHANGE_TEAMS]: ChangeTeamsDialog,
    [BulkActionType.MANAGE_TAGS]: ManageTagsDialog,
    [BulkActionType.REINVITE_USERS]: ReinviteUsersDialog,
    [BulkActionType.RESET_PASSWORDS]: ResetPasswordsDialog,
    [BulkActionType.CHANGE_ROLES]: ChangeRolesDialog,
    [BulkActionType.DELETE_USERS]: DeleteUsersDialog,
};
export const BulkActionModal = ({ actionType, onClose, onConfirm, selectedCount, updatedCount, selectedUserIds, goneAsync, currentUserSelected, companyAdminEmails, }) => {
    const ContentComponent = actionType ? modalComponents[actionType] : null;
    return (_jsx(Dialog, { open: !!actionType, onClose: onClose, children: ContentComponent && (_jsx(ContentComponent, Object.assign({ userCount: selectedCount, onConfirm: onConfirm, onClose: onClose, updatedCount: updatedCount, selectedUserIds: selectedUserIds, goneAsync: goneAsync }, (actionType === BulkActionType.DELETE_USERS && { currentUserSelected }), { companyAdminEmails: companyAdminEmails }))) }));
};
