import { jsx as _jsx } from "react/jsx-runtime";
import { IconButton } from './IconButton/IconButton';
import { CloseRounded as CloseRoundedIcon } from '@mui/icons-material';
import { deepmerge } from '@mui/utils';
import { useTranslation } from 'react-i18next';
export const CloseIconButton = (props) => {
    var _a;
    const { t } = useTranslation('react_components', { keyPrefix: 'components.atoms.closeIconButton' });
    return (_jsx(IconButton, { sx: deepmerge({ width: '30px', height: '30px' }, props.sx), "aria-label": (_a = props['aria-label']) !== null && _a !== void 0 ? _a : t('ariaLabel'), onClick: props.onClick, children: _jsx(CloseRoundedIcon, {}) }));
};
