import angular from 'angular';
import MODULE from './module';

import DsAssessmentResultsTemplate from './DsAttemptResultsDirective.html';
import { getRetriesAllowed } from '../utils';
import { getLanguageInformation } from '../../util/metadata';
import { AssessmentNavigationService } from '../assessmentNavigationService';

const milliSecondsToSeconds = (time) => time / 1000;

const codeBaseSize = {
  'full app': 'large',
  'mini challenges': 'small',
  'legacy mini challenges': 'small',
};
const getCodeBaseSize = (size) => {
  return codeBaseSize[size] ?? 'unknown';
};

const statusMap = {
  pending: 'in_progress',
  'in-progress': 'in_progress',
  failed: 'failed',
  completed: 'passed',
  succeeded: 'passed',
};

const getStatus = (entry, stage, scope) => {
  const status = scope.cblOutcome(entry, stage);

  switch (status) {
    case 'in_progress':
    case 'out_of_reach':
      return 'incomplete';
    case 'correct':
    case 'incorrect':
    default:
      return status;
  }
};

const transformAttempt = (scope) => {
  const viewable = scope.canView();
  return {
    name: scope.assessment.name,
    version: scope.assessment.version + 1,
    description: scope.assessment.description,
    status: scope.attempt.progress?.statuses?.includes('abandoned') ? 'abandoned' : statusMap[scope.attemptStatus()],
    timeLimit: scope.assessment.timeLimit,
    startedAt: scope.attempt.started ? milliSecondsToSeconds(scope.attempt.started) : null,
    finishedAt: scope.attempt.completed ? milliSecondsToSeconds(scope.attempt.completed) : null,
    programId: scope.attempt.programId,
    _lastProgramNavigation: scope.attempt._lastProgramNavigation,
    onDownloadCertificate: scope.hasCertificateAvailable()
      ? function () {
          return scope.downloadCertificate(scope.assessment._id, scope.attempt._id);
        }
      : undefined,
    assessmentAttemptDetailsProps: transformAssessmentDetails(scope),
    assessmentMetricsProps: viewable ? transformMetrics(scope) : undefined,
    strengthPerCategoryProps: viewable ? transformStrengthPerCategory(scope) : undefined,
  };
};

const transformAssessmentDetails = (scope) => ({
  selectedLanguage: scope.attempt.language
    ? getLanguageInformation(scope.$root.metadata.languages)(scope.attempt.language)
    : null,
  challengeDifficulty: scope.assessment.difficulty,
  retriesAllowed: getRetriesAllowed(scope.assessment),
  successRatio: scope.assessment.successRatio,
  challenges: scope.assessment.numberOfChallenges,
  fixedChallenges: scope.assessment.fixedChallenges,
  selfAssessmentAllowed: scope.assessment.selfAssess,
});

const getAverageScore = (scope) => {
  if (scope.assessment.metrics.average_score) {
    return scope.assessment.metrics.average_score;
  }

  if (scope.attempt.score) {
    return scope.attempt.score * 100;
  }

  return 0;
};

const transformMetrics = (scope) => ({
  score: (scope.attempt.score || 0) * 100,
  avgScore: getAverageScore(scope),
  locate: scope.attempt.metrics.general.locate,
  identify: scope.attempt.metrics.general.identify,
  solve: scope.attempt.metrics.general.solve,
  completion: scope.progress(),
});

const transformStrengthPerCategory = (scope) => ({
  categories: scope.attempt.log.map((entry) => ({
    id: entry._id,
    name: scope.subcategoryName(entry.category || entry.challenge.category),
    weight: (entry.max / scope.attempt.points.max) * 100,
    codeBaseSize: getCodeBaseSize(entry.repoType),
    difficulty: scope.calculateDifficulty(entry).toLowerCase(),
    timeSpent: entry.timeSpent ? entry.timeSpent : null,
    weightedScore: (entry.points / scope.attempt.points.max) * 100,
    score: (entry.points / entry.max) * 100,

    locate: getStatus(entry, 'locate', scope),
    solve: getStatus(entry, 'solve', scope),
    identify: getStatus(entry, 'identify', scope),
  })),
});

angular.module(MODULE).directive('dsScwAttemptResultsPage', [
  '$state',
  '$rootScope',
  'AssessmentsApiService',
  '$location',
  function ($state, $rootScope, AssessmentsApiService, $location) {
    return {
      restrict: 'E',
      scope: {
        assessment: '<',
        attempt: '<',
        attemptPromise: '<',
        attemptStatus: '<',
        calculateDifficulty: '<',
        canView: '<',
        cblOutcome: '<',
        hasCertificateAvailable: '<',
        progress: '<',
        subcategoryName: '<',
      },
      templateUrl: DsAssessmentResultsTemplate,
      link: function (scope) {
        scope.assessmentNavigationService = new AssessmentNavigationService();
        scope.assessmentNavigationService.setFrom($location.search());
        scope.navigationDetails = scope.assessmentNavigationService.getReturnDetails();

        const assessmentUrl = $state.href('assessments.view', {
          assessmentId: $state.params.assessmentId,
        });

        scope.$watch('attemptPromise', (promise) => {
          scope.assessmentDetails = promise.then((_attempt) => transformAttempt(scope));
          promise.then((_attempt) => {
            if (_attempt._lastProgramNavigation || _attempt.programId) {
              scope.breadcrumbs = {
                assessmentsListOrProgramUrl: `#/programs/my/${_attempt._lastProgramNavigation || _attempt.programId}`,
                assessmentUrl,
                mode: 'program',
              };
            }
          });
        });

        if ($rootScope.isLMSInitialized()) {
          scope.breadcrumbs = null;
        } else {
          if (scope.navigationDetails?.param) {
            scope.breadcrumbs = {
              assessmentsListOrProgramUrl: $state.href(scope.navigationDetails.state, {
                programId: `${scope.navigationDetails.param}`,
              }),
              assessmentUrl,
              mode: 'program',
            };
          } else {
            scope.breadcrumbs = {
              assessmentsListOrProgramUrl: $state.href('assessments.list'),
              assessmentUrl,
              mode: 'assessment',
            };
          }
        }

        scope.downloadCertificate = (assessment, attempt) => {
          const _assessment = assessment._id || assessment;
          const _attempt = attempt._id || attempt;

          AssessmentsApiService.addLoading();
          return AssessmentsApiService.downloadAssessmentCertificate(_assessment, _attempt)
            .then(function (response) {
              document.location.href = response.body.url;
            })
            .finally(function () {
              AssessmentsApiService.removeLoading();
            });
        };
      },
    };
  },
]);
