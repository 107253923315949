import { z } from 'zod';
export const roleEnum = z.enum(['company admin', 'developer', 'team manager', 'user', 'cms developer']);
export const gridRoleEnum = z.enum(['company admin', 'team manager', 'developer']);
export const filteredRoles = roleEnum.exclude(['user', 'cms developer']);
export const statusEnum = z.enum(['disabled', 'enabled', 'invited', 'registered']);
export const userProfileSchema = z.object({
    name: z.object({
        first: z.string(),
        middle: z.string(),
        last: z.string(),
    }),
});
