import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Typography, Stack } from '@mui/material';
import { Button, OptionSelect } from '@securecodewarrior/design-system-react/lib/wanda';
import { CheckCircleRounded as MuiCheckIcon } from '@mui/icons-material';
import { UserManagementModal } from './UserManagementModal';
import { TagInput } from '../../../../common/TagInput/TagInput';
import { useUsers } from '../../hooks/useUsers';
import { useState } from 'react';
import { DialogSection } from '../../../../common/Dialog';
import { CloseIconButton } from '../../../../../common/components/atoms/CloseIconButton';
import { BulkActionsAsyncDialog } from './BulkActionsAsyncDialog';
const BulkManageTagsPending = ({ onClose, onConfirm, userCount }) => {
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.userManagement' });
    const { tags } = useUsers();
    // Define initial state for both the selected action and the tag input.
    const [state, setState] = useState({
        selectedAction: 'add', // default action, e.g. "add"
        tags: [],
    });
    // actionOptions may be defined according to your needs.
    const actionOptions = {
        add: t('manageTagsDialog.addTags'),
        remove: t('manageTagsDialog.findAndRemoveTags'),
    };
    // A simple change handler for OptionSelect.
    const handleActionChange = (newAction) => {
        setState((prevState) => (Object.assign(Object.assign({}, prevState), { selectedAction: newAction })));
    };
    // A change handler for TagInput.
    const handleTagChange = (newTags) => {
        setState((prevState) => (Object.assign(Object.assign({}, prevState), { tags: newTags })));
    };
    return (_jsxs(_Fragment, { children: [_jsx(DialogSection, { children: _jsxs(Stack, { gap: 6, children: [_jsx(Typography, { variant: "h3", children: t('manageTagsDialog.title', { count: userCount }) }), _jsxs(Stack, { gap: 6, children: [_jsx(Typography, { children: t('manageTagsDialog.description') }), _jsxs(Stack, { gap: 3, children: [_jsx(Stack, { width: 333, children: _jsx(OptionSelect, { label: 'Action', options: actionOptions, value: state.selectedAction, onChange: handleActionChange }) }), _jsx(TagInput, { value: state.tags, onChange: handleTagChange, options: tags, disabled: false })] })] }), _jsx(CloseIconButton, { onClick: onClose, sx: {
                                position: 'absolute',
                                background: 'transparent',
                                top: (theme) => theme.spacing(3),
                                right: (theme) => theme.spacing(3),
                                '&:hover': {
                                    background: 'transparent',
                                },
                            } })] }) }), _jsx(DialogSection, { footer: true, children: _jsxs(Stack, { direction: "row", justifyContent: "space-between", children: [_jsx(Button, { variant: "outlined", onClick: onClose, children: t('common.cancel') }), _jsx(Button, { variant: "contained", onClick: () => onConfirm(state), children: t('manageTagsDialog.confirm', { count: userCount }) })] }) })] }));
};
const BulkManageTagsSuccess = ({ onClose, userCount, updatedCount }) => {
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.userManagement' });
    const alreadyHasTagsCount = userCount - (updatedCount || 0);
    return (_jsx(UserManagementModal, { title: t('manageTagsDialog.successTitle', { count: updatedCount }), icon: _jsx(MuiCheckIcon, { sx: { fontSize: '56px', color: (theme) => theme.palette.text.status.success } }), footer: _jsx(Stack, { direction: "row", justifyContent: "flex-end", children: _jsx(Button, { variant: "contained", onClick: onClose, children: t('common.okay') }) }), children: alreadyHasTagsCount > 0 && (_jsx(Typography, { children: t('manageTagsDialog.alreadyReinvited', {
                count: alreadyHasTagsCount,
            }) })) }));
};
export const ManageTagsDialog = ({ onClose, onConfirm, userCount, updatedCount, goneAsync }) => {
    return goneAsync === true ? (_jsx(BulkActionsAsyncDialog, { onClose: onClose })) : updatedCount || updatedCount === 0 ? (_jsx(BulkManageTagsSuccess, { userCount: userCount, updatedCount: updatedCount, onClose: onClose })) : (_jsx(BulkManageTagsPending, { userCount: userCount, onConfirm: onConfirm, onClose: onClose }));
};
