import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Typography, Skeleton, IconButton, Stack, Box } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import { StatusIcon } from '../../../common/icons/StatusIcon';
import { formatDateLongMonth } from '../../../dateHelper';
import { usePlatformContext } from '../../../platformContext';
import { KeyboardArrowDownRounded as DownArrowIcon, KeyboardArrowUpRounded as UpArrowIcon } from '@mui/icons-material';
export const TopicCardHeader = ({ toggleCollapse, chapterNr, nr, topic, collapsed, topicTimeEstimate, ariaControlsId,
// eslint-disable-next-line sonarjs/cognitive-complexity
 }) => {
    const { t } = useTranslation();
    const { getCategoryTranslation } = usePlatformContext();
    return (_jsxs(Stack, { direction: "row", alignItems: "center", sx: (theme) => ({
            '&:hover button svg': {
                color: theme.palette.text.title,
            },
        }), children: [_jsxs(Stack, { direction: "row", justifyContent: "space-between", py: 4, pl: 7, minHeight: (theme) => theme.typography.pxToRem(72), onClick: toggleCollapse, sx: { cursor: 'pointer' }, flexGrow: "1", children: [_jsxs(Stack, { direction: "row", alignItems: "center", gap: 2, children: [_jsx(Stack, { width: "46px", alignItems: "center", justifyContent: "center", height: "100%", children: topic && topic.completedAt ? (_jsx(StatusIcon, { status: "passed" })) : chapterNr !== undefined && nr !== undefined ? (_jsxs(Typography, { variant: "h3", component: "p", sx: (theme) => ({ color: theme.palette.text.soft }), children: [chapterNr, ".", nr] })) : (_jsx(Skeleton, { sx: { height: '100%', aspectRatio: '1/1', transform: 'unset' } })) }), _jsxs(Box, { children: [_jsx(Typography, { variant: "h6", component: topic ? 'h3' : 'div', children: topic ? getCategoryTranslation(topic.category) : _jsx(Skeleton, {}) }), collapsed !== false && (_jsx(Stack, { gap: 2, direction: "row", children: topic ? (_jsxs(_Fragment, { children: [topic.activities.length > 0 && (_jsx(TopicSectionProgressText, { name: t('pages.quests.activities'), activities: topic.activities })), topic.validation.length > 0 && (_jsx(TopicSectionProgressText, { name: t('pages.quests.quiz'), activities: topic.validation }))] })) : (_jsxs(_Fragment, { children: [_jsx(Skeleton, { width: "120px" }), _jsx(Skeleton, { width: "120px" })] })) }))] })] }), _jsx(Stack, { direction: "row", alignItems: "center", justifyContent: "center", children: topic && topic.completedAt && topic.expiresAt ? (_jsx(Typography, { variant: "caption", sx: { em: { fontWeight: 700 } }, children: _jsx(Trans, { i18nKey: "pages.quests.refresherRequiredOn", values: {
                                    date: formatDateLongMonth(topic.expiresAt),
                                } }) })) : topicTimeEstimate !== undefined ? (_jsx(Typography, { variant: "caption", sx: { whiteSpace: 'nowrap', verticalAlign: 'middle' }, children: t('common.datetime.mins', { minutes: topicTimeEstimate }) })) : (_jsx(Skeleton, { variant: "text", width: "50px" })) })] }), topic ? (_jsx(IconButton, { onClick: toggleCollapse, "aria-label": t(`pages.quests.accessibility.${collapsed ? 'expand' : 'collapse'}`, { name: topic.category }), "aria-expanded": !collapsed, "aria-controls": ariaControlsId, sx: (theme) => ({
                    pl: 1,
                    py: 4,
                    pr: 7,
                    ':focus-visible svg': {
                        outline: `2px solid ${theme.palette.clickable.default}`,
                        borderRadius: '50%',
                    },
                    svg: {
                        color: theme.palette.text.soft,
                        fontSize: theme.typography.pxToRem(24),
                    },
                }), children: collapsed ? _jsx(DownArrowIcon, {}) : _jsx(UpArrowIcon, {}) })) : (_jsx(Skeleton, { variant: "circular", sx: (theme) => ({ ml: 1, mr: 7, width: theme.typography.pxToRem(24), height: theme.typography.pxToRem(24) }) }))] }));
};
const TopicSectionProgressText = ({ name, activities }) => (_jsxs(Typography, { children: [_jsx(Box, { component: "span", display: "inline", fontWeight: 700, children: activities.filter((x) => x.finished).length }), "/", activities.length, "\u00A0", name] }));
