var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Container, DSThemeProvider } from '@securecodewarrior/design-system-react';
import { Typography, Stack, SvgIcon, Box } from '@mui/material';
import { usePlatformContext } from '../../platformContext';
import { Home } from '@mui/icons-material';
import { LearningProgress } from '../components/organisms/learningProgress/LearningProgress';
import { CommunicationsToaster, CommunicationsToasterMessage, } from '../../administration/communications/Communications.styled';
import { Button, Link, Badge } from '@securecodewarrior/design-system-react/lib/wanda';
import { useDashboard } from '../context/dashboardContext';
import { TrustScore } from '../components/organisms/TrustScore/TrustScore';
import { LicenceUsage } from '../components/organisms/licenceUsage/LicenceUsage';
import { LearnerEngagement } from '../components/organisms/LearnerEngagement/LearnerEngagement';
import { useDashboardAnalytics } from '../hooks/useDashboardAnalytics';
import { useTranslation, Trans } from 'react-i18next';
import { isDemoModeEnabled } from '../../demo/isDemoModeEnabled';
const DashboardHero = () => {
    var _a, _b, _c;
    const { sessionUser } = usePlatformContext();
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.dashboard' });
    return (_jsxs(Stack, { direction: "row", justifyContent: "space-between", mb: 10, children: [_jsxs(Stack, { direction: "row", alignItems: "center", gap: 3, children: [_jsx(SvgIcon, { sx: { fontSize: 40 }, children: _jsx(Home, {}) }), _jsxs(Stack, { children: [_jsx(Typography, { variant: "overline", color: "text.soft", children: t('title') }), _jsx(Typography, { component: "h2", variant: "h2", children: ((_c = (_b = (_a = sessionUser === null || sessionUser === void 0 ? void 0 : sessionUser.properties) === null || _a === void 0 ? void 0 : _a.profile) === null || _b === void 0 ? void 0 : _b.name) === null || _c === void 0 ? void 0 : _c.first)
                                    ? `${t('welcome')}, ${sessionUser.properties.profile.name.first}!`
                                    : `${t('welcome')}!` })] })] }), isDemoModeEnabled() && (_jsx(Badge, { color: "red", sx: { alignSelf: 'flex-end' }, children: _jsx(Typography, { children: t('demoMode') }) }))] }));
};
const getErrorMessage = (cards) => {
    const errSet = Object.values(cards).reduce((result, { error }) => {
        error && result.add(error.message);
        return result;
    }, new Set());
    if (errSet.size === 0) {
        return undefined;
    }
    if (errSet.size === 1) {
        return errSet.values().next().value;
    }
    return 'pages.administration.dashboard.errors.default';
};
const DashboardToaster = () => {
    const { state, refreshFailedCards } = useDashboard();
    const [isVisible, setIsVisible] = useState(true);
    const [hasRefreshed, setHasRefreshed] = useState(false);
    const { t } = useTranslation();
    const message = getErrorMessage(state.cards);
    const handleRefresh = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsVisible(false);
        yield refreshFailedCards();
        // Wait a bit before showing potential new errors
        setTimeout(() => {
            setIsVisible(true);
            setHasRefreshed(true);
        }, 1000);
    });
    return (_jsx(CommunicationsToaster, { open: !!message && isVisible, anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: null, message: _jsx(CommunicationsToasterMessage, { children: _jsxs(Box, { display: "flex", flexDirection: "column", children: [_jsx(Typography, { children: t(message) }), hasRefreshed && !(message === null || message === void 0 ? void 0 : message.includes('network')) && (_jsx(Typography, { mt: 2, children: _jsx(Trans, { i18nKey: "pages.administration.dashboard.support", components: {
                                1: _jsx(Link, { href: "mailto:support@securecodewarrior.com", target: "_blank", rel: "noopener noreferrer" }),
                            } }) }))] }) }), action: _jsx(DSThemeProvider, { theme: "light", children: _jsx(Button, { variant: "text", onClick: handleRefresh, children: t('pages.administration.dashboard.refresh') }) }) }));
};
export const DashboardPage = () => {
    const { emitEvent } = useDashboardAnalytics();
    emitEvent('loading_admin_dashboard');
    return (_jsxs(Container, { children: [_jsx(DashboardHero, {}), _jsx(Stack, { gap: 10, children: _jsxs(Box, { sx: {
                        display: 'grid',
                        gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
                        gap: 6,
                    }, children: [_jsx(LearningProgress, {}), _jsx(LicenceUsage, {}), _jsx(TrustScore, {}), _jsx(LearnerEngagement, {})] }) }), _jsx(DashboardToaster, {})] }));
};
