import angular from 'angular';
import MODULE from './module';
import { AUTH_EVENTS } from '../auth/constants';

const app = angular.module(MODULE);

app.run([
  '$rootScope',
  '$state',
  'LearningModulesApi',
  ($rootScope, $state, LearningModulesApi) => {
    // Currently this does not check for url changes within the scw-react part of the application
    // I use $stateChangeStart instead of $locationChangeStart since this also gets triggered when navigating to the
    // same page again
    $rootScope.$on('$stateChangeStart', (_event, next, _current) => {
      const initialUrl = $state.href(next.name, {});
      LearningModulesApi.refreshLearningModules().then(() => {
        const url = $state.href($state.current.name, {});
        if (initialUrl !== url) {
          // No longer relevant, we've already changed to a different page
          return;
        }

        if (url.startsWith('#/assessments') || url.startsWith('#/courses') || url.startsWith('#/programs')) {
          return LearningModulesApi.isLearningModuleEnabled('guidedLearning').then((enabled) => {
            if (enabled === false) {
              $rootScope.defaultRedirects();
            }
          });
        }

        if (
          url.startsWith('#/quests') ||
          url.startsWith('#/learn') ||
          url.startsWith('#/my-quests') ||
          url.startsWith('#/admin/quests')
        ) {
          return LearningModulesApi.isLearningModuleEnabled('quests').then((enabled) => {
            if (enabled === false) {
              $rootScope.defaultRedirects();
            }
          });
        }

        if (url.startsWith('#/training')) {
          return LearningModulesApi.isLearningModuleEnabled('training').then((trainingEnabled) => {
            if (trainingEnabled === false) {
              $rootScope.defaultRedirects();
            }
          });
        }
      });
    });

    $rootScope.$on(AUTH_EVENTS.loginSuccess, async (_event, _next, _current) => {
      await LearningModulesApi.refreshLearningModules(true);
    });
  },
]);
