import { Box, styled, Typography } from '@mui/material';
export const StyledContainer = styled(Box)(({ theme }) => ({
    maxWidth: '444px',
    maxHeight: '240px',
    overflowY: 'auto',
    padding: theme.spacing(3),
    gap: theme.spacing(2),
    borderRadius: theme.shape.borderRadius * 2,
    border: `1px solid ${theme.palette.container.border.input}`,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.container.fill.card1,
    // For data-disabled state
    '&[data-disabled="true"]': {
        borderColor: theme.palette.container.border.default,
        cursor: 'not-allowed',
        pointerEvents: 'none',
    },
    // For webKit-based browsers (Chrome, Edge, Safari)
    '&::-webkit-scrollbar': {
        width: '7px',
    },
    '&::-webkit-scrollbar-track': {
        background: '#BDBDBD',
        borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
        background: '#BDBDBD',
        borderRadius: '4px',
        boxShadow: 'inset 0 0 0 7px #BDBDBD',
        height: '44px',
    },
    // For Modern Firefox
    scrollbarWidth: 'thin',
    scrollbarColor: '#BDBDBD transparent',
}));
export const StyledInputWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    rowGap: theme.spacing(2),
    columnGap: theme.spacing(1),
    backgroundColor: theme.palette.container.fill.card1,
    borderRadius: theme.shape.borderRadius * 2,
    '&:focus-within': {
        borderColor: theme.palette.primary.main,
    },
}));
export const Title = styled(Typography)(({ theme }) => ({
    fontFamily: theme.typography.caption.fontFamily,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 400,
    lineHeight: '16px',
    textDecorationSkipInk: 'none',
    color: theme.palette.text.soft,
}));
