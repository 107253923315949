// Demo data from reporting-service-frontend repo
// https://github.com/SecureCodeWarrior/reporting-service-frontend/blob/main/src/demo/demoUsers.ts
export const demoUsers = [
    {
        email: 'x.glover@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Spring API', 'C# (.NET) Core', 'JavaScript React'],
        name: 'Xavier Glover',
        tags: ['EUR'],
        team: 'GC & AML',
        trustScore: 151,
        userStatus: 'enabled',
    },
    {
        email: 'g.berge@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Gunnar Berge',
        tags: ['APAC'],
        team: 'GC & AML',
        trustScore: 262,
        userStatus: 'enabled',
    },
    {
        email: 'j.effertz@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Android SDK', 'Terraform Basic'],
        name: 'Jewell Effertz',
        tags: ['AMER'],
        team: 'Wealth Management',
        trustScore: 227,
        userStatus: 'enabled',
    },
    {
        email: 'a.bogan@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['PHP Basic', 'Terraform Basic', 'Java Spring API', 'C# (.NET) Core'],
        name: 'Art Bogan',
        tags: ['APAC'],
        team: 'Corporate Banking',
        trustScore: 233,
        userStatus: 'enabled',
    },
    {
        email: 'm.dare@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript Node.js (Express)'],
        name: 'Merritt Dare',
        tags: ['APAC'],
        team: 'Risk Management',
        trustScore: 253,
        userStatus: 'enabled',
    },
    {
        email: 'a.toy@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Typescript Basic', 'PHP Basic', 'JavaScript React', 'C# (.NET) Core'],
        name: 'Angela Toy',
        tags: ['APAC'],
        team: 'Corporate Banking',
        trustScore: 274,
        userStatus: 'enabled',
    },
    {
        email: 'l.bogisich@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript Node.js (Express)'],
        name: 'Lane Bogisich',
        tags: ['AMER'],
        team: 'Risk Management',
        trustScore: 219,
        userStatus: 'enabled',
    },
    {
        email: 'a.kovacek@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Typescript Basic'],
        name: 'Arthur Kovacek',
        tags: ['AMER'],
        team: 'Payment Cards',
        trustScore: 219,
        userStatus: 'enabled',
    },
    {
        email: 'v.mertz@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['C# (.NET) Core'],
        name: 'Viola Mertz',
        tags: ['AMER'],
        team: 'Consumer Products',
        trustScore: 201,
        userStatus: 'enabled',
    },
    {
        email: 'j.stiedemann@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript React', 'PHP Basic', 'Java Spring API', 'JavaScript Node.js (Express)', 'Java Android SDK'],
        name: 'Jewel Stiedemann',
        tags: ['AMER'],
        team: 'Consumer Products',
        trustScore: 267,
        userStatus: 'enabled',
    },
    {
        email: 'j.simonis@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['C# (.NET) Core', 'PHP Basic', 'Java Android SDK'],
        name: 'Jennie Simonis',
        tags: ['EUR'],
        team: 'Underwriting',
        trustScore: 300,
        userStatus: 'enabled',
    },
    {
        email: 'c.hane@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['JavaScript React', 'Java Spring API', 'Terraform Basic', 'C++ Embedded'],
        name: 'Carroll Hane',
        tags: ['APAC'],
        team: 'GC & AML',
        trustScore: 189,
        userStatus: 'enabled',
    },
    {
        email: 'j.hansen@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Android SDK', 'PHP Basic', 'JavaScript Node.js (Express)', 'JavaScript React', 'C# (.NET) Core'],
        name: 'Jennyfer Hansen',
        tags: ['AMER'],
        team: 'GC & AML',
        trustScore: 250,
        userStatus: 'enabled',
    },
    {
        email: 'a.quitzon@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['PHP Basic', 'C# (.NET) Core', 'Java Spring API', 'Terraform Basic', 'Typescript Basic'],
        name: 'Anne Quitzon',
        tags: ['APAC'],
        team: 'Consumer Products',
        trustScore: 88,
        userStatus: 'enabled',
    },
    {
        email: 'd.volkman@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [
            'C# (.NET) Core',
            'Typescript Basic',
            'JavaScript Node.js (Express)',
            'Java Android SDK',
            'Terraform Basic',
        ],
        name: 'Dylan Volkman',
        tags: ['EUR'],
        team: 'Underwriting',
        trustScore: 205,
        userStatus: 'enabled',
    },
    {
        email: 'g.pollich@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['Java Android SDK', 'PHP Basic', 'Java Spring API', 'C++ Embedded', 'Typescript Basic'],
        name: 'Gerson Pollich',
        tags: ['APAC'],
        team: 'GC & AML',
        trustScore: 254,
        userStatus: 'enabled',
    },
    {
        email: 'a.wilkinson@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['Java Spring API', 'Terraform Basic', 'C# (.NET) Core', 'C++ Embedded'],
        name: 'Annie Wilkinson',
        tags: ['AMER'],
        team: 'Payment Cards',
        trustScore: 245,
        userStatus: 'enabled',
    },
    {
        email: 'k.wyman@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['C++ Embedded'],
        name: 'Kirstin Wyman',
        tags: ['EUR'],
        team: 'Wealth Management',
        trustScore: 247,
        userStatus: 'enabled',
    },
    {
        email: 'k.haag@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['Java Spring API', 'C++ Embedded', 'C# (.NET) Core', 'Java Android SDK'],
        name: 'Kamren Haag',
        tags: ['AMER'],
        team: 'Underwriting',
        trustScore: 196,
        userStatus: 'enabled',
    },
    {
        email: 's.fritsch@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['C++ Embedded', 'JavaScript React'],
        name: 'Selina Fritsch',
        tags: ['EUR'],
        team: 'Risk Management',
        trustScore: 255,
        userStatus: 'enabled',
    },
    {
        email: 'c.hermann@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript Node.js (Express)'],
        name: 'Cale Hermann',
        tags: ['AMER'],
        team: 'Payment Cards',
        trustScore: 245,
        userStatus: 'enabled',
    },
    {
        email: 'e.brekke@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Edgardo Brekke',
        tags: ['APAC'],
        team: 'GC & AML',
        trustScore: 163,
        userStatus: 'enabled',
    },
    {
        email: 'l.feest@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['PHP Basic', 'Java Spring API', 'Typescript Basic', 'C++ Embedded'],
        name: 'Letha Feest',
        tags: ['AMER'],
        team: 'GC & AML',
        trustScore: 207,
        userStatus: 'enabled',
    },
    {
        email: 'l.schuppe@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript React'],
        name: 'Liliana Schuppe',
        tags: ['APAC'],
        team: 'Corporate Banking',
        trustScore: 178,
        userStatus: 'enabled',
    },
    {
        email: 'j.mills@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript React', 'JavaScript Node.js (Express)', 'PHP Basic', 'C# (.NET) Core'],
        name: 'Joshua Mills',
        tags: ['AMER'],
        team: 'Corporate Banking',
        trustScore: 216,
        userStatus: 'enabled',
    },
    {
        email: 'p.von@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Polly Von',
        tags: ['EUR'],
        team: 'Wealth Management',
        trustScore: 217,
        userStatus: 'enabled',
    },
    {
        email: 'h.turner@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Terraform Basic', 'C# (.NET) Core'],
        name: 'Heloise Turner',
        tags: ['APAC'],
        team: 'Corporate Banking',
        trustScore: 268,
        userStatus: 'enabled',
    },
    {
        email: 'c.sporer@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Terraform Basic'],
        name: 'Cooper Sporer',
        tags: ['APAC'],
        team: 'Wealth Management',
        trustScore: 209,
        userStatus: 'enabled',
    },
    {
        email: 'a.stiedemann@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [
            'JavaScript React',
            'JavaScript Node.js (Express)',
            'Java Android SDK',
            'C# (.NET) Core',
            'Typescript Basic',
        ],
        name: 'Arnulfo Stiedemann',
        tags: ['EUR'],
        team: 'Wealth Management',
        trustScore: 198,
        userStatus: 'enabled',
    },
    {
        email: 'g.cormier@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['C++ Embedded', 'C# (.NET) Core'],
        name: 'Gayle Cormier',
        tags: ['AMER'],
        team: 'Payment Cards',
        trustScore: 248,
        userStatus: 'enabled',
    },
    {
        email: 'c.cartwright@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['Terraform Basic', 'PHP Basic', 'C++ Embedded'],
        name: 'Ciara Cartwright',
        tags: ['AMER'],
        team: 'Underwriting',
        trustScore: 187,
        userStatus: 'enabled',
    },
    {
        email: 'p.schuppe@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [
            'C# (.NET) Core',
            'Terraform Basic',
            'Java Android SDK',
            'Java Spring API',
            'JavaScript Node.js (Express)',
        ],
        name: 'Pierce Schuppe',
        tags: ['EUR'],
        team: 'Underwriting',
        trustScore: 273,
        userStatus: 'enabled',
    },
    {
        email: 'r.roberts@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Typescript Basic', 'C# (.NET) Core', 'PHP Basic', 'JavaScript Node.js (Express)', 'JavaScript React'],
        name: 'Raina Roberts',
        tags: ['APAC'],
        team: 'Payment Cards',
        trustScore: 263,
        userStatus: 'enabled',
    },
    {
        email: 'd.emard@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['PHP Basic'],
        name: 'Daisha Emard',
        tags: ['AMER'],
        team: 'GC & AML',
        trustScore: 256,
        userStatus: 'enabled',
    },
    {
        email: 'l.zulauf@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['PHP Basic', 'C++ Embedded', 'Typescript Basic', 'JavaScript React'],
        name: 'Leif Zulauf',
        tags: ['APAC'],
        team: 'GC & AML',
        trustScore: 269,
        userStatus: 'enabled',
    },
    {
        email: 'm.gerlach@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [
            'Java Spring API',
            'Typescript Basic',
            'Terraform Basic',
            'Java Android SDK',
            'JavaScript Node.js (Express)',
        ],
        name: 'Madelynn Gerlach',
        tags: ['AMER'],
        team: 'Consumer Products',
        trustScore: 297,
        userStatus: 'enabled',
    },
    {
        email: 'c.boyer@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Terraform Basic'],
        name: 'Courtney Boyer',
        tags: ['APAC'],
        team: 'GC & AML',
        trustScore: 196,
        userStatus: 'enabled',
    },
    {
        email: 'r.marvin@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: [
            'Terraform Basic',
            'Java Android SDK',
            'JavaScript Node.js (Express)',
            'C++ Embedded',
            'Java Spring API',
        ],
        name: 'Roy Marvin',
        tags: ['APAC'],
        team: 'Payment Cards',
        trustScore: 210,
        userStatus: 'enabled',
    },
    {
        email: 'd.grady@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Android SDK', 'JavaScript React', 'Typescript Basic', 'Terraform Basic'],
        name: 'Daniela Grady',
        tags: ['APAC'],
        team: 'Payment Cards',
        trustScore: 194,
        userStatus: 'enabled',
    },
    {
        email: 'u.braun@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Android SDK', 'Terraform Basic', 'JavaScript React', 'PHP Basic'],
        name: 'Urban Braun',
        tags: ['APAC'],
        team: 'Corporate Banking',
        trustScore: 204,
        userStatus: 'enabled',
    },
    {
        email: 'j.haley@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Jannie Haley',
        tags: ['EUR'],
        team: 'Underwriting',
        trustScore: 266,
        userStatus: 'enabled',
    },
    {
        email: 'c.mohr@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Typescript Basic', 'Java Android SDK', 'Terraform Basic', 'C# (.NET) Core'],
        name: 'Corine Mohr',
        tags: ['APAC'],
        team: 'Payment Cards',
        trustScore: 241,
        userStatus: 'enabled',
    },
    {
        email: 'j.fadel@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['C# (.NET) Core', 'Java Spring API'],
        name: 'Josie Fadel',
        tags: ['APAC'],
        team: 'Payment Cards',
        trustScore: 351,
        userStatus: 'enabled',
    },
    {
        email: 'y.nitzsche@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Yasmin Nitzsche',
        tags: ['AMER'],
        team: 'GC & AML',
        trustScore: 252,
        userStatus: 'enabled',
    },
    {
        email: 'c.rath@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Carolina Rath',
        tags: ['APAC'],
        team: 'Consumer Products',
        trustScore: 263,
        userStatus: 'enabled',
    },
    {
        email: 'j.bahringer@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Terraform Basic', 'C# (.NET) Core', 'Java Android SDK', 'PHP Basic'],
        name: 'Jovany Bahringer',
        tags: ['APAC'],
        team: 'Wealth Management',
        trustScore: 222,
        userStatus: 'enabled',
    },
    {
        email: 'k.ferry@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Typescript Basic', 'Terraform Basic'],
        name: 'Kameron Ferry',
        tags: ['AMER'],
        team: 'Wealth Management',
        trustScore: 263,
        userStatus: 'enabled',
    },
    {
        email: 'e.hudson@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Typescript Basic', 'Java Spring API', 'JavaScript Node.js (Express)'],
        name: 'Everett Hudson',
        tags: ['APAC'],
        team: 'Underwriting',
        trustScore: 203,
        userStatus: 'enabled',
    },
    {
        email: 'j.stroman@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['Terraform Basic', 'PHP Basic', 'C++ Embedded'],
        name: 'Joseph Stroman',
        tags: ['AMER'],
        team: 'Consumer Products',
        trustScore: 190,
        userStatus: 'enabled',
    },
    {
        email: 'd.ward@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Spring API', 'PHP Basic', 'Java Android SDK', 'Typescript Basic'],
        name: 'Dominic Ward',
        tags: ['APAC'],
        team: 'Corporate Banking',
        trustScore: 254,
        userStatus: 'enabled',
    },
    {
        email: 'j.shields@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Spring API', 'PHP Basic', 'Terraform Basic', 'C# (.NET) Core'],
        name: 'Jessie Shields',
        tags: ['EUR'],
        team: 'Payment Cards',
        trustScore: 236,
        userStatus: 'enabled',
    },
    {
        email: 't.spencer@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript Node.js (Express)', 'Java Spring API'],
        name: 'Terry Spencer',
        tags: ['AMER'],
        team: 'Payment Cards',
        trustScore: 282,
        userStatus: 'enabled',
    },
    {
        email: 'r.kunze@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Android SDK', 'Java Spring API', 'JavaScript React'],
        name: 'Rocky Kunze',
        tags: ['AMER'],
        team: 'GC & AML',
        trustScore: 251,
        userStatus: 'enabled',
    },
    {
        email: 'b.lang-koepp@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Brooklyn Lang-Koepp',
        tags: ['APAC'],
        team: 'Wealth Management',
        trustScore: 291,
        userStatus: 'enabled',
    },
    {
        email: 'a.dare@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['C++ Embedded', 'Java Android SDK', 'JavaScript Node.js (Express)', 'Java Spring API'],
        name: 'Aurelia Dare',
        tags: ['APAC'],
        team: 'Underwriting',
        trustScore: 135,
        userStatus: 'enabled',
    },
    {
        email: 'k.raynor@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['Java Spring API', 'Terraform Basic', 'C++ Embedded'],
        name: 'Kristopher Raynor',
        tags: ['APAC'],
        team: 'Payment Cards',
        trustScore: 186,
        userStatus: 'enabled',
    },
    {
        email: 'r.dicki@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript Node.js (Express)', 'Java Spring API', 'Terraform Basic', 'C# (.NET) Core', 'PHP Basic'],
        name: 'Rosa Dicki',
        tags: ['APAC'],
        team: 'Payment Cards',
        trustScore: 233,
        userStatus: 'enabled',
    },
    {
        email: 'l.kshlerin@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['C++ Embedded', 'JavaScript Node.js (Express)', 'C# (.NET) Core'],
        name: 'Lydia Kshlerin',
        tags: ['AMER'],
        team: 'Underwriting',
        trustScore: 224,
        userStatus: 'enabled',
    },
    {
        email: 'r.anderson@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Randall Anderson',
        tags: ['APAC'],
        team: 'Risk Management',
        trustScore: 327,
        userStatus: 'enabled',
    },
    {
        email: 'l.weimann@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['C++ Embedded', 'PHP Basic', 'JavaScript React', 'Terraform Basic'],
        name: 'Louvenia Weimann',
        tags: ['AMER'],
        team: 'Wealth Management',
        trustScore: 231,
        userStatus: 'enabled',
    },
    {
        email: 'r.heaney@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Roosevelt Heaney',
        tags: ['APAC'],
        team: 'Corporate Banking',
        trustScore: 184,
        userStatus: 'enabled',
    },
    {
        email: 'c.collins@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['PHP Basic', 'JavaScript Node.js (Express)', 'JavaScript React', 'Java Spring API'],
        name: 'Candice Collins',
        tags: ['EUR'],
        team: 'Payment Cards',
        trustScore: 192,
        userStatus: 'enabled',
    },
    {
        email: 'r.fisher@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Typescript Basic'],
        name: 'Robin Fisher',
        tags: ['APAC'],
        team: 'Payment Cards',
        trustScore: 264,
        userStatus: 'enabled',
    },
    {
        email: 'k.rutherford@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['PHP Basic'],
        name: 'Kevin Rutherford',
        tags: ['EUR'],
        team: 'Underwriting',
        trustScore: 201,
        userStatus: 'enabled',
    },
    {
        email: 'n.barrows@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Nicola Barrows',
        tags: ['APAC'],
        team: 'GC & AML',
        trustScore: 252,
        userStatus: 'enabled',
    },
    {
        email: 'd.shields@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['Java Android SDK', 'Java Spring API', 'C++ Embedded', 'C# (.NET) Core', 'Terraform Basic'],
        name: 'Deven Shields',
        tags: ['AMER'],
        team: 'Payment Cards',
        trustScore: 188,
        userStatus: 'enabled',
    },
    {
        email: 'j.bruen@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Terraform Basic', 'Typescript Basic', 'JavaScript Node.js (Express)', 'JavaScript React'],
        name: 'Jaycee Bruen',
        tags: ['APAC'],
        team: 'Corporate Banking',
        trustScore: 230,
        userStatus: 'enabled',
    },
    {
        email: 'r.mcclure@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Raoul McClure',
        tags: ['EUR'],
        team: 'GC & AML',
        trustScore: 190,
        userStatus: 'enabled',
    },
    {
        email: 'l.trantow@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['Terraform Basic', 'C++ Embedded', 'Java Android SDK', 'JavaScript React'],
        name: 'Loma Trantow',
        tags: ['APAC'],
        team: 'Consumer Products',
        trustScore: 200,
        userStatus: 'enabled',
    },
    {
        email: 'b.padberg@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript React'],
        name: 'Billie Padberg',
        tags: ['AMER'],
        team: 'Payment Cards',
        trustScore: 169,
        userStatus: 'enabled',
    },
    {
        email: 'm.klocko@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['C++ Embedded', 'Java Android SDK'],
        name: 'Mylene Klocko',
        tags: ['AMER'],
        team: 'Consumer Products',
        trustScore: 165,
        userStatus: 'enabled',
    },
    {
        email: 'c.buckridge@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Android SDK', 'PHP Basic', 'Java Spring API', 'JavaScript React', 'Terraform Basic'],
        name: 'Callie Buckridge',
        tags: ['EUR'],
        team: 'GC & AML',
        trustScore: 187,
        userStatus: 'enabled',
    },
    {
        email: 'y.ortiz@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript React', 'JavaScript Node.js (Express)', 'Terraform Basic'],
        name: 'Yolanda Ortiz',
        tags: ['EUR'],
        team: 'Underwriting',
        trustScore: 226,
        userStatus: 'enabled',
    },
    {
        email: 'c.schultz-witting@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['PHP Basic', 'C# (.NET) Core', 'Typescript Basic', 'JavaScript Node.js (Express)', 'Java Spring API'],
        name: 'Coty Schultz-Witting',
        tags: ['AMER'],
        team: 'GC & AML',
        trustScore: 246,
        userStatus: 'enabled',
    },
    {
        email: 'a.braun@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['JavaScript React', 'Java Spring API', 'Typescript Basic', 'Java Android SDK', 'C++ Embedded'],
        name: 'Antonia Braun',
        tags: ['EUR'],
        team: 'Corporate Banking',
        trustScore: 287,
        userStatus: 'enabled',
    },
    {
        email: 'l.upton@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['Typescript Basic', 'C++ Embedded'],
        name: 'Lincoln Upton',
        tags: ['EUR'],
        team: 'Underwriting',
        trustScore: 192,
        userStatus: 'enabled',
    },
    {
        email: 'a.fay@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['PHP Basic'],
        name: 'Aric Fay',
        tags: ['EUR'],
        team: 'GC & AML',
        trustScore: 252,
        userStatus: 'enabled',
    },
    {
        email: 'a.fay@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Android SDK', 'JavaScript React', 'Typescript Basic', 'Terraform Basic', 'PHP Basic'],
        name: 'Alex Fay',
        tags: ['APAC'],
        team: 'Payment Cards',
        trustScore: 258,
        userStatus: 'enabled',
    },
    {
        email: 'm.stehr@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Spring API', 'PHP Basic', 'Terraform Basic', 'JavaScript Node.js (Express)'],
        name: 'Maynard Stehr',
        tags: ['APAC'],
        team: 'Payment Cards',
        trustScore: 139,
        userStatus: 'enabled',
    },
    {
        email: 's.kirlin@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Stephanie Kirlin',
        tags: ['APAC'],
        team: 'Corporate Banking',
        trustScore: 237,
        userStatus: 'enabled',
    },
    {
        email: 'w.swaniawski@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Watson Swaniawski',
        tags: ['AMER'],
        team: 'Payment Cards',
        trustScore: 169,
        userStatus: 'enabled',
    },
    {
        email: 'h.wiegand@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Herman Wiegand',
        tags: ['APAC'],
        team: 'Risk Management',
        trustScore: 165,
        userStatus: 'enabled',
    },
    {
        email: 'j.glover@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Jarred Glover',
        tags: ['APAC'],
        team: 'Consumer Products',
        trustScore: 259,
        userStatus: 'enabled',
    },
    {
        email: 'm.conroy@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Terraform Basic', 'JavaScript Node.js (Express)'],
        name: 'Mylene Conroy',
        tags: ['AMER'],
        team: 'Wealth Management',
        trustScore: 181,
        userStatus: 'enabled',
    },
    {
        email: "m.o'kon-witting@securecodewarrior.com",
        jobDescription: 'Full Stack',
        languages: ['Terraform Basic', 'Typescript Basic', 'PHP Basic', 'Java Spring API'],
        name: "Mathew O'Kon-Witting",
        tags: ['EUR'],
        team: 'Payment Cards',
        trustScore: 104,
        userStatus: 'enabled',
    },
    {
        email: 'a.herzog@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['C++ Embedded', 'JavaScript React', 'Typescript Basic'],
        name: 'Aylin Herzog',
        tags: ['EUR'],
        team: 'Underwriting',
        trustScore: 283,
        userStatus: 'enabled',
    },
    {
        email: 'j.little@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Terraform Basic', 'JavaScript Node.js (Express)'],
        name: 'Jakayla Little',
        tags: ['AMER'],
        team: 'Corporate Banking',
        trustScore: 172,
        userStatus: 'enabled',
    },
    {
        email: 'r.wolf@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Android SDK', 'PHP Basic', 'JavaScript Node.js (Express)'],
        name: 'Rene Wolf',
        tags: ['APAC'],
        team: 'Consumer Products',
        trustScore: 195,
        userStatus: 'enabled',
    },
    {
        email: 'r.predovic@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['PHP Basic', 'Terraform Basic'],
        name: 'Roxane Predovic',
        tags: ['EUR'],
        team: 'Risk Management',
        trustScore: 241,
        userStatus: 'enabled',
    },
    {
        email: 'a.legros@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Terraform Basic'],
        name: 'Aurelia Legros',
        tags: ['APAC'],
        team: 'Wealth Management',
        trustScore: 231,
        userStatus: 'enabled',
    },
    {
        email: 'm.goodwin@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Typescript Basic'],
        name: 'Marcellus Goodwin',
        tags: ['AMER'],
        team: 'Risk Management',
        trustScore: 267,
        userStatus: 'enabled',
    },
    {
        email: 'b.jacobson@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript React'],
        name: 'Betsy Jacobson',
        tags: ['EUR'],
        team: 'Underwriting',
        trustScore: 197,
        userStatus: 'enabled',
    },
    {
        email: 'w.jacobson@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Spring API', 'JavaScript React'],
        name: 'Wellington Jacobson',
        tags: ['EUR'],
        team: 'Consumer Products',
        trustScore: 288,
        userStatus: 'enabled',
    },
    {
        email: 'a.pacocha@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Albertha Pacocha',
        tags: ['AMER'],
        team: 'Payment Cards',
        trustScore: 157,
        userStatus: 'enabled',
    },
    {
        email: 'd.hegmann@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['PHP Basic', 'Typescript Basic', 'C++ Embedded', 'Java Spring API', 'JavaScript React'],
        name: 'Darien Hegmann',
        tags: ['APAC'],
        team: 'Consumer Products',
        trustScore: 196,
        userStatus: 'enabled',
    },
    {
        email: 'k.bauch@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['C++ Embedded', 'C# (.NET) Core', 'Typescript Basic', 'PHP Basic', 'Java Spring API'],
        name: 'Keanu Bauch',
        tags: ['AMER'],
        team: 'Consumer Products',
        trustScore: 173,
        userStatus: 'enabled',
    },
    {
        email: 'b.pouros@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Terraform Basic', 'Typescript Basic'],
        name: 'Brycen Pouros',
        tags: ['EUR'],
        team: 'Underwriting',
        trustScore: 287,
        userStatus: 'enabled',
    },
    {
        email: 's.gutmann@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Shana Gutmann',
        tags: ['AMER'],
        team: 'Underwriting',
        trustScore: 250,
        userStatus: 'enabled',
    },
    {
        email: 'o.mccullough@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Android SDK', 'PHP Basic'],
        name: 'Oceane McCullough',
        tags: ['EUR'],
        team: 'Risk Management',
        trustScore: 246,
        userStatus: 'enabled',
    },
    {
        email: 'r.stokes@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Reta Stokes',
        tags: ['EUR'],
        team: 'GC & AML',
        trustScore: 204,
        userStatus: 'enabled',
    },
    {
        email: 'r.bartell@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['PHP Basic', 'C# (.NET) Core', 'JavaScript Node.js (Express)'],
        name: 'Rosina Bartell',
        tags: ['EUR'],
        team: 'Consumer Products',
        trustScore: 241,
        userStatus: 'enabled',
    },
    {
        email: 't.franey@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript React', 'Terraform Basic'],
        name: 'Taurean Franey',
        tags: ['AMER'],
        team: 'Corporate Banking',
        trustScore: 221,
        userStatus: 'enabled',
    },
    {
        email: 'n.toy@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript Node.js (Express)', 'C# (.NET) Core', 'PHP Basic', 'Java Android SDK'],
        name: 'Nova Toy',
        tags: ['APAC'],
        team: 'Risk Management',
        trustScore: 228,
        userStatus: 'enabled',
    },
    {
        email: 'j.larson@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['C# (.NET) Core', 'JavaScript React'],
        name: 'Janet Larson',
        tags: ['APAC'],
        team: 'Payment Cards',
        trustScore: 261,
        userStatus: 'enabled',
    },
    {
        email: 'n.batz@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Nathaniel Batz',
        tags: ['APAC'],
        team: 'Payment Cards',
        trustScore: 208,
        userStatus: 'enabled',
    },
    {
        email: 's.toy-lind@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['C++ Embedded', 'JavaScript Node.js (Express)', 'JavaScript React', 'PHP Basic', 'Java Spring API'],
        name: 'Sabina Toy-Lind',
        tags: ['EUR'],
        team: 'Wealth Management',
        trustScore: 262,
        userStatus: 'enabled',
    },
    {
        email: 'l.vonrueden@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Terraform Basic', 'JavaScript React', 'Java Android SDK'],
        name: 'Liam VonRueden',
        tags: ['AMER'],
        team: 'GC & AML',
        trustScore: 258,
        userStatus: 'enabled',
    },
    {
        email: 'a.hilll@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['PHP Basic', 'C++ Embedded', 'Java Android SDK'],
        name: 'Armand Hilll',
        tags: ['APAC'],
        team: 'Wealth Management',
        trustScore: 198,
        userStatus: 'enabled',
    },
    {
        email: 'd.armstrong@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['C# (.NET) Core', 'JavaScript React', 'JavaScript Node.js (Express)'],
        name: 'Della Armstrong',
        tags: ['AMER'],
        team: 'Underwriting',
        trustScore: 219,
        userStatus: 'enabled',
    },
    {
        email: 'l.bogisich@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Lucienne Bogisich',
        tags: ['AMER'],
        team: 'Corporate Banking',
        trustScore: 245,
        userStatus: 'enabled',
    },
    {
        email: 'u.muller@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Urban Muller',
        tags: ['AMER'],
        team: 'Risk Management',
        trustScore: 253,
        userStatus: 'enabled',
    },
    {
        email: 'r.fadel@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Reina Fadel',
        tags: ['AMER'],
        team: 'Consumer Products',
        trustScore: 226,
        userStatus: 'enabled',
    },
    {
        email: 'n.bernhard@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Spring API', 'JavaScript Node.js (Express)', 'Typescript Basic'],
        name: 'Nicholaus Bernhard',
        tags: ['APAC'],
        team: 'Consumer Products',
        trustScore: 237,
        userStatus: 'enabled',
    },
    {
        email: 'k.lowe@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Android SDK', 'PHP Basic', 'C# (.NET) Core'],
        name: 'Kayla Lowe',
        tags: ['APAC'],
        team: 'Payment Cards',
        trustScore: 249,
        userStatus: 'enabled',
    },
    {
        email: 'a.marks@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Albert Marks',
        tags: ['AMER'],
        team: 'Wealth Management',
        trustScore: 168,
        userStatus: 'enabled',
    },
    {
        email: 'r.kunze-conn@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['C# (.NET) Core', 'JavaScript React', 'Java Android SDK', 'C++ Embedded'],
        name: 'Rowena Kunze-Conn',
        tags: ['APAC'],
        team: 'Wealth Management',
        trustScore: 229,
        userStatus: 'enabled',
    },
    {
        email: 'a.rempel@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Terraform Basic', 'Typescript Basic', 'Java Android SDK'],
        name: 'Alba Rempel',
        tags: ['EUR'],
        team: 'Payment Cards',
        trustScore: 229,
        userStatus: 'enabled',
    },
    {
        email: 'r.kessler@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['C++ Embedded', 'JavaScript Node.js (Express)'],
        name: 'Reyna Kessler',
        tags: ['AMER'],
        team: 'Consumer Products',
        trustScore: 225,
        userStatus: 'enabled',
    },
    {
        email: 'c.murray@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Android SDK', 'Java Spring API'],
        name: 'Christian Murray',
        tags: ['EUR'],
        team: 'GC & AML',
        trustScore: 221,
        userStatus: 'enabled',
    },
    {
        email: 'e.jacobs@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['C# (.NET) Core', 'PHP Basic', 'C++ Embedded'],
        name: 'Eleazar Jacobs',
        tags: ['APAC'],
        team: 'Corporate Banking',
        trustScore: 205,
        userStatus: 'enabled',
    },
    {
        email: 'j.tillman@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['C++ Embedded', 'C# (.NET) Core'],
        name: 'Julian Tillman',
        tags: ['AMER'],
        team: 'Wealth Management',
        trustScore: 242,
        userStatus: 'enabled',
    },
    {
        email: 'e.kertzmann@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['Java Android SDK', 'JavaScript Node.js (Express)', 'PHP Basic', 'C++ Embedded', 'Java Spring API'],
        name: 'Elizabeth Kertzmann',
        tags: ['AMER'],
        team: 'Risk Management',
        trustScore: 208,
        userStatus: 'enabled',
    },
    {
        email: 't.wilderman@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [
            'JavaScript Node.js (Express)',
            'Typescript Basic',
            'C# (.NET) Core',
            'Java Android SDK',
            'JavaScript React',
        ],
        name: 'Thora Wilderman',
        tags: ['EUR'],
        team: 'Wealth Management',
        trustScore: 256,
        userStatus: 'enabled',
    },
    {
        email: 'm.mills@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Spring API', 'PHP Basic', 'Java Android SDK', 'Typescript Basic', 'JavaScript React'],
        name: 'Mitchel Mills',
        tags: ['APAC'],
        team: 'Wealth Management',
        trustScore: 226,
        userStatus: 'enabled',
    },
    {
        email: 'a.boehm@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Android SDK'],
        name: 'Alexandra Boehm',
        tags: ['AMER'],
        team: 'Consumer Products',
        trustScore: 155,
        userStatus: 'enabled',
    },
    {
        email: 'm.hills@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [
            'C# (.NET) Core',
            'JavaScript Node.js (Express)',
            'Java Spring API',
            'Java Android SDK',
            'Typescript Basic',
        ],
        name: 'Mack Hills',
        tags: ['APAC'],
        team: 'Payment Cards',
        trustScore: 268,
        userStatus: 'enabled',
    },
    {
        email: 'a.wuckert@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Terraform Basic'],
        name: 'Alva Wuckert',
        tags: ['EUR'],
        team: 'Risk Management',
        trustScore: 197,
        userStatus: 'enabled',
    },
    {
        email: 'j.halvorson-kerluke@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['C++ Embedded', 'C# (.NET) Core', 'JavaScript React', 'Java Android SDK'],
        name: 'Jonas Halvorson-Kerluke',
        tags: ['EUR'],
        team: 'GC & AML',
        trustScore: 316,
        userStatus: 'enabled',
    },
    {
        email: 'r.abshire@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Spring API', 'Java Android SDK', 'JavaScript React'],
        name: 'Romaine Abshire',
        tags: ['APAC'],
        team: 'Corporate Banking',
        trustScore: 247,
        userStatus: 'enabled',
    },
    {
        email: 'a.schneider@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Typescript Basic'],
        name: 'Annabel Schneider',
        tags: ['APAC'],
        team: 'Corporate Banking',
        trustScore: 243,
        userStatus: 'enabled',
    },
    {
        email: 'd.hettinger@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['Terraform Basic', 'C++ Embedded', 'C# (.NET) Core'],
        name: 'Dina Hettinger',
        tags: ['AMER'],
        team: 'Underwriting',
        trustScore: 251,
        userStatus: 'enabled',
    },
    {
        email: 's.schulist@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript Node.js (Express)', 'C# (.NET) Core', 'Java Android SDK', 'PHP Basic'],
        name: 'Stefan Schulist',
        tags: ['EUR'],
        team: 'Payment Cards',
        trustScore: 195,
        userStatus: 'enabled',
    },
    {
        email: 'm.franecki@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['Java Android SDK', 'Typescript Basic', 'C++ Embedded', 'C# (.NET) Core', 'Java Spring API'],
        name: 'Markus Franecki',
        tags: ['EUR'],
        team: 'Payment Cards',
        trustScore: 240,
        userStatus: 'enabled',
    },
    {
        email: 'd.greenholt@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['C++ Embedded', 'JavaScript Node.js (Express)', 'JavaScript React'],
        name: 'Dominique Greenholt',
        tags: ['EUR'],
        team: 'GC & AML',
        trustScore: 205,
        userStatus: 'enabled',
    },
    {
        email: 'a.halvorson@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Alejandra Halvorson',
        tags: ['EUR'],
        team: 'Underwriting',
        trustScore: 265,
        userStatus: 'enabled',
    },
    {
        email: 'a.cummings@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript React', 'JavaScript Node.js (Express)'],
        name: 'Aaron Cummings',
        tags: ['EUR'],
        team: 'Underwriting',
        trustScore: 254,
        userStatus: 'enabled',
    },
    {
        email: 'g.kunde@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['JavaScript React', 'C++ Embedded'],
        name: 'Garnet Kunde',
        tags: ['APAC'],
        team: 'GC & AML',
        trustScore: 198,
        userStatus: 'enabled',
    },
    {
        email: 'c.aufderhar@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript React', 'Typescript Basic', 'JavaScript Node.js (Express)'],
        name: 'Clark Aufderhar',
        tags: ['EUR'],
        team: 'GC & AML',
        trustScore: 137,
        userStatus: 'enabled',
    },
    {
        email: 'j.stiedemann@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Spring API', 'Terraform Basic', 'C# (.NET) Core'],
        name: 'Jaydon Stiedemann',
        tags: ['APAC'],
        team: 'Underwriting',
        trustScore: 201,
        userStatus: 'enabled',
    },
    {
        email: 'j.gleason@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: [
            'Terraform Basic',
            'JavaScript Node.js (Express)',
            'C++ Embedded',
            'Java Spring API',
            'JavaScript React',
        ],
        name: 'Julian Gleason',
        tags: ['AMER'],
        team: 'GC & AML',
        trustScore: 263,
        userStatus: 'enabled',
    },
    {
        email: 'd.balistreri@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['Typescript Basic', 'Terraform Basic', 'PHP Basic', 'C++ Embedded'],
        name: 'Demarcus Balistreri',
        tags: ['EUR'],
        team: 'GC & AML',
        trustScore: 176,
        userStatus: 'enabled',
    },
    {
        email: 'b.block@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['Java Android SDK', 'C++ Embedded', 'C# (.NET) Core', 'Typescript Basic', 'Terraform Basic'],
        name: 'Benjamin Block',
        tags: ['AMER'],
        team: 'Wealth Management',
        trustScore: 194,
        userStatus: 'enabled',
    },
    {
        email: 'l.thiel@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Typescript Basic'],
        name: 'Lucy Thiel',
        tags: ['AMER'],
        team: 'Consumer Products',
        trustScore: 253,
        userStatus: 'enabled',
    },
    {
        email: 'm.marquardt-larkin@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript React', 'Java Android SDK'],
        name: 'Mariam Marquardt-Larkin',
        tags: ['AMER'],
        team: 'Wealth Management',
        trustScore: 274,
        userStatus: 'enabled',
    },
    {
        email: 'r.legros@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Typescript Basic', 'PHP Basic', 'Terraform Basic'],
        name: 'Roel Legros',
        tags: ['AMER'],
        team: 'Wealth Management',
        trustScore: 202,
        userStatus: 'enabled',
    },
    {
        email: 'c.smith@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['C++ Embedded', 'Java Spring API', 'Terraform Basic', 'JavaScript Node.js (Express)'],
        name: 'Claudine Smith',
        tags: ['APAC'],
        team: 'Payment Cards',
        trustScore: 202,
        userStatus: 'enabled',
    },
    {
        email: 'r.wiza@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['C# (.NET) Core', 'C++ Embedded', 'JavaScript React'],
        name: 'Ronaldo Wiza',
        tags: ['EUR'],
        team: 'Underwriting',
        trustScore: 167,
        userStatus: 'enabled',
    },
    {
        email: 'j.daugherty@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['Typescript Basic', 'C++ Embedded', 'C# (.NET) Core', 'PHP Basic'],
        name: 'Jasmin Daugherty',
        tags: ['AMER'],
        team: 'Wealth Management',
        trustScore: 191,
        userStatus: 'enabled',
    },
    {
        email: 't.heathcote@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['C++ Embedded', 'JavaScript React', 'Terraform Basic', 'PHP Basic', 'Java Android SDK'],
        name: 'Tabitha Heathcote',
        tags: ['EUR'],
        team: 'Consumer Products',
        trustScore: 225,
        userStatus: 'enabled',
    },
    {
        email: 'i.kerluke@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript React'],
        name: 'Ila Kerluke',
        tags: ['AMER'],
        team: 'Corporate Banking',
        trustScore: 273,
        userStatus: 'enabled',
    },
    {
        email: 'r.johnston@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['C# (.NET) Core', 'Java Spring API'],
        name: 'Ronaldo Johnston',
        tags: ['EUR'],
        team: 'Risk Management',
        trustScore: 248,
        userStatus: 'enabled',
    },
    {
        email: 'c.osinski@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Terraform Basic', 'Java Spring API', 'JavaScript Node.js (Express)'],
        name: 'Casandra Osinski',
        tags: ['AMER'],
        team: 'GC & AML',
        trustScore: 302,
        userStatus: 'enabled',
    },
    {
        email: 'm.bogan@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Spring API', 'JavaScript Node.js (Express)', 'Java Android SDK'],
        name: 'Myron Bogan',
        tags: ['APAC'],
        team: 'Risk Management',
        trustScore: 241,
        userStatus: 'enabled',
    },
    {
        email: 'c.romaguera@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Celia Romaguera',
        tags: ['AMER'],
        team: 'Payment Cards',
        trustScore: 221,
        userStatus: 'enabled',
    },
    {
        email: 'k.ledner@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Kennedy Ledner',
        tags: ['EUR'],
        team: 'Payment Cards',
        trustScore: 236,
        userStatus: 'enabled',
    },
    {
        email: 't.watsica@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['C++ Embedded'],
        name: 'Terence Watsica',
        tags: ['APAC'],
        team: 'Payment Cards',
        trustScore: 209,
        userStatus: 'enabled',
    },
    {
        email: 'e.mcclure@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['JavaScript React', 'PHP Basic', 'Terraform Basic', 'C++ Embedded'],
        name: 'Emelia McClure',
        tags: ['EUR'],
        team: 'Consumer Products',
        trustScore: 210,
        userStatus: 'enabled',
    },
    {
        email: 'l.mertz@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['C# (.NET) Core', 'Typescript Basic'],
        name: 'Lauryn Mertz',
        tags: ['AMER'],
        team: 'Consumer Products',
        trustScore: 235,
        userStatus: 'enabled',
    },
    {
        email: 'c.robel@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['C# (.NET) Core', 'PHP Basic'],
        name: 'Carlo Robel',
        tags: ['AMER'],
        team: 'GC & AML',
        trustScore: 265,
        userStatus: 'enabled',
    },
    {
        email: 'j.beer@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Jaylin Beer',
        tags: ['APAC'],
        team: 'Payment Cards',
        trustScore: 238,
        userStatus: 'enabled',
    },
    {
        email: 'c.russel@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['PHP Basic', 'Terraform Basic', 'Typescript Basic', 'Java Spring API', 'C# (.NET) Core'],
        name: 'Carmelo Russel',
        tags: ['EUR'],
        team: 'GC & AML',
        trustScore: 284,
        userStatus: 'enabled',
    },
    {
        email: 'p.russel@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['C++ Embedded', 'Terraform Basic', 'C# (.NET) Core', 'Typescript Basic'],
        name: 'Petra Russel',
        tags: ['APAC'],
        team: 'Underwriting',
        trustScore: 189,
        userStatus: 'enabled',
    },
    {
        email: 'r.fadel@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['Terraform Basic', 'C++ Embedded'],
        name: 'Rodolfo Fadel',
        tags: ['EUR'],
        team: 'Consumer Products',
        trustScore: 180,
        userStatus: 'enabled',
    },
    {
        email: 'o.leannon@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript React', 'PHP Basic', 'Terraform Basic', 'Java Android SDK', 'JavaScript Node.js (Express)'],
        name: 'Otho Leannon',
        tags: ['EUR'],
        team: 'Wealth Management',
        trustScore: 170,
        userStatus: 'enabled',
    },
    {
        email: 'l.rau@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['PHP Basic', 'Java Spring API', 'C++ Embedded', 'Java Android SDK'],
        name: 'Linnie Rau',
        tags: ['APAC'],
        team: 'Risk Management',
        trustScore: 216,
        userStatus: 'enabled',
    },
    {
        email: 't.hartmann@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Android SDK', 'JavaScript React'],
        name: 'Tyshawn Hartmann',
        tags: ['AMER'],
        team: 'Wealth Management',
        trustScore: 194,
        userStatus: 'enabled',
    },
    {
        email: 'a.ritchie@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript Node.js (Express)', 'C# (.NET) Core', 'Java Spring API', 'Typescript Basic'],
        name: 'Alva Ritchie',
        tags: ['APAC'],
        team: 'Payment Cards',
        trustScore: 186,
        userStatus: 'enabled',
    },
    {
        email: 'a.kerluke@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['C++ Embedded'],
        name: 'Adele Kerluke',
        tags: ['APAC'],
        team: 'Underwriting',
        trustScore: 218,
        userStatus: 'enabled',
    },
    {
        email: 'a.farrell-fahey@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['PHP Basic', 'C++ Embedded'],
        name: 'Anibal Farrell-Fahey',
        tags: ['AMER'],
        team: 'Wealth Management',
        trustScore: 259,
        userStatus: 'enabled',
    },
    {
        email: 'a.reilly@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Alyce Reilly',
        tags: ['APAC'],
        team: 'Corporate Banking',
        trustScore: 238,
        userStatus: 'enabled',
    },
    {
        email: 'k.bartell@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['C++ Embedded', 'Terraform Basic', 'PHP Basic', 'C# (.NET) Core', 'Java Android SDK'],
        name: 'Kiana Bartell',
        tags: ['APAC'],
        team: 'Consumer Products',
        trustScore: 242,
        userStatus: 'enabled',
    },
    {
        email: 'h.considine@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Terraform Basic', 'JavaScript Node.js (Express)'],
        name: 'Hyman Considine',
        tags: ['AMER'],
        team: 'Corporate Banking',
        trustScore: 374,
        userStatus: 'enabled',
    },
    {
        email: 'e.koss@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Typescript Basic', 'C# (.NET) Core', 'JavaScript React'],
        name: 'Esperanza Koss',
        tags: ['AMER'],
        team: 'Risk Management',
        trustScore: 179,
        userStatus: 'enabled',
    },
    {
        email: 'n.mann-brakus@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['Java Android SDK', 'C++ Embedded'],
        name: 'Ned Mann-Brakus',
        tags: ['EUR'],
        team: 'Corporate Banking',
        trustScore: 278,
        userStatus: 'enabled',
    },
    {
        email: 'j.aufderhar-goodwin@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Android SDK', 'JavaScript React', 'JavaScript Node.js (Express)', 'C# (.NET) Core'],
        name: 'Jermaine Aufderhar-Goodwin',
        tags: ['EUR'],
        team: 'Wealth Management',
        trustScore: 200,
        userStatus: 'enabled',
    },
    {
        email: 's.quitzon@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Sadie Quitzon',
        tags: ['AMER'],
        team: 'Payment Cards',
        trustScore: 193,
        userStatus: 'enabled',
    },
    {
        email: 'c.hoppe@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['Typescript Basic', 'JavaScript React', 'C++ Embedded', 'JavaScript Node.js (Express)'],
        name: 'Carley Hoppe',
        tags: ['AMER'],
        team: 'Underwriting',
        trustScore: 203,
        userStatus: 'enabled',
    },
    {
        email: 'k.stehr@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['JavaScript React', 'Typescript Basic', 'C++ Embedded'],
        name: 'Kasandra Stehr',
        tags: ['AMER'],
        team: 'Corporate Banking',
        trustScore: 224,
        userStatus: 'enabled',
    },
    {
        email: 'd.abernathy@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['C# (.NET) Core', 'Java Android SDK', 'Typescript Basic', 'Terraform Basic', 'PHP Basic'],
        name: 'Dessie Abernathy',
        tags: ['AMER'],
        team: 'Wealth Management',
        trustScore: 241,
        userStatus: 'enabled',
    },
    {
        email: 'a.huel@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript React', 'C# (.NET) Core', 'Java Spring API'],
        name: 'Adeline Huel',
        tags: ['EUR'],
        team: 'Risk Management',
        trustScore: 152,
        userStatus: 'enabled',
    },
    {
        email: 't.donnelly@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [
            'JavaScript Node.js (Express)',
            'JavaScript React',
            'Java Spring API',
            'Terraform Basic',
            'C# (.NET) Core',
        ],
        name: 'Ted Donnelly',
        tags: ['AMER'],
        team: 'Payment Cards',
        trustScore: 206,
        userStatus: 'enabled',
    },
    {
        email: "m.o'connell@securecodewarrior.com",
        jobDescription: 'Full Stack',
        languages: [],
        name: "Meda O'Connell",
        tags: ['AMER'],
        team: 'Risk Management',
        trustScore: 249,
        userStatus: 'enabled',
    },
    {
        email: 'd.huels@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: [
            'JavaScript Node.js (Express)',
            'Java Android SDK',
            'C++ Embedded',
            'C# (.NET) Core',
            'Terraform Basic',
        ],
        name: 'Delores Huels',
        tags: ['AMER'],
        team: 'Payment Cards',
        trustScore: 286,
        userStatus: 'enabled',
    },
    {
        email: 'e.paucek@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Edgar Paucek',
        tags: ['APAC'],
        team: 'Underwriting',
        trustScore: 226,
        userStatus: 'enabled',
    },
    {
        email: 'l.runte@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Lottie Runte',
        tags: ['APAC'],
        team: 'Payment Cards',
        trustScore: 253,
        userStatus: 'enabled',
    },
    {
        email: 'r.cassin@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Android SDK', 'JavaScript Node.js (Express)', 'JavaScript React', 'C# (.NET) Core', 'PHP Basic'],
        name: 'Rick Cassin',
        tags: ['EUR'],
        team: 'Corporate Banking',
        trustScore: 191,
        userStatus: 'enabled',
    },
    {
        email: 'f.connelly@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['C++ Embedded', 'Terraform Basic', 'C# (.NET) Core', 'PHP Basic', 'Typescript Basic'],
        name: 'Freda Connelly',
        tags: ['EUR'],
        team: 'GC & AML',
        trustScore: 276,
        userStatus: 'enabled',
    },
    {
        email: 'o.gibson@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Orlo Gibson',
        tags: ['EUR'],
        team: 'Risk Management',
        trustScore: 290,
        userStatus: 'enabled',
    },
    {
        email: 'g.gleichner@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript React'],
        name: 'Gilberto Gleichner',
        tags: ['AMER'],
        team: 'Corporate Banking',
        trustScore: 256,
        userStatus: 'enabled',
    },
    {
        email: 'g.little@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Android SDK', 'Java Spring API', 'JavaScript Node.js (Express)'],
        name: 'Gaetano Little',
        tags: ['APAC'],
        team: 'Consumer Products',
        trustScore: 255,
        userStatus: 'enabled',
    },
    {
        email: 's.breitenberg@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Typescript Basic'],
        name: 'Saul Breitenberg',
        tags: ['AMER'],
        team: 'GC & AML',
        trustScore: 172,
        userStatus: 'enabled',
    },
    {
        email: 's.kling@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['PHP Basic', 'Typescript Basic'],
        name: 'Stefan Kling',
        tags: ['EUR'],
        team: 'Corporate Banking',
        trustScore: 286,
        userStatus: 'enabled',
    },
    {
        email: 'r.predovic@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['C# (.NET) Core', 'JavaScript React', 'PHP Basic'],
        name: 'Rosie Predovic',
        tags: ['APAC'],
        team: 'Wealth Management',
        trustScore: 242,
        userStatus: 'enabled',
    },
    {
        email: 'b.bartell@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Beatrice Bartell',
        tags: ['APAC'],
        team: 'Wealth Management',
        trustScore: 168,
        userStatus: 'enabled',
    },
    {
        email: 'g.hauck@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['PHP Basic', 'Java Spring API', 'C++ Embedded'],
        name: 'Genoveva Hauck',
        tags: ['APAC'],
        team: 'Payment Cards',
        trustScore: 247,
        userStatus: 'enabled',
    },
    {
        email: 'a.koss@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['C# (.NET) Core', 'Java Spring API', 'Typescript Basic', 'PHP Basic'],
        name: 'Amanda Koss',
        tags: ['AMER'],
        team: 'Wealth Management',
        trustScore: 168,
        userStatus: 'enabled',
    },
    {
        email: 'm.franey@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['C++ Embedded'],
        name: 'Manley Franey',
        tags: ['EUR'],
        team: 'GC & AML',
        trustScore: 161,
        userStatus: 'enabled',
    },
    {
        email: 'j.jast@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['Typescript Basic', 'C++ Embedded', 'Terraform Basic', 'Java Spring API', 'PHP Basic'],
        name: 'Jermaine Jast',
        tags: ['APAC'],
        team: 'Payment Cards',
        trustScore: 200,
        userStatus: 'enabled',
    },
    {
        email: 'j.gerhold@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['PHP Basic'],
        name: 'Jodie Gerhold',
        tags: ['APAC'],
        team: 'Corporate Banking',
        trustScore: 270,
        userStatus: 'enabled',
    },
    {
        email: 'a.schulist@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Terraform Basic', 'C# (.NET) Core', 'JavaScript Node.js (Express)'],
        name: 'Arnaldo Schulist',
        tags: ['EUR'],
        team: 'Corporate Banking',
        trustScore: 220,
        userStatus: 'enabled',
    },
    {
        email: 'l.becker@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript Node.js (Express)'],
        name: 'Lexi Becker',
        tags: ['EUR'],
        team: 'Risk Management',
        trustScore: 165,
        userStatus: 'enabled',
    },
    {
        email: 'l.stark@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['PHP Basic', 'Terraform Basic', 'Java Spring API', 'Typescript Basic', 'C# (.NET) Core'],
        name: 'Lea Stark',
        tags: ['AMER'],
        team: 'GC & AML',
        trustScore: 259,
        userStatus: 'enabled',
    },
    {
        email: 'l.roob@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['PHP Basic'],
        name: 'Lura Roob',
        tags: ['APAC'],
        team: 'Wealth Management',
        trustScore: 140,
        userStatus: 'enabled',
    },
    {
        email: 'v.spencer@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Vernice Spencer',
        tags: ['AMER'],
        team: 'Wealth Management',
        trustScore: 230,
        userStatus: 'enabled',
    },
    {
        email: 'a.langosh@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Terraform Basic', 'JavaScript Node.js (Express)', 'PHP Basic'],
        name: 'Adelia Langosh',
        tags: ['EUR'],
        team: 'Underwriting',
        trustScore: 219,
        userStatus: 'enabled',
    },
    {
        email: 'a.rippin@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['Java Spring API', 'JavaScript Node.js (Express)', 'PHP Basic', 'C++ Embedded'],
        name: 'Arnaldo Rippin',
        tags: ['AMER'],
        team: 'Wealth Management',
        trustScore: 295,
        userStatus: 'enabled',
    },
    {
        email: 'n.huel@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['C++ Embedded'],
        name: 'Nola Huel',
        tags: ['APAC'],
        team: 'Corporate Banking',
        trustScore: 188,
        userStatus: 'enabled',
    },
    {
        email: 'c.schmitt@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Claire Schmitt',
        tags: ['EUR'],
        team: 'Risk Management',
        trustScore: 252,
        userStatus: 'enabled',
    },
    {
        email: 'b.rohan@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['C++ Embedded', 'JavaScript Node.js (Express)'],
        name: 'Barrett Rohan',
        tags: ['EUR'],
        team: 'Risk Management',
        trustScore: 248,
        userStatus: 'enabled',
    },
    {
        email: 's.rosenbaum@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['PHP Basic', 'C# (.NET) Core', 'C++ Embedded', 'Typescript Basic', 'JavaScript React'],
        name: 'Shirley Rosenbaum',
        tags: ['EUR'],
        team: 'Payment Cards',
        trustScore: 202,
        userStatus: 'enabled',
    },
    {
        email: 'a.mante@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Terraform Basic'],
        name: 'Assunta Mante',
        tags: ['EUR'],
        team: 'Consumer Products',
        trustScore: 231,
        userStatus: 'enabled',
    },
    {
        email: 'r.hilpert@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: [
            'C# (.NET) Core',
            'Typescript Basic',
            'C++ Embedded',
            'Terraform Basic',
            'JavaScript Node.js (Express)',
        ],
        name: 'Ramiro Hilpert',
        tags: ['APAC'],
        team: 'Consumer Products',
        trustScore: 280,
        userStatus: 'enabled',
    },
    {
        email: 'c.welch@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['C++ Embedded'],
        name: 'Courtney Welch',
        tags: ['APAC'],
        team: 'Underwriting',
        trustScore: 213,
        userStatus: 'enabled',
    },
    {
        email: 'r.kulas@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Spring API'],
        name: 'River Kulas',
        tags: ['APAC'],
        team: 'Corporate Banking',
        trustScore: 271,
        userStatus: 'enabled',
    },
    {
        email: 'd.maggio@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Android SDK'],
        name: 'Damian Maggio',
        tags: ['AMER'],
        team: 'Payment Cards',
        trustScore: 283,
        userStatus: 'enabled',
    },
    {
        email: 'a.walker@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['PHP Basic'],
        name: 'Adolphus Walker',
        tags: ['APAC'],
        team: 'Corporate Banking',
        trustScore: 195,
        userStatus: 'enabled',
    },
    {
        email: 'j.windler@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript React', 'JavaScript Node.js (Express)', 'Java Android SDK', 'Java Spring API', 'PHP Basic'],
        name: 'Jessy Windler',
        tags: ['EUR'],
        team: 'Underwriting',
        trustScore: 219,
        userStatus: 'enabled',
    },
    {
        email: 'c.hansen@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['C++ Embedded', 'JavaScript React', 'Java Android SDK', 'Java Spring API', 'PHP Basic'],
        name: 'Claudine Hansen',
        tags: ['EUR'],
        team: 'Wealth Management',
        trustScore: 212,
        userStatus: 'enabled',
    },
    {
        email: 'r.kilback@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Terraform Basic', 'PHP Basic'],
        name: 'Rebekah Kilback',
        tags: ['EUR'],
        team: 'Underwriting',
        trustScore: 301,
        userStatus: 'enabled',
    },
    {
        email: 'k.walsh@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['C# (.NET) Core', 'C++ Embedded'],
        name: 'Kendall Walsh',
        tags: ['EUR'],
        team: 'Risk Management',
        trustScore: 201,
        userStatus: 'enabled',
    },
    {
        email: 'c.miller@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript React', 'PHP Basic', 'JavaScript Node.js (Express)', 'Typescript Basic'],
        name: 'Chesley Miller',
        tags: ['APAC'],
        team: 'Underwriting',
        trustScore: 206,
        userStatus: 'enabled',
    },
    {
        email: 'e.zulauf@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['PHP Basic', 'Java Android SDK', 'JavaScript React', 'C++ Embedded', 'Typescript Basic'],
        name: 'Eden Zulauf',
        tags: ['APAC'],
        team: 'Payment Cards',
        trustScore: 182,
        userStatus: 'enabled',
    },
    {
        email: 'w.purdy@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['C++ Embedded', 'Terraform Basic', 'JavaScript Node.js (Express)'],
        name: 'Wilbert Purdy',
        tags: ['APAC'],
        team: 'Risk Management',
        trustScore: 302,
        userStatus: 'enabled',
    },
    {
        email: 'a.wisoky@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['JavaScript React', 'PHP Basic', 'C++ Embedded'],
        name: 'Alden Wisoky',
        tags: ['APAC'],
        team: 'Corporate Banking',
        trustScore: 175,
        userStatus: 'enabled',
    },
    {
        email: 's.langworth@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Terraform Basic', 'PHP Basic', 'C# (.NET) Core'],
        name: 'Stanton Langworth',
        tags: ['AMER'],
        team: 'GC & AML',
        trustScore: 192,
        userStatus: 'enabled',
    },
    {
        email: 'j.fritsch@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Android SDK'],
        name: 'Johnathon Fritsch',
        tags: ['AMER'],
        team: 'Consumer Products',
        trustScore: 274,
        userStatus: 'enabled',
    },
    {
        email: 'm.kerluke@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['Terraform Basic', 'Java Android SDK', 'C++ Embedded', 'JavaScript React'],
        name: 'Marian Kerluke',
        tags: ['EUR'],
        team: 'Consumer Products',
        trustScore: 268,
        userStatus: 'enabled',
    },
    {
        email: 'j.walter@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Android SDK', 'Terraform Basic'],
        name: 'Julien Walter',
        tags: ['EUR'],
        team: 'Consumer Products',
        trustScore: 173,
        userStatus: 'enabled',
    },
    {
        email: 'm.hilll@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['JavaScript React', 'C++ Embedded'],
        name: 'Mattie Hilll',
        tags: ['APAC'],
        team: 'Corporate Banking',
        trustScore: 289,
        userStatus: 'enabled',
    },
    {
        email: 'e.gislason@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Spring API', 'Java Android SDK', 'PHP Basic'],
        name: 'Eddie Gislason',
        tags: ['AMER'],
        team: 'Consumer Products',
        trustScore: 241,
        userStatus: 'enabled',
    },
    {
        email: 'h.walker@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['C# (.NET) Core', 'Terraform Basic'],
        name: 'Hertha Walker',
        tags: ['APAC'],
        team: 'GC & AML',
        trustScore: 320,
        userStatus: 'enabled',
    },
    {
        email: 'a.schmitt@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Android SDK', 'Typescript Basic'],
        name: 'Alexanne Schmitt',
        tags: ['EUR'],
        team: 'GC & AML',
        trustScore: 126,
        userStatus: 'enabled',
    },
    {
        email: 'n.prohaska@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Typescript Basic', 'PHP Basic', 'JavaScript React', 'Java Spring API', 'Java Android SDK'],
        name: 'Nathanial Prohaska',
        tags: ['AMER'],
        team: 'Underwriting',
        trustScore: 268,
        userStatus: 'enabled',
    },
    {
        email: 'v.harvey@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['C# (.NET) Core'],
        name: 'Vena Harvey',
        tags: ['APAC'],
        team: 'Wealth Management',
        trustScore: 182,
        userStatus: 'enabled',
    },
    {
        email: 'g.mraz@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['Typescript Basic', 'Java Android SDK', 'C++ Embedded', 'PHP Basic'],
        name: 'Geovany Mraz',
        tags: ['APAC'],
        team: 'Consumer Products',
        trustScore: 172,
        userStatus: 'enabled',
    },
    {
        email: 'p.little@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['C# (.NET) Core', 'Java Spring API', 'Terraform Basic', 'C++ Embedded', 'PHP Basic'],
        name: 'Pauline Little',
        tags: ['EUR'],
        team: 'GC & AML',
        trustScore: 160,
        userStatus: 'enabled',
    },
    {
        email: 'k.lockman@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Typescript Basic', 'PHP Basic', 'JavaScript Node.js (Express)', 'Terraform Basic', 'Java Spring API'],
        name: 'Kasey Lockman',
        tags: ['APAC'],
        team: 'Underwriting',
        trustScore: 222,
        userStatus: 'enabled',
    },
    {
        email: 'a.oberbrunner@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['PHP Basic', 'Terraform Basic', 'Java Spring API'],
        name: 'Araceli Oberbrunner',
        tags: ['AMER'],
        team: 'Consumer Products',
        trustScore: 229,
        userStatus: 'enabled',
    },
    {
        email: 'z.ernser@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript React'],
        name: 'Zora Ernser',
        tags: ['AMER'],
        team: 'Consumer Products',
        trustScore: 156,
        userStatus: 'enabled',
    },
    {
        email: 's.turner@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Stuart Turner',
        tags: ['EUR'],
        team: 'GC & AML',
        trustScore: 210,
        userStatus: 'enabled',
    },
    {
        email: 'b.ward@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['PHP Basic', 'C# (.NET) Core'],
        name: 'Baby Ward',
        tags: ['APAC'],
        team: 'GC & AML',
        trustScore: 176,
        userStatus: 'enabled',
    },
    {
        email: 'b.jacobson@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['PHP Basic', 'Typescript Basic'],
        name: 'Baylee Jacobson',
        tags: ['APAC'],
        team: 'Payment Cards',
        trustScore: 289,
        userStatus: 'enabled',
    },
    {
        email: 'r.boyer@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript React', 'JavaScript Node.js (Express)'],
        name: 'Rashawn Boyer',
        tags: ['EUR'],
        team: 'Consumer Products',
        trustScore: 192,
        userStatus: 'enabled',
    },
    {
        email: 'm.gibson@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Mohammed Gibson',
        tags: ['AMER'],
        team: 'GC & AML',
        trustScore: 209,
        userStatus: 'enabled',
    },
    {
        email: 'h.balistreri@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [
            'Typescript Basic',
            'C# (.NET) Core',
            'Java Android SDK',
            'JavaScript Node.js (Express)',
            'Java Spring API',
        ],
        name: 'Hillary Balistreri',
        tags: ['APAC'],
        team: 'Corporate Banking',
        trustScore: 144,
        userStatus: 'enabled',
    },
    {
        email: "p.o'kon@securecodewarrior.com",
        jobDescription: 'Full Stack',
        languages: ['C# (.NET) Core', 'JavaScript Node.js (Express)', 'JavaScript React'],
        name: "Percival O'Kon",
        tags: ['APAC'],
        team: 'Wealth Management',
        trustScore: 222,
        userStatus: 'enabled',
    },
    {
        email: 'a.robel@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Spring API', 'C# (.NET) Core', 'Terraform Basic'],
        name: 'Arielle Robel',
        tags: ['EUR'],
        team: 'Consumer Products',
        trustScore: 222,
        userStatus: 'enabled',
    },
    {
        email: 'a.franecki@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['C++ Embedded', 'Typescript Basic', 'C# (.NET) Core', 'PHP Basic'],
        name: 'Adrien Franecki',
        tags: ['APAC'],
        team: 'Wealth Management',
        trustScore: 285,
        userStatus: 'enabled',
    },
    {
        email: 'd.weber@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Typescript Basic', 'C# (.NET) Core', 'Java Spring API', 'JavaScript React'],
        name: 'Diamond Weber',
        tags: ['AMER'],
        team: 'Underwriting',
        trustScore: 135,
        userStatus: 'enabled',
    },
    {
        email: 'd.hodkiewicz@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript React', 'Typescript Basic', 'Java Spring API'],
        name: 'Dallin Hodkiewicz',
        tags: ['EUR'],
        team: 'Wealth Management',
        trustScore: 254,
        userStatus: 'enabled',
    },
    {
        email: 'r.champlin@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Ryleigh Champlin',
        tags: ['APAC'],
        team: 'GC & AML',
        trustScore: 201,
        userStatus: 'enabled',
    },
    {
        email: 'w.jones@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['Java Spring API', 'C++ Embedded'],
        name: 'Wayne Jones',
        tags: ['EUR'],
        team: 'Consumer Products',
        trustScore: 209,
        userStatus: 'enabled',
    },
    {
        email: 'r.witting@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Terraform Basic', 'C# (.NET) Core', 'Java Android SDK'],
        name: 'Randal Witting',
        tags: ['APAC'],
        team: 'Wealth Management',
        trustScore: 239,
        userStatus: 'enabled',
    },
    {
        email: 'l.mckenzie@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['C++ Embedded'],
        name: 'Leilani McKenzie',
        tags: ['APAC'],
        team: 'Payment Cards',
        trustScore: 204,
        userStatus: 'enabled',
    },
    {
        email: 'w.padberg-stracke@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Typescript Basic', 'JavaScript React', 'JavaScript Node.js (Express)'],
        name: 'Walton Padberg-Stracke',
        tags: ['EUR'],
        team: 'Risk Management',
        trustScore: 148,
        userStatus: 'enabled',
    },
    {
        email: 'h.nolan@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['Terraform Basic', 'Java Android SDK', 'C++ Embedded'],
        name: 'Haylie Nolan',
        tags: ['APAC'],
        team: 'Underwriting',
        trustScore: 196,
        userStatus: 'enabled',
    },
    {
        email: 's.nikolaus@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['C++ Embedded', 'Typescript Basic', 'Terraform Basic', 'JavaScript React', 'Java Spring API'],
        name: 'Shanny Nikolaus',
        tags: ['APAC'],
        team: 'Corporate Banking',
        trustScore: 209,
        userStatus: 'enabled',
    },
    {
        email: 'm.gleason@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Android SDK', 'Java Spring API', 'PHP Basic'],
        name: 'Muhammad Gleason',
        tags: ['EUR'],
        team: 'Wealth Management',
        trustScore: 166,
        userStatus: 'enabled',
    },
    {
        email: 'd.lindgren@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['C++ Embedded', 'C# (.NET) Core'],
        name: 'Dorcas Lindgren',
        tags: ['EUR'],
        team: 'Consumer Products',
        trustScore: 246,
        userStatus: 'enabled',
    },
    {
        email: 'm.gibson@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Spring API', 'Java Android SDK'],
        name: 'Maryjane Gibson',
        tags: ['APAC'],
        team: 'Underwriting',
        trustScore: 295,
        userStatus: 'enabled',
    },
    {
        email: 's.langosh@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Android SDK', 'JavaScript React', 'Terraform Basic', 'PHP Basic', 'JavaScript Node.js (Express)'],
        name: 'Santiago Langosh',
        tags: ['APAC'],
        team: 'Consumer Products',
        trustScore: 311,
        userStatus: 'enabled',
    },
    {
        email: 'n.predovic@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['C++ Embedded', 'JavaScript React'],
        name: 'Norene Predovic',
        tags: ['APAC'],
        team: 'Payment Cards',
        trustScore: 247,
        userStatus: 'enabled',
    },
    {
        email: 'h.hoeger-bailey@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Hassan Hoeger-Bailey',
        tags: ['APAC'],
        team: 'Corporate Banking',
        trustScore: 235,
        userStatus: 'enabled',
    },
    {
        email: 'l.weimann@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Typescript Basic', 'JavaScript React', 'JavaScript Node.js (Express)'],
        name: 'Lavada Weimann',
        tags: ['EUR'],
        team: 'GC & AML',
        trustScore: 262,
        userStatus: 'enabled',
    },
    {
        email: 'b.kuhn@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Brad Kuhn',
        tags: ['EUR'],
        team: 'Underwriting',
        trustScore: 253,
        userStatus: 'enabled',
    },
    {
        email: 'l.swift-hodkiewicz@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Lavon Swift-Hodkiewicz',
        tags: ['AMER'],
        team: 'Consumer Products',
        trustScore: 241,
        userStatus: 'enabled',
    },
    {
        email: 'v.stoltenberg@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [
            'Java Spring API',
            'Typescript Basic',
            'C# (.NET) Core',
            'JavaScript Node.js (Express)',
            'Java Android SDK',
        ],
        name: 'Vicente Stoltenberg',
        tags: ['AMER'],
        team: 'Payment Cards',
        trustScore: 301,
        userStatus: 'enabled',
    },
    {
        email: 'l.ryan@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Libby Ryan',
        tags: ['EUR'],
        team: 'Consumer Products',
        trustScore: 227,
        userStatus: 'enabled',
    },
    {
        email: 'e.daugherty@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: [
            'JavaScript React',
            'JavaScript Node.js (Express)',
            'Java Android SDK',
            'Java Spring API',
            'C++ Embedded',
        ],
        name: 'Emely Daugherty',
        tags: ['AMER'],
        team: 'Payment Cards',
        trustScore: 228,
        userStatus: 'enabled',
    },
    {
        email: 'a.swaniawski@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Android SDK'],
        name: 'Ara Swaniawski',
        tags: ['EUR'],
        team: 'Corporate Banking',
        trustScore: 308,
        userStatus: 'enabled',
    },
    {
        email: 'c.little@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['C++ Embedded', 'Java Spring API', 'PHP Basic'],
        name: 'Chauncey Little',
        tags: ['APAC'],
        team: 'Wealth Management',
        trustScore: 235,
        userStatus: 'enabled',
    },
    {
        email: 'h.mccullough@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Android SDK', 'Typescript Basic'],
        name: 'Henri McCullough',
        tags: ['APAC'],
        team: 'Corporate Banking',
        trustScore: 151,
        userStatus: 'enabled',
    },
    {
        email: 'e.jacobs@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Edmond Jacobs',
        tags: ['AMER'],
        team: 'Corporate Banking',
        trustScore: 232,
        userStatus: 'enabled',
    },
    {
        email: 'l.zemlak@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Android SDK', 'C# (.NET) Core', 'Typescript Basic'],
        name: 'Lorine Zemlak',
        tags: ['EUR'],
        team: 'Underwriting',
        trustScore: 233,
        userStatus: 'enabled',
    },
    {
        email: 's.ward@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Shawn Ward',
        tags: ['AMER'],
        team: 'GC & AML',
        trustScore: 251,
        userStatus: 'enabled',
    },
    {
        email: 'k.dibbert-kassulke@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript React', 'C# (.NET) Core', 'JavaScript Node.js (Express)', 'Terraform Basic', 'PHP Basic'],
        name: 'Kailey Dibbert-Kassulke',
        tags: ['AMER'],
        team: 'Consumer Products',
        trustScore: 111,
        userStatus: 'enabled',
    },
    {
        email: 'j.witting@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Typescript Basic'],
        name: 'Jon Witting',
        tags: ['APAC'],
        team: 'Payment Cards',
        trustScore: 160,
        userStatus: 'enabled',
    },
    {
        email: 'm.stroman@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['Java Spring API', 'C++ Embedded', 'PHP Basic', 'C# (.NET) Core'],
        name: 'Monserrat Stroman',
        tags: ['AMER'],
        team: 'Corporate Banking',
        trustScore: 273,
        userStatus: 'enabled',
    },
    {
        email: 't.hudson@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Android SDK', 'Java Spring API'],
        name: 'Tina Hudson',
        tags: ['APAC'],
        team: 'Risk Management',
        trustScore: 239,
        userStatus: 'enabled',
    },
    {
        email: 'd.bogisich-dare@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['JavaScript Node.js (Express)', 'C++ Embedded'],
        name: 'Dudley Bogisich-Dare',
        tags: ['APAC'],
        team: 'Risk Management',
        trustScore: 252,
        userStatus: 'enabled',
    },
    {
        email: 'j.nader@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['Typescript Basic', 'JavaScript React', 'C++ Embedded', 'PHP Basic'],
        name: 'Javier Nader',
        tags: ['AMER'],
        team: 'Wealth Management',
        trustScore: 241,
        userStatus: 'enabled',
    },
    {
        email: 'm.schuppe@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Spring API', 'JavaScript Node.js (Express)', 'Java Android SDK'],
        name: 'Madisyn Schuppe',
        tags: ['EUR'],
        team: 'Corporate Banking',
        trustScore: 221,
        userStatus: 'enabled',
    },
    {
        email: 'b.collier@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['Terraform Basic', 'PHP Basic', 'Java Android SDK', 'C++ Embedded', 'Java Spring API'],
        name: 'Benjamin Collier',
        tags: ['APAC'],
        team: 'Underwriting',
        trustScore: 295,
        userStatus: 'enabled',
    },
    {
        email: 'e.rowe@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript Node.js (Express)'],
        name: 'Evert Rowe',
        tags: ['AMER'],
        team: 'GC & AML',
        trustScore: 188,
        userStatus: 'enabled',
    },
    {
        email: 'n.glover@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['C++ Embedded', 'Java Spring API', 'JavaScript React'],
        name: 'Novella Glover',
        tags: ['APAC'],
        team: 'Wealth Management',
        trustScore: 199,
        userStatus: 'enabled',
    },
    {
        email: 'j.prosacco@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['Java Spring API', 'Typescript Basic', 'JavaScript React', 'C++ Embedded'],
        name: 'Jedediah Prosacco',
        tags: ['EUR'],
        team: 'Risk Management',
        trustScore: 226,
        userStatus: 'enabled',
    },
    {
        email: 'r.mann@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript Node.js (Express)'],
        name: 'Rollin Mann',
        tags: ['APAC'],
        team: 'Risk Management',
        trustScore: 264,
        userStatus: 'enabled',
    },
    {
        email: 'y.heidenreich@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Yessenia Heidenreich',
        tags: ['AMER'],
        team: 'GC & AML',
        trustScore: 276,
        userStatus: 'enabled',
    },
    {
        email: 'r.rowe@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript React', 'Java Spring API'],
        name: 'Roma Rowe',
        tags: ['AMER'],
        team: 'Risk Management',
        trustScore: 227,
        userStatus: 'enabled',
    },
    {
        email: 'g.windler@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Typescript Basic', 'Java Spring API'],
        name: 'Gretchen Windler',
        tags: ['APAC'],
        team: 'Corporate Banking',
        trustScore: 308,
        userStatus: 'enabled',
    },
    {
        email: 'r.ondricka@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Ralph Ondricka',
        tags: ['APAC'],
        team: 'GC & AML',
        trustScore: 260,
        userStatus: 'enabled',
    },
    {
        email: 'i.conn@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript Node.js (Express)', 'Terraform Basic'],
        name: 'Ibrahim Conn',
        tags: ['EUR'],
        team: 'Payment Cards',
        trustScore: 216,
        userStatus: 'enabled',
    },
    {
        email: 'l.kling@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Lew Kling',
        tags: ['AMER'],
        team: 'Wealth Management',
        trustScore: 152,
        userStatus: 'enabled',
    },
    {
        email: 'v.rath@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Typescript Basic', 'JavaScript Node.js (Express)', 'C# (.NET) Core'],
        name: 'Vincenza Rath',
        tags: ['AMER'],
        team: 'Wealth Management',
        trustScore: 192,
        userStatus: 'enabled',
    },
    {
        email: 'g.schmidt-lubowitz@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript Node.js (Express)', 'Typescript Basic', 'Java Spring API', 'PHP Basic'],
        name: 'Grayson Schmidt-Lubowitz',
        tags: ['APAC'],
        team: 'Underwriting',
        trustScore: 253,
        userStatus: 'enabled',
    },
    {
        email: 'j.sawayn@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['PHP Basic', 'Terraform Basic', 'Typescript Basic', 'Java Android SDK', 'Java Spring API'],
        name: 'Jorge Sawayn',
        tags: ['EUR'],
        team: 'Risk Management',
        trustScore: 262,
        userStatus: 'enabled',
    },
    {
        email: 's.metz@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript React', 'Java Spring API', 'PHP Basic', 'C# (.NET) Core', 'Terraform Basic'],
        name: 'Sophie Metz',
        tags: ['EUR'],
        team: 'Consumer Products',
        trustScore: 244,
        userStatus: 'enabled',
    },
    {
        email: 'f.abbott@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['Java Android SDK', 'C++ Embedded', 'Java Spring API'],
        name: 'Frank Abbott',
        tags: ['AMER'],
        team: 'Payment Cards',
        trustScore: 261,
        userStatus: 'enabled',
    },
    {
        email: 't.medhurst@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript Node.js (Express)'],
        name: 'Theodore Medhurst',
        tags: ['APAC'],
        team: 'Wealth Management',
        trustScore: 279,
        userStatus: 'enabled',
    },
    {
        email: 'c.schmeler@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Spring API'],
        name: 'Camryn Schmeler',
        tags: ['EUR'],
        team: 'Underwriting',
        trustScore: 285,
        userStatus: 'enabled',
    },
    {
        email: 'a.bechtelar@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Typescript Basic', 'C# (.NET) Core', 'Java Spring API', 'Java Android SDK'],
        name: 'Arjun Bechtelar',
        tags: ['EUR'],
        team: 'GC & AML',
        trustScore: 244,
        userStatus: 'enabled',
    },
    {
        email: 'c.lemke@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Typescript Basic'],
        name: 'Cletus Lemke',
        tags: ['EUR'],
        team: 'GC & AML',
        trustScore: 162,
        userStatus: 'enabled',
    },
    {
        email: 'y.romaguera@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Android SDK', 'PHP Basic'],
        name: 'Yolanda Romaguera',
        tags: ['AMER'],
        team: 'Underwriting',
        trustScore: 202,
        userStatus: 'enabled',
    },
    {
        email: 'm.ritchie@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['C# (.NET) Core', 'Typescript Basic'],
        name: 'Mylene Ritchie',
        tags: ['AMER'],
        team: 'GC & AML',
        trustScore: 244,
        userStatus: 'enabled',
    },
    {
        email: 's.schmeler@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Sylvan Schmeler',
        tags: ['AMER'],
        team: 'Risk Management',
        trustScore: 194,
        userStatus: 'enabled',
    },
    {
        email: 'b.wilkinson@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Typescript Basic', 'JavaScript React', 'C# (.NET) Core', 'Java Spring API', 'Terraform Basic'],
        name: 'Bobbie Wilkinson',
        tags: ['EUR'],
        team: 'Risk Management',
        trustScore: 160,
        userStatus: 'enabled',
    },
    {
        email: 'g.russel@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Android SDK', 'Terraform Basic', 'C# (.NET) Core', 'PHP Basic'],
        name: 'Gerald Russel',
        tags: ['AMER'],
        team: 'Corporate Banking',
        trustScore: 235,
        userStatus: 'enabled',
    },
    {
        email: 't.kuhic@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript Node.js (Express)'],
        name: 'Toby Kuhic',
        tags: ['APAC'],
        team: 'Wealth Management',
        trustScore: 211,
        userStatus: 'enabled',
    },
    {
        email: 'w.weber@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['C# (.NET) Core', 'Java Spring API'],
        name: 'Wilhelmine Weber',
        tags: ['APAC'],
        team: 'Risk Management',
        trustScore: 169,
        userStatus: 'enabled',
    },
    {
        email: 'f.kuhic@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript Node.js (Express)', 'PHP Basic', 'Typescript Basic', 'JavaScript React', 'Java Spring API'],
        name: 'Fredy Kuhic',
        tags: ['EUR'],
        team: 'Risk Management',
        trustScore: 209,
        userStatus: 'enabled',
    },
    {
        email: 'a.kulas@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['C++ Embedded', 'Terraform Basic'],
        name: 'Annamae Kulas',
        tags: ['EUR'],
        team: 'GC & AML',
        trustScore: 279,
        userStatus: 'enabled',
    },
    {
        email: 'a.jaskolski@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript React'],
        name: 'Annamarie Jaskolski',
        tags: ['AMER'],
        team: 'Risk Management',
        trustScore: 197,
        userStatus: 'enabled',
    },
    {
        email: 'k.koch@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Spring API'],
        name: 'Kacie Koch',
        tags: ['APAC'],
        team: 'Payment Cards',
        trustScore: 210,
        userStatus: 'enabled',
    },
    {
        email: 'b.walker@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [
            'C# (.NET) Core',
            'Java Spring API',
            'JavaScript Node.js (Express)',
            'JavaScript React',
            'Terraform Basic',
        ],
        name: 'Braeden Walker',
        tags: ['AMER'],
        team: 'Payment Cards',
        trustScore: 181,
        userStatus: 'enabled',
    },
    {
        email: 'e.carroll@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Android SDK', 'PHP Basic', 'JavaScript React'],
        name: 'Elise Carroll',
        tags: ['AMER'],
        team: 'GC & AML',
        trustScore: 124,
        userStatus: 'enabled',
    },
    {
        email: 'j.bergnaum@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript Node.js (Express)'],
        name: 'Judy Bergnaum',
        tags: ['APAC'],
        team: 'GC & AML',
        trustScore: 322,
        userStatus: 'enabled',
    },
    {
        email: 'c.wunsch@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Spring API'],
        name: 'Carlos Wunsch',
        tags: ['APAC'],
        team: 'Corporate Banking',
        trustScore: 221,
        userStatus: 'enabled',
    },
    {
        email: 'p.king@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['PHP Basic', 'Java Spring API', 'JavaScript Node.js (Express)'],
        name: 'Paul King',
        tags: ['EUR'],
        team: 'Underwriting',
        trustScore: 255,
        userStatus: 'enabled',
    },
    {
        email: 'i.roob@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript React', 'JavaScript Node.js (Express)', 'PHP Basic', 'Java Android SDK', 'C# (.NET) Core'],
        name: 'Ivory Roob',
        tags: ['APAC'],
        team: 'Underwriting',
        trustScore: 241,
        userStatus: 'enabled',
    },
    {
        email: 'j.yundt@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['Terraform Basic', 'C++ Embedded'],
        name: 'Jarod Yundt',
        tags: ['AMER'],
        team: 'Wealth Management',
        trustScore: 260,
        userStatus: 'enabled',
    },
    {
        email: 's.cremin@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Scottie Cremin',
        tags: ['AMER'],
        team: 'Consumer Products',
        trustScore: 220,
        userStatus: 'enabled',
    },
    {
        email: 'a.romaguera@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['JavaScript React', 'Java Spring API', 'C++ Embedded', 'C# (.NET) Core', 'Terraform Basic'],
        name: 'America Romaguera',
        tags: ['AMER'],
        team: 'Risk Management',
        trustScore: 220,
        userStatus: 'enabled',
    },
    {
        email: 'e.conroy@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['C# (.NET) Core', 'Java Android SDK', 'PHP Basic', 'Terraform Basic', 'JavaScript React'],
        name: 'Elian Conroy',
        tags: ['AMER'],
        team: 'Corporate Banking',
        trustScore: 227,
        userStatus: 'enabled',
    },
    {
        email: 'l.bashirian@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Lois Bashirian',
        tags: ['AMER'],
        team: 'Payment Cards',
        trustScore: 267,
        userStatus: 'enabled',
    },
    {
        email: 'd.toy-stoltenberg@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['Java Android SDK', 'C++ Embedded', 'Java Spring API'],
        name: 'Dwight Toy-Stoltenberg',
        tags: ['AMER'],
        team: 'Wealth Management',
        trustScore: 262,
        userStatus: 'enabled',
    },
    {
        email: 'l.fadel@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Spring API', 'Typescript Basic', 'JavaScript React'],
        name: 'Lillie Fadel',
        tags: ['APAC'],
        team: 'Payment Cards',
        trustScore: 263,
        userStatus: 'enabled',
    },
    {
        email: 'a.leuschke@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Aiden Leuschke',
        tags: ['EUR'],
        team: 'Wealth Management',
        trustScore: 323,
        userStatus: 'enabled',
    },
    {
        email: 'f.west-berge@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Francisca West-Berge',
        tags: ['EUR'],
        team: 'Payment Cards',
        trustScore: 272,
        userStatus: 'enabled',
    },
    {
        email: 'r.collier@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['C# (.NET) Core', 'Terraform Basic', 'PHP Basic', 'Java Spring API', 'JavaScript Node.js (Express)'],
        name: 'Rickie Collier',
        tags: ['AMER'],
        team: 'Underwriting',
        trustScore: 271,
        userStatus: 'enabled',
    },
    {
        email: 'a.little@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript React', 'Java Spring API', 'Terraform Basic', 'PHP Basic'],
        name: 'Adolfo Little',
        tags: ['APAC'],
        team: 'GC & AML',
        trustScore: 254,
        userStatus: 'enabled',
    },
    {
        email: 'k.gleason@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['C++ Embedded'],
        name: 'Keyon Gleason',
        tags: ['AMER'],
        team: 'Underwriting',
        trustScore: 218,
        userStatus: 'enabled',
    },
    {
        email: 'r.abshire@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['JavaScript Node.js (Express)', 'C# (.NET) Core', 'C++ Embedded'],
        name: 'Roslyn Abshire',
        tags: ['EUR'],
        team: 'GC & AML',
        trustScore: 173,
        userStatus: 'enabled',
    },
    {
        email: 'k.schuppe@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['C# (.NET) Core', 'Typescript Basic', 'Java Spring API', 'JavaScript Node.js (Express)'],
        name: 'Krystal Schuppe',
        tags: ['AMER'],
        team: 'Payment Cards',
        trustScore: 177,
        userStatus: 'enabled',
    },
    {
        email: 'l.nienow@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['JavaScript Node.js (Express)', 'C++ Embedded', 'Terraform Basic', 'C# (.NET) Core'],
        name: 'Laurine Nienow',
        tags: ['APAC'],
        team: 'Wealth Management',
        trustScore: 289,
        userStatus: 'enabled',
    },
    {
        email: 'h.pfeffer@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['Java Android SDK', 'Typescript Basic', 'C++ Embedded', 'Java Spring API'],
        name: 'Haylee Pfeffer',
        tags: ['EUR'],
        team: 'Payment Cards',
        trustScore: 228,
        userStatus: 'enabled',
    },
    {
        email: 'm.jacobson@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Spring API', 'Terraform Basic'],
        name: 'Maxine Jacobson',
        tags: ['AMER'],
        team: 'Underwriting',
        trustScore: 234,
        userStatus: 'enabled',
    },
    {
        email: 'e.crooks@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Eriberto Crooks',
        tags: ['APAC'],
        team: 'Wealth Management',
        trustScore: 164,
        userStatus: 'enabled',
    },
    {
        email: 'd.ankunding@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: [
            'JavaScript React',
            'Java Spring API',
            'C# (.NET) Core',
            'C++ Embedded',
            'JavaScript Node.js (Express)',
        ],
        name: 'Delaney Ankunding',
        tags: ['AMER'],
        team: 'Payment Cards',
        trustScore: 165,
        userStatus: 'enabled',
    },
    {
        email: 'e.kris@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['C# (.NET) Core', 'Typescript Basic', 'Terraform Basic'],
        name: 'Eileen Kris',
        tags: ['APAC'],
        team: 'Payment Cards',
        trustScore: 168,
        userStatus: 'enabled',
    },
    {
        email: 'k.runolfsson@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Android SDK', 'JavaScript React'],
        name: 'Kendall Runolfsson',
        tags: ['AMER'],
        team: 'Risk Management',
        trustScore: 196,
        userStatus: 'enabled',
    },
    {
        email: 'p.boyer@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript Node.js (Express)', 'Java Android SDK', 'C# (.NET) Core'],
        name: 'Polly Boyer',
        tags: ['APAC'],
        team: 'GC & AML',
        trustScore: 242,
        userStatus: 'enabled',
    },
    {
        email: 'l.bergstrom@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['Typescript Basic', 'C++ Embedded', 'Java Android SDK', 'Terraform Basic', 'JavaScript React'],
        name: 'Lenny Bergstrom',
        tags: ['APAC'],
        team: 'Payment Cards',
        trustScore: 250,
        userStatus: 'enabled',
    },
    {
        email: 'e.durgan@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Ezekiel Durgan',
        tags: ['EUR'],
        team: 'Risk Management',
        trustScore: 219,
        userStatus: 'enabled',
    },
    {
        email: 'l.little@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript Node.js (Express)'],
        name: 'Linnie Little',
        tags: ['AMER'],
        team: 'GC & AML',
        trustScore: 210,
        userStatus: 'enabled',
    },
    {
        email: 'c.lueilwitz@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['C# (.NET) Core', 'Typescript Basic', 'JavaScript React', 'C++ Embedded'],
        name: 'Carolyne Lueilwitz',
        tags: ['EUR'],
        team: 'GC & AML',
        trustScore: 294,
        userStatus: 'enabled',
    },
    {
        email: 'l.bergnaum@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Lamont Bergnaum',
        tags: ['AMER'],
        team: 'GC & AML',
        trustScore: 217,
        userStatus: 'enabled',
    },
    {
        email: 'a.doyle@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Adrain Doyle',
        tags: ['AMER'],
        team: 'Payment Cards',
        trustScore: 191,
        userStatus: 'enabled',
    },
    {
        email: 'a.kuvalis@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['Java Android SDK', 'Java Spring API', 'PHP Basic', 'C++ Embedded'],
        name: 'Annetta Kuvalis',
        tags: ['EUR'],
        team: 'Corporate Banking',
        trustScore: 225,
        userStatus: 'enabled',
    },
    {
        email: 'f.halvorson@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Spring API', 'PHP Basic'],
        name: 'Frida Halvorson',
        tags: ['AMER'],
        team: 'Payment Cards',
        trustScore: 203,
        userStatus: 'enabled',
    },
    {
        email: 'a.feeney@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Spring API', 'C# (.NET) Core', 'PHP Basic'],
        name: 'Alfonzo Feeney',
        tags: ['EUR'],
        team: 'GC & AML',
        trustScore: 177,
        userStatus: 'enabled',
    },
    {
        email: 'r.kilback@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Rosemarie Kilback',
        tags: ['APAC'],
        team: 'Wealth Management',
        trustScore: 229,
        userStatus: 'enabled',
    },
    {
        email: 'k.beer@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript Node.js (Express)'],
        name: 'Kathlyn Beer',
        tags: ['AMER'],
        team: 'Wealth Management',
        trustScore: 268,
        userStatus: 'enabled',
    },
    {
        email: 'g.olson@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Android SDK', 'C# (.NET) Core', 'JavaScript React'],
        name: 'Gregg Olson',
        tags: ['APAC'],
        team: 'GC & AML',
        trustScore: 243,
        userStatus: 'enabled',
    },
    {
        email: 'j.watsica@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Jacklyn Watsica',
        tags: ['AMER'],
        team: 'Underwriting',
        trustScore: 229,
        userStatus: 'enabled',
    },
    {
        email: 'e.schmeler@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Terraform Basic', 'JavaScript Node.js (Express)'],
        name: 'Eddie Schmeler',
        tags: ['EUR'],
        team: 'GC & AML',
        trustScore: 154,
        userStatus: 'enabled',
    },
    {
        email: 'a.cartwright@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['Terraform Basic', 'Typescript Basic', 'Java Spring API', 'C++ Embedded'],
        name: 'Autumn Cartwright',
        tags: ['APAC'],
        team: 'Risk Management',
        trustScore: 198,
        userStatus: 'enabled',
    },
    {
        email: 't.daugherty@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Spring API', 'JavaScript React', 'C# (.NET) Core'],
        name: 'Tamara Daugherty',
        tags: ['EUR'],
        team: 'GC & AML',
        trustScore: 0,
        userStatus: 'enabled',
    },
    {
        email: 'm.kozey@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Mabel Kozey',
        tags: ['EUR'],
        team: 'GC & AML',
        trustScore: 0,
        userStatus: 'enabled',
    },
    {
        email: 'b.aufderhar@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['Terraform Basic', 'C++ Embedded', 'Typescript Basic', 'PHP Basic'],
        name: 'Bradford Aufderhar',
        tags: ['EUR'],
        team: 'Corporate Banking',
        trustScore: 0,
        userStatus: 'enabled',
    },
    {
        email: 'e.thiel@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['C# (.NET) Core', 'Terraform Basic', 'Java Spring API'],
        name: 'Emilio Thiel',
        tags: ['EUR'],
        team: 'Payment Cards',
        trustScore: 0,
        userStatus: 'enabled',
    },
    {
        email: 'l.johns@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: [
            'JavaScript Node.js (Express)',
            'Typescript Basic',
            'Java Spring API',
            'C# (.NET) Core',
            'C++ Embedded',
        ],
        name: 'Libby Johns',
        tags: ['AMER'],
        team: 'Corporate Banking',
        trustScore: 0,
        userStatus: 'enabled',
    },
    {
        email: 'z.beier@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Typescript Basic', 'Java Android SDK'],
        name: 'Zora Beier',
        tags: ['AMER'],
        team: 'Corporate Banking',
        trustScore: 0,
        userStatus: 'enabled',
    },
    {
        email: 'j.labadie@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['JavaScript React', 'C++ Embedded'],
        name: 'Jaquelin Labadie',
        tags: ['EUR'],
        team: 'Wealth Management',
        trustScore: 0,
        userStatus: 'enabled',
    },
    {
        email: 'c.toy@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['C++ Embedded', 'JavaScript Node.js (Express)', 'JavaScript React', 'Java Android SDK'],
        name: 'Chet Toy',
        tags: ['EUR'],
        team: 'Consumer Products',
        trustScore: 0,
        userStatus: 'enabled',
    },
    {
        email: 't.lynch@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Typescript Basic'],
        name: 'Tate Lynch',
        tags: ['EUR'],
        team: 'GC & AML',
        trustScore: 0,
        userStatus: 'enabled',
    },
    {
        email: 'c.ullrich@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Android SDK', 'Terraform Basic', 'JavaScript Node.js (Express)'],
        name: 'Concepcion Ullrich',
        tags: ['APAC'],
        team: 'Underwriting',
        trustScore: 0,
        userStatus: 'enabled',
    },
    {
        email: 'h.gerlach@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript Node.js (Express)', 'C# (.NET) Core', 'PHP Basic'],
        name: 'Hunter Gerlach',
        tags: ['APAC'],
        team: 'Risk Management',
        trustScore: 0,
        userStatus: 'enabled',
    },
    {
        email: 'k.reichel@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Spring API', 'C# (.NET) Core', 'PHP Basic', 'Typescript Basic', 'Terraform Basic'],
        name: 'Keira Reichel',
        tags: ['APAC'],
        team: 'Consumer Products',
        trustScore: 0,
        userStatus: 'enabled',
    },
    {
        email: 'z.durgan@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['JavaScript React', 'Java Spring API', 'C++ Embedded'],
        name: 'Zaria Durgan',
        tags: ['EUR'],
        team: 'Risk Management',
        trustScore: 0,
        userStatus: 'enabled',
    },
    {
        email: 'm.runolfsdottir@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['Java Android SDK', 'Java Spring API', 'C++ Embedded', 'Terraform Basic', 'JavaScript React'],
        name: 'Magnus Runolfsdottir',
        tags: ['APAC'],
        team: 'Underwriting',
        trustScore: 0,
        userStatus: 'enabled',
    },
    {
        email: 'e.hintz@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Eladio Hintz',
        tags: ['APAC'],
        team: 'Payment Cards',
        trustScore: 0,
        userStatus: 'enabled',
    },
    {
        email: 'a.fahey@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Spring API', 'JavaScript Node.js (Express)'],
        name: 'Addie Fahey',
        tags: ['APAC'],
        team: 'Wealth Management',
        trustScore: 0,
        userStatus: 'enabled',
    },
    {
        email: 'a.bruen@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Arden Bruen',
        tags: ['EUR'],
        team: 'Corporate Banking',
        trustScore: 0,
        userStatus: 'enabled',
    },
    {
        email: 'j.mcclure@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Jovan McClure',
        tags: ['APAC'],
        team: 'Payment Cards',
        trustScore: 0,
        userStatus: 'enabled',
    },
    {
        email: 'm.christiansen@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript React', 'PHP Basic', 'Terraform Basic'],
        name: 'Marlin Christiansen',
        tags: ['AMER'],
        team: 'Underwriting',
        trustScore: 0,
        userStatus: 'enabled',
    },
    {
        email: 'y.hegmann@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Spring API'],
        name: 'Yadira Hegmann',
        tags: ['EUR'],
        team: 'Corporate Banking',
        trustScore: 0,
        userStatus: 'enabled',
    },
    {
        email: 'l.cremin@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Android SDK', 'C# (.NET) Core', 'JavaScript Node.js (Express)', 'PHP Basic'],
        name: 'Lucy Cremin',
        tags: ['AMER'],
        team: 'Consumer Products',
        trustScore: 0,
        userStatus: 'enabled',
    },
    {
        email: 'c.champlin@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['Terraform Basic', 'Java Spring API', 'JavaScript React', 'Java Android SDK', 'C++ Embedded'],
        name: 'Camron Champlin',
        tags: ['APAC'],
        team: 'Risk Management',
        trustScore: 0,
        userStatus: 'enabled',
    },
    {
        email: 'a.jones@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Astrid Jones',
        tags: ['EUR'],
        team: 'Corporate Banking',
        trustScore: 0,
        userStatus: 'enabled',
    },
    {
        email: 'a.smith@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript React'],
        name: 'Anahi Smith',
        tags: ['APAC'],
        team: 'Risk Management',
        trustScore: 0,
        userStatus: 'enabled',
    },
    {
        email: 'e.skiles@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript Node.js (Express)', 'Java Spring API', 'Typescript Basic', 'Java Android SDK'],
        name: 'Edgar Skiles',
        tags: ['AMER'],
        team: 'Payment Cards',
        trustScore: 0,
        userStatus: 'enabled',
    },
    {
        email: 'c.kerluke@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['Java Spring API', 'Terraform Basic', 'C++ Embedded', 'PHP Basic'],
        name: 'Carmine Kerluke',
        tags: ['EUR'],
        team: 'Consumer Products',
        trustScore: 0,
        userStatus: 'enabled',
    },
    {
        email: 'l.kessler-schulist@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Spring API'],
        name: 'Lola Kessler-Schulist',
        tags: ['AMER'],
        team: 'Payment Cards',
        trustScore: 0,
        userStatus: 'enabled',
    },
    {
        email: 'm.mayer@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['C++ Embedded', 'Java Android SDK'],
        name: 'Mittie Mayer',
        tags: ['AMER'],
        team: 'GC & AML',
        trustScore: 0,
        userStatus: 'enabled',
    },
    {
        email: 'i.beahan@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Terraform Basic', 'Typescript Basic', 'PHP Basic', 'Java Android SDK'],
        name: 'Ivah Beahan',
        tags: ['AMER'],
        team: 'Risk Management',
        trustScore: 0,
        userStatus: 'enabled',
    },
    {
        email: 'a.wisozk@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Alena Wisozk',
        tags: ['EUR'],
        team: 'GC & AML',
        trustScore: 0,
        userStatus: 'enabled',
    },
    {
        email: 'a.nikolaus@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['C# (.NET) Core'],
        name: 'Arno Nikolaus',
        tags: ['AMER'],
        team: 'Corporate Banking',
        trustScore: 0,
        userStatus: 'enabled',
    },
    {
        email: 'p.mertz@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['JavaScript Node.js (Express)', 'Java Android SDK', 'PHP Basic', 'C++ Embedded'],
        name: 'Pedro Mertz',
        tags: ['APAC'],
        team: 'Underwriting',
        trustScore: 0,
        userStatus: 'enabled',
    },
    {
        email: 'm.leannon@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Android SDK', 'Terraform Basic'],
        name: 'Maurice Leannon',
        tags: ['APAC'],
        team: 'Payment Cards',
        trustScore: 0,
        userStatus: 'enabled',
    },
    {
        email: 'v.rolfson@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Virgie Rolfson',
        tags: ['EUR'],
        team: 'GC & AML',
        trustScore: 0,
        userStatus: 'enabled',
    },
    {
        email: 'a.fay@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['PHP Basic', 'Java Android SDK', 'Terraform Basic', 'C++ Embedded', 'Java Spring API'],
        name: 'Alayna Fay',
        tags: ['AMER'],
        team: 'Risk Management',
        trustScore: 0,
        userStatus: 'enabled',
    },
    {
        email: 't.trantow@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Toy Trantow',
        tags: ['EUR'],
        team: 'Wealth Management',
        trustScore: 0,
        userStatus: 'enabled',
    },
    {
        email: 'm.sporer@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['Terraform Basic', 'C++ Embedded', 'JavaScript Node.js (Express)', 'PHP Basic', 'C# (.NET) Core'],
        name: 'Manuela Sporer',
        tags: ['AMER'],
        team: 'Risk Management',
        trustScore: 0,
        userStatus: 'enabled',
    },
    {
        email: 's.mohr@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript Node.js (Express)', 'Typescript Basic', 'Java Spring API'],
        name: 'Shirley Mohr',
        tags: ['EUR'],
        team: 'Corporate Banking',
        trustScore: 0,
        userStatus: 'enabled',
    },
    {
        email: 'a.becker@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: [],
        name: 'Aubree Becker',
        tags: ['EUR'],
        team: 'Consumer Products',
        trustScore: 0,
        userStatus: 'enabled',
    },
    {
        email: 'i.abbott@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['PHP Basic', 'Java Spring API', 'Java Android SDK', 'C++ Embedded', 'C# (.NET) Core'],
        name: 'Isaias Abbott',
        tags: ['EUR'],
        team: 'Underwriting',
        trustScore: 0,
        userStatus: 'enabled',
    },
    {
        email: 'j.lind@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Spring API', 'PHP Basic', 'Terraform Basic'],
        name: 'Jaqueline Lind',
        tags: ['APAC'],
        team: 'Risk Management',
        trustScore: 0,
        userStatus: 'enabled',
    },
    {
        email: 'd.funk-doyle@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['C# (.NET) Core', 'JavaScript React'],
        name: 'Desmond Funk-Doyle',
        tags: ['EUR'],
        team: 'Risk Management',
        trustScore: 0,
        userStatus: 'enabled',
    },
    {
        email: 'm.blanda@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['C++ Embedded', 'PHP Basic', 'Terraform Basic'],
        name: 'Mariela Blanda',
        tags: ['APAC'],
        team: 'Consumer Products',
        trustScore: 0,
        userStatus: 'enabled',
    },
    {
        email: 'l.hegmann@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['C# (.NET) Core', 'Java Spring API', 'Java Android SDK', 'C++ Embedded', 'PHP Basic'],
        name: 'Linnie Hegmann',
        tags: ['APAC'],
        team: 'Consumer Products',
        trustScore: 0,
        userStatus: 'enabled',
    },
    {
        email: 'g.hackett@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['JavaScript React', 'Java Spring API', 'JavaScript Node.js (Express)'],
        name: 'Gertrude Hackett',
        tags: ['AMER'],
        team: 'Payment Cards',
        trustScore: 0,
        userStatus: 'enabled',
    },
    {
        email: 't.schimmel@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Android SDK', 'JavaScript Node.js (Express)'],
        name: 'Trenton Schimmel',
        tags: ['EUR'],
        team: 'Payment Cards',
        trustScore: 0,
        userStatus: 'enabled',
    },
    {
        email: 'f.ryan@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['Java Android SDK', 'PHP Basic', 'JavaScript Node.js (Express)'],
        name: 'Freddy Ryan',
        tags: ['APAC'],
        team: 'Wealth Management',
        trustScore: 0,
        userStatus: 'enabled',
    },
    {
        email: 'c.williamson@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['Terraform Basic', 'C++ Embedded'],
        name: 'Carmen Williamson',
        tags: ['EUR'],
        team: 'Payment Cards',
        trustScore: 0,
        userStatus: 'enabled',
    },
    {
        email: 'm.frami@securecodewarrior.com',
        jobDescription: 'Full Stack',
        languages: ['PHP Basic'],
        name: 'Mallie Frami',
        tags: ['AMER'],
        team: 'Consumer Products',
        trustScore: 0,
        userStatus: 'enabled',
    },
    {
        email: 'j.wuckert@securecodewarrior.com',
        jobDescription: 'Embedded',
        languages: ['C++ Embedded', 'Java Android SDK', 'PHP Basic', 'JavaScript Node.js (Express)'],
        name: 'Josh Wuckert',
        tags: ['AMER'],
        team: 'Wealth Management',
        trustScore: 0,
        userStatus: 'enabled',
    },
];
