import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography, Dialog, Box, Divider, Stack, Radio } from '@mui/material';
import { DataGrid } from '@securecodewarrior/design-system-react/lib/labs';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import { CloseIconButton } from '../../../../../common/components/atoms/CloseIconButton';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
export const QuestSelectionModal = ({ open, onClose, onSave, quests, selectedQuestId }) => {
    const [localSelectedQuestId, setLocalSelectedQuestId] = useState(selectedQuestId);
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.dashboard.learningProgress' });
    const columns = [
        {
            field: 'select',
            headerName: '',
            width: 50,
            sortable: false,
            align: 'center',
            renderCell: (params) => (_jsx(Radio, { checked: params.id === localSelectedQuestId, onChange: () => setLocalSelectedQuestId(params.id.toString()), value: params.id, name: "quest-radios", inputProps: {
                    'aria-label': t('ariaLabelSelectQuest', { questName: params.row.name }),
                }, sx: {
                    padding: 0,
                } })),
        },
        {
            field: 'name',
            headerName: t('questModelHeaderName'),
            flex: 1,
        },
        {
            field: 'participantCount',
            headerName: t('questModelTableParticipant'),
            flex: 1,
            align: 'right',
            headerAlign: 'right',
        },
    ];
    const rows = quests.map((quest) => ({
        id: quest.questId,
        name: quest.name,
        participantCount: quest.participantCount,
    }));
    const onSelectionUpdate = (updatedSelectedRowIds) => {
        if (updatedSelectedRowIds.length > 0) {
            setLocalSelectedQuestId(updatedSelectedRowIds[0].toString());
        }
    };
    const handleSave = () => {
        if (localSelectedQuestId) {
            onSave(localSelectedQuestId);
        }
    };
    return (_jsx(Dialog, { open: open, onClose: onClose, maxWidth: "md", fullWidth: true, scroll: "body", sx: (theme) => ({
            '& .MuiPaper-root:not(.MuiAlert-root)': {
                border: `1px solid ${theme.palette.container.border.default}`,
                borderRadius: 3,
                maxHeight: '720px',
            },
        }), children: _jsxs(Box, { bgcolor: (theme) => theme.palette.container.fill.card1, position: "relative", sx: (theme) => ({
                display: 'flex',
                flexDirection: 'column',
                minHeight: 0,
                overflow: 'hidden',
                maxHeight: theme.typography.pxToRem(754),
                paddingTop: 7,
                paddingBottom: 8,
            }), children: [_jsx(CloseIconButton, { "aria-label": "Cancel", onClick: onClose, sx: {
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                    } }), _jsxs(Stack, { gap: 3, mb: 7, mx: 7, children: [_jsx(Typography, { variant: "h3", children: t('selectQuest') }), _jsx(Typography, { children: t('selectQuestModelDescription') })] }), _jsx(Stack, { gap: 4, marginX: 7, sx: (theme) => ({
                        flex: 1,
                        minHeight: 0,
                        marginBottom: 6,
                        '& > div': { maxHeight: theme.typography.pxToRem(454) },
                        '& input[type="checkbox"]': { marginTop: 0 },
                    }), children: _jsx(DataGrid, { variant: "default", name: "quest-selection", columnVisibilityModel: {}, dataSource: { data: rows, loading: false }, columns: columns, rowSelectionModel: localSelectedQuestId ? [localSelectedQuestId] : [], loading: false, hideFooter: true, disableChildrenFiltering: true, disableChildrenSorting: true, disableColumnMenu: true, disableColumnPinning: true, disableColumnReorder: true, disableColumnResize: true, visibleRows: 8, rowHeight: 44, columnHeaderHeight: 44, slotProps: {
                            toolbar: {
                                disableColumnSearch: true,
                                csvOptions: { disableToolbarButton: true },
                            },
                        }, sx: (theme) => ({
                            flex: 1,
                            minHeight: 0,
                            maxHeight: theme.typography.pxToRem(454),
                            overflow: 'visible',
                            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                                width: 'unset',
                                height: 'unset',
                            },
                            '& .MuiDataGrid-row': {
                                cursor: 'pointer',
                            },
                            '& .MuiDataGrid-cell': {
                                background: 'none',
                            },
                            '& .MuiDataGrid-cell:focus': {
                                outline: 'none !important',
                            },
                        }), onRowSelectionModelChange: onSelectionUpdate }) }), _jsx("div", { children: _jsx(Divider, {}) }), _jsxs(Stack, { direction: "row", justifyContent: "space-between", gap: 2, paddingX: 7, paddingTop: 7, children: [_jsx("div", { children: _jsx(Button, { variant: "outlined", onClick: onClose, children: t('questModelTableCancel') }) }), _jsx("div", { children: _jsx(Button, { variant: "contained", sx: { textWrap: 'nowrap' }, disabled: !localSelectedQuestId, onClick: handleSave, children: t('questModelTableConfirm') }) })] })] }) }));
};
