import { ZodError } from 'zod';
import { useTeamApis } from './useTeamApis';
import { useQuery, queryOptions } from '@tanstack/react-query';
export const useTeam = (teamId) => {
    const { getTeam } = useTeamApis();
    const config = queryOptions({
        queryKey: ['get-team', teamId],
        enabled: !!teamId,
        retry: (failureCount, error) => (error instanceof ZodError ? false : failureCount < 3),
        queryFn: () => getTeam(teamId),
        refetchOnMount: 'always',
    });
    const { data, isLoading, isError, error } = useQuery(config);
    return { team: data, isLoading, isError, error };
};
