import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createContext, useContext, useState } from 'react';
import { Toaster } from '../../../common/Toaster/Toaster';
import { CloseRounded, Error as MuiErrorIcon } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
const AdminErrorContext = createContext(undefined);
const ToasterMessage = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
}));
const AdminToaster = styled(Toaster)({
    top: 'calc(64px + 24px) !important',
});
export const AdminErrorProvider = ({ children }) => {
    const [error, setError] = useState({
        open: false,
        message: '',
    });
    const showError = (message) => {
        setError({
            open: true,
            message,
        });
    };
    const clearError = () => {
        setError({
            open: false,
            message: '',
        });
    };
    return (_jsxs(AdminErrorContext.Provider, { value: { showError, clearError }, children: [children, _jsx(AdminToaster, { open: error.open, anchorOrigin: { vertical: 'top', horizontal: 'center' }, onClose: clearError, autoHideDuration: 5000, message: _jsxs(ToasterMessage, { children: [_jsx(MuiErrorIcon, { fontSize: "large" }), _jsx(Typography, { children: error.message })] }), action: _jsx(IconButton, { size: "small", "aria-label": "close", color: "inherit", onClick: clearError, children: _jsx(CloseRounded, { fontSize: "large" }) }) })] }));
};
export const useAdminError = () => {
    const context = useContext(AdminErrorContext);
    if (!context) {
        throw new Error('useAdminError must be used within an AdminErrorProvider');
    }
    return context;
};
