import { courseActivityAnalytics } from '../../analytics.activities.course';
export const myQuestsCourseActivityAnalytics = (sendEvent, sessionUser, scope) => courseActivityAnalytics(scope, 'Back to My Quests Clicked', sessionUser, sendEvent);
export const myQuestsHomepageAnalytics = (sendEvent, scope) => ({
    onQuestFilterApplied: (questId) => {
        sendEvent({ event: questId ? 'Quest Filter Applied' : 'Quest Filter Cleared', scope });
    },
    onChangeTab: (to) => {
        const event = to === 'active' ? 'Change tab to Active Quests' : 'Change tab to Completed Quests';
        sendEvent({ event, scope });
    },
    onOpenCompletedQuestDetails: (quest) => {
        sendEvent({ event: 'Open Completed Quest Details', scope, quest_type: quest.type });
    },
    onEditLanguagesClicked: () => {
        sendEvent({ event: 'Edit your languages button clicked', scope });
    },
    onSelectLanguagesClicked: () => {
        sendEvent({ event: 'Select your languages button clicked', scope });
    },
    onTopicLanguageChanged: (topic, oldLanguage, newLanguage) => {
        sendEvent({ event: 'Topic Language Changed', scope, topic, oldLanguage, newLanguage });
    },
});
