import { z } from 'zod';
export const teamSchema = z.object({
    _id: z.coerce.string(),
    name: z.string(),
    status: z.enum(['enabled', 'disabled']),
    tags: z.array(z.string()).default([]),
    createdAt: z.coerce.date(),
    teamSize: z.number(),
});
export const teamListSchema = z.array(teamSchema);
