import { useMemo } from 'react';
import { createGridProps } from '../utils/createGridProps';
import { useTeams } from './useTeams';
import { gridTeams } from '../schema/team';
export const useTeamsDataGridData = () => {
    const { teams, tags, isLoading, isError, error, refetch } = useTeams();
    const gridProps = useMemo(() => createGridProps(teams.length), [teams.length]);
    return {
        teams: gridTeams.parse(teams),
        tags,
        isLoading,
        isError,
        error,
        gridProps,
        refetch,
    };
};
