import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CompletedQuestDetail } from '../components/CompletedQuestDetail';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import { Card } from '@securecodewarrior/design-system-react/lib/labs';
import { completionConfetti } from '../components/completionConfetti';
import { Box, Typography } from '@mui/material';
import { ScwLogo } from './components/ScwLogo';
import { Trans } from 'react-i18next';
import { useEffect } from 'react';
export const LmsCompletionPageView = (props) => {
    const { quest } = props;
    useEffect(() => {
        var _a;
        if ((_a = window.top) === null || _a === void 0 ? void 0 : _a.opener) {
            window.top.opener.postMessage({ id: quest.id, state: 'END' }, '*');
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    return (_jsxs(Box, { py: 12.5, sx: (theme) => ({ background: `${theme.palette.container.fill.body} ${completionConfetti}` }), children: [_jsxs(Box, { textAlign: "center", px: 10, sx: (theme) => ({ background: theme.palette.container.fill.body }), children: [_jsx(ScwLogo, { sx: (theme) => ({
                            color: theme.palette.text.soft,
                            width: theme.typography.pxToRem(56),
                            height: theme.typography.pxToRem(56),
                        }) }), _jsx(Typography, { variant: "h3", component: "h1", my: 3, children: _jsx(Trans, { i18nKey: "pages.lms.completionPage.congratulations" }) }), _jsx(Typography, { children: _jsx(Trans, { i18nKey: "pages.lms.completionPage.youHaveCompleted" }) })] }), _jsx(Box, { p: 10, children: _jsx(Card, { mx: "auto", p: "0", sx: { maxWidth: '960px' }, children: _jsx(CompletedQuestDetail, { quest: Object.assign(Object.assign({}, quest), { type: 'lms' }) }) }) }), _jsx(Box, { textAlign: "center", children: _jsx(Button, { variant: "text", onClick: props.onClickReturn, children: _jsx(Trans, { i18nKey: "pages.lms.completionPage.returnToLMS" }) }) })] }));
};
