var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState, useCallback, useEffect } from 'react';
import { useApiClient } from '../../../ApiContext';
import { useTranslation } from 'react-i18next';
export function useCommunicationPreferences({ showToaster }) {
    const { trpc, wrapErrorPromise } = useApiClient();
    const [preferences, setPreferences] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const { t } = useTranslation();
    const loadPreferences = useCallback(() => {
        setIsLoading(true);
        wrapErrorPromise(trpc.adminPreferences.getCommunicationPreferences
            .query()
            .then((resp) => {
            setPreferences({
                emailSettings: {
                    addICSAttachmentsToEmails: resp.emailSettings.addICSAttachmentsToEmails,
                },
                communicationEvents: {
                    sendAutomatedInactivityReminders: resp.communicationEvents.sendAutomatedInactivityReminders,
                    scimSendInvitationEmails: resp.communicationEvents.scimSendInvitationEmails,
                    goalEmailsEnabled: resp.communicationEvents.goalEmailsEnabled,
                },
                communicationChannels: {
                    msTeams: resp.communicationChannels.msTeams,
                },
            });
            setIsLoading(false);
        })
            .catch((e) => {
            console.error('Failed to fetch communication preferences', e);
            showToaster(t('pages.administration.communicationsCentre.error.loadCommunicationPreferences'));
            setIsLoading(false);
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        loadPreferences();
    }, [loadPreferences]);
    const savePreferences = useCallback((newPreferences) => __awaiter(this, void 0, void 0, function* () {
        if (!preferences)
            return;
        try {
            const result = yield trpc.adminPreferences.saveCommunicationPreferences.mutate(newPreferences);
            if (result.kind === 'error') {
                throw new Error(result.message);
            }
            // Update the local preferences state
            setPreferences((prev) => (Object.assign(Object.assign({}, prev), newPreferences)));
        }
        catch (e) {
            console.error('Failed to save communication preferences', e);
            showToaster(t('pages.administration.communicationsCentre.error.saveCommunicationPreferences'));
            throw e; // Re-throw the error to propagate it to the OptimisticSwitch
        }
    }), [trpc, preferences, showToaster, t]);
    return {
        preferences,
        isLoading,
        savePreferences,
        refetch: loadPreferences,
    };
}
